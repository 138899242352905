import React from 'react'
import moment from 'moment'
import * as Headers from '../tbl/headers'
//eslint-disable-next-line
import worker from 'workerize-loader!./worker'
import { isUndefined } from 'lodash'
import ls from 'local-storage'
import { getTiposCliente, monthSelect } from '../constData/constData'
import PercentageGraph from '../components/_global/PercentageGraph'
import _ from 'lodash'
import Axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { distributors } from '../constData/urls'
import { emptyCoachingResponses as emptyResponses } from '../constData/constData'

const transformFilterData = filterData => {
  const jsonDataFields = {
    armazem: '',
    tipo_prod: '',
    tipo_produto: '',
    segmento1: '',
    segmento2: '',
    status_cliente: '',
    tipo_nf: '',
    tipo_cliente: '',
    tipo_cliente_coaching: '',
    tipo_matriz_desempenho: '',
    situacao: '',
    canal: '',
    distributor_only: ''
  }

  Object.keys(jsonDataFields).forEach(field => {
    jsonDataFields[field] =
      filterData[field] !== undefined && filterData[field] !== ''
        ? filterData[field].value
        : ''
  })

  jsonDataFields.distributor_only = filterData.distribuidor.value

  return jsonDataFields
}

const loadParams = (params, cleanedData, filterData, loadedModule) => {
  let tipo_cliente = ''

  if (
    loadedModule !== 'BONovo' &&
    loadedModule !== 'BONovo2' &&
    loadedModule !== 'BOPadrao' &&
    loadedModule !== 'BOFinancas' &&
    loadedModule !== 'BOFee' &&
    loadedModule !== 'FeeResumo' &&
    loadedModule !== 'BOFee1' &&
    loadedModule !== 'FeeResumo1'
  ) {
    tipo_cliente = cleanedData.tipo_cliente
  } else {
    let values = ''
    filterData.tipo_cliente.forEach((tipo, i) => {
      values += `${i !== 0 ? ',' : ''}${tipo.value}`
    })
    tipo_cliente = values
  }

  const baseJSON = {
    data_inicial:
      loadedModule === 'BOFee1' || loadedModule === 'FeeResumo1'
        ? moment(filterData.dt_inicial).format('DD/MM/YYYY')
        : moment(filterData.data_inicial).format('DD/MM/YYYY'),
    data_final:
      loadedModule === 'BOFee1' || loadedModule === 'FeeResumo1'
        ? moment(filterData.dt_final).format('DD/MM/YYYY')
        : moment(filterData.data_final).format('DD/MM/YYYY'),
    tipo_cliente: tipo_cliente,
    cnpjs: filterData.cnpjs,
    armazem: cleanedData.armazem,
    tipo_prod: cleanedData.tipo_prod,
    ano: filterData.ano,
    tipo:
      loadedModule === 'BOCoaching'
        ? cleanedData.tipo_cliente_coaching
        : loadedModule === 'BOMatrizDesempenho'
        ? cleanedData.tipo_matriz_desempenho
        : '',
    ult_compra_de:
      filterData.ult_compra_de === ''
        ? ''
        : moment(filterData.ult_compra_de).format('DD/MM/YYYY'),
    ult_compra_ate:
      filterData.ult_compra_ate === ''
        ? ''
        : moment(filterData.ult_compra_ate).format('DD/MM/YYYY'),
    status_cliente: cleanedData.status_cliente,
    segmento1: cleanedData.segmento1,
    segmento2: cleanedData.segmento2,
    tipo_produto: cleanedData.tipo_produto,
    tipo_nf: cleanedData.tipo_nf,
    vendedor: filterData.vendedor,
    supervisor: filterData.supervisor,
    gerente: filterData.gerente,
    dt_inicial: moment(filterData.dt_inicial).format('DD/MM/YYYY'),
    dt_final: moment(filterData.dt_final).format('DD/MM/YYYY'),
    emissao_inicial: moment(filterData.emissao_inicial).format('DD/MM/YYYY'),
    emissao_final: moment(filterData.emissao_final).format('DD/MM/YYYY'),
    vencimento_inicial: moment(filterData.vencimento_inicial).format(
      'DD/MM/YYYY'
    ),
    vencimento_final: moment(filterData.vencimento_final).format('DD/MM/YYYY'),
    pagamento_inicial:
      filterData.pagamento_inicial === ''
        ? ''
        : moment(filterData.pagamento_inicial).format('DD/MM/YYYY'),
    pagamento_final:
      filterData.pagamento_final === ''
        ? ''
        : moment(filterData.pagamento_final).format('DD/MM/YYYY'),
    situacao: cleanedData.situacao,
    canal: cleanedData.canal
  }

  let returnedJSON = {}

  params.forEach(key => {
    returnedJSON[key] = baseJSON[key]
  })

  return returnedJSON
}

export const getFormattedJSON = (filterData, loadedModule, distributor) => {
  const cleanedData = transformFilterData(filterData)

  let distributorList = []
  let distributor_only

  if (
    loadedModule !== 'FeeEstoque' &&
    loadedModule !== 'BOFee1' &&
    loadedModule !== 'FeeResumo1'
  ) {
    filterData.distribuidor.forEach(dist => {
      distributorList.push({ distribuidor: dist.value })
    })
  } else {
    distributor_only = cleanedData.distributor_only
  }

  let params = []
  let json = {}

  switch (loadedModule) {
    case 'BOPadrao':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOMovInternos':
      params = ['data_inicial', 'data_final', 'armazem', 'tipo_prod']
      break
    case 'BOCoaching':
      params = ['ano', 'tipo']
      break
    case 'BOCliente':
      params = [
        'ult_compra_de',
        'ult_compra_ate',
        'status_cliente',
        'cnpjs',
        'segmento1',
        'segmento2'
      ]
      break
    case 'BOCombo':
      params = ['ult_compra_de', 'ult_compra_ate', 'cnpjs']
      break
    case 'BOProduto':
      params = ['tipo_produto']
      break
    case 'BOPedidos':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOPedidosPorSKU':
      params = ['data_inicial', 'data_final', 'tipo_cliente', 'tipo_nf']
      break
    case 'BOVisitaBPS':
      params = [
        'data_inicial',
        'data_final',
        'vendedor',
        'supervisor',
        'gerente'
      ]
      break
    case 'FeeEstoque':
      params = ['dt_inicial', 'dt_final']
      break
    case 'BONovo':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BONovo2':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOScore':
      params = ['data_inicial', 'data_final']
      break
    case 'RLEstoque':
      params = ['armazem', 'tipo_produto']
      break
    case 'BOFinancas':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOFee':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOFee1':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'FeeResumo':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'FeeResumo1':
      params = [
        'data_inicial',
        'data_final',
        'tipo_cliente',
        'cnpjs',
        'armazem',
        'tipo_prod'
      ]
      break
    case 'BOMatrizDesempenho':
      params = ['data_inicial', 'data_final', 'tipo']
      break
    case 'BOPesquisaSatisfacao':
      params = ['data_inicial', 'data_final']
      break
    case 'BOMatinalVespertina':
      params = ['data_inicial', 'data_final']
      break
    case 'BOColetas':
      params = ['data_inicial', 'data_final']
      break
    case 'BOJustificativaColeta':
      params = ['data_inicial', 'data_final']
      break
    case 'RLTitulos':
      params = [
        'emissao_inicial',
        'emissao_final',
        'vencimento_inicial',
        'vencimento_final',
        'pagamento_inicial',
        'pagamento_final',
        'situacao',
        'canal'
      ]
      break
    case 'BOSpancopCallPlan':
      params = ['data_inicial', 'data_final']
      break
    case 'BOHSSE':
      params = ['data_inicial', 'data_final']
      break
    case 'ControlPanel':
      json = {
        supervisor: ls.get('codigo_vededor'),
        anomes: moment(filterData.data_inicial).format('YYYYMM')
      }
      break
    case 'ChecklistMotoClube':
      params = ['data_inicial', 'data_final']
      break
    case 'ChecklistClubeProfissional':
      params = ['data_inicial', 'data_final']
      break
    case 'CheckListVarejo':
      params = ['data_inicial', 'data_final']
      break
    case 'OportunidadesSpancop':
      params = ['data_inicial', 'data_final']
      break
    case 'SupervisorCoachingReports':
      json = {
        distribuidor: distributor,
        supervisor: ls.get('codigo_vededor'),
        vendedor: filterData.vendedor ? filterData.vendedor.value : '',
        data_inicial: moment(filterData.data_inicial).format('DD/MM/YYYY'),
        data_fim: moment(filterData.data_final).format('DD/MM/YYYY'),
        com_respostas: 'S'
      }
      break
    default:
      json = {}
  }

  if (Object.keys(json).length === 0) {
    json = loadParams(params, cleanedData, filterData, loadedModule)
  }

  loadedModule === 'FeeEstoque' ||
  loadedModule === 'BOFee1' ||
  loadedModule === 'FeeResumo1'
    ? (json.distribuidor = distributor_only)
    : distributor
    ? (json.distribuidor = distributor)
    : (json.distribuidores = distributorList)

  // json.paginas = ''
  // json.registros = ''
  return json
}

export const logout = () => {
  if (ls.get('tipo') === 'SUPERVISOR') {
    ls.remove('codigo_vededor')
    ls.remove('user')
    ls.remove('tipo')
    ls.remove('perfis')
    ls.remove('sellers')
    ls.remove('session')
    ls.remove('loadedModule')
    window.location = '/supervisor'
  } else {
    ls.remove('user')
    ls.remove('user_id')
    ls.remove('session')
    ls.remove('perfil')
    ls.remove('api')
    ls.remove('perfis')
    ls.remove('localCustomers')
    ls.remove('codigo_supervisor')
    ls.remove('token')
    ls.remove('loadedModule')
    ls.remove('mkt_allowed')
    ls.remove('mkt_profile')
    ls.remove('mkt_segments')
    ls.remove('mkt_dfbans')
    ls.remove('codigo_vededor')
    ls.remove('tipo')
    ls.remove('sellers')
    window.location = '/'
  }
}

export const loadAPI = (loadedModule, filterData, externalUrl) => {
  const url = externalUrl ? externalUrl : ls.get('api')
  switch (loadedModule) {
    case 'BOPadrao':
      return {
        url: `${url}INT_SHELL_BO_PADRAO`
      }
    case 'BOCoaching':
      return {
        url: `${url}INT_SHELL_BO_COACHING`
      }
    case 'BOCliente':
      return {
        url: `${url}INT_SHELL_BO_CLIENTES`
      }
    case 'BOCombo':
      return {
        url: `${url}INT_SHELL_BO_COMBO`
      }
    case 'BOProduto':
      return {
        url: `${url}INT_SHELL_BO_PRODUTOS`
      }
    case 'BOPedidos':
      return {
        url: `${url}INT_SHELL_BO_PEDIDOS`
      }
    case 'BOPedidosPorSKU':
      return {
        url: `${url}INT_SHELL_BO_PEDIDOSSKU`
      }
    case 'BOVisitaBPS':
      return {
        url: `${url}INT_SHELL_BO_VISITASBPS`
      }
    case 'BONovo':
      return {
        url: `${url}INT_SHELL_BO_NOVO`
      }
    case 'BONovo2':
      return {
        url: `${url}INT_SHELL_BO_NOVOANT`
      }
    case 'BOScore':
      return {
        url: `${url}INT_SHELL_BO_CHECKLISTSCORE`
      }
    case 'RLEstoque':
      return {
        url: `${url}INT_SHELL_BO_ESTOQUE`
      }
    case 'FeeEstoque':
      return {
        url: `${url}INT_SHELL_FEE_ESTOQUE`
      }
    case 'BOFinancas':
      return {
        url: `${url}INT_SHELL_BO_FINANCAS`
      }
    case 'BOFee':
      return {
        url: `${url}INT_SHELL_BO_FEE`
      }
    case 'BOFee1':
      return {
        url: `${url}INT_SHELL_BO_FEE`
      }
    case 'FeeResumo':
      return {
        url: `${url}INT_SHELL_BO_CONSFEE`
      }
    case 'FeeResumo1':
      return {
        url: `${url}INT_SHELL_BO_CONSFEE`
      }
    case 'BOMatrizDesempenho':
      return {
        url: `${url}INT_SHELL_BO_MATRIZDESEMPENHO`
      }
    case 'BOPesquisaSatisfacao':
      return {
        url: `${url}INT_SHELL_BO_PESQUISA`
      }
    case 'BOMatinalVespertina':
      return {
        url: `${url}INT_SHELL_BO_MATINAL`
      }
    case 'BOColetas':
      return {
        url: `${url}INT_SHELL_BO_COLETA`
      }
    case 'BOJustificativaColeta':
      return {
        url: `${url}INT_SHELL_BO_JUSTIFICATIVACOLETA`
      }
    case 'RLTitulos':
      return {
        url: `${url}INT_SHELL_BO_TITULOS`
      }
    case 'BOSpancopCallPlan':
      return {
        url: `${url}INT_SHELL_BO_CALLPLAN`
      }
    case 'BOHSSE':
      return {
        url: `${url}INT_SHELL_BO_HSSE`
      }
    case 'ControlPanel':
      return {
        url: `${url}INT_SHELL_BUSCA_MATINAL`
      }
    case 'SupervisorOrderReports':
      return { url: `${url}INT_SHELL_APP_PEDIDOS` }
    case 'SupervisorRevenueReports':
      return { url: `${url}INT_SHELL_APP_PEDIDOS` }
    case 'ChecklistMotoClube':
      return {
        url: `${url}INT_SHELL_BO_CHECKLISTMOTOCLUBE`
      }
    case 'ChecklistClubeProfissional':
      return {
        url: `${url}INT_SHELL_BO_CHECKLISTCLUBE`
      }
    case 'CheckListVarejo':
      return {
        url: `${url}INT_SHELL_BO_CHECKLISTVAREJO`
      }
    case 'OportunidadesSpancop':
      return {
        url: `${url}INT_SHELL_BO_OPORTUNIDADESPANCOP`
      }
    case 'LimitePC':
      return {
        url: `${url}INT_SHELL_LIMPCS`
      }
    case 'LimitePCPeriods':
      return { url: `${url}INT_SHELL_PER_LIMPCS` }
    case 'SupervisorCoachingReports':
      return {
        url: `${url}INT_SHELL_BUSCA_COACHING`
      }
    case 'BOPromocoes':
      return {
        url: `${url}INT_SHELL_BO_PROMOCAO`
      }
    case 'BOTrocaOleo':
      return {
        url: `${url}INT_SHELL_BO_TROCAOLEO`
      }
    case 'VisitReports':
      return {
        url: `${url}INT_SHELL_APP_CONSVISITAS`
      }
    case 'BOMovInternos':
      return {
        url: `${url}INT_SHELL_BO_MOVINTERNOS`
      }
    default:
      return {}
  }
}

export const loadLabelBase = loadedModule => {
  switch (loadedModule) {
    case 'BOCliente':
      return {
        label: 'BackOffice Clientes',
        title: 'Shell - BackOffice Clientes'
      }
    case 'BOCoaching':
      return {
        label: 'BackOffice Coaching',
        title: 'Shell - BackOffice ClienCoachingtes'
      }
    case 'BOCombo':
      return { label: 'BackOffice Combo', title: 'Shell - BackOffice Combo' }
    case 'BODashboardClube':
      return {
        label: 'BackOffice Dashboard Clube',
        title: 'Shell - BackOffice Dashboard Clube'
      }
    case 'BONovo':
      return {
        label: 'BackOffice Vendas Novo',
        title: 'Shell - BackOffice Vendas Novo'
      }
    case 'BONovo2':
      return {
        label: 'BackOffice Vendas Novo 2',
        title: 'Shell - BackOffice Vendas Novo 2'
      }
    case 'BOScore':
      return {
        label: 'BackOffice Score Varejo',
        title: 'Shell - BackOffice Score Varejo'
      }
    case 'BOPadrao':
      return {
        label: 'BackOffice Vendas Padrão',
        title: 'Shell - BackOffice Vendas Padrão'
      }
    case 'BoMovInternos':
      return {
        label: 'BackOffice Movimentos Internos',
        title: 'Shell - BackOffice Movimentos Internos'
      }
    case 'BOPedidos':
      return {
        label: 'BackOffice Pedidos',
        title: 'Shell - BackOffice Pedidos'
      }
    case 'BOPedidosPorSKU':
      return {
        label: 'BackOffice Pedidos por SKU',
        title: 'Shell - BackOffice Pedidos por SKU'
      }
    case 'BOProduto':
      return {
        label: 'BackOffice Produtos',
        title: 'Shell - BackOffice Produtos'
      }
    case 'BOVisitaBPS':
      return {
        label: 'BackOffice Visita BPS',
        title: 'Shell - BackOffice Visita BPS'
      }
    case 'RLEstoque':
      return {
        label: 'Relatório Estoque',
        title: 'Shell - Relatório Estoque'
      }
    case 'RLTitulos':
      return {
        label: 'Relatório Títulos',
        title: 'Shell - Relatório Títulos'
      }
    case 'FeeEstoque':
      return {
        label: 'Relatório Fee Estoque',
        title: 'Shell - Relatório Fee Estoque'
      }
    case 'BOFinancas':
      return {
        label: 'BackOffice Finanças',
        title: 'Shell - BackOffice Finanças'
      }
    case 'BOFee':
      return {
        label: 'BackOffice Fee',
        title: 'Shell - BackOffice Fee'
      }
    case 'FeeResumo':
      return {
        label: 'BackOffice Fee Resumo',
        title: 'Shell - BackOffice Fee Resumo'
      }
    case 'BOMatrizDesempenho':
      return {
        label: 'BackOffice Matriz Desempenho',
        title: 'Shell - BackOffice Matriz Desempenho'
      }
    case 'BOPesquisaSatisfacao':
      return {
        label: 'BackOffice Pesquisa Satisfação',
        title: 'Shell - BackOffice Pesquisa Satisfação'
      }
    case 'BOColetas':
      return {
        label: 'BackOffice Coletas',
        title: 'Shell - BackOffice Coletas'
      }
    case 'BOJustificativaColeta':
      return {
        label: 'BackOffice Justificativa Coletas',
        title: 'Shell - BackOffice Justificativa Coletas'
      }
    case 'BOMatinalVespertina':
      return {
        label: 'BackOffice Detalhamento Matinal Vespertina',
        title: 'Shell - BackOffice Detalhamento Matinal Vespertina'
      }
    case 'BOSpancopCallPlan':
      return {
        label: 'BackOffice Spancop CallPlan',
        title: 'Shell - BackOffice Spancop CallPlan'
      }
    case 'BOHSSE':
      return {
        label: 'BackOffice HSSE',
        title: 'Shell - BackOffice HSSE'
      }
    case 'NewCampaign':
      return {
        label: 'Nova Campanha',
        title: 'Shell - Nova Campanha'
      }
    case 'ListCampaign':
      return {
        label: 'Listar Campanhas',
        title: 'Shell - Listar Campanhas'
      }
    case 'ChecklistMotoClube':
      return {
        label: 'BackOffice Checklist MotoClube',
        title: 'Shell - BackOffice Checklist MotoClube'
      }
    case 'ChecklistClubeProfissional':
      return {
        label: 'BackOffice Checklist Clube Profissional',
        title: 'Shell - BackOffice Checklist Clube Profissional'
      }
    case 'CheckListVarejo':
      return {
        label: 'BackOffice Checklist Varejo',
        title: 'Shell - BackOffice Checklist Varejo'
      }
    case 'ShowDashboard':
      return {
        label: 'Dashboard Campanhas',
        title: 'Shell - Dashboard Campanhas'
      }
    case 'OportunidadesSpancop':
      return {
        label: 'BackOffice Oportunidades Spancop',
        title: 'Shell - BackOffice Oportunidades Spancop'
      }
    case 'BOPromocoes':
      return {
        label: 'BackOffice Promoções',
        title: 'Shell - BackOffice Promoções'
      }
    case 'BOTrocaOleo':
      return {
        label: 'BackOffice Troca de Óleo',
        title: 'Shell - BackOffice Troca de Óleo'
      }
    case 'UserControl':
      return {
        label: 'Controle de Usuários',
        title: 'Shell - Controle de Usuários'
      }
    default:
      return {}
  }
}

export const updateSelect = (value, field, state, handle) => {
  handle({ ...state, [field]: value })
}

export const loadDefaultFilter = loadedModule => {
  const distribuidoresList = ls.get('perfis')
  let distribuidores

  if (
    loadedModule !== 'BOSpancopCallPlan' &&
    loadedModule !== 'OportunidadesSpancop'
  ) {
    distribuidores = distribuidoresList.filter(
      perfil => perfil.id !== 86 && perfil.id !== 87
    )
  } else {
    distribuidores = distribuidoresList
  }

  if (loadedModule !== 'BOFinancas') {
    distribuidores = distribuidores.filter(perfil => perfil.id !== 28)
  }

  const tipo = ls.get('tipo')
  const user = ls.get('user')
  let i = 0
  let defaultDistribuidores = []
  let envelope = ''

  if (
    loadedModule === 'FeeEstoque' ||
    loadedModule === 'BOFee' ||
    loadedModule === 'FeeResumo'
  ) {
    distribuidores.forEach(d => {
      if (envelope !== d.envelope_fee) {
        i++
        defaultDistribuidores.push({
          value: d.envelope_fee,
          label: d.envelope_fee,
          key: i
        })
      }
      envelope = d.envelope_fee
    })
  } else {
    defaultDistribuidores = distribuidores
      .filter(
        distribuidor =>
          distribuidor.id !== 51 &&
          distribuidor.id !== 52 &&
          distribuidor.id !== 53 &&
          distribuidor.id !== 54 &&
          distribuidor.id !== 55
      )
      .map(d => {
        i++
        if (tipo === 'SUPERVISOR') {
          return {
            value: d.value,
            label: d.label,
            key: d.key
          }
        }

        return {
          value: d.distribuidor,
          label: d.distribuidor,
          key: i
        }
      })
  }

  const defaultTipoCliente = () => {
    if (
      user === 'marcos.donizete' ||
      user === 'frederico.petrucelli' ||
      user === 'guilherme.takamine'
    ) {
      return getTiposCliente(loadedModule)
    } else {
      return loadedModule !== 'BONovo' &&
        loadedModule !== 'BONovo2' &&
        loadedModule !== 'BOPadrao' &&
        loadedModule !== 'BOFinancas' &&
        loadedModule !== 'BOFee' &&
        loadedModule !== 'FeeResumo' &&
        loadedModule !== 'FeeEstoque'
        ? ''
        : getTiposCliente(loadedModule)
    }
  }

  const defaultData = type => {
    if (loadedModule === 'ControlPanel') {
      const currentMonth = getCurrentMonth()

      if (type === 'inicio') {
        return currentMonth.value.inicioFull
      } else {
        return currentMonth.value.terminoFull
      }
    } else if (loadedModule === 'Coaching') {
      const now = new Date()
      if (type === 'inicio') {
        return new Date(now.getFullYear(), now.getMonth() - 5, 1)
      } else {
        return new Date(now.getFullYear(), now.getMonth() + 1, 0)
      }
    } else {
      return ''
    }
  }

  return {
    distribuidor: defaultDistribuidores || [],
    distribuidores: [],
    ult_compra_de: '',
    ult_compra_ate: '',
    status_cliente: '',
    dt_inicial: '',
    dt_final: '',
    data_inicial: defaultData('inicio') || '',
    data_final: defaultData('termino') || '',
    tipo_cliente: defaultTipoCliente(),
    cnpjs: '',
    segmento1: '',
    segmento2: '',
    armazem:
      user === 'rodolfo.godoy' ||
      user === 'marcos.donizete' ||
      user === 'frederico.petrucelli' ||
      user === 'guilherme.takamine'
        ? { value: '20', label: '20', key: 3 }
        : '',
    tipo_prod: '',
    tipo_nf: '',
    tipo_produto: '',
    vendedor: '',
    supervisor: '',
    gerente: '',
    emissao_inicial: '',
    emissao_final: '',
    vencimento_inicial: '',
    vencimento_final: '',
    situacao: '',
    canal_venda: '',
    pagamento_inicial: '',
    pagamento_final: '',
    canal: '',
    loaded_report: '',
    dates: []
  }
}

export const processJSONHeaders = data => {
  let initialSKU = 0
  const newArray = []
  let id = 1
  data.forEach(item => {
    if (item.sku !== initialSKU) {
      newArray.push({
        id: `${id}_${item.sku}`,
        sku: item.sku,
        distribuidor: '',
        descricao: item.descricao,
        armazem: item.armazem,
        limite: 0,
        pedido: 0,
        extra: 0,
        qtd_trava_nova: 0,
        items: []
      })
      initialSKU = item.sku
      id++
    }
  })

  newArray.forEach((item, i) => {
    const arrayItems = data.filter(i => {
      return i.sku === item.sku
    })

    newArray[i].items = arrayItems

    newArray[i].limite = arrayItems.reduce((acc, item) => {
      return item.limite !== 'NaN' && Number(acc + item.limite)
    }, 0)
    newArray[i].pedido = arrayItems.reduce((acc, item) => {
      return item.pedido !== 'NaN' && Number(acc + item.pedido)
    }, 0)
    newArray[i].extra = arrayItems.reduce((acc, item) => {
      return item.extra !== 'NaN' && acc + Number(item.extra)
    }, 0)
    newArray[i].qtd_trava_nova = arrayItems.reduce((acc, item) => {
      if (!isUndefined(item.nova_trava)) {
        return acc + Number(item.nova_trava)
      } else {
        return acc + Number(item.limite + item.extra)
      }
    }, 0)

    for (let j = 0; j < arrayItems.length; j++) {
      delete arrayItems[j].datafim
      delete arrayItems[j].dataini
      arrayItems[j].alterado = arrayItems[j].subiu === 'S' ? true : false
      const { limite, extra, nova_trava } = arrayItems[j]
      arrayItems[j].qtd_trava_nova = !isUndefined(item.nova_trava)
        ? nova_trava
        : Number(limite + extra)
    }
  })

  return newArray
}

export const loadComponentHeaders = loadedModule => {
  const componentHeaders = {
    BOCliente: Headers.BOCliente,
    BOCombo: Headers.BOCombo,
    BOPadrao: Headers.BOPadrao,
    BOPedidosPorSKU: Headers.BOPedidosPorSKU,
    BOProduto: Headers.BOProduto,
    BOVisitaBPS: Headers.BOVisitaBPS,
    BOPedidos: Headers.BOPedidos,
    BODashboardClube: Headers.BODashboardClube,
    RLTitulos: Headers.RLTitulos,
    RLEstoque: Headers.RLEstoque,
    BONovo: Headers.BONovo,
    BONovo2: Headers.BONovo2,
    BOScore: Headers.BOScore,
    ApproveExtra: Headers.ApproveExtra,
    ApproveExtraExport: Headers.ApproveExtraExport,
    FeeEstoque: Headers.FeeEstoque,
    BOFinancas: Headers.BOFinancas,
    BOPesquisaSatisfacao: Headers.BOPesquisaSatisfacao,
    BOMatinalVespertina: Headers.BOMatinalVespertina,
    BOColetas: Headers.BOColetas,
    BOCoaching: Headers.BOCoaching,
    BOMatrizDesempenho: Headers.BOMatrizDesempenho,
    BOSpancopCallPlan: Headers.BOSpancopCallPlan,
    BOHSSE: Headers.BOHSSE,
    BOFee: Headers.BOFee,
    FeeResumo: Headers.FeeResumo,
    BOFee1: Headers.BOFee,
    FeeResumo1: Headers.FeeResumo,
    BOJustificativaColeta: Headers.BOJustificativaColeta,
    ChecklistMotoClube: Headers.ChecklistMotoClube,
    ChecklistClubeProfissional: Headers.ChecklistClubeProfissional,
    CheckListVarejo: Headers.CheckListVarejo,
    OportunidadesSpancop: Headers.OportunidadesSpancop,
    BOPromocoes: Headers.BOPromocoes,
    BOTrocaOleo: Headers.BOTrocaOleo,
    BOMovInternos: Headers.BOMovInternos
  }

  return componentHeaders[loadedModule]
}

export const makeJson = async (loadedModule, data) => {
  const fileName = loadedModule
  const headers = loadComponentHeaders(loadedModule)
  const generateExcelJson = { headers, data: data, fileName }
  return generateExcelJson
}

export const returnBlob = async (
  loadedModule,
  generateExcelJson,
  exportType
) => {
  const workerInstance = worker()
  return workerInstance.processJSON(
    generateExcelJson.data,
    generateExcelJson.headers,
    exportType
  )
}

export const returnBlobMultiple = async (generateExcelJson, exportType) => {
  const workerInstance = worker()
  return workerInstance.processJSONMultiple(generateExcelJson, exportType)
}

export const changeFilterField = (e, sendId, filterData) => {
  const value =
    sendId === 'ult_compra_de' ||
    sendId === 'ult_compra_ate' ||
    sendId === 'dt_inicial' ||
    sendId === 'dt_final' ||
    sendId === 'data_inicial' ||
    sendId === 'data_final' ||
    sendId === 'emissao_inicial' ||
    sendId === 'emissao_final' ||
    sendId === 'vencimento_inicial' ||
    sendId === 'vencimento_final' ||
    sendId === 'pagamento_inicial' ||
    sendId === 'pagamento_final'
      ? e
      : e.target.value
  const update = {
    id: sendId ? sendId : e.target.id,
    value: value
  }
  return { ...filterData, [update.id]: update.value }
}

export const retrieveLimiteData = async (loadedModule, filterData) => {
  const { distribuidor } = filterData

  const mx = filterData.date_range.value.split('-')

  const dt_inicial = mx[0].trim()
  const dt_final = mx[1].trim()
  const { sku, descri, armazem, meses_suges, perc_suges, granel } = filterData
  const promises = []

  const perfil = distributors.find(perfil => {
    return perfil.id === distribuidor.key
  })

  if (perfil.url !== '') {
    const api = loadAPI(loadedModule, filterData, perfil.url)

    const getString = `?distribuidor=${
      perfil.distribuidor
    }&sku=${sku}&dt_inicial=${dt_inicial}&dt_final=${dt_final}&meses_suges=${meses_suges}&perc_suges=${perc_suges}&descri=${descri}&granel=${granel}&armazem=${
      armazem.value || ''
    }`

    promises.push(
      Axios.get(`${api.url}${getString}`, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
    )
  }

  let promiseData = {}

  await trackPromise(
    Promise.all(promises.map(p => p.catch(e => e)))
      .then(results => {
        const errors = []
        const data = []
        results.forEach(r => {
          if (r instanceof Error) {
            errors.push(r)
          } else {
            data.push(r)
          }
        })

        promiseData = { results: data, errors: errors }
      })
      .catch(e => console.log(e))
  )

  let getData = []

  if (promiseData.results[0]?.status !== 204) {
    getData = promiseData.results
      .filter(
        row =>
          row.data.length !== 0 &&
          row.data.message !==
            'The request has been fulfilled and resulted in a new resource being created.' &&
          row.data !== ''
      )
      .reduce((arr, row) => arr.concat(row.data), [])
  }

  return { result: getData, errors: promiseData.errors }
}

export const processSupervisorDataTable = (data, indicador) => {
  const newRows = data.map(r => {
    const currentAlvo = r.alvos.find(alvo => {
      const mx = alvo.descricao.split('-')
      const id = Number(mx[0])
      return id === indicador
    })

    const objData = {
      vendedor: r.vendedor,
      nome_vendedor: r.nome_vendedor,
      objetivo_mes: currentAlvo?.objetivo_mes || 0,
      real_mes: currentAlvo?.real_mes || 0,
      objetivo_dia: currentAlvo?.objetivo_dia || 0,
      objetivo_proporcional: currentAlvo?.objetivo_proporcional || 0,
      real_proporcional: currentAlvo?.real_proporcional || 0,
      acordado_volume: r.acordado_volume,
      acordado_premium: r.acordado_premium,
      acordado_efetivos: r.acordado_efetivos,
      real_dia: currentAlvo?.real_dia || 0,
      status_vendedor: r.status_vendedor,
      vespertina: r.vespertina,
      vespertina_volume: r.vespertina_volume,
      vespertina_premium: r.vespertina_premium,
      vespertina_efetivos: r.vespertina_efetivos
    }

    return {
      ...objData,
      percentage: (
        <PercentageGraph
          obj={currentAlvo?.objetivo_mes || 0}
          realiz={currentAlvo?.real_mes || 0}
        />
      )
    }
  })
  return newRows
}

export const calculateCampaignMonths = campaign => {
  const { data_inicial, data_final } = campaign
  const diff =
    data_inicial &&
    data_final &&
    (Date.parse(data_final) - Date.parse(data_inicial)) / 1000 / 60 / 60 / 24
  const diffMonths = Math.round(diff / 30)
  return diffMonths
}

export const getCurrentMonth = () => {
  const now = new Date()
  const months = monthSelect()
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  const currentMonth = months.find(
    month =>
      month.value.inicioFull.toLocaleDateString('pt-BR') ===
      firstDayOfMonth.toLocaleDateString('pt-BR')
  )

  return currentMonth
}

export const locateSegmentByCNPJ = (customers, cnpj) => {
  const findedCustomer = customers.find(
    customer => customer.cnpj === unmaskCnpj(cnpj)
  )
  const { nome_segmento1, grupo_descricao, razao_social } = findedCustomer || {}
  return { nome_segmento1, grupo_descricao, razao_social }
}

export const unmaskCnpj = cnpj => {
  if (cnpj) {
    const unmaskedCnpj = cnpj.replace(/[^\w\s]/gi, '')
    return unmaskedCnpj
  }
}

export const getDistributors = () => {
  let distributors = []
  const perfis = ls.get('perfis')
  let x = 0
  perfis.forEach(perfil => {
    const exp = perfil.distribuidor.split('/')
    const data = {
      value: perfil.distribuidor,
      label: perfil.distribuidor,
      key: ++x,
      sortColumn: exp[0].trim() + exp[1].trim()
    }
    distributors = [...distributors, data]
  })

  const distributorsSorted = _.sortBy(distributors, [distr => distr.sortColumn])
  return distributorsSorted
}

export const getEnvelopes = () => {
  const perfis = ls.get('perfis')
  const distributors = []
  let x = 0
  let envelope = ''
  const sorted = _.sortBy(perfis, [perfil => perfil.envelope_fee])
  sorted.forEach(perfil => {
    if (envelope !== perfil.envelope_fee) {
      distributors.push({
        value: perfil.envelope_fee,
        label: perfil.envelope_fee,
        key: ++x
      })
      envelope = perfil.envelope_fee
    }
  })

  const distributorsSorted = _.sortBy(distributors, [distr => distr.value])
  return distributorsSorted
}

export const dateFromBRString = date => {
  const mx = date.split('/')
  const newDate = new Date('20' + mx[2], mx[1] - 1, mx[0])
  return new Date(newDate)
}

export const getMonthName = date => {
  const newDate = new Date(date)
  const options = {
    month: 'long'
  }
  return newDate.toLocaleDateString('pt-BR', options)
}

export const getMonthNumber = date => {
  return moment(new Date(date)).format('YYYYMM')
}

export const listCampaignMonths = campaign => {
  const { data_inicial } = campaign
  const diffMonths = calculateCampaignMonths(campaign)
  const monthsArray = []

  if (diffMonths <= 1) {
    const month = getMonthName(data_inicial)
    const monthNumber = getMonthNumber(data_inicial)
    monthsArray.push({ month: month, number: monthNumber })
  } else {
    for (let i = 0; i < diffMonths; i++) {
      const initDate = new Date(data_inicial)
      const actualDate = new Date(
        initDate.getFullYear(),
        initDate.getMonth() + i,
        initDate.getDate()
      )
      const month = getMonthName(actualDate)
      const monthNumber = getMonthNumber(actualDate)
      monthsArray.push({ month: month, number: monthNumber })
    }
  }

  return monthsArray
}

export const retrieveData = async (filterData, loadedModule, get) => {
  const distributorList = filterData.distribuidor
  const { data_inicial, data_final, vendedor } = filterData
  const promises = []

  const tipo = ls.get('tipo')

  distributorList &&
    distributorList.forEach(distributor => {
      const perfis = ls.get('perfis')

      const codigo_supervisor = ls.get('codigo_vededor')
      let perfil

      if (tipo === 'SUPERVISOR') {
        perfil = perfis.filter(perfil => perfil.value === distributor.value)
      } else {
        perfil = perfis.filter(perfil => {
          return perfil.distribuidor === distributor.value
        })
      }

      if (perfil[0].url !== '') {
        const api = loadAPI(loadedModule, filterData, perfil[0].url)

        if (!get) {
          const json = getFormattedJSON(
            filterData,
            loadedModule,
            distributor.value
          )

          promises.push(
            Axios.post(api.url, json, {
              headers: {
                //'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept-Language': 'pt-BR,en;q=0.9'
              }
            })
          )
        } else {
          let getString
          if (
            loadedModule === 'SupervisorOrderReports' ||
            loadedModule === 'SupervisorRevenueReports' ||
            loadedModule === 'VisitReports'
          ) {
            getString = `?distribuidor=${distributor.value}&codigo_vend=${
              vendedor.value || ''
            }&codigo_super=${codigo_supervisor}&data_inicial=${moment(
              data_inicial
            ).format('DD/MM/YYYY')}&data_final=${moment(data_final).format(
              'DD/MM/YYYY'
            )}`
          }

          promises.push(
            Axios.get(`${api.url}${getString}`, {
              headers: {
                //'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'application/json; charset=UTF-8'
              }
            })
          )
        }
      }
    })

  let promiseData = {}

  await trackPromise(
    Promise.all(promises.map(p => p.catch(e => e)))
      .then(results => {
        const errors = []
        const data = []
        results.forEach(r => {
          if (r instanceof Error) {
            errors.push(r)
          } else {
            data.push(r)
          }
        })

        promiseData = { results: data, errors: errors }
      })
      .catch(e => console.log(e))
  )

  let getData = []

  if (promiseData.results[0]?.status !== 204) {
    getData = promiseData.results
      .filter(
        row =>
          row.data.length !== 0 &&
          row.data.message !==
            'The request has been fulfilled and resulted in a new resource being created.' &&
          row.data !== ''
      )
      .reduce((arr, row) => arr.concat(row.data), [])
  }

  return getData
}

export const convertStringBRDate = date => {
  const mx = date.split('/')
  return new Date(mx[2], mx[1], mx[0])
}

export const loadTrackingCustomers = async perfis => {
  const promises = perfis.map(perfil => {
    const json = {
      distribuidor: perfil.distribuidor,
      cnpjs: '',
      segmento2: 'FWS'
    }

    Axios.defaults.timeout = 45000

    return Axios.post(`${perfil.url}INT_SHELL_TRACKING_CLIENTES`, json, {
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
  })

  const promiseData = await Promise.all(promises.map(p => p.catch(e => e)))
  const errors = []
  const data = []

  promiseData.forEach(p => {
    if (p instanceof Error) {
      errors.push(p)
    } else {
      data.push(p)
    }
  })

  const results = { errors: errors, data: data }

  return results
}

export const loadFinanceData = async (inicio, final, acompanhamento_por) => {
  const filteredDistributors = distributors.filter(
    distributor => distributor.url !== '' && distributor.envelope_fee !== 'EURO'
  )
  const promises = filteredDistributors.map(perfil => {
    const json = {
      distribuidor: perfil.distribuidor,
      data_inicial:
        typeof inicio === 'object'
          ? moment(inicio).format('DD/MM/YYYY')
          : inicio,
      data_final:
        typeof final === 'object' ? moment(final).format('DD/MM/YYYY') : final,
      tipo_cliente: 'FWS',
      cnpjs: '',
      armazem: '',
      tipo_prod: '',
      tipo_info: acompanhamento_por === 'faturamento' ? 'F' : 'P'
    }

    Axios.defaults.timeout = 20000

    return Axios.post(`${perfil.url}INT_SHELL_BO_TRACKING`, json, {
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
  })

  const promiseData = await Promise.all(promises.map(p => p.catch(e => e)))
  const errors = []
  const data = []

  promiseData.forEach(p => {
    if (p instanceof Error) {
      errors.push(p)
    } else {
      data.push(p)
    }
  })

  const unifiedData = data
    .filter(
      row =>
        row.data.length !== 0 &&
        row.data.message !==
          'The request has been fulfilled and resulted in a new resource being created.'
    )
    .reduce((arr, row) => arr.concat(row.data), [])

  const results = { errors: errors, data: unifiedData }

  return results
}

export const loadMarketingColumns = (
  alvoHasId,
  currentCampaign,
  seeAllCompany,
  mkt_profile,
  campaignMonths
) => {
  const columns = [
    { name: 'grupo', title: 'Grupo' },
    { name: 'distribuidor', title: 'Distribuidor' },
    { name: 'cod_vend1', title: 'Cod Vendedor' },
    { name: 'nome_vend1', title: 'Vendedor' },
    { name: 'cnpj', title: 'CNPJ' },
    { name: 'ibm_shell', title: 'IBM Shell' }
  ]

  alvoHasId &&
    columns.push({ name: 'custom_id', title: currentCampaign.custom_field })

  columns.push(
    { name: 'municipio', title: 'Cidade' },
    { name: 'estado', title: 'Estado' },
    { name: 'dealer_name', title: 'Dealer Name' }
  )

  if (currentCampaign?.tipo_agrupamento?.value === 'categoria') {
    columns.push({ name: 'categoria', title: 'Categoria' })
  }

  if (mkt_profile === 'root' || mkt_profile === 'mainlevel') {
    // const campaignMonths = currentCampaign
    //   ? listCampaignMonths(currentCampaign)
    //   : []
    campaignMonths.forEach((c, i) => {
      if (c.checked) {
        columns.push({ name: `alvo_${i}`, title: `Alvo ${c.month}` })
        columns.push({ name: `faturado_${i}`, title: `Faturado ${c.month}` })
        columns.push({ name: `perc_${i}`, title: `% ${c.month}` })
      }
    })
  } else {
    if (!seeAllCompany) {
      // const campaignMonths = currentCampaign
      //   ? listCampaignMonths(currentCampaign)
      //   : []
      campaignMonths.forEach((c, i) => {
        if (c.checked) {
          columns.push({ name: `alvo_${i}`, title: `Alvo ${c.month}` })
          columns.push({ name: `faturado_${i}`, title: `Faturado ${c.month}` })
          columns.push({ name: `perc_${i}`, title: `% ${c.month}` })
        }
      })
    }
  }

  if (mkt_profile === 'root' || mkt_profile === 'mainlevel') {
    columns.push(
      { name: 'alvo_total', title: 'Alvo Total' },
      { name: 'faturado_total', title: 'Faturado Total' }
    )
  } else {
    if (!seeAllCompany) {
      columns.push(
        { name: 'alvo_total', title: 'Alvo Total' },
        { name: 'faturado_total', title: 'Faturado Total' }
      )
    }
  }

  columns.push({ name: 'perc_total', title: '% Total' })

  if (currentCampaign?.sku === 'SIM') {
    if (mkt_profile === 'root' || mkt_profile === 'mainlevel') {
      columns.push({
        name: 'sku_ok',
        title: `CX SKU ${currentCampaign.sku_number} (Alvo ${currentCampaign.sku_quant})?`
      })
    } else {
      if (!seeAllCompany) {
        columns.push({
          name: 'sku_ok',
          title: `CX SKU ${currentCampaign.sku_number} (Alvo ${currentCampaign.sku_quant})?`
        })
      }
    }
  }

  const findedRanking = currentCampaign?.tipo_apuracao
    ?.filter(tipo => tipo)
    .find(t => t.value === 'r')

  if (findedRanking) {
    columns.push({ name: 'ranking_pos', title: 'Ranking' })
  }

  return columns
}

export const loadMarketingWidths = (
  alvoHasId,
  currentCampaign,
  campaignMonths
) => {
  const widths = [
    { columnName: 'distribuidor', width: 120 },
    { columnName: 'cod_vend1', width: 120 },
    { columnName: 'nome_vend1', width: 150 },
    { columnName: 'cnpj', width: 150 },
    { columnName: 'ibm_shell', width: 120 },
    { columnName: 'dealer_code', width: 120 }
  ]

  alvoHasId && widths.push({ columnName: 'custom_id', width: 120 })

  widths.push(
    { columnName: 'municipio', width: 120 },
    { columnName: 'estado', width: 120 },
    { columnName: 'dealer_name', width: 240 },
    { columnName: 'grupo', width: 240 }
  )
  if (currentCampaign?.tipo_agrupamento?.value === 'categoria') {
    widths.push({ columnName: 'categoria', width: 120 })
  }

  // const campaignMonths = currentCampaign
  //   ? listCampaignMonths(currentCampaign)
  //   : []
  campaignMonths.forEach((c, i) => {
    if (c.checked) {
      widths.push(
        { columnName: `alvo_${i}`, width: 120 },
        { columnName: `faturado_${i}`, width: 120 },
        { columnName: `perc_${i}`, width: 120 }
      )
    }
  })

  widths.push(
    { columnName: 'alvo_total', width: 120 },
    { columnName: 'faturado_total', width: 120 },
    { columnName: 'perc_total', width: 120 }
  )

  if (currentCampaign?.sku === 'SIM') {
    widths.push({ columnName: 'sku_ok', width: 120 })
  }

  const findedRanking = currentCampaign?.tipo_apuracao?.find(
    t => t?.value === 'r'
  )

  if (findedRanking) {
    widths.push({ columnName: 'ranking_pos', width: 120 })
  }

  return widths
}

export const loadCampaignDates = (currentCampaign, type) => {
  let data_inicial
  let data_final

  if (Object.keys(currentCampaign).length > 0) {
    const objInicial = currentCampaign.data_inicial
    const objFinal = currentCampaign.data_final

    const dataInicialLastYear = new Date(
      objInicial.getFullYear() - 1,
      objInicial.getMonth(),
      objInicial.getDate()
    )
    const dataFinalLastYear = new Date(
      objFinal.getFullYear() - 1,
      objFinal.getMonth(),
      objFinal.getDate()
    )

    const threeMonthsInicial = new Date(
      objInicial.getFullYear(),
      objInicial.getMonth() - 3,
      objInicial.getDate()
    )
    const threeMonthsFinal = new Date(
      objInicial.getFullYear(),
      objInicial.getMonth(),
      0
    )

    switch (type) {
      case 'current':
        data_inicial = currentCampaign.data_inicial
        data_final = currentCampaign.data_final
        break
      case 'lastYear':
        data_inicial = dataInicialLastYear
        data_final = dataFinalLastYear
        break
      case '3Months':
        data_inicial = threeMonthsInicial
        data_final = threeMonthsFinal
        break
      default:
        data_inicial = currentCampaign.data_inicial
        data_final = currentCampaign.data_final
        break
    }
  }

  return { data_inicial, data_final }
}

export const completeCoachingResponses = responses => {
  if (!responses) {
    return []
  }

  const fields = [
    'mercha',
    'exposi',
    'precif',
    'planej',
    'verifi',
    'verexp',
    'relchk',
    'verest',
    'verprc',
    'verpri'
  ]

  const totals = {
    mercha: { count: 0, sim: 0, perc: 0, validCount: 0 },
    exposi: { count: 0, sim: 0, perc: 0, validCount: 0 },
    precif: { count: 0, sim: 0, perc: 0, validCount: 0 },
    planej: { count: 0, sim: 0, perc: 0, validCount: 0 },
    verifi: { count: 0, sim: 0, perc: 0, validCount: 0 },
    verexp: { count: 0, sim: 0, perc: 0, validCount: 0 },
    relchk: { count: 0, sim: 0, perc: 0, validCount: 0 },
    verest: { count: 0, sim: 0, perc: 0, validCount: 0 },
    verprc: { count: 0, sim: 0, perc: 0, validCount: 0 },
    verpri: { count: 0, sim: 0, perc: 0, validCount: 0 }
  }

  fields.forEach(field => {
    responses.forEach(response => {
      if (response[field]) {
        totals[field].count++
        if (response[field] === 'Sim' || response[field] === 'Não') {
          totals[field].validCount++
        }
        response[field] === 'Sim' && totals[field].sim++
      }
    })
  })

  fields.forEach(field => {
    if (totals[field].count !== 0) {
      totals[field].perc =
        totals[field].validCount === 0
          ? 0
          : Math.round((totals[field].sim / totals[field].validCount) * 100)
    }
  })

  let newTotals = {}
  fields.forEach(field => {
    newTotals[field] = totals[field].perc
  })

  let newResponses = []
  if (responses.length <= 10) {
    newResponses = responses
    const missed = 10 - responses.length
    for (let i = 0; i < missed; i++) {
      let empty = { ...emptyResponses, id: i + 10 }
      newResponses.push(empty)
    }
  }

  newResponses.push(newTotals)

  return newResponses
}

export const completeSpancopCoachingResponses = responses => {
  if (!responses) {
    return []
  }

  const fields = [
    'qualidade',
    'utiliza',
    'folha',
    'calculo',
    'proposta',
    'dvrs'
  ]

  const totals = {
    qualidade: { count: 0, sim: 0, perc: 0, validCount: 0 },
    utiliza: { count: 0, sim: 0, perc: 0, validCount: 0 },
    folha: { count: 0, sim: 0, perc: 0, validCount: 0 },
    calculo: { count: 0, sim: 0, perc: 0, validCount: 0 },
    proposta: { count: 0, sim: 0, perc: 0, validCount: 0 },
    dvrs: { count: 0, sim: 0, perc: 0, validCount: 0 }
  }

  fields.forEach(field => {
    responses.forEach(response => {
      if (response[field]) {
        totals[field].count++
        if (response[field] === 'Sim' || response[field] === 'Não') {
          totals[field].validCount++
        }
        response[field] === 'Sim' && totals[field].sim++
      }
    })
  })

  fields.forEach(field => {
    if (totals[field].count !== 0) {
      totals[field].perc =
        totals[field].validCount === 0
          ? 0
          : Math.round((totals[field].sim / totals[field].validCount) * 100)
    }
  })

  let newTotals = {}
  fields.forEach(field => {
    newTotals[field] = totals[field].perc
  })

  let newResponses = []
  if (responses.length <= 5) {
    newResponses = responses
    const missed = 5 - responses.length
    for (let i = 0; i < missed; i++) {
      let empty = { ...emptyResponses, id: i + 5 }
      newResponses.push(empty)
    }
  }

  newResponses.push(newTotals)

  return newResponses
}
export default {
  getFormattedJSON,
  logout,
  loadAPI,
  loadLabelBase,
  loadComponentHeaders,
  makeJson,
  returnBlob
}
