import { atom } from 'jotai'


/* Controle de Abertorua do Modal do Formulario de Coaching do Spancop*/ 
export const openSpancopCoachingDialogAtom = atom(false)
export const spancopCoachingDialogTypeAtom = atom('hide')

/* Controle dos dados do formulario do coaching*/
export const spancopCoachingDataAtom = atom({})
export const newSpancopCoachingTypeAtom = atom('show')
export const spancopAllResponsesAtom = atom([])
