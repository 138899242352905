import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import React, { Fragment } from 'react'

const QuestionResponseItem = ({
    item,
    handleCoachingResponse,
    newResponses
}) => (
    <div className='border border-gray-300 p-2 rounded mb-2'>
        <FormControl component='fieldset'>
            <FormLabel component='legend'>{item.question}</FormLabel>
            <RadioGroup
                aria-label={item.key}
                id={item.key}
                value={newResponses[item.key]}
                onChange={e => handleCoachingResponse(item.key, e)}
            >
                {/* <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
                <FormControlLabel value='Não' control={<Radio />} label='Não' />
                <FormControlLabel value='NA' control={<Radio />} label='NA' /> */}
                
                {item.key === 'qualidade' && (
                    <Fragment>
                        <FormControlLabel value='Nenhuma evidência' control={<Radio />} label='Nenhuma evidência - Necessidade de desenvovimento importante' />
                        <FormControlLabel value='Alguma evidência' control={<Radio />} label='Alguma evidência - Espaço para desenvolvimento' />
                        <FormControlLabel value='Boa' control={<Radio />} label='Boa - Ajuste fino' />
                        <FormControlLabel value='Muito boa' control={<Radio />} label='Muito boa' />
                    </Fragment>
                )}

                {item.key !== 'qualidade' && (
                    <Fragment>
                        <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
                        <FormControlLabel value='Não' control={<Radio />} label='Não' />
                        <FormControlLabel value='NA' control={<Radio />} label='NA' />
                    </Fragment>
                )}

            </RadioGroup>
        </FormControl>
    </div>
)

export default QuestionResponseItem
