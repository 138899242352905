import React from 'react'
import { useAtom } from 'jotai'
import { filterDataAtom } from '../_global/Atoms'

import InputForm from '../Inputs/InputForm'
import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'
import {
  listSegments,
  listStatusCliente,
  getTiposCliente
} from '../../constData/constData'

import {
  changeFilterField,
  updateSelect,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap  mb-4'>
        <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            isMulti
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp de *'
            id='ult_compra_de'
            selected={filterData.ult_compra_de}
            onChange={e => {
              handleChange(e, 'ult_compra_de')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp ate *'
            id='ult_compra_ate'
            selected={filterData.ult_compra_ate}
            onChange={e => {
              handleChange(e, 'ult_compra_ate')
            }}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'status_cliente', filterData, setFilterData)
            }}
            value={
              filterData.status_cliente
                ? filterData.status_cliente
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Status Cliente'
            id='status_cliente *'
            options={listStatusCliente()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <InputForm
            onChange={handleChange}
            value={filterData.cnpjs}
            label='CNPJ'
            id='cnpjs'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'segmento1', filterData, setFilterData)
            }}
            value={
              filterData.segmento1
                ? filterData.segmento1
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Segmento 1 *'
            id='segmento1'
            options={listSegments()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'segmento2', filterData, setFilterData)
            }}
            value={
              filterData.segmento2
                ? filterData.segmento2
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Segmento 2 *'
            id='segmento2'
            options={getTiposCliente()}
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
        </div>
      </div>
    </form>
  )
}
