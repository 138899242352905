import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import InputForm from '../Inputs/InputForm'

export default function CampaignSKUFields({ campaign, handleChange }) {
  return (
    <div className='flex flex-row items-center justify-between gap-2 mt-2 mb-3'>
      <div className='w-1/6'>
        <label
          className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
        >
          Configurar SKU <FontAwesomeIcon icon={faArrowCircleRight} />
        </label>
      </div>
      <div className='w-1/2'>
        <InputForm
          id='sku_number'
          label='SKU do Produto'
          defaultValue={campaign?.sku_number}
          onChange={handleChange}
        />
      </div>
      <div className='w-1/2'>
        <InputForm
          id='sku_quant'
          label='Quantidade do Produto'
          defaultValue={campaign?.sku_quant}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
