import React from 'react'
import Logo from '../../assets/logo.png'

export default function Header({ title }) {
  return (
    <div className="mb-4 grid grid-cols-2">
      <div className="">
        <img src={Logo} alt="logo" width="30%" align="center" />
      </div>
      <div className="text-right font-bold text-indigo-800 text-lg">
        {title}
      </div>
    </div>
  )
}
