import React, { useState } from 'react'
import Axios from 'axios'
// eslint-disable-next-line
import dotenv from 'dotenv'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilter,
  faFileExcel,
  faCircleNotch,
  faFileCsv,
  faFileUpload
} from '@fortawesome/free-solid-svg-icons'

import * as Forms from '../Forms'
import DataTable from '../Table/DataTable'
import DataTableFeeEstoque from '../FeeEstoque/DataTable'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  getFormattedJSON,
  loadAPI,
  loadComponentHeaders,
  makeJson,
  returnBlob,
  returnBlobMultiple
} from '../../functions/functions'
import { useAtom } from 'jotai'
import {
  loadedModuleAtom,
  reportDataAtom,
  timeoutAtom,
  filterDataAtom,
  emptyAlertAtom,
  processXLSAtom,
  feeReportsDataAtom
} from '../_global/Atoms'
import { LoadingIndicator } from './LoadingIndicator'
import FileSaver from 'file-saver'
import AlertDialog from './AlertDialog'
import ls from 'local-storage'
import moment from 'moment'
import { distributors } from '../../constData/urls'

const formComponents = {
  FormBOCliente: Forms.FormBOCliente,
  FormBOCoaching: Forms.FormBOCoaching,
  FormBOCombo: Forms.FormBOCombo,
  FormBOPadrao: Forms.FormBOPadrao,
  FormBOPedidos: Forms.FormBOPedidos,
  FormBONovo: Forms.FormBONovo,
  FormBONovo2: Forms.FormBONovo,
  FormBOScore: Forms.FormBOMatinalVespertina,
  FormBOPedidosPorSKU: Forms.FormBOPedidosPorSKU,
  FormBOProduto: Forms.FormBOProduto,
  FormBOVisitaBPS: Forms.FormBOVisitaBPS,
  FormRLEstoque: Forms.FormRLEstoque,
  FormRLTitulos: Forms.FormRLTitulos,
  FormBODashboardClube: Forms.FormBODashboardClube,
  FormFeeEstoque: Forms.FormFeeEstoque,
  FormBOFinancas: Forms.FormBOFinancas,
  FormBOPesquisaSatisfacao: Forms.FormBOPesquisaSatisfacao,
  FormBOMatinalVespertina: Forms.FormBOMatinalVespertina,
  FormBOMatrizDesempenho: Forms.FormBOMatrizDesempenho,
  FormBOSpancopCallPlan: Forms.FormBOSpancopCallPlan,
  FormBOHSSE: Forms.FormBOHSSE,
  FormBOFee: Forms.FormBOFee,
  FormFeeResumo: Forms.FormBOFee,
  FormBOColetas: Forms.FormBOMatinalVespertina,
  FormBOJustificativaColeta: Forms.FormBOMatinalVespertina,
  FormChecklistMotoClube: Forms.FormBOMatinalVespertina,
  FormChecklistClubeProfissional: Forms.FormBOMatinalVespertina,
  FormCheckListVarejo: Forms.FormBOMatinalVespertina,
  FormOportunidadesSpancop: Forms.FormBOHSSE,
  FormBOPromocoes: Forms.FormBOPromocoes,
  FormBOTrocaOleo: Forms.FormBOPromocoes,
  FormBOMovInternos: Forms.FormBOMovInternos
}

export default function Filter() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [timeoutA, setTimeoutA] = useAtom(timeoutAtom)
  // eslint-disable-next-line
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  // eslint-disable-next-line
  const [emptyAlert, setEmptyAlert] = useAtom(emptyAlertAtom)
  const [apiError, setApiError] = useState(false)
  const [apiErrorList, setApiErrorList] = useState([])
  const [feeProfile, setFeeProfile] = useState({})
  const [feeReportsData, setFeeReportsData] = useAtom(feeReportsDataAtom)
  const [rstDialog, setRstDialog] = useState(false)
  const [processFechamento, setProcessFechamento] = useState(false)

  const [processXLS, setProcessXLS] = useAtom(processXLSAtom)

  const { promiseInProgress } = usePromiseTracker()

  const handleExportClick = async exportType => {
    setProcessXLS(true)

    let distributorName = ''
    if (loadedModule === 'FeeEstoque') {
      const d = feeProfile.distribuidor.split(' / ')
      distributorName = `_${d[0]}`
    }
    const fileName = `${loadedModule}${distributorName}`
    const generateExcelJson = await makeJson(loadedModule, reportData)
    const blob = await returnBlob(loadedModule, generateExcelJson, exportType)
    setProcessXLS(false)
    // eslint-disable-next-line
    const saved = await FileSaver(blob, `${fileName}.${exportType}`)
  }

  const handleExportMultiple = async () => {
    setProcessXLS(true)

    let distributorName = ''
    if (loadedModule === 'FeeEstoque') {
      const d = feeProfile.distribuidor.split(' / ')
      distributorName = `_${d[0]}`
    }
    const fileName = `${loadedModule}${distributorName}`
    const generateJSON = feeReportsData.map(async r => {
      const json = await makeJson(
        r.report,
        r.data.message !==
          'The request has been fulfilled and resulted in a new resource being created.'
          ? r.data
          : []
      )
      return { ...r, json: json }
    })
    const generateExcelJson = await Promise.all(generateJSON)

    // const generateExcelJson = await makeJson(loadedModule, reportData)
    const blob = await returnBlobMultiple(generateExcelJson, 'xlsb')
    setProcessXLS(false)
    // // eslint-disable-next-line
    await FileSaver(blob, `${fileName}.xlsb`)
  }

  const loadReport = async () => {
    setReportData([])
    setTimeoutA(false)

    let api = {}

    let json = getFormattedJSON(filterData, loadedModule)

    // traz o total de registros
    // json.pagina = '-1'

    // const getTotalRegisters = await Axios.post(api.url, json).catch(e =>
    //   setTimeout(true)
    // )

    // const pages = Math.ceil(getTotalRegisters / 1000)
    // const promises = []

    // for (let i = 0; i < pages; i++) {
    //   json.pagina = i + i
    //   json.registros = 1000
    //   promises.push(Axios.post(api.url, json))
    // }

    // const getData = await Promise.all(promises)

    // if (timeout) {
    //   toast.error(
    //     'Erro ao acessar a api ou tempo limite de processamento excedido. Por favor contate o administrador.'
    //   )
    //   setLoading(false)
    // }

    // console.log(json)
    // return false

    // Validação

    switch (loadedModule) {
      case 'BOCliente':
        if (filterData.distribuidor.length === 0) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOCoaching':
        if (filterData.distribuidor.length === 0 || filterData.ano === '') {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOCombo':
        if (
          filterData.ult_compra_de === '' ||
          filterData.ult_compra_ate === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BODashboardClube':
        if (
          filterData.ult_compra_de === '' ||
          filterData.ult_compra_ate === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BONovo':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BONovo2':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOScore':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOPadrao':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOMovInternos':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOPedidos':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOPedidosPorSKU':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOProduto':
        if (filterData.distribuidor.length === 0) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOVisitaBPS':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'RLEstoque':
        if (filterData.distribuidor.length === 0) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'RLTitulos':
        if (
          filterData.emissao_inicial === '' ||
          filterData.emissao_final === '' ||
          filterData.vencimento_inicial === '' ||
          filterData.vencimento_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOFinancas':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOFee':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'FeeResumo':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOMatrizDesempenho':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOPesquisaSatisfacao':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOMatinalVespertina':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOColetas':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOJustificativaColeta':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOSpancopCallPlan':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOHSSE':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'OportunidadesSpancop':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'ChecklistMotoClube':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'ChecklistClubeProfissional':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'CheckListVarejo':
        if (
          filterData.data_inicial === '' ||
          filterData.data_final === '' ||
          filterData.distribuidor.length === 0
        ) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOPromocoes':
        if (filterData.distribuidor.length === 0) {
          setEmptyAlert(true)
          return false
        }
        break
      case 'BOTrocaOleo':
        if (filterData.distribuidor.length === 0) {
          setEmptyAlert(true)
          return false
        }
        break
      default:
        setEmptyAlert(false)
    }

    Axios.defaults.timeout = 1800000
    let getData

    if (loadedModule === 'FeeEstoque') {
      const perfis = ls.get('perfis')

      const perfil = perfis.find(p => {
        return p.envelope_fee === filterData.distribuidor.value
      })
      setFeeProfile(perfil)

      const rpt = ['BOFee1', 'FeeEstoque', 'FeeResumo1']

      const promises = []

      rpt.forEach(r => {
        json = getFormattedJSON(filterData, r)
        api = loadAPI(r, filterData, perfil.url)

        if (r !== 'FeeEstoque') {
          promises.push(
            Axios.post(api.url, json, {
              headers: {
                //'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept-Language': 'pt-BR,en;q=0.9'
              }
            })
          )
        } else {
          promises.push(
            Axios.get(
              api.url,
              { params: json },
              {
                headers: {
                  //'Content-Type': 'application/x-www-form-urlencoded',
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Accept-Language': 'pt-BR,en;q=0.9'
                }
              }
            ).catch(e => {
              setTimeoutA(true)
            })
          )
        }
      })

      let results

      try {
        results = await trackPromise(Promise.all(promises))
      } catch (error) {
        setRstDialog(error.message)
        return false
      }
      getData = results[1]
      const rptData = rpt.map((r, i) => {
        const { data } = results[i]
        return { report: r, data: data }
      })
      setFeeReportsData(rptData)
    } else if (loadedModule === 'BOFee' || loadedModule === 'FeeResumo') {
      const distributorList = filterData.distribuidor
      const promises = []

      distributorList.forEach(distributor => {
        const perfis = ls.get('perfis')
        const perfil = perfis.find(p => {
          return p.envelope_fee === distributor.value
        })

        json = getFormattedJSON(filterData, loadedModule, distributor.value)
        api = loadAPI(loadedModule, filterData, perfil.url)

        promises.push(
          Axios.post(api.url, json, {
            headers: {
              //'Content-Type': 'application/x-www-form-urlencoded',
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept-Language': 'pt-BR,en;q=0.9'
            }
          })
        )
      })

      let promiseData = {}

      await trackPromise(
        Promise.all(promises.map(p => p.catch(e => e)))
          .then(results => {
            const errors = []
            const data = []
            results.forEach(r => {
              if (r instanceof Error) {
                errors.push(r)
              } else {
                data.push(r)
              }
            })

            promiseData = { results: data, errors: errors }
          })
          .catch(e => console.log(e))
      )

      if (promiseData.errors.length > 0) {
        const errors = promiseData.errors.map(err => {
          const dataError = JSON.parse(err.config.data)
          return {
            error: err.message,
            url: err.config.url,
            distribuidor: dataError.distribuidor
          }
        })
        setApiErrorList(errors)
        setApiError(true)
      }

      getData = promiseData.results
        .filter(
          row =>
            row.data.length !== 0 &&
            row.data.message !==
              'The request has been fulfilled and resulted in a new resource being created.'
        )
        .reduce((arr, row) => arr.concat(row.data), [])

      //const promiseData = { results: results.data, errors: errors }
      //getData = promiseData
    } else {
      const distributorList = filterData.distribuidor
      const promises = []

      distributorList.forEach(distributor => {
        const perfis = ls.get('perfis')

        const perfil = perfis.filter(perfil => {
          return perfil.distribuidor === distributor.value
        })

        if (perfil[0].url !== '') {
          api = loadAPI(loadedModule, filterData, perfil[0].url)
          json = getFormattedJSON(filterData, loadedModule, distributor.value)
          promises.push(
            Axios.post(api.url, json, {
              headers: {
                //'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept-Language': 'pt-BR,en;q=0.9'
              }
            })
          )
        }
      })

      let promiseData = {}

      await trackPromise(
        Promise.all(promises.map(p => p.catch(e => e)))
          .then(results => {
            const errors = []
            const data = []
            results.forEach(r => {
              if (r instanceof Error) {
                errors.push(r)
              } else {
                data.push(r)
              }
            })

            promiseData = { results: data, errors: errors }
          })
          .catch(e => console.log(e))
      )

      if (promiseData.errors.length > 0) {
        const errors = promiseData.errors.map(err => {
          const dataError = JSON.parse(err.config.data)
          return {
            error: err.message,
            url: err.config.url,
            distribuidor: dataError.distribuidor
          }
        })
        setApiErrorList(errors)
        setApiError(true)
      }

      getData = promiseData.results
        .filter(
          row =>
            row.data.length !== 0 &&
            row.data.message !==
              'The request has been fulfilled and resulted in a new resource being created.'
        )
        .reduce((arr, row) => arr.concat(row.data), [])
    }

    if (!timeoutA) {
      let newData = []
      if (getData !== undefined || getData.data !== undefined) {
        newData = getData.data ? getData.data : getData
      }

      setReportData(newData)
    }
  }

  const getHeaderItems = () => {
    return loadComponentHeaders(loadedModule)
  }

  const handleFechamento = async () => {
    setProcessFechamento(true)
    const json = {
      distribuidor: filterData.distribuidor.value,
      data_inicial: moment(filterData.dt_inicial).format('DD/MM/YYYY'),
      data_final: moment(filterData.dt_final).format('DD/MM/YYYY'),
      itens: reportData
    }

    const distributorIndex = distributors.findIndex(
      d => d.envelope_fee === filterData.distribuidor.value
    )
    const url = distributors[distributorIndex].url

    let data

    try {
      const result = await Axios.post(`${url}INT_SHELL_FEE_GRAVACAO`, json)
      data = result.data
    } catch (error) {
      setRstDialog(
        `ERRO!: ${error.response.data.errorCode} - ${error.response.data.errorMessage}`
      )
      setProcessFechamento(false)
      return false
    }

    setProcessFechamento(false)
    setRstDialog(data.retorno)
  }

  const LoadedForm = formComponents['Form' + loadedModule]

  const buttonExcelClass =
    reportData.length === 0
      ? 'bg-gray-300 text-gray-100 py-2 px-4 rounded text-sm cursor-default mr-2'
      : 'bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded text-sm cursor-pointer mr-2'

  const buttonFechamentoClass =
    reportData.length === 0
      ? 'bg-gray-300 text-gray-100 py-2 px-4 rounded text-sm cursor-default mr-2'
      : 'bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded text-sm cursor-pointer mr-2'

  const Alert = () => {
    let messages =
      'As urls abaixo retornaram erros e seus dados não foram retornados na consulta: <br/><br/>'

    apiErrorList.forEach(err => {
      messages += `<p><b>Distribuidor:</b> <span>${err.distribuidor}</span> <br/><b>URL:</b> <span className='text-red-700'>${err.url}</span> - <b>Erro:</b> <span className='text-red-700'>${err.error}</span></p>`
    })

    messages +=
      '<p>Os dados dos demais distribuidores/filiais, se houverem, estão prontos para exportação.</p>'

    return (
      <AlertDialog
        title='Atenção'
        open={apiError}
        text={messages}
        onClick={() => {
          setApiError(false)
        }}
      />
    )
  }

  return (
    <div
      className='w-full rounded overflow-hidden shadow-lg m-auto mt-8 border border-solid border-gray-400 bg-white'
      style={{ minHeight: 400 }}
    >
      <div className='px-6 py-4'>
        <div className='text-xl mb-2'>
          Parâmetros{' '}
          <span className='text-red-600 text-xs'>
            Campos com * são obrigatórios
          </span>
        </div>

        <hr />

        {loadedModule && <LoadedForm />}

        <hr />
        <div className='flex flex-reverse text-red-400 text-xs'>
          {rstDialog && rstDialog.toUpperCase()}
        </div>
      </div>
      <div className='px-6 pb-2 float-right'>
        <button
          className='bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 rounded text-sm'
          onClick={loadReport}
        >
          {promiseInProgress ? (
            <span>
              <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' />{' '}
              Aguarde
            </span>
          ) : (
            <span>
              <FontAwesomeIcon icon={faFilter} /> Filtrar
            </span>
          )}
        </button>
        <button
          className={buttonExcelClass}
          onClick={() => handleExportClick('xlsb')}
          disabled={reportData.length === 0}
        >
          <span>
            <FontAwesomeIcon
              icon={processXLS ? faCircleNotch : faFileExcel}
              className={`${processXLS ? 'fa-spin' : ''}`}
            />{' '}
            {processXLS
              ? 'Processando exportação, aguarde...'
              : loadedModule === 'FeeEstoque'
              ? 'Exportar este relatório - Excel'
              : 'Exportar para Excel'}
          </span>
        </button>
        {loadedModule === 'FeeEstoque' && (
          <button
            className={buttonExcelClass}
            onClick={() => handleExportMultiple('xlsb')}
          >
            <span>
              <FontAwesomeIcon
                icon={processXLS ? faCircleNotch : faFileExcel}
                className={`${processXLS ? 'fa-spin' : ''}`}
              />{' '}
              {processXLS
                ? 'Processando exportação, aguarde...'
                : 'Exportar todos os relatórios - Excel'}
            </span>
          </button>
        )}
        <button
          className={buttonExcelClass}
          onClick={() => handleExportClick('csv')}
          disabled={reportData.length === 0}
        >
          <span>
            <FontAwesomeIcon
              icon={processXLS ? faCircleNotch : faFileCsv}
              className={`${processXLS ? 'fa-spin' : ''}`}
            />{' '}
            {processXLS
              ? 'Processando exportação, aguarde...'
              : 'Exportar este relatório - CSV'}
          </span>
        </button>
        {loadedModule === 'FeeEstoque' && (
          <button className={buttonFechamentoClass} onClick={handleFechamento}>
            <span>
              <FontAwesomeIcon
                icon={
                  processXLS || processFechamento ? faCircleNotch : faFileUpload
                }
                className={`${
                  processXLS || processFechamento ? 'fa-spin' : ''
                }`}
              />{' '}
              {processXLS || processFechamento ? '' : 'Fechamento'}
            </span>
          </button>
        )}
      </div>
      <div className='px-6 py-4'>
        {promiseInProgress ? (
          <LoadingIndicator />
        ) : (
          reportData.length !== 0 &&
          (loadedModule === 'FeeEstoque' ? (
            <DataTableFeeEstoque />
          ) : (
            <DataTable headerItems={getHeaderItems()} />
          ))
        )}
      </div>
      <Alert />
    </div>
  )
}
