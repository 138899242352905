import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import {
  PagingState,
  IntegratedPaging,
  EditingState,
  SummaryState,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  FilteringState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableInlineCellEditing,
  TableGroupRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui'
import { rawApproveDataAtom, selectionAtom } from '../_global/Atoms'
import { useAtom } from 'jotai'
import { usePromiseTracker } from 'react-promise-tracker'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import ls from 'local-storage'

const columns = [
  { name: 'sku', title: 'SKU' },
  { name: 'distribuidor', title: 'Distribuidor' },
  { name: 'descricao', title: 'Produto' },
  { name: 'armazem', title: 'Armazém' },
  { name: 'limite', title: 'Qtd Trava (Embalagem)' },
  { name: 'pedido', title: 'Qtd Comprada' },
  { name: 'extra', title: 'Qtd Extra' },
  { name: 'nova_trava', title: 'Qtd Trava (Nova)' }
]

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
)

const TableRow = ({ row, ...restProps }) => (
  <Table.Row
    {...restProps}
    // eslint-disable-next-line no-alert
    //onClick={() => alert(JSON.stringify(row))}
    style={{
      cursor: 'pointer',
      backgroundColor: row.subiu === 'S' ? 'yellow' : 'white'
    }}
  />
)

const tableMessages = {
  noData: 'Nenhum dado.'
}

const pagingPanelMessages = {
  showAll: 'Todos',
  rowsPerPage: 'Registros por página',
  info: 'Registros {from} até {to} ({count} registros)'
}

// const summaryMessages = {
//   sum: 'Soma'
// }

export default function () {
  const user = ls.get('user')
  const [rawApproveData, setRawApproveData] = useAtom(rawApproveDataAtom)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [pageSizes] = useState([25, 50, 100])
  const [selection, setSelection] = useAtom(selectionAtom)
  const [editingStateColumnExtensions, setEditing] = useState([
    { columnName: 'sku', editingEnabled: false },
    { columnName: 'distribuidor', editingEnabled: false },
    { columnName: 'descricao', editingEnabled: false },
    { columnName: 'armazem', editingEnabled: false },
    { columnName: 'limite', editingEnabled: false },
    { columnName: 'pedido', editingEnabled: false },
    { columnName: 'extra', editingEnabled: false }
  ])
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'distribuidor', filteringEnabled: false },
    { columnName: 'descricao', filteringEnabled: false },
    { columnName: 'armazem', filteringEnabled: false },
    { columnName: 'limite', filteringEnabled: false },
    { columnName: 'pedido', filteringEnabled: false },
    { columnName: 'extra', filteringEnabled: false },
    { columnName: 'nova_trava', filteringEnabled: false }
  ])

  useEffect(() => {
    const ids = rawApproveData.map(r => r.id)
    setSelection(ids)
    // eslint-disable-next-line
  }, [rawApproveData])

  useEffect(() => {
    if (user === 'planejamento') {
      const newEditing = editingStateColumnExtensions
      newEditing.push({ columnName: 'nova_trava', editingEnabled: true })
      setEditing(newEditing)
    } else {
      const newEditing = editingStateColumnExtensions
      newEditing.push({ columnName: 'nova_trava', editingEnabled: false })
      setEditing(newEditing)
    }
  }, [user, editingStateColumnExtensions])

  const { promiseInProgress } = usePromiseTracker()

  const getRowId = row => row.id

  const commitChanges = ({ changed }) => {
    let changedRows
    if (changed) {
      const keys = Object.keys(changed)
      const id = keys[0]
      if (changed[id]) {
        changed[id].nova_trava = Number(changed[id].nova_trava)
      }
      changedRows = rawApproveData.map(row =>
        changed[row.id] ? { ...row, ...changed[row.id], subiu: 'S' } : row
      )
    }
    setRawApproveData(changedRows)
    setSelection(changedRows)
  }

  return (
    <div>
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Paper>
          <Grid rows={rawApproveData} columns={columns} getRowId={getRowId}>
            {user !== 'planejamento' && (
              <SelectionState
                selection={selection}
                onSelectionChange={setSelection}
              />
            )}
            {user !== 'planejamento' && <IntegratedSelection />}
            <GroupingState grouping={[{ columnName: 'sku' }]} />
            <IntegratedGrouping />
            <SummaryState
              totalItems={[
                { columnName: 'sku', type: 'count' },
                { columnName: 'limite', type: 'sum' },
                { columnName: 'pedido', type: 'sum' },
                { columnName: 'extra', type: 'sum' },
                { columnName: 'nova_trava', type: 'sum' }
              ]}
              groupItems={[
                { columnName: 'sku', type: 'count' },
                { columnName: 'limite', type: 'sum', alignByColumn: true },
                { columnName: 'pedido', type: 'sum', alignByColumn: true },
                { columnName: 'extra', type: 'sum', alignByColumn: true },
                { columnName: 'nova_trava', type: 'sum', alignByColumn: true }
              ]}
            />
            <IntegratedSummary />
            <FilteringState columnExtensions={filteringStateColumnExtensions} />
            <IntegratedFiltering />
            <EditingState
              onCommitChanges={commitChanges}
              columnExtensions={editingStateColumnExtensions}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              cellComponent={FocusableCell}
              rowComponent={TableRow}
              messages={tableMessages}
            />
            <TableHeaderRow />
            {user !== 'planejamento' && <TableSelection showSelectAll />}
            <TableGroupRow />
            <TableInlineCellEditing startEditAction='click' />
            <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
          </Grid>
        </Paper>
      )}
    </div>
  )
}
