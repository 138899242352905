import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {
  filterDataAtom,
  reportDataAtom,
  selectedRowsAtom
} from '../../../_global/Atoms'
import DataTable from '../DataTable'

const columnsLevel1 = [
  { name: 'tipo_produto', title: 'Tipo do Produto' },
  { name: 'pedidos', title: 'Produto por notas' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

const columnsLevel2 = [
  { name: 'cod_produto', title: 'Código' },
  { name: 'descricao_produto', title: 'Produto' },
  { name: 'pedidos', title: 'Produto por notas' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

const columnsLevel3 = [
  { name: 'nome_cliente', title: 'Cliente' },
  { name: 'emissao', title: 'Emitido em' },
  { name: 'data_inclusao_erp', title: 'Data Inclusão no ERP' },
  { name: 'codigo_vendedor', title: 'Vendedor' },
  { name: 'numero', title: 'No. Notas' },
  { name: 'status', title: 'Status do Pedido' },
  { name: 'total', title: 'R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

export default function RevenuesByProduct() {
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [currentColumns, setCurrentColumns] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [filterData] = useAtom(filterDataAtom)
  const [selectedRows] = useAtom(selectedRowsAtom)
  const [dataLevel1, setDataLevel1] = useState([])
  const [dataLevel2, setDataLevel2] = useState([])
  const [dataLevel3, setDataLevel3] = useState([])

  // Trazer os dados do relatório geral e reduzir para o relatório por dia
  //const resumeDataColumns = () => {}

  useEffect(() => {
    if (reportData.length > 0) {
      const level1 = jsonLevel1()
      setDataLevel1(level1)
    } else {
      setDataLevel1([])
    }
    // eslint-disable-next-line
  }, [reportData])

  useEffect(() => {
    if (Object.keys(selectedRows.level1).length > 0) {
      const level2 = jsonLevel2()
      setDataLevel2(level2)
    }

    if (Object.keys(selectedRows.level2).length > 0) {
      const level3 = jsonLevel3()
      setDataLevel3(level3)
    }
    // eslint-disable-next-line
  }, [selectedRows])

  const jsonLevel1 = () => {
    const newArray = []
    let numero

    const filtered = reportData.filter(row => row.tipo === 'NF')

    filtered.forEach(row => {
      row.itens.forEach(item => {
        const pedidos = numero !== row.numero ? 1 : 0
        const findIndex = newArray.findIndex(
          arrItem => arrItem.tipo_produto === item.tipo_produto
        )

        if (findIndex === -1) {
          newArray.push({
            tipo_produto: item.tipo_produto,
            pedidos: 1,
            total: item.valor_produto,
            quantidade: item.qtde_produto,
            volume: item.volume_produto
          })
        } else {
          newArray[findIndex].pedidos += pedidos
          newArray[findIndex].total += item.valor_produto
          newArray[findIndex].quantidade += item.qtde_produto
          newArray[findIndex].volume += item.volume_produto
        }
        numero = row.numero
      })
    })

    return newArray
  }

  const jsonLevel2 = () => {
    const newArray = []
    let numero = ''
    //console.log(selectedRows)

    const filtered = reportData
      .filter(row => row.tipo === 'NF')
      .filter(row =>
        row.itens.find(
          i => i.tipo_produto === selectedRows.level1.row.tipo_produto
        )
      )

    filtered.forEach(row => {
      row.itens.forEach(item => {
        const pedidos = numero !== row.numero ? 1 : 0
        const findIndex = newArray.findIndex(
          arrItem => arrItem.cod_produto === item.cod_produto
        )

        if (item.tipo_produto === selectedRows.level1.row.tipo_produto) {
          if (findIndex === -1) {
            newArray.push({
              cod_produto: item.cod_produto,
              descricao_produto: item.descricao_produto,
              pedidos: 1,
              total: item.valor_produto,
              quantidade: item.qtde_produto,
              volume: item.volume_produto
            })
          } else {
            newArray[findIndex].pedidos += pedidos
            newArray[findIndex].total += item.valor_produto
            newArray[findIndex].quantidade += item.qtde_produto
            newArray[findIndex].volume += item.volume_produto
          }
          numero = row.numero
        }
      })
    })

    return newArray
  }

  const jsonLevel3 = () => {
    const { tipo_produto } = selectedRows.level1.row
    const { cod_produto } = selectedRows.level2.row
    const filtered = reportData
      .filter(item => item.tipo === 'NF')
      .filter(item =>
        item.itens.find(
          i => i.tipo_produto === tipo_produto && i.cod_produto === cod_produto
        )
      )

    const newArray = filtered.map(row => {
      const quantidade = row.itens
        .filter(item => item.tipo_produto === tipo_produto)
        .reduce((acc, item) => acc + item.qtde_produto, 0)
      return {
        nome_cliente: row.nome_cliente,
        emissao: row.emissao,
        data_inclusao_erp: '',
        codigo_vendedor: row.codigo_vendedor,
        numero: row.numero,
        status: row.status,
        total: row.valor,
        quantidade: quantidade,
        volume: row.volume
      }
    })

    return newArray
  }

  useEffect(() => {
    switch (filterData.report_level) {
      case 1:
        setCurrentColumns(columnsLevel1)
        setCurrentData(dataLevel1)
        break
      case 2:
        setCurrentColumns(columnsLevel2)
        setCurrentData(dataLevel2)
        break
      case 3:
        setCurrentColumns(columnsLevel3)
        setCurrentData(dataLevel3)
        break
      default:
        return false
    }
    // eslint-disable-next-line
  }, [filterData.report_level, dataLevel1])

  return (
    <div className='mt-2'>
      <DataTable
        columns={currentColumns}
        rows={currentData}
        title='Pedidos por Produto'
        report='ordersByProduct'
      />
    </div>
  )
}
