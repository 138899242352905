import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { emptyAlertAtom, loadedModuleAtom } from '../components/_global/Atoms'

import Header from './_global/Header'
import Accordion from './_global/Accordion'

import { listMenuItems } from '../constData/constData'
import BaseComponent from './Bases/BaseComponent'
import NewTab from './Bases/NewTab'

import AlertDialog from './_global/AlertDialog'
import ls from 'local-storage'
import { logout } from '../functions/functions'
import api from '../api/api'
import LimitePC from '../components/LimitePC/LimitePC'
import UploadFWS from './Campaign/UploadFWS'
import UserControl from './UserControl/UserControl'

const items = listMenuItems(ls.get('user'), ls.get('perfil'))

const loadModule = loadedModule => {
  let module = null
  switch (loadedModule) {
    case 'dashboard':
      module = <NewTab url='/dashboard' />
      break
    case 'LimitePC':
      module = <LimitePC />
      break
    case 'ApproveExtra':
      module = <NewTab url='/aprovacaoextra' />
      break
    case 'Research':
      module = <NewTab url='/pesquisa/1' />
      break
    case 'FeeEstoque':
      module = <NewTab url='/feeestoque' />
      break
    case 'campaignModuleItem':
      module = <NewTab url='/campaign' />
      break
    case 'uploadFWS':
      module = <UploadFWS />
      break
    case 'UserControl':
      module = <UserControl />
      break
    default:
      module = <BaseComponent />
  }
  return module
}

export default function Home() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [emptyAlert, setEmptyAlert] = useAtom(emptyAlertAtom)

  useEffect(() => {
    if (ls.get('perfil') === 'supervisor') {
      window.location = '/supervisor'
    }
  }, [])

  useEffect(() => {
    setInterval(() => {
      const sessionID = ls.get('session')
      const getData = async () => {
        const { data } = await api.validate({ session: sessionID })
        if (data.status !== 'valid') {
          logout()
        }
      }
      getData()
    }, 20000)
  }, [])

  const user = ls.get('user')

  return (
    <div className='w-full h-full'>
      <Header
        label='BackOffice Shell'
        title='Shell BackOffice - Menu'
        user={user}
        exit={true}
      />
      <div className='m-2'>
        <div className='grid gap-2 grid-cols-9'>
          <div className='col-span-2 border-gray-400 border-2 rounded p-2 text-sm'>
            {items.map(item => (
              <Accordion
                key={item.name}
                title={item.label}
                items={item.items}
              />
            ))}
          </div>
          <div className='col-span-7 border-gray-400 border-2 rounded p-2 w-full'>
            {loadedModule && loadModule(loadedModule)}
          </div>
        </div>
      </div>
      <AlertDialog
        title='Atenção'
        text='Campos obrigatórios não preenchidos. Todos os campos com * devem ser preenchidos antes de continuar.'
        onClick={() => setEmptyAlert(false)}
        open={emptyAlert}
      />
    </div>
  )
}
