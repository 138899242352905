import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import React from 'react'
import ResultsTable from './ResultsTable'

export default function DialogList({ data, resultsByMonth }) {
  let dataGrid

  const dataMonth = resultsByMonth.find(item => item.month === data.month)

  if (data.type === 'atingiram') {
    dataGrid = dataMonth?.atingiramList
  } else {
    dataGrid = dataMonth?.naoAtingiramList
  }

  const compiled = dataGrid
    ?.map(item => {
      return {
        ...item,
        perc_total: Math.round((item.faturado / item.alvo) * 100)
      }
    })
    .sort((a, b) => b.perc_total - a.perc_total)

  return (
    <Dialog open={data.open} fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <ResultsTable data={compiled} />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => data.setOpen({ ...data, open: false })}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
