import { atom } from 'jotai'

export const loadedAtom = atom(false)
export const loadingAtom = atom(false)
export const loadedModuleAtom = atom(null)
export const timeoutAtom = atom(false)
export const reportDataAtom = atom([])
export const filterDataAtom = atom({
  distribuidor: [],
  distribuidores: [],
  tipo_cliente: '',
  ult_compra_de: '',
  ult_compra_ate: '',
  status_cliente: '',
  dt_inicial: '',
  dt_final: '',
  data_inicial: '',
  data_final: '',
  cnpjs: '',
  segmento1: '',
  segmento2: '',
  armazem: '',
  tipo_prod: '',
  tipo_nf: '',
  tipo_produto: '',
  vendedor: '',
  supervisor: '',
  gerente: '',
  emissao_inicial: '',
  emissao_final: '',
  vencimento_inicial: '',
  vencimento_final: '',
  situacao: '',
  canal_venda: '',
  pagamento_inicial: '',
  pagamento_final: '',
  ano: '',
  tipo_cliente_coaching: '',
  tipo_matriz_desempenho: '',
  canal: '',
  loaded_report: '',
  report_level: 1,
  dates: []
})

export const approveExtraAtom = atom([])
export const rawApproveDataAtom = atom([])
export const importedDataAtom = atom([])
export const approveExtraHideAll = atom(false)
export const emptyAlertAtom = atom(false)
export const processXLSAtom = atom(false)
export const apiErrorAtom = atom(false)
export const apiErrorListAtom = atom([])
export const viewErrorsAtom = atom(false)
export const errorMessageAtom = atom('')
export const approveItemsCountAtom = atom(0)
export const selectionAtom = atom([])
export const campaignAtom = atom({})
export const indicadorAtom = atom(false)
export const supervisorRowsAtom = atom([])
export const supervisorModuleGraphsDataAtom = atom([])
export const selectedSupervisorMonthAtom = atom(false)
export const selectedCoachingAtom = atom(false)
export const openCoachingDialogAtom = atom(false)
export const coachingDialogTypeAtom = atom(false)
export const alertParamsAtom = atom({ open: false, text: '', onClick: false })
export const selectedSellerAtom = atom(false)
export const sellerCustomersAtom = atom([])
export const newResponsesAtom = atom({
  distribuidor: '',
  supervisor: '',
  vendedor: '',
  data_coaching: '',
  id_coaching: '',
  tipo: '',
  codigo_cliente: '',
  loja_cliente: '',
  mercha: '',
  exposi: '',
  precif: '',
  planej: '',
  verifi: '',
  verexp: '',
  relchk: '',
  verest: '',
  verprc: '',
  verpri: ''
})

export const supervisorReportDatesAtom = atom({ from: '', to: '' })
export const supervisorSellerAtom = atom({})
export const selectedRowsAtom = atom({ level1: {}, level2: {}, level3: {} })
export const feeReportsDataAtom = atom([])
export const tempCampaignListAtom = atom([])
export const currentCampaignAtom = atom({})
export const openGroupTargetsAtom = atom(false)
export const groupRowAtom = atom({})
export const customersAtom = atom([])
export const customersLoadedAtom = atom(false)
export const tableColumnsAtom = atom([])