import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useAtom } from 'jotai'

import Header from '../_global/Header'
import DataTable from './DataTable'
import ComponentContainer from '../_global/ComponentContainer'
import {
  rawApproveDataAtom,
  loadedModuleAtom,
  loadingAtom,
  apiErrorAtom,
  apiErrorListAtom,
  filterDataAtom,
  errorMessageAtom,
  selectionAtom
} from '../_global/Atoms'
import { makeJson, returnBlob } from '../../functions/functions'
import FileSaver from 'file-saver'
import Form from './Form'
import moment from 'moment'
import Axios from 'axios'
import Buttons from './Buttons'
import md5 from 'md5'
import AlertDialog from '../_global/AlertDialog'
import ls from 'local-storage'
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ApproveExtra() {
  // eslint-disable-next-line
  const [rawApproveData, setRawApproveData] = useAtom(rawApproveDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const [apiError, setApiError] = useAtom(apiErrorAtom)
  // eslint-disable-next-line
  const [apiErrorList, setApiErrorList] = useAtom(apiErrorListAtom)
  // eslint-disable-next-line
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)
  const [selection] = useAtom(selectionAtom)
  const [finished, setFinished] = useState(true)

  useEffect(() => {
    if (apiErrorList) {
      if (apiErrorList.length !== 0) {
        const message = getErrorMessage()
        setErrorMessage(message)
      }
    }
    // eslint-disable-next-line
  }, [apiErrorList])

  const getErrorMessage = () => {
    let message = 'As urls abaixo apresentaram erro:<br><br>'
    apiErrorList.forEach(err => {
      message += `<b>URL:</b> ${err.url}<br><b>Error:</b> ${err.error}<br><br>`
    })
    return message
  }

  const handleExportClick = async () => {
    setLoading(true)
    const exportData = rawApproveData.map(r => {
      return {
        id: r.id,
        sku: r.sku,
        distribuidor: r.distribuidor,
        descricao: r.descricao,
        armazem: r.armazem,
        limite: r.limite,
        pedido: r.pedido,
        extra: r.extra,
        nova_trava: r.nova_trava
      }
    })

    const fileName = loadedModule
    const generateExcelJson = await makeJson('ApproveExtraExport', exportData)
    const blob = await returnBlob(loadedModule, generateExcelJson, 'xlsb')
    // eslint-disable-next-line
    const saved = await FileSaver(blob, `${fileName}.xlsb`)
    setLoading(false)
  }

  const user = ls.get('user')

  const handleSave = async () => {
    await setProcessing(true)
    await setApiError(false)
    await setApiErrorList([])
    const perfis = ls.get('perfis')
    const promises = []

    perfis.forEach(perfil => {
      const data = rawApproveData
        .filter(d => {
          return d.distribuidor === perfil.distribuidor
        })
        .map(d => {
          return {
            distribuidor: d.distribuidor,
            sku: d.sku,
            armazem: d.armazem,
            dataini: moment(filterData.dt_inicial).format('DD/MM/YYYY'),
            datafim: moment(filterData.dt_final).format('DD/MM/YYYY'),
            nova_trava: d.nova_trava,
            usuario: user
          }
        })

      if (data.length !== 0) {
        if (perfil.url !== '') {
          promises.push(
            Axios.post(`${perfil.url}INT_SHELL_LIMPCS_NOVATRAVA`, data)
          )
        }
      }
    })

    let promiseData = {}

    Promise.all(promises.map(p => p.catch(e => e)))
      .then(results => {
        const errors = []
        const data = []
        results.forEach(r => {
          if (r instanceof Error) {
            errors.push(r)
          } else {
            data.push(r)
          }
        })

        promiseData = { results: data, errors: errors }

        if (promiseData.errors.length > 0) {
          const errors = promiseData.errors.map(err => {
            return {
              id: md5(new Date()),
              error: err.message,
              url: err.config.url,
              distribuidor: err.config.url
            }
          })

          setApiErrorList(errors)
        }

        setProcessing(false)
        setFinished(true)
      })
      .catch(e => console.log(e))
  }

  const handleApprove = async () => {
    await setProcessing(true)
    await setApiError(false)
    await setApiErrorList([])
    const perfis = ls.get('perfis')
    const promises = []

    const dataApproved = rawApproveData.filter(d => selection.includes(d.id))

    perfis.forEach(perfil => {
      const data = dataApproved
        .filter(d => {
          return d.distribuidor === perfil.distribuidor
        })
        .map(d => {
          return {
            distribuidor: d.distribuidor,
            sku: d.sku,
            armazem: d.armazem,
            dataini: moment(filterData.dt_inicial).format('DD/MM/YYYY'),
            datafim: moment(filterData.dt_final).format('DD/MM/YYYY'),
            nova_trava: d.nova_trava,
            usuario: user
          }
        })

      if (data.length !== 0) {
        if (perfil.url !== '') {
          promises.push(
            Axios.post(`${perfil.url}INT_SHELL_LIMPCS_NOVATRAVA`, data)
          )
        }
      }
    })

    let promiseData = {}

    Promise.all(promises.map(p => p.catch(e => e)))
      .then(results => {
        const errors = []
        const data = []
        results.forEach(r => {
          if (r instanceof Error) {
            errors.push(r)
          } else {
            data.push(r)
          }
        })

        promiseData = { results: data, errors: errors }

        if (promiseData.errors.length > 0) {
          const errors = promiseData.errors.map(err => {
            return {
              id: md5(new Date()),
              error: err.message,
              url: err.config.url,
              distribuidor: err.config.url
            }
          })

          setApiErrorList(errors)
        }

        setProcessing(false)
        setFinished(true)
      })
      .catch(e => console.log(e))
  }

  return (
    <Fragment>
      <Header
        label='Aprovação de Compras Extras'
        title='Aprovação de Compras Extras'
        user={user}
      />
      <ComponentContainer>
        <Form />

        <Buttons
          handleSave={handleSave}
          handleExportClick={handleExportClick}
          handleApprove={handleApprove}
          user={user}
          loading={loading}
        />

        <div>
          {processing && (
            <div className='p-2 mt-5 bg-red-400 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex w-full '>
              Processando...
            </div>
          )}
          {finished && (
            <div className='flex flex-row-reverse p-2 mt-5 bg-indigo-400 items-center text-indigo-100 leading-none lg:rounded-full  lg:inline-flex w-full '>
              <div className='w-1/12 cursor-pointer'>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => setFinished(false)}
                />
              </div>
              <div className='w-11/12 '>
                Dados enviados. Se houverem erros no envio serão listados
                abaixo.
              </div>
            </div>
          )}
        </div>

        {apiErrorList && apiErrorList.length !== 0 && (
          <div className='text-xs text-red-300'>
            <FontAwesomeIcon icon={faInfoCircle} className='text-red-400' />{' '}
            {apiErrorList.length} urls retornaram com erro.{' '}
            <u onClick={() => setApiError(true)} className='cursor-pointer'>
              Clique para ver.
            </u>
          </div>
        )}
        <div className='mt-4 p-2'>
          <DataTable />
        </div>

        <hr />
      </ComponentContainer>
      <AlertDialog
        text={errorMessage}
        open={apiError}
        scroll='paper'
        onClick={() => {
          setApiError(false)
        }}
      />
    </Fragment>
  )
}
