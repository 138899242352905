import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import Axios from 'axios'

import ConfirmDialog from '../_global/ConfirmDialog'
import AlertDialog from '../_global/AlertDialog'

import Form from './Form'
import DataTable from './DataTable'
import {
  filterDataAtom,
  modalDataAtom,
  modalInsertAtom,
  okAlertAtom,
  promptSendAllAtom,
  promptUpdateTodasDatasAtom,
  reportDataAtom,
  sendErrorAtom,
  sendSuccessAtom,
  updateDataAtom
} from './Atoms'
import { useAtom } from 'jotai'
import { Fragment } from 'react'
import {
  loadDefaultFilter,
  retrieveLimiteData
} from '../../functions/functions'
import { loadedModuleAtom } from '../_global/Atoms'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { Button } from '@material-ui/core'
import {
  AddCircle,
  Backup,
  DeleteForever,
  DeleteForeverSharp,
  Edit,
  FilterListSharp
} from '@material-ui/icons'

import ImportDataDialog from './ImportDataDialog'
import UpdateDataDialog from './UpdateDataDialog'
import AddDataDialog from './AddDataDialog'
import ls from 'local-storage'

const Filter = () => {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [updateData] = useAtom(updateDataAtom)
  const [modalData, setModalData] = useAtom(modalDataAtom)
  const [modalInsert, setModalInsert] = useAtom(modalInsertAtom)
  const [promptSendAll, setPromptSendAll] = useAtom(promptSendAllAtom)
  const [promptUpdateTodasDatas, setPromptUpdateTodasDatas] = useAtom(
    promptUpdateTodasDatasAtom
  )
  const [okAlert, setOkAlert] = useAtom(okAlertAtom)
  const [sendError, setSendError] = useAtom(sendErrorAtom)
  const [sendSuccess, setSendSuccess] = useAtom(sendSuccessAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [importOpen, setImportOpen] = useState(false)
  const [cleanTravasDialog, setCleanTravasDialog] = useState(false)
  const { promiseInProgress } = usePromiseTracker()

  const cleanReport = () => {
    setReportData([])
    loadDefaultFilter(loadedModule)
  }

  const loadReport = async () => {
    const { result } = await trackPromise(
      retrieveLimiteData(loadedModule, filterData)
    )

    let id = 1
    const resultWithId = result.map(item => {
      return {
        ...item,
        id: id++,
        new_item: false,
        alter_datas: false,
        alter_limite: false,
        alter_extra: false,
        imported: false
      }
    })

    setReportData(resultWithId)
  }

  const openPromptSendAll = () => {
    setPromptSendAll(true)
  }

  const closePromptSendAll = () => {
    setPromptSendAll(false)
  }

  const closePromptUpdateTodasDatas = () => {
    setPromptUpdateTodasDatas(false)
  }

  const editDates = () => {
    const newReportData = reportData.map(item => {
      return {
        ...item,
        dataini: updateData.dataini,
        datafim: updateData.datafim
      }
    })
    setReportData(newReportData)
    setPromptUpdateTodasDatas(false)
  }

  const sendAll = async type => {
    const user = ls.get('user')
    const sendDataJSON = reportData.map(item => {
      return {
        distribuidor: item.distribuidor.trim(),
        sku: item.sku,
        descricao: item.descricao,
        dataini: item.dataini,
        datafim: item.datafim,
        armazem: item.armazem,
        limite: item.limite,
        pedido: item.pedido,
        atingido: item.atingido,
        extra: item.extra,
        origem: user === 'icam' ? 'icam' : 'master'
      }
    })

    if (type === 'save') {
      const { data } = await Axios.post(
        `${filterData.distribuidor.url}INT_SHELL_LIMPCS`,
        sendDataJSON
      )
      if (data.CODIGO !== 'OK') {
        setSendError(true)
        setPromptSendAll(false)
      } else {
        setSendSuccess(true)
        setPromptSendAll(false)
      }
    } else {
      const { data } = await Axios.post(
        `${filterData.distribuidor.url}INT_SHELL_EXCL_LIMPCS`,
        sendDataJSON
      )
      if (data.CODIGO !== 'OK') {
        setSendError(true)
        setCleanTravasDialog(false)
      } else {
        setSendSuccess(true)
        setCleanTravasDialog(false)
      }
    }
  }

  const user = ls.get('user')

  return (
    <Fragment>
      <div
        className='w-full rounded shadow-lg m-auto mt-8 border border-solid border-gray-400 bg-white'
        style={{ minHeight: 300 }}
      >
        <div className='px-6 py-4'>
          <div className='text-xl mb-2'>Parâmetros</div>
          <hr />

          <Form />

          <hr />
        </div>
        <div className='flex px-6 pb-2 float-right gap-2'>
          {(user === 'pricing' || user === 'master') && (
            <Fragment>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => setImportOpen(true)}
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              >
                Importar
              </Button>
            </Fragment>
          )}
          <Button
            variant='contained'
            color='primary'
            startIcon={<FilterListSharp />}
            onClick={loadReport}
          >
            Filtrar
          </Button>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<DeleteForever />}
            onClick={cleanReport}
          >
            Limpar
          </Button>
        </div>
      </div>
      <br />
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          {reportData.length > 0 && (
            <div
              className='w-full rounded overflow-hidden shadow-lg m-auto mt-2 border border-solid border-gray-400 bg-white'
              style={{ minHeight: 400 }}
            >
              <div className='px-6 py-4'>
                <div className='mb-2 pb-2'>
                  <div className='float-left text-xl'>
                    Limites{' '}
                    {reportData?.length > 0 && (
                      <span className='inline-block bg-teal-500 text-white text-xs pr-2 pl-2 ml-2 rounded-full'>
                        {reportData.length} resultado
                        {reportData.length > 1 && 's'}
                      </span>
                    )}
                  </div>
                  <div className='flex float-right gap-2'>
                    {(user === 'pricing' || user === 'master') && (
                      <Fragment>
                        <Button
                          variant='outlined'
                          startIcon={<Edit />}
                          onClick={() => {
                            reportData.length > 0 && setModalData(true)
                          }}
                        >
                          Editar datas
                        </Button>
                        <Button
                          variant='outlined'
                          startIcon={<AddCircle />}
                          onClick={() => setModalInsert(true)}
                        >
                          Adicionar
                        </Button>
                        <Button
                          variant='outlined'
                          color='secondary'
                          onClick={() => setCleanTravasDialog(true)}
                          startIcon={<DeleteForeverSharp />}
                        >
                          Limpar Trava
                        </Button>
                      </Fragment>
                    )}
                    {(user === 'icam' || user === 'master') && (
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setImportOpen(true)}
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                      >
                        Importar
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<Backup />}
                      onClick={openPromptSendAll}
                    >
                      Salvar e Enviar
                    </Button>
                  </div>
                </div>
              </div>

              <div className='px-6 py-4'>
                <hr />

                <DataTable />
              </div>

              <UpdateDataDialog open={modalData} setOpen={setModalData} />
              <AddDataDialog open={modalInsert} setOpen={setModalInsert} />

              <ConfirmDialog
                open={promptUpdateTodasDatas}
                text='Deseja alterar todas as datas registradas para as selecionadas?'
                onOK={() => {
                  editDates()
                }}
                onCancel={() => {
                  closePromptUpdateTodasDatas()
                }}
              />

              <ConfirmDialog
                open={promptSendAll}
                text='Deseja salvar e enviar todas as alterações?'
                onOK={() => {
                  sendAll('save')
                }}
                onCancel={() => {
                  closePromptSendAll()
                }}
              />

              <AlertDialog
                open={sendError}
                text='Erro no Envio'
                onClick={() => {
                  setSendError(false)
                  setPromptSendAll(false)
                }}
              />

              <AlertDialog
                open={sendSuccess}
                text='Todos os dados enviados e gravados.'
                onClick={() => {
                  setSendSuccess(false)
                  setPromptSendAll(false)
                }}
              />

              <AlertDialog
                open={okAlert}
                text='Dados atualizados temporariamente! Lembre-se de salvar e enviar as travas!'
                onClick={() => {
                  setOkAlert(false)
                }}
              />

              <ConfirmDialog
                open={cleanTravasDialog}
                text='Tem certeza que deseja limpar todas as travas do distribuidor/periodo selecionado?'
                onOK={() => {
                  sendAll('cleanTravas')
                }}
                onCancel={() => {
                  setCleanTravasDialog(false)
                }}
              />
            </div>
          )}
        </Fragment>
      )}
      <ImportDataDialog
        open={importOpen}
        setOpen={setImportOpen}
        setReportData={setReportData}
        setOkAlert={setOkAlert}
      />
    </Fragment>
  )
}

export default Filter
