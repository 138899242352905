import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/pt-br'
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar as MUIToolbar,
  Typography
} from '@material-ui/core'
import TransitionComponent from '../Campaign/TransitionComponent'
import { Close, PictureAsPdf, Refresh } from '@material-ui/icons'
import Form from './CallPlan/Form'
import ls from 'local-storage'
import Axios from 'axios'
import {
  selectedSellerAtom,
  callPlanModeAtom,
  selectedCallPlanDataAtom,
  defaultCallPlanDataAtom,
  oportunitiesAtom,
  rawEventsAtom
} from './Atoms'
import { useAtom } from 'jotai'
import { horarioList, setorList, tipoVisitaList } from './CallPlan/constData'
import { alertParamsAtom } from '../_global/Atoms'
import CallPlanTable from './CallPlan/CallPlanTable'
import PrintCallPlan from './CallPlan/PrintCallPlan'

const localizer = momentLocalizer(moment)

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const returnHeaderColor = realizado => {
  if (realizado.value) {
    if (realizado?.value === 'S') {
      return {
        backgroundColor: '#2dad1f',
        color: 'white'
      }
    } else {
      return {
        backgroundColor: '#ad1f1f',
        color: 'white'
      }
    }
  }

  if (realizado) {
    if (realizado === 'S') {
      return {
        backgroundColor: '#2dad1f',
        color: 'white'
      }
    } else {
      return {
        backgroundColor: '#ad1f1f',
        color: 'white'
      }
    }
  }

  return {
    backgroundColor: '#e5eb34',
    color: 'black'
  }
}

const DialogCallPlan = ({
  open,
  setOpen,
  schedule,
  callPlanData,
  setOpenPDF
}) => {
  const classes = useStyles()
  const [callPlanMode] = useAtom(callPlanModeAtom)

  const { backgroundColor, color } = returnHeaderColor(callPlanData.realizado)

  return (
    <Dialog fullScreen open={open} TransitionComponent={TransitionComponent}>
      <DialogContent>
        <AppBar className={classes.appBar} style={{ backgroundColor, color }}>
          <MUIToolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <Close />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              CallPlan
            </Typography>
            {callPlanMode === 'edit' && (
              <PictureAsPdf
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenPDF(true)}
              />
            )}
          </MUIToolbar>
        </AppBar>
        <Form {...{ schedule, setOpen }} />
      </DialogContent>
    </Dialog>
  )
}

const today = new Date()

const eventStyleGetter = (event, start, end, isSelected) => {
  let backgroundColor = ''

  if (event.realizado) {
    if (event.realizado === 'S') {
      backgroundColor = '#2dad1f'
    } else {
      backgroundColor = '#ad1f1f'
    }
  } else {
    backgroundColor = '#e5eb34'
  }

  const style = {
    backgroundColor: backgroundColor,
    borderRadius: '15px',
    opacity: 0.8,
    color: 'black',
    border: '0px',
    display: 'block'
  }
  return {
    style: style
  }
}

const MyCalendar = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [schedule, setSchedule] = useState({})
  const [events, setEvents] = useState([])
  const [rawEvents, setRawEvents] = useAtom(rawEventsAtom)
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [, setCallPlanMode] = useAtom(callPlanModeAtom)
  const [callPlanData, setCallPlanData] = useAtom(selectedCallPlanDataAtom)
  const [defaultCallPlanData] = useAtom(defaultCallPlanDataAtom)
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [openPDF, setOpenPDF] = useState(false)

  const makeEvents = events => {
    const transformedEvents = events.map(event => {
      const mx = event.data_prevista.split('/')
      const my = event.hora_inicio.split(':')
      const mz = event.hora_fim.split(':')

      const startDate = new Date(
        '20' + mx[2],
        Number(mx[1]) - 1,
        mx[0],
        my[0] || 0,
        my[1] || 0
      )
      const endDate = new Date(
        '20' + mx[2],
        Number(mx[1]) - 1,
        mx[0],
        mz[0] || 0,
        mz[1] || 0
      )

      return {
        id: event.id,
        title: event.empresa,
        allDay: false,
        start: startDate,
        realizado: event.realizado,
        end: endDate
      }
    })

    setEvents(transformedEvents)
  }

  const getData = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      vendedor: selectedSeller.value,
      supervisor: ''
    }
    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_CALLPLAN`,
      json,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )
    setRawEvents(result.data)
    makeEvents(result.data)
  }

  // useEffect(() => {
  //   selectedSeller.value && getData()
  //   // eslint-disable-next-line
  // }, [selectedSeller])

  useEffect(() => {
    !openDialog && getData()
    // eslint-disable-next-line
  }, [openDialog])

  function getMonday(d) {
    d = new Date(d)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    return new Date(d.setDate(diff))
  }

  const handleNewEvent = e => {
    const now = new Date()

    if (e.start < getMonday(now)) {
      setAlertParams({
        open: true,
        text: 'Não é possível registrar callplans em dias/horários anteriores à semana atual.',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    const tipo = ls.get('tipo')
    setCallPlanData(defaultCallPlanData)
    setCallPlanMode('new')

    if (tipo === 'VENDEDOR') {
      setSchedule(e)
      setTimeout(() => {
        setOpenDialog(true)
      }, 200)
    }
  }

  const handleEditEvent = event => {
    const selectedCallPlan = rawEvents.find(e => e.id === event.id)

    let dataPrevista = ''
    let dataConclusao = ''

    if (selectedCallPlan.data_prevista !== '') {
      const mx = selectedCallPlan.data_prevista.split('/')
      dataPrevista = new Date('20' + mx[2], mx[1] - 1, mx[0])
    }

    if (selectedCallPlan.data_conclusao !== '') {
      const mx = selectedCallPlan.data_conclusao.split('/')
      dataConclusao = new Date('20' + mx[2], mx[1] - 1, mx[0])
    }

    const hora_inicio =
      horarioList.find(v => v.label === selectedCallPlan.hora_inicio) || ''

    const hora_fim =
      horarioList.find(v => v.label === selectedCallPlan.hora_fim) || ''

    const setor = setorList.find(v => v.label === selectedCallPlan.setor) || ''
    const tipo_visita =
      tipoVisitaList.find(v => v.label === selectedCallPlan.tipo_visita) || ''

    const yesterday = new Date()
    yesterday.setHours(0)
    yesterday.setMinutes(0)
    yesterday.setSeconds(0)
    yesterday.setDate(yesterday.getDate() - 1)

    setCallPlanData({
      ...selectedCallPlan,
      data_prevista: dataPrevista,
      data_conclusao: dataConclusao,
      hora_inicio,
      hora_fim,
      setor,
      tipo_visita,
      expired: false
    })
    setCallPlanMode('edit')
    setTimeout(() => {
      setOpenDialog(true)
    }, 200)
  }

  return (
    <div>
      <Box
        display='flex'
        justifyContent='flex-end'
        onClick={() => getData()}
        style={{ cursor: 'pointer' }}
      >
        <Refresh /> Atualizar{' '}
      </Box>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        defaultView='work_week'
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 7)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 22)
        }
        views={['work_week']}
        style={{ height: 450 }}
        culture='pt-br'
        selectable={true}
        eventPropGetter={eventStyleGetter}
        onSelectSlot={e => handleNewEvent(e)}
        onSelectEvent={event => handleEditEvent(event)}
      />
      <DialogCallPlan
        {...{ schedule, callPlanData, setCallPlanMode, setOpenPDF }}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <PrintCallPlan {...{ openPDF, setOpenPDF }} />
    </div>
  )
}

export default function CallPlan() {
  const [, setOportunities] = useAtom(oportunitiesAtom)
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [tab, setTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const getOportunities = async () => {
    const perfis = ls.get('perfis')
    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_BUSCA_OPORTUNIDADE`,
      {
        distribuidor: perfis[0].label,
        vendedor: selectedSeller.value,
        supervisor: ''
      },
      {
        headers: {
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    if (result.status !== 200 && result.status !== 201) {
      // erro
      return false
    }

    return result.data
  }

  const getData = async () => {
    const data = await getOportunities()
    setOportunities(data)
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  //return <MyCalendar />

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label='basic tabs example'
        >
          <Tab label='Calendário' {...a11yProps(0)} />
          <Tab label='Tabela' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <MyCalendar />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CallPlanTable />
      </TabPanel>
    </>
  )
}
