import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {
  filterDataAtom,
  reportDataAtom,
  selectedRowsAtom
} from '../../../_global/Atoms'
import DataTable from '../DataTable'

const columnsLevel1 = [
  { name: 'codigo_vendedor', title: 'Código' },
  { name: 'nome_vendedor', title: 'Vendedor' },
  { name: 'pedidos', title: 'No. Pedidos' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume_total', title: 'Volume Total' }
]

const columnsLevel2 = [
  { name: 'tipo_produto', title: 'Tipo do Produto' },
  { name: 'pedidos', title: 'No. Pedidos' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

const columnsLevel3 = [
  { name: 'nome_cliente', title: 'Cliente' },
  { name: 'emissao', title: 'Emitido em' },
  { name: 'data_inclusao_erp', title: 'Data Inclusão no ERP' },
  { name: 'nome_vendedor', title: 'Vendedor' },
  { name: 'numero', title: 'No. Pedido' },
  { name: 'status', title: 'Status do Pedido' },
  { name: 'total', title: 'R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

export default function OrdersCut() {
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [currentColumns, setCurrentColumns] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [filterData] = useAtom(filterDataAtom)
  const [selectedRows] = useAtom(selectedRowsAtom)
  const [dataLevel1, setDataLevel1] = useState([])
  const [dataLevel2, setDataLevel2] = useState([])
  const [dataLevel3, setDataLevel3] = useState([])

  useEffect(() => {
    //console.log(reportData)
    if (reportData.length > 0) {
      const level1 = jsonLevel1()
      setDataLevel1(level1)
    } else {
      setDataLevel1([])
    }
    // eslint-disable-next-line
  }, [reportData])

  useEffect(() => {
    if (Object.keys(selectedRows.level1).length > 0) {
      const level2 = jsonLevel2()
      setDataLevel2(level2)
    }

    if (Object.keys(selectedRows.level2).length > 0) {
      const level3 = jsonLevel3()
      setDataLevel3(level3)
    }
    // eslint-disable-next-line
  }, [selectedRows])

  const jsonLevel1 = () => {
    const newArray = []
    const filtered = reportData.filter(row => row.tipo === 'Pedido')
    filtered.forEach(row => {
      const findIndex = newArray.findIndex(
        arr => arr.nome_vendedor === row.nome_vendedor
      )

      if (findIndex === -1) {
        newArray.push({
          codigo_vendedor: row.codigo_vendedor,
          nome_vendedor: row.nome_vendedor,
          pedidos: 1,
          total: row.valor,
          quantidade: row.itens.reduce(
            (acc, item) =>
              acc + Number(item.qtde_produto ? item.qtde_produto : 0),
            0
          ),
          volume_total: row.volume
        })
      } else {
        newArray[findIndex].pedidos += 1
        newArray[findIndex].total += row.valor
        newArray[findIndex].quantidade += row.itens.reduce(
          (acc, item) =>
            acc + Number(item.qtde_produto ? item.qtde_produto : 0),
          0
        )
        newArray[findIndex].volume_total += row.volume
      }
    })

    return newArray
  }

  const jsonLevel2 = () => {
    const { codigo_vendedor } = selectedRows.level1.row
    const newArray = []
    let tipo_produto = ''
    let numero

    const filtered = reportData.filter(
      item => item.codigo_vendedor === codigo_vendedor && item.tipo === 'Pedido'
    )

    filtered.forEach(row => {
      row.itens.forEach(item => {
        const pedidos = numero !== row.numero ? 1 : 0
        const findIndex = newArray.findIndex(
          arrItem => arrItem.tipo_produto === item.tipo_produto
        )
        if (item.tipo_produto !== tipo_produto) {
          if (findIndex !== -1) {
            newArray[findIndex].pedidos += pedidos
            newArray[findIndex].total += item.valor_produto
            newArray[findIndex].quantidade += Number(item.qtde_produto)
            newArray[findIndex].volume += item.volume_produto
          } else {
            newArray.push({
              tipo_produto: item.tipo_produto,
              pedidos: 1,
              total: item.valor_produto,
              quantidade: item.qtde_produto,
              volume: item.volume_produto
            })
          }
          tipo_produto = item.tipo_produto
        } else {
          newArray[findIndex].pedidos += pedidos
          newArray[findIndex].total += item.valor_produto
          newArray[findIndex].quantidade += Number(item.qtde_produto)
          newArray[findIndex].volume += item.volume_produto
        }
        numero = row.numero
      })
    })

    return newArray
  }

  const jsonLevel3 = () => {
    const { codigo_vendedor } = selectedRows.level1.row
    const { tipo_produto } = selectedRows.level2.row
    const filtered = reportData
      .filter(
        item =>
          item.codigo_vendedor === codigo_vendedor && item.tipo === 'Pedido'
      )
      .filter(item => item.itens.find(i => i.tipo_produto === tipo_produto))

    const newArray = filtered.map(row => {
      const quantidade = row.itens
        .filter(item => item.tipo_produto === tipo_produto)
        .reduce((acc, item) => acc + item.qtde_produto, 0)

      return {
        nome_cliente: row.nome_cliente,
        emissao: row.emissao,
        data_inclusao_erp: '',
        nome_vendedor: row.nome_vendedor,
        numero: row.numero,
        status: row.status,
        total: row.valor,
        quantidade: quantidade,
        volume: row.volume
      }
    })

    return newArray
  }

  useEffect(() => {
    switch (filterData.report_level) {
      case 1:
        setCurrentColumns(columnsLevel1)
        setCurrentData(dataLevel1)
        break
      case 2:
        setCurrentColumns(columnsLevel2)
        setCurrentData(dataLevel2)
        break
      case 3:
        setCurrentColumns(columnsLevel3)
        setCurrentData(dataLevel3)
        break
      default:
        return false
    }
    // eslint-disable-next-line
  }, [filterData.report_level, dataLevel1])

  return (
    <div className='mt-2'>
      <DataTable
        columns={currentColumns}
        rows={currentData}
        title='Corte de Pedido'
        report='ordersCut'
      />
    </div>
  )
}
