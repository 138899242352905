import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useAtom } from 'jotai'
import {
  KPIAlvosAtom,
  KPIOportunitiesAtom,
  selectedSellerAtom,
  selectedTipoNegocioAtom
} from '../Atoms'
import Chart from 'react-google-charts'
import ls from 'local-storage'
import Axios from 'axios'

const GaugeTitle = ({ title, subtitle }) => (
  <div className='flex flex-col justify-center align-center'>
    <div className='tracking-widest'>{title}</div>
    <div className='tracking-widest italic text-xs'>{subtitle}</div>
  </div>
)

const calculateVolumes = (cellsData, tipoNegocio) => {
  const values = { currentVolume: 0, nextVolume: 0, churn: 0, alvo: 0 }

  const broker_anoatual = cellsData.reduce(
    (acc, item) => acc + item.broker_anoatual,
    0
  )
  const broker_anoanterior = cellsData.reduce(
    (acc, item) => acc + item.broker_anoanterior,
    0
  )
  const broker_churn = cellsData.reduce(
    (acc, item) => acc + item.broker_churn,
    0
  )
  const broker_incremental = cellsData.reduce(
    (acc, item) => acc + item.broker_incremental,
    0
  )
  const franquia_anoatual = cellsData.reduce(
    (acc, item) => acc + item.franquia_anoatual,
    0
  )
  const franquia_anoanterior = cellsData.reduce(
    (acc, item) => acc + item.franquia_anoanterior,
    0
  )
  const franquia_churn = cellsData.reduce(
    (acc, item) => acc + item.franquia_churn,
    0
  )
  const franquia_incremental = cellsData.reduce(
    (acc, item) => acc + item.franquia_incremental,
    0
  )
  const fws_anoatual = cellsData.reduce(
    (acc, item) => acc + item.fws_anoatual,
    0
  )
  const fws_anoanterior = cellsData.reduce(
    (acc, item) => acc + item.fws_anoanterior,
    0
  )
  const fws_churn = cellsData.reduce((acc, item) => acc + item.fws_churn, 0)
  const fws_incremental = cellsData.reduce(
    (acc, item) => acc + item.fws_incremental,
    0
  )

  switch (tipoNegocio) {
    case 'Broker':
      values.currentVolume = broker_anoatual
      values.nextVolume = broker_anoanterior
      values.churn = broker_churn / cellsData.length
      values.alvo = broker_incremental
      break
    case 'Franquia':
      values.currentVolume = franquia_anoatual
      values.nextVolume = franquia_anoanterior
      values.churn = franquia_churn / cellsData.length
      values.alvo = franquia_incremental
      break
    case 'FWS SubFornecimento':
      values.currentVolume = fws_anoatual
      values.nextVolume = fws_anoanterior
      values.churn = fws_churn / cellsData.length
      values.alvo = fws_incremental
      break
    default:
      const churn =
        (broker_churn + franquia_churn + fws_churn) / cellsData.length
      values.currentVolume = broker_anoatual + franquia_anoatual + fws_anoatual
      values.nextVolume =
        broker_anoanterior + franquia_anoanterior + fws_anoanterior
      values.churn = Math.round((churn + Number.EPSILON) * 100) / 100
      values.alvo = broker_incremental + franquia_incremental + fws_incremental
  }

  return values
}

const SellTargetsTable = () => {
  const [cellsData, setCellsData] = useState([])
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [selectedTipoNegocio] = useAtom(selectedTipoNegocioAtom)
  const [KPIOportunities] = useAtom(KPIOportunitiesAtom)

  const getCelulas = async () => {
    const perfis = ls.get('perfis')
    const codigo_vededor = ls.get('codigo_vededor')
    const tipo = ls.get('tipo')
    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_BUSCA_CELULA`,
      {
        distribuidor: perfis[0].label,
        vendedor: tipo === 'SUPERVISOR' ? '' : selectedSeller.value,
        supervisor: tipo === 'SUPERVISOR' ? codigo_vededor : ''
      }
    )

    if (result.data.message) {
      setCellsData([])
    } else {
      setCellsData(result.data)
    }
  }

  useEffect(() => {
    getCelulas()
    // eslint-disable-next-line
  }, [])

  const now = new Date()
  const currentYear = now.getFullYear()
  now.setFullYear(now.getFullYear() + 1)
  const nextYear = now.getFullYear()

  const { currentVolume, nextVolume, churn, alvo } = calculateVolumes(
    cellsData,
    selectedTipoNegocio.value
  )

  const {
    volume_prospect,
    volume_analise,
    volume_negociacao,
    volume_contrato,
    volume_ordem,
    volume_pagamento
  } = KPIOportunities

  const data = [
    {
      title: `VOLUME ${currentYear}`,
      value: currentVolume || 0,
      measure: 'Litros'
    },
    {
      title: `VOLUME ${nextYear}`,
      value: nextVolume || 0,
      measure: 'Litros'
    },
    {
      title: 'CHURN MEDIO',
      value: churn || 0,
      measure: '%'
    },
    {
      title: 'ALVO INCREMENTAL',
      value: alvo || 0,
      measure: 'Litros'
    },
    {
      title: 'VOLUME PANC',
      value: Object.values({
        volume_prospect,
        volume_analise,
        volume_negociacao,
        volume_contrato
      }).reduce((a, b) => a + b),
      measure: 'Litros'
    },
    {
      title: 'VOLUME OP',
      value: Object.values({
        volume_ordem,
        volume_pagamento
      }).reduce((a, b) => a + b),
      measure: 'Litros'
    }
  ]

  return (
    <div className='flex flex-col justify-center'>
      <div>ALVOS DE VENDA</div>
      <Box style={{ border: '1px solid #cccccc', padding: '5px' }}>
        {data.map((item, i) => (
          <div key={i} className='flex w-full mb-2'>
            <div className='w-1/2'>{item.title}</div>
            <div className='w-1/4'>{item.value}</div>
            <div className='w-1/4'>{item.measure}</div>
          </div>
        ))}
      </Box>
    </div>
  )
}

const SPANCOPItem = ({ indicator, percent, volume }) => {
  const fullWidth = 340
  const internalWidth = (fullWidth * percent) / 100

  return (
    <div className='flex gap-4 mb-2'>
      <div>{indicator}</div>
      <div
        className='bg-gray-500 border border-gray-600 flex justify-center'
        style={{ minWidth: `${fullWidth}px` }}
      >
        <div
          className='bg-red-400 flex justify-center'
          style={{ width: `${internalWidth}px` }}
        >
          <div>{volume}</div>
        </div>
      </div>
    </div>
  )
}

const SPANCOPFunnel = () => {
  const [KPIOportunities] = useAtom(KPIOportunitiesAtom)

  const {
    volume_suspect,
    volume_prospect,
    volume_analise,
    volume_negociacao,
    volume_contrato,
    volume_ordem,
    volume_pagamento,
    volume_lost
  } = KPIOportunities

  const total = Object.values({
    volume_suspect,
    volume_prospect,
    volume_analise,
    volume_negociacao,
    volume_contrato,
    volume_ordem,
    volume_pagamento,
    volume_lost
  }).reduce((a, b) => a + b)

  const spancopArray = [
    {
      indicator: 'S',
      percent: (volume_suspect / total) * 100,
      volume: volume_suspect
    },
    {
      indicator: 'P',
      percent: (volume_prospect / total) * 100,
      volume: volume_prospect
    },
    {
      indicator: 'A',
      percent: (volume_analise / total) * 100,
      volume: volume_analise
    },
    {
      indicator: 'N',
      percent: (volume_negociacao / total) * 100,
      volume: volume_negociacao
    },
    {
      indicator: 'C',
      percent: (volume_contrato / total) * 100,
      volume: volume_contrato
    },
    {
      indicator: 'O',
      percent: (volume_ordem / total) * 100,
      volume: volume_ordem
    },
    {
      indicator: 'P',
      percent: (volume_pagamento / total) * 100,
      volume: volume_pagamento
    }
  ]

  return (
    <div>
      {spancopArray.map((item, i) => {
        const { indicator, percent, volume } = item
        return <SPANCOPItem key={i} {...{ indicator, percent, volume }} />
      })}
    </div>
  )
}

export default function Spancop() {
  const [KPIAlvos] = useAtom(KPIAlvosAtom)

  const {
    entregue_target1,
    entregue_target2,
    entregue_target3,
    entregue_valor,
    ciclo_target1,
    ciclo_target2,
    ciclo_target3,
    ciculo_valor,
    sucesso_target1,
    sucesso_target2,
    sucesso_target3,
    sucesso_valor,
    tubo_target1,
    tubo_target2,
    tubo_target3,
    tubo_valor
  } = KPIAlvos

  return (
    <div className='flex gap-8'>
      <div className='w-1/3 flex flex-col'>
        <div className='mb-4'>
          <SellTargetsTable />
        </div>
        <div className='flex flex-col justify-center border border-gray-400 p-3'>
          <div>FUNIL DE VENDAS</div>
          <div>
            <SPANCOPFunnel />
          </div>
        </div>
      </div>
      <div className='w-2/3'>
        <div>
          <div className='grid grid-cols-2'>
            <div className='flex flex-col align-center'>
              <GaugeTitle
                title='ALVO ENTREGUE'
                subtitle={`Target Delivered (> 1)`}
              />
              <div>
                <Chart
                  chartType='Gauge'
                  width={250}
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Label', ''],
                    ['', entregue_valor]
                  ]}
                  options={{
                    redFrom: 0,
                    redTo: entregue_target1,
                    yellowFrom: entregue_target1,
                    yellowTo: entregue_target2,
                    greenFrom: entregue_target2,
                    greenTo: entregue_target3,
                    max: entregue_target3
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col align-center'>
              <GaugeTitle
                title='CICLO DE VENDAS'
                subtitle='Cycle Time (30-90 dias)'
              />
              <div>
                <Chart
                  chartType='Gauge'
                  width={250}
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Label', 'Value'],
                    ['', ciculo_valor]
                  ]}
                  options={{
                    redFrom: ciclo_target2,
                    redTo: ciclo_target3,
                    yellowFrom: ciclo_target1,
                    yellowTo: ciclo_target2,
                    greenFrom: 0,
                    greenTo: ciclo_target1,
                    max: ciclo_target3
                  }}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2'>
            <div className='flex flex-col align-center'>
              <GaugeTitle
                title='TAXA DE SUCESSO'
                subtitle={`Hit Rate (> 20%)`}
              />
              <div>
                {' '}
                <Chart
                  chartType='Gauge'
                  width={250}
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Label', 'Value'],
                    ['', sucesso_valor]
                  ]}
                  options={{
                    redFrom: 0,
                    redTo: sucesso_target1,
                    yellowFrom: sucesso_target1,
                    yellowTo: sucesso_target2,
                    greenFrom: sucesso_target2,
                    greenTo: sucesso_target3,
                    max: sucesso_target3
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col align-center'>
              <GaugeTitle
                title='Força do Tubo'
                subtitle={`Pipeline Strength (> 5)`}
              />
              <div>
                <Chart
                  chartType='Gauge'
                  width={250}
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Label', 'Value'],
                    ['', tubo_valor]
                  ]}
                  options={{
                    redFrom: 0,
                    redTo: tubo_target1,
                    yellowFrom: tubo_target1,
                    yellowTo: tubo_target2,
                    greenFrom: tubo_target2,
                    greenTo: tubo_target3,
                    max: tubo_target3
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
