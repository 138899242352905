import { atom } from 'jotai'

export const selectedSellerAtom = atom(false)
export const selectedYearAtom = atom(false)
export const selectedTipoNegocioAtom = atom(false)
export const selectedOportunityAtom = atom({
  distribuidor: '',
  id: '',
  vendedor: '',
  empresa: '',
  tipo_negocio: '',
  setor: '',
  ranking: '',
  tipo_oportunidade: '',
  grupo_produto: '',
  segmento: '',
  data_primeiraordem: '',
  propabilidade_negocio: '',
  volume_estimativa: 0,
  razoes_chave: '',
  data_suspeito: '',
  data_prospeccao: '',
  data_analise: '',
  data_negociacao: '',
  data_contrato: '',
  data_ordem: '',
  data_pagamento: '',
  produto: '',
  fornecedor_atual: '',
  produto_atual: '',
  requisitos_especiais: '',
  valor_entregue: '',
  notas: '',
  data_proximo: '',
  proximos_passo: '',
  data_lost: '',
  volume_previsao: 0,
  volume_real: 0
})

export const defaultOportunityAtom = atom({
  distribuidor: '',
  id: '',
  vendedor: '',
  empresa: '',
  tipo_negocio: '',
  setor: '',
  ranking: '',
  tipo_oportunidade: '',
  grupo_produto: '',
  segmento: '',
  data_primeiraordem: '',
  propabilidade_negocio: '',
  volume_estimativa: 0,
  razoes_chave: '',
  data_suspeito: '',
  data_prospeccao: '',
  data_analise: '',
  data_negociacao: '',
  data_contrato: '',
  data_ordem: '',
  data_pagamento: '',
  produto: '',
  fornecedor_atual: '',
  produto_atual: '',
  requisitos_especiais: '',
  valor_entregue: '',
  notas: '',
  data_proximo: '',
  proximos_passo: '',
  data_lost: '',
  volume_previsao: 0,
  volume_real: 0
})

export const selectedCallPlanDataAtom = atom({
  distribuidor: '',
  id: '',
  vendedor: '',
  empresa: '',
  data_prevista: '',
  hora_inicio: '',
  hora_fim: '',
  tipo_visita: '',
  realizado: '',
  data_conclusao: '',
  proposito: '',
  objetivo: '',
  premissa: '',
  estrategia: '',
  antecipar: '',
  notas: '',
  motivo_naorealizado: '',
  informacao_adicional: '',
  conclusao: '',
  volume_estimativa: '',
  contato: '',
  oportunidades: [],
  arquivos: [],
  expired: false
})

export const defaultCallPlanDataAtom = atom({
  distribuidor: '',
  id: '',
  vendedor: '',
  empresa: '',
  data_prevista: '',
  hora_prevista: '',
  tipo_visita: '',
  realizado: '',
  data_conclusao: '',
  proposito: '',
  objetivo: '',
  premissa: '',
  estrategia: '',
  antecipar: '',
  notas: '',
  motivo_naorealizado: '',
  informacao_adicional: '',
  conclusao: '',
  volume_estimativa: '',
  contato: '',
  oportunidades: [],
  arquivos: []
})

export const newSpancopResponsesAtom = atom({
  distribuidor: '',
  supervisor: '',
  vendedor: '',
  data_coaching: '',
  id_coaching: '',
  tipo: '',
  codigo_cliente: '',
  loja_cliente: '',
  qualidade: '',
  // utiliza: '',
  folha: '',
  calculo: '',
  proposta: '',
  dvrs: ''
})

export const callPlanModeAtom = atom('')
export const companiesAtom = atom([])
export const productsAtom = atom([])
export const oportunitiesAtom = atom([])
export const rawEventsAtom = atom([])
export const grupoProdutoListAtom = atom([])
export const segmentListAtom = atom([])

export const selectedCellAtom = atom({})
export const defaultCellAtom = atom({
  vendedor: '',
  franquia_anoatual: 0,
  franquia_anoanterior: 0,
  franquia_churn: 0,
  franquia_incremental: 0,
  broker_anoatual: 0,
  broker_anoanterior: 0,
  broker_churn: 0,
  broker_incremental: 0,
  fws_anoatual: 0,
  fws_anoanterior: 0,
  fws_churn: 0,
  fws_incremental: 0
})

export const KPIOportunitiesAtom = atom({
  distribuidor: '',
  ano: '',
  registros_suspect: 0,
  volume_suspect: 0,
  registros_prospect: 0,
  volume_prospect: 0,
  registros_analise: 0,
  volume_analise: 0,
  registros_negociacao: 0,
  volume_negociacao: 0,
  registros_contrato: 0,
  volume_contrato: 0,
  registros_ordem: 0,
  volume_ordem: 0,
  registros_pagamento: 0,
  volume_pagamento: 0,
  registros_lost: 0,
  volume_lost: 0
})

export const KPIAlvosAtom = atom({
  distribuidor: '',
  ano: '',
  entregue_target1: 0,
  entregue_target2: 0,
  entregue_target3: 0,
  entregue_valor: 0,
  ciclo_target1: 0,
  ciclo_target2: 0,
  ciclo_target3: 0,
  ciculo_valor: 0,
  sucesso_target1: 0,
  sucesso_target2: 0,
  sucesso_target3: 0,
  sucesso_valor: 0,
  tubo_target1: 0,
  tubo_target2: 0,
  tubo_target3: 0,
  tubo_valor: 0
})
