import React from 'react'

export default function SKUGoalDataSection({ currentCampaign }) {
  return (
    <div className='w-1/3 mx-4 border-gray-400 border rounded-md p-2'>
      <div>Atigimento por SKU? {currentCampaign?.sku}</div>
      {currentCampaign?.sku === 'SIM' && (
        <div>
          <div>Código SKU: {currentCampaign?.sku_number}</div>
          <div>Quantidade: {currentCampaign?.sku_quant}</div>
        </div>
      )}
    </div>
  )
}
