import { useAtom } from 'jotai'
import React from 'react'
import {
  selectedCoachingAtom
} from '../../_global/Atoms'

import { openSpancopCoachingDialogAtom, spancopCoachingDialogTypeAtom} from './Atom'

export default function SpancopCoachingItem({ data }) {
  //eslint-disable-next-line
  const [selectedCoaching, setSelectedCoaching] = useAtom(selectedCoachingAtom)
  //eslint-disable-next-line
  const [openSpancopCoachingDialog, setOpenCoachingDialog] = useAtom(openSpancopCoachingDialogAtom)
  //eslint-disable-next-line
  const [spancopCoachingDialogType, setCoachingDialogType] = useAtom(spancopCoachingDialogTypeAtom)

  const handleClick = () => {
    setSelectedCoaching(data)
    setOpenCoachingDialog(true) 
    setCoachingDialogType('show') 
  }

  return (
    <div className='border-b-2 border-gray-400'>
      <div
        className='flex flex-row justify-between text-sm items-center'
        onClick={handleClick}
      >
        <div className='text-xl text-yellow-700 cursor-pointer'>
          Coaching {data.tipo}
        </div>
        <div>{data.nome_vendedor}</div>
        <div>
          PDV's executados: <span className='text-xl'>{data.total_pdvs}</span>
        </div>
      </div>
      <div className='flex flex-row justify-between text-sm mb-2'>
        <div>Abertura: {data.data_abertura}</div>
        <div>
          Data realização:{' '}
          <span className=' text-yellow-700'>{data.data_realizacao}</span>
        </div>
        <div>
          {data.data_cancelamento === '' ? (
            <span>
              Fechamento:{' '}
              {data.data_fechamento === '' ? (
                <span className='text-yellow-800'>Em aberto</span>
              ) : (
                data.data_fechamento
              )}
            </span>
          ) : (
            <span className='text-red-800'>Coaching cancelado</span>
          )}
        </div>
      </div>
    </div>
    
  )
}
