import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../Inputs/ReactSelect'
import {
  alertParamsAtom,
  filterDataAtom,
  newResponsesAtom,
  sellerCustomersAtom
} from '../../_global/Atoms'
import CoachingNewResponsesForm from './CoachingNewResponsesForm'
import { emptyCoachingResponses as emptyResponses } from '../../../constData/constData'
import { CloseRounded, Save } from '@material-ui/icons'
import { allResponsesAtom, coachingDataAtom } from './Atoms'
import moment from 'moment'
import { distributors } from '../../../constData/urls'
import Axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { completeCoachingResponses } from '../../../functions/functions'

const DialogCoachingWizard = ({ open, setOpen }) => {
  const [selectedCustomerToCoaching, setSelectedCustomerToCoaching] =
    useState(false)
  const [sellerCustomers, setSellerCustomers] = useAtom(sellerCustomersAtom)
  const [showCoachingNewResponsesForm, setShowCoachingNewResponsesForm] =
    useState(false)
  // eslint-disable-next-line
  const [newResponses, setNewResponses] = useAtom(newResponsesAtom)
  const [selectSellersOptions, setSelectSellersOptions] = useState([])
  const [customerData, setCustomerData] = useState({})
  const [coachingData, setCoachingData] = useAtom(coachingDataAtom)
  const [filterData] = useAtom(filterDataAtom)
  const [saving, setSaving] = useState(false)
  // eslint-disable-next-line
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  // eslint-disable-next-line
  const [allResponses, setAllResponses] = useAtom(allResponsesAtom)
  // eslint-disable-next-line
  const [loadedCustomers, setLoadedCustomers] = useState(false)

  const defaultResponses = {
    mercha: '',
    exposi: '',
    precif: '',
    planej: '',
    verifi: '',
    verexp: '',
    relchk: '',
    verest: '',
    verprc: '',
    verpri: ''
  }

  // const defaultHeader = {
  //   distribuidor: '',
  //   supervisor: '',
  //   vendedor: '',
  //   data_coaching: '',
  //   id_coaching: '',
  //   tipo: '',
  //   codigo_cliente: '',
  //   loja_cliente: ''
  // }

  const loadCoachingData = async () => {
    const { supervidor, vendedor, id_coaching, tipo } = coachingData

    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervidor,
      vendedor: vendedor,
      objetivo: '',
      id_coaching: id_coaching,
      atualiza: 'N',
      tipo: tipo,
      data_fechamento: '',
      data_cancelamento: '',
      feedback: '',
      acoes: '',
      observacao: ''
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_GRAVA_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      })
    )

    setAllResponses(completeCoachingResponses(data.clientes))
    setCoachingData(data)
  }

  const loadCustomers = async () => {
    const { supervisor, vendedor, tipo, data_fechamento } = coachingData

    if (data_fechamento && data_fechamento !== '') {
      return false
    }

    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervisor,
      vendedor: vendedor,
      data_coaching: moment(new Date()).format('DD/MM/YYYY'),
      tipo: tipo
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_BUSCA_CLI_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
    )

    if (
      data.message ===
      'The request has been fulfilled and resulted in a new resource being created.'
    ) {
      setSellerCustomers([])
    } else {
      setSellerCustomers(data)
    }
    setLoadedCustomers(true)
  }

  useEffect(() => {
    sellerCustomers.length === 0 &&
      Object.keys(coachingData).length > 0 &&
      loadCustomers()

    //eslint-disable-next-line
  }, [coachingData])

  useEffect(() => {
    const mappedSellers = sellerCustomers?.map(seller => {
      return {
        label: `${seller.nome_cliente} - ${seller.cnpj}`,
        value: seller.codigo_cliente,
        key: seller.codigo_cliente
      }
    })
    setSelectSellersOptions(mappedSellers)
  }, [sellerCustomers])

  useEffect(() => {
    const mappedCompany = sellerCustomers.find(
      customer => customer.codigo_cliente === selectedCustomerToCoaching.value
    )

    setCustomerData(mappedCompany)
    mappedCompany &&
      setNewResponses({
        ...newResponses,
        ...defaultResponses,
        distribuidor: filterData.distribuidor[0].value,
        supervisor: coachingData.supervisor,
        vendedor: coachingData.vendedor,
        data_coaching: moment(new Date()).format('DD/MM/YYYY'),
        id_coaching: coachingData.id_coaching,
        tipo: coachingData.tipo,
        codigo_cliente: mappedCompany?.codigo_cliente,
        loja_cliente: mappedCompany?.loja_cliente
      })
    //eslint-disable-next-line
  }, [selectedCustomerToCoaching])

  const saveCustomer = async () => {
    setSaving(true)
    const responsesKeys = Object.keys(newResponses)
    let blank = 0
    responsesKeys.forEach(key => {
      newResponses[key] === '' && blank++
    })

    if (blank > 0) {
      setAlertParams({
        open: true,
        text: 'Preencha todos os dados para salvar este coaching.',
        onClick: () => {
          setAlertParams(false)
        }
      })
      setSaving(false)
      return false
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await Axios.post(
      `${distributorData.url}INT_SHELL_GRAVA_CLI_COACHING`,
      [newResponses],
      {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
    )

    setSaving(false)
    setAlertParams({
      open: true,
      text: data[0].retorno,
      onClick: () => {
        setAlertParams({ open: false })
        if (data.retorno === 'Cliente já inserido no coaching') {
          setNewResponses({ ...newResponses, ...defaultResponses })
        } else {
          setNewResponses({ ...newResponses, ...defaultResponses })
          setSelectedCustomerToCoaching(false)
        }
        setShowCoachingNewResponsesForm(false)
      }
    })
  }

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle>Adicionando Cliente ao Coaching</DialogTitle>
      <DialogContent>
        <ReactSelect
          onChange={v => {
            setSelectedCustomerToCoaching(v)
            setShowCoachingNewResponsesForm(true)
          }}
          value={selectedCustomerToCoaching}
          id='vendedor'
          options={selectSellersOptions}
          placeholder='Selecione...'
          label='Cliente'
          maxMenuHeight={200}
          menuPlacement='auto'
        />
        {customerData && (
          <div className='mt-4 mb-4 border rounded p-2'>
            <div className='flex items-center justify-between gap-2'>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  CNPJ
                </span>
                {customerData?.cnpj}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  CODIGO CLIENTE
                </span>
                {customerData?.codigo_cliente}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  NOME CLIENTE
                </span>
                {customerData?.nome_cliente}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  LOJA CLIENTE
                </span>
                {customerData?.loja_cliente}
              </div>
            </div>

            <div className='flex items-center justify-between gap-2'>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  ENDERECO
                </span>
                {customerData?.endereco}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  ESTADO/CIDADE
                </span>
                {customerData?.municipio}/{customerData?.estado}
              </div>

              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  TELEFONE
                </span>
                {customerData?.telefone}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  ULTIMA COMPRA
                </span>
                {customerData?.ultima_compra}
              </div>
            </div>
            <div className='flex items-center justify-between gap-2'>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  ULTIMA VISITA
                </span>
                {customerData?.ultima_visita}
              </div>
              <div>
                <span className='text-bold tracking-wide text-gray-500 mr-3'>
                  STATUS CLUBE
                </span>
                {customerData?.status_clube}
              </div>
            </div>
          </div>
        )}
        <CoachingNewResponsesForm show={showCoachingNewResponsesForm} />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          startIcon={<Save />}
          onClick={saveCustomer}
        >
          {saving ? 'Salvando, aguarde...' : 'Salvar esse cliente'}
        </Button>
        <Button
          startIcon={<CloseRounded />}
          variant='contained'
          color='secondary'
          onClick={() => {
            loadCoachingData()
            setNewResponses(emptyResponses)
            setSelectedCustomerToCoaching(false)
            setOpen(false)
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCoachingWizard
