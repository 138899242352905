import { IntegratedFiltering, SearchState } from '@devexpress/dx-react-grid'
import {
  Grid,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import { IconButton, Paper, Tooltip } from '@material-ui/core'
import { Assessment, DeleteForever, Edit, FileCopy } from '@material-ui/icons'
import moment from 'moment'
import React, { useState } from 'react'
import { Fragment } from 'react'
import StatusBadge from '../StatusBadge'
import ls from 'local-storage'
import { alertParamsAtom } from '../../_global/Atoms'
import { useAtom } from 'jotai'

const columns = [
  { title: 'ID', name: 'id', key: 1 },
  { title: 'Nome', name: 'name', key: 2 },
  { title: 'Início', name: 'data_inicial', key: 3 },
  { title: 'Término', name: 'data_final', key: 4 },
  { title: 'Status', name: 'status', key: 5 },
  { title: 'Detalhar', name: 'action_button', key: 6 }
]

export default function ListTable({
  campaignList,
  setCampaignList,
  setCampaign,
  setOpenEdit,
  setOpenCampaignResults,
  deleteCampaign
}) {
  const [searchValue, setSearchState] = useState('')
  const [defaultColumnWidths] = useState([
    { columnName: 'id', width: 150 },
    { columnName: 'name', width: 240 },
    { columnName: 'data_inicial', width: 100 },
    { columnName: 'data_final', width: 100 },
    { columnName: 'status', width: 200 },
    { columnName: 'action_button', width: 240 }
  ])
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)

  const handleDuplicate = row => {
    const user = ls.get('user')
    const newRow = { ...row, name: `${row.name} cópia`, id: 0, usuario: user }
    const list = [...campaignList, newRow]
    setCampaignList(list)
  }

  const ButtonCell = ({ onClick, ...restProps }) => {
    const { column, value, row } = restProps

    const handleEdit = () => {
      const user = ls.get('user')
      const mkt_profile = ls.get('mkt_profile')
      const editCampaignIndex = campaignList.findIndex(
        campaign => campaign.id === row.id
      )
      if (editCampaignIndex !== -1) {
        const findedCampaign = campaignList[editCampaignIndex]
        if (findedCampaign.usuario !== user && mkt_profile !== 'root') {
          setAlertParams({
            open: true,
            text: 'Você não pode editar campanhas criadas por outros usuários',
            onClick: () => setAlertParams({ ...alertParams, open: false })
          })
          return false
        }

        setCampaign(findedCampaign)
        setOpenEdit(true)
      }
    }

    return (
      <Table.Cell {...restProps}>
        {column.name === 'action_button' ? (
          <Fragment>
            <Tooltip title='Editar'>
              <IconButton color='secondary' onClick={handleEdit} size='small'>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title='Duplicar'>
              <IconButton color='primary' onClick={() => handleDuplicate(row)}>
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title='Apuração'>
              <IconButton
                color='primary'
                onClick={() => setOpenCampaignResults(row.id)}
              >
                <Assessment />
              </IconButton>
            </Tooltip>
            <Tooltip title='Excluir Campanha'>
              <IconButton
                color='primary'
                onClick={() => deleteCampaign(row.id)}
              >
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </Fragment>
        ) : column.name === 'status' ? (
          <StatusBadge date={row.data_final} />
        ) : column.name === 'data_inicial' || column.name === 'data_final' ? (
          moment(value).format('DD/MM/YYYY')
        ) : column.name === 'id' ? (
          value === 0 ? (
            <div className='text-red-400'>Campanha duplicada</div>
          ) : (
            value
          )
        ) : (
          value
        )}
      </Table.Cell>
    )
  }

  return (
    <Paper>
      <Grid columns={columns} rows={campaignList}>
        <SearchState value={searchValue} onValueChange={setSearchState} />
        <IntegratedFiltering />
        <Table
          cellComponent={ButtonCell}
          messages={{ noData: 'Nenhum dado encontrado' }}
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel messages={{ searchPlaceholder: 'Buscar...' }} />
      </Grid>
    </Paper>
  )
}
