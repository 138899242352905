import React from 'react'
import { Fragment } from 'react'

const headers = [
  { classItem: 'bg-white', label: '', key: 'h-1' },
  { classItem: 'bg-white', label: 'Distribuidor', key: 'h-2' },
  { classItem: 'bg-white', label: 'Código', key: 'h-3' },
  { classItem: 'bg-white', label: 'Descrição', key: 'h-4' },
  { classItem: 'bg-gray-700 text-white', label: 'Embalagens', key: 'h-5' },
  { classItem: 'bg-gray-700 text-white', label: 'Vol Lts', key: 'h-6' },
  { classItem: 'bg-gray-700 text-white', label: 'Preço Unit', key: 'h-7' },
  { classItem: 'bg-gray-700 text-white', label: 'Preço Total', key: 'h-8' },
  { classItem: 'bg-gray-500 text-white', label: 'Emb', key: 'h-9' },
  { classItem: 'bg-gray-500 text-white', label: 'Vol Lts', key: 'h-10' },
  { classItem: 'bg-gray-500 text-white', label: 'Valor', key: 'h-11' },
  { classItem: 'bg-orange-500 text-white', label: 'Emb', key: 'h-12' },
  { classItem: 'bg-orange-500 text-white', label: 'Vol Lts', key: 'h-13' },
  { classItem: 'bg-orange-500 text-white', label: 'Valor', key: 'h-14' },
  { classItem: 'bg-gray-700 text-white', label: 'Emb', key: 'h-15' },
  { classItem: 'bg-gray-700 text-white', label: 'Vol Lts', key: 'h-16' },
  { classItem: 'bg-gray-700 text-white', label: 'Valor', key: 'h-17' },
  { classItem: 'bg-red-700 text-white', label: 'Emb', key: 'h-18' },
  { classItem: 'bg-red-700 text-white', label: 'Vol Lts', key: 'h-19' },
  { classItem: 'bg-red-700 text-white', label: 'Preço Unitário', key: 'h-20' },
  { classItem: 'bg-red-700 text-white', label: 'Preço Total', key: 'h-21' },
  { classItem: 'bg-green-700 text-white', label: 'Emb', key: 'h-22' },
  { classItem: 'bg-green-700 text-white', label: 'Vol Lts', key: 'h-23' },
  { classItem: 'bg-green-700 text-white', label: 'CMV', key: 'h-24' },
  { classItem: 'bg-green-700 text-white', label: 'Receita', key: 'h-25' },
  { classItem: 'bg-orange-500 text-white', label: 'Emb', key: 'h-26' },
  { classItem: 'bg-orange-500 text-white', label: 'Vol Lts', key: 'h-27' },
  { classItem: 'bg-orange-500 text-white', label: 'CMV', key: 'h-28' },
  { classItem: 'bg-orange-500 text-white', label: 'Receita', key: 'h-29' },
  { classItem: 'bg-gray-700 text-white', label: 'Emb', key: 'h-30' },
  { classItem: 'bg-gray-700 text-white', label: 'Vol Lts', key: 'h-31' },
  { classItem: 'bg-gray-700 text-white', label: 'Valor', key: 'h-32' },
  { classItem: 'bg-red-300 text-white', label: 'Emb', key: 'h-33' },
  { classItem: 'bg-red-300 text-white', label: 'Vol Lts', key: 'h-34' },
  { classItem: 'bg-red-300 text-white', label: 'Valor', key: 'h-35' },
  { classItem: 'bg-red-700 text-white', label: 'Emb', key: 'h-36' },
  { classItem: 'bg-red-700 text-white', label: 'Vol Lts', key: 'h-37' },
  { classItem: 'bg-red-700 text-white', label: 'Uni', key: 'h-38' },
  { classItem: 'bg-red-700 text-white', label: 'Valor', key: 'h-39' }
]

export default function HeaderItems() {
  return (
    <Fragment>
      <tr>
        <th colSpan='4' className='bg-white'></th>
        <th
          colSpan='4'
          className='border bg-gray-700 text-white font-light text-center text-xs p-2'
        >
          Estoque Inicial
        </th>
        <th
          colSpan='3'
          className='border bg-gray-500 text-white font-light text-center text-xs p-2'
        >
          Compras
        </th>
        <th
          colSpan='3'
          className='border bg-orange-500 text-white font-light text-center text-xs p-2'
        >
          Movimentação de Estoques
        </th>
        <th
          colSpan='3'
          className='border bg-gray-700 text-white font-light text-center text-xs p-2'
        >
          Devolução de Compras
        </th>
        <th
          colSpan='4'
          className='border bg-red-700 text-white font-light text-center text-xs p-2'
        >
          Estoque Total
        </th>
        <th
          colSpan='4'
          className='border bg-green-700 text-white font-light text-center text-xs p-2'
        >
          Vendas
        </th>
        <th
          colSpan='4'
          className='border bg-orange-500 text-white font-light text-center text-xs p-2'
        >
          Venda Futura
        </th>
        <th
          colSpan='3'
          className='border bg-gray-700 text-white font-light text-center text-xs p-2'
        >
          Entrega Futura
        </th>
        <th
          colSpan='3'
          className='border bg-red-300 text-white font-light text-center text-xs p-2'
        >
          Doações
        </th>
        <th
          colSpan='4'
          className='border bg-red-700 text-white font-light text-center text-xs p-2'
        >
          Estoque Total
        </th>
      </tr>

      <tr>
        {headers.map(h => {
          const tdClass = `border text-xs font-semibold p-2 text-center ${h.classItem}`
          return (
            <td className={tdClass} key={h.key}>
              {h.label}
            </td>
          )
        })}
      </tr>
    </Fragment>
  )
}
