import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { loadLabelBase } from '../../functions/functions'
import { loadedModuleAtom } from '../_global/Atoms'
import Header from '../_global/Header'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import api from '../../api/api'
import UserItem from './UserItem'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import InputForm from '../Inputs/InputForm'
import { Cancel, PersonAdd, Save } from '@material-ui/icons'
import ReactSelect from '../Inputs/ReactSelect'
import { Alert } from '@material-ui/lab'

const listProfiles = () => {
  return [
    { value: 'master', label: 'master' },
    { value: 'quite', label: 'quite' },
    { value: 'dellas', label: 'dellas' },
    { value: 'formula', label: 'formula' },
    { value: 'lubtrol', label: 'lubtrol' },
    { value: 'brazmax', label: 'brazmax' },
    { value: 'lubpar', label: 'lubpar' },
    { value: 'gamma', label: 'gamma' },
    { value: 'portolub', label: 'portolub' },
    { value: 'agricopel', label: 'agricopel' },
    { value: 'nacional', label: 'nacional' },
    { value: 'boanova', label: 'boanova' },
    { value: 'gasoleo', label: 'gasoleo' },
    { value: 'planejamento', label: 'planejamento' },
    { value: 'pricing', label: 'pricing' },
    { value: 'wesley.junior', label: 'wesley.junior' },
    { value: 'paulo.freitas', label: 'paulo.freitas' },
    { value: 'spm', label: 'spm' },
    { value: 'rodolfo.godoy', label: 'rodolfo.godoy' },
    { value: 'marcos.donizete', label: 'marcos.donizete' },
    { value: 'rodrigo.santos', label: 'rodrigo.santos' },
    { value: 'frederico.petrucelli', label: 'frederico.petrucelli' },
    { value: 'guilherme.takamine', label: 'guilherme.takamine' },
    { value: 'marketing', label: 'marketing' }
  ]
}

const CreateUser = ({ setOpen }) => (
  <div className='flex justify-end'>
    <Button
      variant='contained'
      color='primary'
      startIcon={<PersonAdd />}
      onClick={() => setOpen(true)}
    >
      Novo
    </Button>
  </div>
)

const CreateUserDialog = ({ open, setOpen, getData }) => {
  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [perfil, setPerfil] = useState('')
  const [message, setMessage] = useState(false)

  const saveUser = async () => {
    const json = {
      user,
      pwd,
      perfil: perfil.value,
      status: 'OK'
    }
    await api.createUser(json)
    setMessage(true)

    setTimeout(() => {
      getData()
      setOpen(false)
    }, 3000)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Adicionando usuário</DialogTitle>
      <DialogContent>
        <div className='h-72'>
          <InputForm
            onChange={e => setUser(e.target.value)}
            value={user}
            label='Usuário'
            id='user'
          />
          <InputForm
            onChange={e => setPwd(e.target.value)}
            value={pwd}
            label='Senha'
            id='pwd'
          />
          <ReactSelect
            onChange={v => {
              setPerfil(v)
            }}
            value={perfil}
            label='Perfil'
            id='perfil'
            options={listProfiles()}
            placeholder='Selecione...'
            maxMenuHeight={70}
          />
        </div>
        {message && (
          <div className='mt-3'>
            <Alert severity='info'>
              Usuário criado com sucesso! Aguarde...
            </Alert>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<Cancel />}
          onClick={() => setOpen(false)}
        >
          Fechar sem criar
        </Button>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<Save />}
          onClick={saveUser}
        >
          Salvar novo usuário
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function UserControl() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const labels = loadLabelBase(loadedModule)
  const [users, setUsers] = useState([])
  const [openAdd, setOpenAdd] = useState(false)
  const { promiseInProgress } = usePromiseTracker()

  function compare(a, b) {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  const getData = async () => {
    const { data } = await trackPromise(api.loadUsers())
    const sortedUsers = data.sort((a, b) => compare(a.user, b.user))
    setUsers(sortedUsers)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header label={labels.label} title={labels.title} />
      <CreateUser setOpen={setOpenAdd} />
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <div className='flex w-full flex-wrap'>
          {users?.map(user => (
            <UserItem key={Math.random()} user={user} />
          ))}
        </div>
      )}
      <CreateUserDialog open={openAdd} setOpen={setOpenAdd} {...{ getData }} />
    </>
  )
}
