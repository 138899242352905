import React from 'react'

const TDItems = props => {
  let i = 0
  const entries = Object.entries(props.item)
  return (
    <tr className='hover:bg-yellow-500' key={props.item.cnpj}>
      <td className='border px-2 py-1 text-xs'>
        <span>{props.j}</span>
      </td>
      {entries.map(entry => {
        ++i
        return (
          <td className='border px-2 py-1 text-xs' key={i}>
            {entry[1]}
          </td>
        )
      })}
    </tr>
  )
}

export default TDItems
