import {
  ExportPanel,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  Toolbar as MUIToolbar,
  Box
} from '@material-ui/core'
import React, {
  useCallback,
  useRef,
  Fragment,
  useState,
  useEffect
} from 'react'
import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { Close, ControlPoint, Refresh } from '@material-ui/icons'
import TransitionComponent from '../Campaign/TransitionComponent'
import Form from './Opportunities/Form'
import { useAtom } from 'jotai'
import {
  selectedSellerAtom,
  selectedOportunityAtom,
  defaultOportunityAtom,
  productsAtom,
  selectedTipoNegocioAtom
} from './Atoms'
import { Chip } from '@material-ui/core'
import ls from 'local-storage'
import Axios from 'axios'
import {
  big5List,
  possibilidadeNegocioList,
  setorList,
  tipoNegocioList,
  tipoOportunidadeList
} from './Opportunities/constData'
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import { usePromiseTracker } from 'react-promise-tracker'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}))

const DialogOportunity = ({ open, setOpen }) => {
  const classes = useStyles()

  return (
    <Dialog fullScreen open={open} TransitionComponent={TransitionComponent}>
      <DialogContent>
        <AppBar className={classes.appBar}>
          <MUIToolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <Close />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Oportunidade
            </Typography>
          </MUIToolbar>
        </AppBar>
        <Form setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  )
}

const OportunitiesTable = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [, setSelectedOportunity] = useAtom(selectedOportunityAtom)
  const [defaultOpportunity] = useAtom(defaultOportunityAtom)
  const [data, setData] = useState([])
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [products] = useAtom(productsAtom)
  const [selectedTipoNegocio] = useAtom(selectedTipoNegocioAtom)
  const [defaultColumnWidths] = useState([
    { columnName: 'dias', width: 100 },
    { columnName: 'primOrder', width: 100 },
    { columnName: 'empresa', width: 180 },
    { columnName: 'vendedor', width: 180 },
    { columnName: 'nome_vendedor', width: 180 },
    { columnName: 'existente', width: 180 },
    { columnName: 'tipo_negocio', width: 180 },
    { columnName: 'grupo_produto', width: 180 },
    { columnName: 'setor', width: 180 },
    { columnName: 'segmento', width: 180 },
    { columnName: 'ranking', width: 180 },
    { columnName: 'tipo_oportunidade', width: 180 },
    { columnName: 'data_primeiraordem', width: 180 },
    { columnName: 'propabilidade_negocio', width: 180 },
    { columnName: 'volume_estimativa', width: 180 },
    { columnName: 'razoes_chave', width: 180 },
    { columnName: 'data_suspeito', width: 180 },
    { columnName: 'data_prospeccao', width: 180 },
    { columnName: 'data_analise', width: 180 },
    { columnName: 'data_negociacao', width: 180 },
    { columnName: 'data_contrato', width: 180 },
    { columnName: 'data_ordem', width: 180 },
    { columnName: 'volume_real', width: 180 },
    { columnName: 'data_pagamento', width: 180 },
    { columnName: 'produto', width: 180 },
    { columnName: 'fornecedor_atual', width: 180 },
    { columnName: 'produto_atual', width: 180 },
    { columnName: 'requisitos_especiais', width: 180 },
    { columnName: 'valor_entregue', width: 180 },
    { columnName: 'notas', width: 180 },
    { columnName: 'data_proximo', width: 180 },
    { columnName: 'proximos_passo', width: 180 }
  ])
  const [sorting, setSorting] = useState([])
  const [filters, setFilters] = useState([])
  const [pageSizes] = useState([50, 100, 200, 0])

  const columns = [
    { name: 'dias', title: '+30dias' },
    { name: 'primOrder', title: 'Prim Ordem' },
    { name: 'empresa', title: 'Empresa' },
    { name: 'vendedor', title: 'Cód Vendedor' },
    { name: 'nome_vendedor', title: 'vendedor' },
    { name: 'existente', title: 'Cliente Existente' },
    { name: 'tipo_negocio', title: 'Tipo de Negócio' },
    { name: 'grupo_produto', title: 'Grupo de Produto' },
    { name: 'setor', title: 'Setor' },
    { name: 'segmento', title: 'Segmento' },
    { name: 'ranking', title: 'BIG5/TOP5' },
    { name: 'tipo_oportunidade', title: 'Tipo de Oportunidade' },
    { name: 'data_primeiraordem', title: 'Data Estimada 1a Ordem' },
    { name: 'propabilidade_negocio', title: 'Possibilidade de Negocio' },
    { name: 'volume_estimativa', title: 'Estimativa Volume Anual' },
    { name: 'razoes_chave', title: 'Razões Chave' },
    { name: 'data_suspeito', title: 'Suspect' },
    { name: 'data_prospeccao', title: 'Prospect' },
    { name: 'data_analise', title: 'Analysis' },
    { name: 'data_negociacao', title: 'Negociation' },
    { name: 'data_contrato', title: 'Contract' },
    { name: 'data_ordem', title: 'Order' },
    { name: 'volume_real', title: 'Volume Real' },
    { name: 'data_pagamento', title: 'Payment' },
    { name: 'produto', title: 'Produto Ofertado' },
    { name: 'fornecedor_atual', title: 'Fornecedor Atual' },
    { name: 'produto_atual', title: 'Produto Atual' },
    { name: 'requisitos_especiais', title: 'Requisitos Especiais' },
    { name: 'valor_entregue', title: 'Valor Entregue ao Cliente' },
    { name: 'notas', title: 'Notas' },
    { name: 'data_proximo', title: 'Data Proximo Passo' },
    { name: 'proximos_passo', title: 'Próximo Passo' }
  ]

  const { promiseInProgress } = usePromiseTracker()

  const listProducts = () => {
    return products.map(p => {
      return {
        label: `${p.sku} - ${p.descricao}`,
        value: `${p.sku} - ${p.descricao}`
      }
    })
  }

  const getOportunities = async () => {
    const perfis = ls.get('perfis')
    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_BUSCA_OPORTUNIDADE`,
      {
        distribuidor: perfis[0].label,
        vendedor: selectedSeller.value,
        supervisor: ''
      },
      {
        headers: {
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    if (result.status !== 200 && result.status !== 201) {
      // erro
      return false
    }

    const processedData = result.data.map(item => {
      let dias = ''
      let primOrder = ''

      if (
        item.data_ultimaalteracao &&
        item.propabilidade_negocio !== 'Ganho' &&
        item.propabilidade_negocio !== 'Perdido'
      ) {
        const dataMx = item.data_ultimaalteracao?.split('/')
        const lastUpdate = new Date(
          Number('20' + dataMx[2]),
          Number(dataMx[1] - 1),
          Number(dataMx[0])
        )

        const now = new Date()

        const diff = Math.abs(now - lastUpdate)
        const days = diff / (1000 * 60 * 60 * 24)

        if (days > 30) {
          dias = 'x'
        }
      }

      if (
        item.data_primeiraordem &&
        item.propabilidade_negocio !== 'Ganho' &&
        item.propabilidade_negocio !== 'Perdido'
      ) {
        const dataMx = item.data_primeiraordem?.split('/')
        const primOrdem = new Date(
          Number('20' + dataMx[2]),
          Number(dataMx[1] - 1),
          Number(dataMx[0])
        )

        const now = new Date()

        if (Date.parse(primOrdem) < Date.parse(now)) {
          primOrder = 'x'
        }
      }

      return { ...item, dias, primOrder }
    })

    return processedData
  }

  const getData = async () => {
    const data = await getOportunities()

    if (selectedTipoNegocio?.value) {
      const filteredData = data.filter(
        d => d.tipo_negocio === selectedTipoNegocio.label
      )
      setData(filteredData)
    } else {
      setData(data)
    }
  }

  useEffect(() => {
    !openDialog && getData()
    // eslint-disable-next-line
  }, [openDialog])

  const exporterRef = useRef()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Oportunidades.xlsx'
      )
    })
  }

  const addOportunity = () => {
    setSelectedOportunity(defaultOpportunity)
    setOpenDialog(true)
  }

  const editOportunity = async row => {
    const filteredOpportunity = data.find(d => d.id === row.id)
    const tipo_negocio = tipoNegocioList.find(
      v => v.label === filteredOpportunity.tipo_negocio
    )
    const setor = setorList.find(v => v.label === filteredOpportunity.setor)

    const big5 = big5List.find(v => v.label === filteredOpportunity.ranking)
    const tipo_oportunidade = tipoOportunidadeList.find(
      v => v.label === filteredOpportunity.tipo_oportunidade
    )
    const propabilidade_negocio = possibilidadeNegocioList.find(
      v => v.label === filteredOpportunity.propabilidade_negocio
    )

    const allProducts = listProducts()

    const produto = allProducts.find(
      v => v.value === filteredOpportunity.produto
    )
    const perfis = ls.get('perfis')

    setSelectedOportunity({
      ...filteredOpportunity,
      tipo_negocio,
      setor,
      ranking: big5,
      tipo_oportunidade,
      propabilidade_negocio,
      distribuidor: perfis[0].value,
      vendedor: selectedSeller.value,
      produto
    })
    setOpenDialog(true)
  }

  const FocusableCell = ({ onClick, column, row, ...restProps }) => {
    // if (column.name === 'status') {
    //   const chips = []

    //   if (
    //     row.data_ultimaalteracao &&
    //     row.propabilidade_negocio !== 'Ganho' &&
    //     row.propabilidade_negocio !== 'Perdido'
    //   ) {
    //     const dataMx = row.data_ultimaalteracao?.split('/')
    //     const lastUpdate = new Date(
    //       Number('20' + dataMx[2]),
    //       Number(dataMx[1] - 1),
    //       Number(dataMx[0])
    //     )

    //     const now = new Date()

    //     const diff = Math.abs(now - lastUpdate)
    //     const days = diff / (1000 * 60 * 60 * 24)

    //     if (days > 30) {
    //       chips.push(
    //         <Chip label='+30Dias' color='secondary' key={Math.random()} />
    //       )
    //     }
    //   }

    //   if (
    //     row.data_primeiraordem &&
    //     row.propabilidade_negocio !== 'Ganho' &&
    //     row.propabilidade_negocio !== 'Perdido'
    //   ) {
    //     const dataMx = row.data_primeiraordem?.split('/')
    //     const primOrdem = new Date(
    //       Number('20' + dataMx[2]),
    //       Number(dataMx[1] - 1),
    //       Number(dataMx[0])
    //     )

    //     const now = new Date()

    //     if (Date.parse(primOrdem) < Date.parse(now)) {
    //       chips.push(
    //         <Chip
    //           label='Prim Ord Vencida'
    //           color='secondary'
    //           key={Math.random()}
    //         />
    //       )
    //     }
    //   }

    //   return (
    //     <Table.Cell {...restProps}>
    //       {chips.length > 0 ? chips.map(c => c) : column.value}
    //     </Table.Cell>
    //   )
    // }

    if (column.name === 'dias') {
      if (row.dias === 'x') {
        return (
          <Table.Cell {...restProps}>
            <Chip label='+30Dias' color='secondary' />
          </Table.Cell>
        )
      }

      return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
    }

    if (column.name === 'primOrder') {
      if (row.primOrder === 'x') {
        return (
          <Table.Cell {...restProps}>
            <Chip label='Prim Ord Vencida' color='secondary' />
          </Table.Cell>
        )
      }

      return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
    }

    return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
  }

  const RowComponent = ({ row, ...restProps }) => {
    return (
      <Table.Row
        {...restProps}
        // eslint-disable-next-line no-alert
        onClick={() => editOportunity(row)}
        key={Math.random()}
        style={{
          cursor: 'pointer'
        }}
      />
    )
  }

  const tipo = ls.get('tipo')

  return (
    <Fragment>
      <Box
        display='flex'
        justifyContent='flex-end'
        onClick={() => getData()}
        style={{ cursor: 'pointer' }}
      >
        <Refresh /> Atualizar{' '}
      </Box>
      <div>
        {tipo === 'VENDEDOR' && (
          <div className='mb-5'>
            <Button variant='outlined' color='primary' onClick={addOportunity}>
              <ControlPoint /> Adicionar
            </Button>
          </div>
        )}
        {promiseInProgress ? (
          <LoadingIndicator />
        ) : (
          <Paper>
            <Grid rows={data} columns={columns}>
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <FilteringState filters={filters} onFiltersChange={setFilters} />
              <IntegratedFiltering />
              <PagingState defaultCurrentPage={0} defaultPageSize={50} />
              <IntegratedPaging />
              <Table
                rowComponent={RowComponent}
                cellComponent={FocusableCell}
                messages={{ noData: 'Nenhum dado' }}
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow
                showSortingControls
                messages={{ sortingHint: 'Ordenar' }}
              />
              <PagingPanel
                pageSizes={pageSizes}
                messages={{
                  rowsPerPage: 'Linhas por página',
                  showAll: 'Todos'
                }}
              />
              <TableFilterRow messages={{ filterPlaceholder: 'Filtrar' }} />
              <Toolbar />
              <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
              ref={exporterRef}
              columns={columns}
              rows={data}
              onSave={onSave}
            />
          </Paper>
        )}
      </div>

      <DialogOportunity open={openDialog} setOpen={setOpenDialog} />
    </Fragment>
  )
}

export default function Oportunidades() {
  const [, setProducts] = useAtom(productsAtom)

  useEffect(() => {
    const getData = async () => {
      const perfis = ls.get('perfis')
      const json = {
        distribuidor: perfis[0].value,
        tipo_produto: 'SH',
        exporta: 'T'
      }
      const result = await Axios.post(
        `${perfis[0].url}INT_SHELL_BO_PRODUTOS`,
        json
      )

      setProducts(result.data)
    }
    getData()
    // eslint-disable-next-line
  }, [])
  return <OportunitiesTable />
}
