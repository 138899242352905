import { Button } from '@material-ui/core'
import { useAtom } from 'jotai'
import React, { Fragment, useEffect, useState } from 'react'
import { TrackChanges, Save } from '@material-ui/icons'
import xlsxParser from 'xlsx-parse-json'
import Axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import ls from 'local-storage'

import Header from '../_global/Header'
import {
  calculateCampaignMonths,
  listCampaignMonths,
  loadLabelBase,
  locateSegmentByCNPJ,
  unmaskCnpj
} from '../../functions/functions'
import { DateInput } from '../Inputs/DateInput'
import InputForm from '../Inputs/InputForm'
import {
  alertParamsAtom,
  campaignAtom,
  customersAtom,
  loadedModuleAtom,
  tempCampaignListAtom
} from '../_global/Atoms'
import ReactSelect from '../Inputs/ReactSelect'
import TargetsTable from './TargetsTable'
import TargetDialog from './TargetDialog'
import {
  acompanhamentoPor,
  chave,
  tiposAgrupamento,
  tiposApuracao
} from '../../constData/constData'
import SelectCampaignRanges from './SelectCampaignRanges'
import CampaignRangeFields from './CampaignRangeFields'
import CampaignSKUFields from './CampaignSKUFields'
import AlertDialog from '../_global/AlertDialog'
import { SendingIndicator } from '../_global/SendingIndicator'
import { transformData } from '../../functions/campaign/functions'
import { trackPromise } from 'react-promise-tracker'

export default function Form({ type, setOpen }) {
  const [loadedModule, setLoadedModule] = useAtom(loadedModuleAtom)
  const [campaign, setCampaign] = useAtom(campaignAtom)
  const [dialogTargetOpen, setDialogTargetOpen] = useState(false)
  const [file, setFile] = useState('')
  const [disabledRanking, setDisabledRanking] = useState(true)
  const [saved, setSaved] = useState(false)
  const [customers] = useAtom(customersAtom)
  const [segments, setSegments] = useState([])
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [processing, setProcessing] = useState(false)
  // eslint-disable-next-line
  const [campaignList, setCampaignList] = useAtom(tempCampaignListAtom)

  useEffect(() => {
    type !== 'edit' &&
      setCampaign({ ...campaign, faixas: 'NAO', faixa_1: 100, sku: 'NAO' })
    type === 'edit' && setSaved(true)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (type === 'edit' && campaign.alvos) {
      processData(campaign.alvos)
    }
    // eslint-disable-next-line
  }, [customers])

  useEffect(() => {
    let segmento = ''
    let i = 0
    const values = []

    const getData = () => {
      const { alvos } = campaign

      if (alvos && alvos.length > 0) {
        const ordered = alvos[0]
          .map(item => {
            return {
              nome_segmento1: item.nome_segmento1
            }
          })
          .filter(item => item.nome_segmento1)
          .sort((a, b) => {
            if (a.nome_segmento1 > b.nome_segmento1) {
              return 1
            }
            if (a.nome_segmento1 < b.nome_segmento1) {
              return -1
            }
            return 0
          })

        ordered.forEach(alvo => {
          if (
            segmento !== alvo.nome_segmento1 &&
            !values.includes(alvo.nome_segmento1)
          ) {
            values.push({
              label: alvo.nome_segmento1,
              value: alvo.nome_segmento1,
              key: ++i
            })
          }
          segmento = alvo.nome_segmento1
        })
        const mkt_profile = ls.get('mkt_profile')
        const mkt_segments = JSON.parse(ls.get('mkt_segments'))
        let filteredSegments

        if (mkt_profile === 'sales') {
          filteredSegments = values.filter(value =>
            mkt_segments.includes(value)
          )
        } else {
          filteredSegments = values
        }
        
        setSegments(filteredSegments)
      }
    }
    getData()
    // eslint-disable-next-line
  }, [campaign.alvos])

  const commitChanges = ({ changed }) => {
    const changedRows = campaign.alvos?.map(row => {
      const keys = Object.keys(changed)
      const id = keys[0]
      const newRows = row.map(r => {
        return +id === r.id ? { ...r, ...changed[id] } : r
      })
      return newRows
    })

    setCampaign({ ...campaign, alvos: changedRows })
  }

  const handleChange = e => {
    const { id, value, checked } = e.target
    if (id === 'replicate_target') {
      setCampaign({ ...campaign, [id]: checked })
    } else {
      setCampaign({ ...campaign, [id]: value })
    }
  }

  const handleDate = (e, id) => {
    setCampaign({ ...campaign, [id]: e })
  }

  const handleFile = e => {
    setFile(e.target.files[0])
  }

  const parseFile = () => {
    xlsxParser.onFileSelection(file).then(data => processData(data))
  }

  const processAlvoArray = (id, customers, map) => {
    const cnpj = unmaskCnpj(map.cnpj).padStart(14, '0')

    const segmentData = locateSegmentByCNPJ(customers, cnpj)

    const alvo = String(map.alvo).replace(',', '')

    return {
      id: id,
      cnpj: cnpj,
      alvo: parseFloat(alvo),
      categoria: map.categoria,
      custom_id: map.custom_id,
      grupo_descricao: map.cod_grupo
        ? `${map.cod_grupo} - ${map.grupo}`
        : map.grupo
        ? map.grupo
        : segmentData.grupo_descricao,
      nome_segmento1: segmentData.nome_segmento1,
      razao: segmentData.razao_social
    }
  }

  const processData = data => {
    const keys = Object.keys(data)

    let newData

    const months = calculateCampaignMonths(campaign)
    let id = 0
    if (months > 1) {
      if (campaign.replicate_target) {
        let total = 0
        const array = []
        const uniqueTargetArray = data[keys[0]].map(d => {
          return processAlvoArray(id, customers, d)
        })
        do {
          array.push(uniqueTargetArray)
          id++
          total++
        } while (total < months)
        newData = array
      } else {
        newData = keys.map(key => {
          const rows = data[key].map(d => {
            id++
            return processAlvoArray(id, customers, d)
          })
          return rows
        })
      }
    } else {
      const array = []
      const uniqueTargetArray = data[keys[0]].map(d => {
        id++
        return processAlvoArray(id, customers, d)
      })
      array.push(uniqueTargetArray)
      newData = array
    }

    let undef = 0
    newData.forEach(d => {
      d.forEach(item => {
        if (_.isUndefined(item.categoria)) {
          undef++
        }
      })
    })

    const selectedTipoAgrupamento =
      undef > 0
        ? tiposAgrupamento.find(t => t.value === 'individual')
        : tiposAgrupamento.find(t => t.value === 'categoria')

    setCampaign({
      ...campaign,
      tipo_agrupamento: selectedTipoAgrupamento,
      alvos: newData
    })
  }

  const labels = loadLabelBase(loadedModule)

  useEffect(() => {
    const values = campaign.tipo_apuracao
      ?.filter(tipo => tipo)
      .find(tipo => tipo.value === 'r')
    setDisabledRanking(values?.value ? false : true)
  }, [campaign])

  const normalizeCampaign = () => {
    const { alvos } = campaign
    const months = listCampaignMonths(campaign)
    const alvosArray =
      alvos?.map((alvo, i) => {
        return alvo?.map(item => {
          return {
            mesano: months[i].number,
            cnpj: unmaskCnpj(item.cnpj),
            alvo: parseFloat(item.alvo),
            categoria: item.categoria || '',
            custom_id: item.custom_id || '',
            grupo: item.grupo_descricao || ''
          }
        })
      }) || []

    const tiposApuracao = ['', '']

    campaign.tipo_apuracao.length > 0 &&
      campaign.tipo_apuracao
        .filter(tipo => tipo)
        .forEach(tipo => {
          tipo.value === 'bl'
            ? (tiposApuracao[0] = tipo.value)
            : (tiposApuracao[1] = tipo.value)
        })

    return {
      id: campaign.id || '',
      name: campaign.name || '',
      usuario: ls.get('user'),
      data_inicial: moment(campaign.data_inicial).format('DD/MM/YYYY'),
      data_final: moment(campaign.data_final).format('DD/MM/YYYY'),
      tipo_apuracao: tiposApuracao,
      quant_ranking: parseInt(campaign.quant_ranking) || 0,
      tipo_agrupamento: campaign.tipo_agrupamento?.value || '',
      chave: campaign.chave?.value || '',
      segmento: campaign.segmento?.value || '',
      acompanhamento_por: campaign.acompanhamento_por?.value || '',
      faixas: campaign.faixas || 'NAO',
      faixa_1: parseInt(campaign.faixa_1) || 100,
      faixa_2: parseInt(campaign.faixa_2) || 0,
      faixa_3: parseInt(campaign.faixa_3) || 0,
      faixa_4: parseInt(campaign.faixa_4) || 0,
      faixa_5: parseInt(campaign.faixa_5) || 0,
      sku: campaign.sku || 'NAO',
      sku_number: campaign.sku_number || '',
      sku_quant: parseInt(campaign.sku_quant) || 0,
      alvos: alvosArray,
      custom_field: campaign.custom_field || ''
    }
  }

  const validateNewCampaign = campaign => {
    const {
      name,
      data_inicial,
      data_final,
      tipo_apuracao,
      chave,
      segmento,
      acompanhamento_por,
      quant_ranking,
      sku,
      sku_number,
      sku_quant,
      alvos
    } = campaign

    if (
      !name ||
      !data_inicial ||
      !data_final ||
      !tipo_apuracao ||
      !chave ||
      !segmento ||
      !acompanhamento_por
    ) {
      setAlertParams({
        open: true,
        text: 'Preencha todos os campos obrigatórios',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    const findRanking = tipo_apuracao?.filter(t => t === 'r')
    if (
      findRanking.length > 0 &&
      (!quant_ranking || parseInt(quant_ranking) === 0)
    ) {
      setAlertParams({
        open: true,
        text: 'Preencha a quantidade de clientes/grupos premiados no ranking',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    if (sku === 'SIM' && (!sku_number || sku_quant === 0)) {
      setAlertParams({
        open: true,
        text: 'Preencha o numero/quantidade do sku a ser avaliado',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    if (alvos.length === 0) {
      setAlertParams({
        open: true,
        text: 'Você deve realizar o upload da planilha de alvos.',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    return true
  }

  const handleSave = async () => {
    setProcessing(true)
    const normalizedCampaign = normalizeCampaign()
    const validate = validateNewCampaign(normalizedCampaign)

    if (validate) {
      if (normalizedCampaign.id === 0) {
        normalizedCampaign.id = ''
      }
      const { data } = await Axios.post(
        'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_GRAVA_TRACKING',
        normalizedCampaign,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      setProcessing(false)

      if (data.id) {
        setAlertParams({
          open: true,
          text: 'Campanha Inserida',
          onClick: () => {
            setAlertParams({ ...alertParams, open: false })
            setCampaign({})
            setLoadedModule('ListCampaign')
          }
        })
      } else {
        setAlertParams({
          open: true,
          text: 'Erro ao inserir campanha. Tente novamente mais tarde.',
          onClick: () => {
            setAlertParams({ ...alertParams, open: false })
          }
        })
      }
    } else {
      setProcessing(false)
    }
  }

  const handleUpdate = async () => {
    setProcessing(true)
    const normalizedCampaign = normalizeCampaign()
    const validate = validateNewCampaign(normalizedCampaign)
    if (normalizedCampaign.id === 0) {
      normalizedCampaign.id = ''
    }

    if (validate) {
      const { data } = await Axios.post(
        'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_GRAVA_TRACKING',
        normalizedCampaign,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      if (data.id) {
        const json = {
          id: '',
          usuario: ''
        }
        const result = await trackPromise(
          Axios.post(
            'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_BUSCA_TRACKING',
            json,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          )
        )
        setCampaignList(transformData(result.data))

        setAlertParams({
          open: true,
          text: 'Campanha Alterada!',
          onClick: () => {
            setProcessing(false)
            setOpen(false)
            setAlertParams({ ...alertParams, open: false })
          }
        })
      }
    }
  }

  const handleFaixasRange = (e, value) => {
    setCampaign({ ...campaign, faixas: value })
  }

  const handleSKURange = (e, value) => {
    setCampaign({ ...campaign, sku: value })
  }

  return (
    <Fragment>
      <Header label={labels.label} title={labels.title} />
      <div>
        <div
          className='w-full rounded overflow-auto shadow-lg m-auto border border-solid border-gray-400 bg-white p-4'
          style={{ minHeight: 600, maxHeight: 600 }}
        >
          <div className='flex flex-row justify-between gap-2 mt-2'>
            <div className='w-4/6'>
              <InputForm
                id='name'
                label='Nome da Campanha *'
                defaultValue={campaign?.name}
                onChange={handleChange}
              />
            </div>

            <div className='w-1/6'>
              <DateInput
                label='Data Inicial *'
                id='data_inicial'
                selected={campaign?.data_inicial}
                onChange={e => {
                  handleDate(e, 'data_inicial')
                }}
                popperPlacement='bottom-start'
              />
            </div>

            <div className='w-1/6'>
              <DateInput
                label='Data Final *'
                id='data_final'
                selected={campaign?.data_final}
                onChange={e => {
                  handleDate(e, 'data_final')
                }}
                popperPlacement='bottom-end'
              />
            </div>
          </div>
          <div className='flex flex-row justify-between gap-2 mt-2'>
            <div className='w-2/6'>
              <ReactSelect
                onChange={v => {
                  handleDate(v, 'tipo_apuracao')
                }}
                value={campaign?.tipo_apuracao}
                label='Tipos de Apuração *'
                id='tipo_apuracao'
                options={tiposApuracao}
                allowSelectAll={true}
                isMulti
                placeholder='Selecione...'
                maxMenuHeight={200}
              />
            </div>
            <div className='w-1/6'>
              <InputForm
                id='quant_ranking'
                label='Premiados no Ranking'
                defaultValue={campaign?.quant_ranking}
                onChange={handleChange}
                disabledinput={disabledRanking ? 'true' : 'false'}
                labelcolor={disabledRanking ? 'text-gray-400' : 'false'}
                bg={disabledRanking ? 'bg-gray-400' : 'false'}
              />
            </div>
            <div className='w-1/6'>
              <ReactSelect
                onChange={v => {
                  handleDate(v, 'tipo_agrupamento')
                }}
                value={campaign?.tipo_agrupamento}
                label='Tipo Agrupamento'
                id='tipo_agrupamento'
                options={tiposAgrupamento}
                placeholder='Selecione...'
                maxMenuHeight={200}
                isDisabled={true}
              />
            </div>
            <div className='w-1/6'>
              <ReactSelect
                onChange={v => {
                  handleDate(v, 'chave')
                }}
                value={campaign?.chave}
                label='Chave *'
                id='chave'
                options={chave}
                placeholder='Selecione...'
                maxMenuHeight={200}
              />
            </div>
            <div className='w-1/6'>
              <InputForm
                id='custom_field'
                label='Nome Campo Pers.'
                defaultValue={campaign?.custom_field}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between gap-2'>
            {campaign?.alvos?.length > 0 && (
              <div className='w-1/2'>
                <ReactSelect
                  onChange={v => {
                    handleDate(v, 'segmento')
                  }}
                  value={campaign?.segmento}
                  label='Segmento *'
                  id='segmentos'
                  options={segments}
                  placeholder='Selecione...'
                  maxMenuHeight={200}
                />
              </div>
            )}
            <div className='w-1/3'>
              <ReactSelect
                onChange={v => {
                  handleDate(v, 'acompanhamento_por')
                }}
                value={campaign?.acompanhamento_por}
                label='Acompanhamento por *'
                id='acompanhamento_por'
                options={acompanhamentoPor}
                placeholder='Selecione...'
                maxMenuHeight={200}
              />
            </div>
            <div className='w-1/3'>
              <SelectCampaignRanges
                value={campaign.faixas}
                label='Faixas de Atingimento?'
                id='faixas'
                handleCampaignRange={handleFaixasRange}
              />
            </div>
            <div className='w-1/3'>
              <SelectCampaignRanges
                value={campaign.sku}
                label='Atingimento de SKU?'
                id='sku'
                handleCampaignRange={handleSKURange}
              />
            </div>
          </div>

          {campaign.faixas === 'SIM' && (
            <CampaignRangeFields
              campaign={campaign}
              handleChange={handleChange}
            />
          )}

          {campaign.sku === 'SIM' && (
            <CampaignSKUFields
              campaign={campaign}
              handleChange={handleChange}
            />
          )}

          <div className='flex flex-row gap-2 justify-between w-full'>
            <div className='w-1/2 mr-2 flex gap-2'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<TrackChanges />}
                onClick={() => setDialogTargetOpen(true)}
                disabled={!campaign.data_inicial || !campaign.data_final}
              >
                Carregar Alvos
              </Button>
              <a href='/CampaignTargetsModel.xlsx' target='_blank'>
                Baixar modelo
              </a>
            </div>
            <div>
              <Button
                variant='contained'
                color='primary'
                startIcon={<Save />}
                onClick={type === 'edit' ? handleUpdate : handleSave}
              >
                Salvar Campanha
              </Button>
            </div>
          </div>
          <div>{processing && <SendingIndicator />}</div>
          {saved && (
            <div className='flex flex-col mt-4 justify-center'>
              <div className='font-thin text-blue-400 text-xl'>
                Alvos Definidos{' '}
                <div
                  className={`text-xs ${
                    campaign.alvos?.length > 0
                      ? 'text-blue-400'
                      : 'text-red-400'
                  }`}
                >
                  {campaign.alvos?.length > 0
                    ? `${campaign.alvos.length} alvo(s) `
                    : 'Nenhum alvo encontrado'}
                </div>
              </div>
              <TargetsTable campaign={campaign} commitChanges={commitChanges} />
            </div>
          )}
        </div>
      </div>
      <TargetDialog
        open={dialogTargetOpen}
        setOpen={setDialogTargetOpen}
        handleFile={handleFile}
        campaign={campaign}
        parseFile={parseFile}
        handleChange={handleChange}
        file={file}
        setCampaign={setCampaign}
        setFile={setFile}
        setSaved={setSaved}
      />
      <AlertDialog
        open={alertParams.open}
        text={alertParams.text}
        onClick={alertParams.onClick}
      />
    </Fragment>
  )
}
