import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Paper,
  TextField
} from '@material-ui/core'
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { DateInput } from '../../Inputs/DateInput'
import InputForm from '../../Inputs/InputForm'
import ReactSelect from '../../Inputs/ReactSelect'
import ls from 'local-storage'
import moment from 'moment'
import { useAtom } from 'jotai'
import {
  selectedSellerAtom,
  selectedCallPlanDataAtom,
  callPlanModeAtom,
  oportunitiesAtom,
  companiesAtom
  //rawEventsAtom
} from '../Atoms'
import Axios from 'axios'
import { PostAdd, Save, Delete, FileCopy } from '@material-ui/icons'
import { alertParamsAtom } from '../../_global/Atoms'
import {
  big5List,
  horarioList,
  motivoNaoRealizacaoList,
  realizadoList,
  segmentoList,
  setorList,
  tipoNegocioList,
  tipoVisitaList
} from './constData'
import { EditingState } from '@devexpress/dx-react-grid'
import AddCompanyDialog from '../Opportunities/AddCompanyDialog'
import ConfirmDialog from '../../_global/ConfirmDialog'
import TextAreaForm from '../../Inputs/TextAreaForm'

const useStyles = makeStyles({
  companyInput: {
    width: '100%'
  }
})

const RelatedOportunity = ({ selectedOportunity, setSelectedOportunity }) => {
  const [relatedOportunities, setRelatedOportunities] = useState([])
  const [oportunities] = useAtom(oportunitiesAtom)
  const [callPlanData] = useAtom(selectedCallPlanDataAtom)

  const listOportunities = () => {
    const filteredOportunities = oportunities.filter(
      op => op.empresa === callPlanData?.empresa
    )
    return filteredOportunities.map(filt => {
      return {
        value: filt.id,
        label: `${filt.id} - ${filt.grupo_produto} - ${filt.produto}`
      }
    })
  }

  const getOportunities = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_oportunidade: '',
      id_callplan: callPlanData.id
    }
    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_OPORTUNIDADEXCALLPLAN`,
      json,
      {
        headers: {
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    if (result.data.message) {
      setRelatedOportunities([])
      return false
    }

    const newRelatedOportunities = result.data.map(({ id_oportunidade }) => {
      const { grupo_produto, produto } = oportunities.find(
        op => op.id === id_oportunidade
      )

      return { id_oportunidade, grupo_produto, produto }
    })

    setRelatedOportunities(newRelatedOportunities)
  }

  const createOportunity = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_oportunidade: selectedOportunity.value,
      id_callplan: callPlanData.id
    }

    await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_GRAVA_INCOPORTUNIDADEXCALLPLAN`,
      json
    )

    getOportunities()
  }

  const removeOportunity = async value => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_oportunidade: value,
      id_callplan: callPlanData.id
    }

    await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_GRAVA_EXCOPORTUNIDADEXCALLPLAN`,
      json
    )
  }

  useEffect(() => {
    callPlanData.id && getOportunities()
    //eslint-disable-next-line
  }, [callPlanData])

  const relatedOportunityColumns = [
    { name: 'id_oportunidade', title: 'Oportunidade' },
    { name: 'grupo_produto', title: 'Grupo' },
    { name: 'produto', title: 'Produto' }
  ]

  const getRowId = row => row.id

  const commitChanges = ({ deleted }) => {
    let changedRows

    if (deleted) {
      const deletedSet = new Set(deleted)
      let id
      changedRows = relatedOportunities.filter(row => {
        id = row.id_oportunidade
        return !deletedSet.has(row.id)
      })
      removeOportunity(id)
    }
    setRelatedOportunities(changedRows)
  }

  return (
    <>
      <div className='flex flex-col p-2'>
        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
          Oportunidades
        </label>
        <div className='flex gap-2'>
          <ReactSelect
            onChange={v => {
              setSelectedOportunity(v)
            }}
            value={selectedOportunity}
            id='oportunity'
            options={listOportunities()}
            placeholder='Selecione...'
            isDisabled={!!callPlanData.data_conclusao}
          />
          {callPlanData.id && (
            <Button
              variant='outlined'
              color='primary'
              startIcon={<Save />}
              size='xs'
              onClick={createOportunity}
              disabled={!!callPlanData.data_conclusao}
            >
              Relacionar
            </Button>
          )}
        </div>
      </div>
      <div>
        <Paper>
          <Grid
            rows={relatedOportunities}
            columns={relatedOportunityColumns}
            getRowId={getRowId}
          >
            <EditingState onCommitChanges={commitChanges} />
            <Table messages={{ noData: 'Não há oportunidades relacionadas' }} />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              showDeleteCommand
              messages={{ deleteCommand: 'Excluir' }}
            />
          </Grid>
        </Paper>
      </div>
    </>
  )
}

const RelatedFiles = () => {
  return (
    <div className='border-gray-700'>
      <label
        className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
      >
        Arquivos
      </label>
    </div>
  )
}

const FormLabel = ({ label, value }) => (
  <>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
    >
      {label}
    </label>
    <div>{value}</div>
  </>
)

export default function Form({ schedule, setOpen }) {
  const [callPlanData, setCallPlanData] = useAtom(selectedCallPlanDataAtom)
  const [companies, setCompanies] = useAtom(companiesAtom)
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [selectedCompany, setSelectedCompany] = useState('')
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [callPlanMode] = useAtom(callPlanModeAtom)
  const [selectedOportunity, setSelectedOportunity] = useState({})
  const [loading, setLoading] = useState(false)
  const [openInsertDialog, setOpenInsertDialog] = useState(false)
  //const [rawEvents] = useAtom(rawEventsAtom)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  const [disabledFields, setDisabledFields] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setDisabledFields(false)
    checkDisabledFields()
    //eslint-disable-next-line
  }, [])

  const checkDisabledFields = () => {
    if (callPlanData.expired) {
      setDisabledFields(true)
    } else {
      const { data_prevista } = callPlanData

      const newDateObject = new Date()
      newDateObject.setHours(0, 0, 0, 0)

      const dataPrevistaZero = new Date(data_prevista)
      dataPrevistaZero.setHours(0, 0, 0, 0)

      if (
        !!callPlanData.data_conclusao &&
        !!callPlanData.realizado &&
        !!callPlanData.conclusao
      )
        setDisabledFields(true)
    }
  }

  const createOportunity = async id => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_oportunidade: selectedOportunity.value,
      id_callplan: id
    }

    await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_GRAVA_INCOPORTUNIDADEXCALLPLAN`,
      json
    )
  }

  const normalizeJSON = () => {
    const {
      data_prevista,
      data_conclusao,
      hora_inicio,
      hora_fim,
      realizado,
      tipo_visita,
      volume_estimativa,
      motivo_naorealizado,
      ranking,
      segmento,
      setor,
      empresa
    } = callPlanData

    const sendJSON = {
      ...callPlanData,
      data_conclusao:
        data_conclusao === ''
          ? ''
          : moment(data_conclusao).format('DD/MM/YYYY'),
      data_prevista: moment(data_prevista).format('DD/MM/YYYY'),
      hora_inicio: hora_inicio.value || hora_inicio,
      hora_fim: hora_fim.value || hora_fim,
      realizado: realizado ? realizado.value : '',
      tipo_visita: tipo_visita.value || tipo_visita,
      empresa: selectedCompany.empresa || empresa,
      volume_estimativa: Number(volume_estimativa),
      motivo_naorealizado: motivo_naorealizado ? motivo_naorealizado.value : '',
      ranking: ranking ? ranking : '',
      segmento: segmento ? segmento : '',
      setor: setor ? setor : ''
    }

    return sendJSON
  }

  const addCallPlan = async () => {
    setLoading(true)

    // const mxStart = json.data_prevista.split('/')
    // console.log(
    //   json,
    //   `${mxStart[0]}/${mxStart[1]}/${mxStart[2].substring(2, 4)}`
    // )

    // const findedEvent = rawEvents.find(
    //   raw =>
    //     raw.data_prevista ===
    //       `${mxStart[0]}/${mxStart[1]}/${mxStart[2].substring(2, 4)}` &&
    //     raw.hora_inicio === json.hora_inicio
    // )

    // if (findedEvent) {
    //   setAlertParams({
    //     open: true,
    //     text: 'Já existe evento registrado para a mesma data/horario. Tente outra data / horário para prosseguir.',
    //     onClick: () => setAlertParams({ ...alertParams, open: false })
    //   })
    //   setLoading(false)
    //   return false
    // }

    if (!callPlanData.empresa) {
      setAlertParams({
        open: true,
        text: 'Insira a empresa',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      setLoading(false)
      return false
    }

    if (!callPlanData.hora_fim) {
      setAlertParams({
        open: true,
        text: 'Insira a hora de término do callplan',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      setLoading(false)
      return false
    }

    if (!callPlanData.tipo_visita) {
      setAlertParams({
        open: true,
        text: 'Insira o tipo de visita do callplan',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      setLoading(false)
      return false
    }

    if (
      callPlanData.realizado.value === 'N' &&
      !callPlanData.motivo_naorealizado
    ) {
      setAlertParams({
        open: true,
        text: 'Preencha o motivo da não realização do callplan',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      setLoading(false)
      return false
    }

    if (callPlanData.realizado && !callPlanData.data_conclusao) {
      setAlertParams({
        open: true,
        text: 'Preencha a data da conclusão do callplan',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      setLoading(false)
      return false
    }

    const json = normalizeJSON()

    const perfis = ls.get('perfis')
    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_GRAVA_CALLPLAN`,
      json,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    if (selectedOportunity.value) {
      createOportunity(result.data.id)
    }

    setTimeout(() => {
      setAlertParams({
        open: true,
        text: result.data.retorno,
        onClick: () => {
          setAlertParams({ ...alertParams, open: false })
          if (!!json.data_conclusao) setOpen(false)
          if (result.data.id) setOpen(false)
        }
      })
      setLoading(false)

      return false
    }, 2000)
  }

  const processNewCallPlan = () => {
    const newCallPlanData = {
      ...callPlanData,
      realizado: '',
      id: '',
      data_prevista: '',
      hora_inicio: '',
      hora_fim: '',
      data_conclusao: '',
      motivo_naorealizado: ''
    }

    setCallPlanData(newCallPlanData)
    setDisabledFields(false)
  }

  const processRemoveCallPlan = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_callplan: callPlanData.id
    }

    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_EXCLUI_CALLPLAN`,
      json
    )

    setAlertParams({
      open: true,
      text: result.data.retorno,
      onClick: () => {
        setAlertParams({ ...alertParams, open: false })
        setOpen(false)
      }
    })
  }

  const companyChange = (event, value) => {
    setSelectedCompany(value)
    if (value !== '') {
      const tipo_negocio = tipoNegocioList.find(
        v => v.label === value?.tipo_negocio
      )

      const setor = setorList.find(v => v.label === value?.setor) || ''
      const segmento = segmentoList.find(v => v.label === value?.segmento) || ''
      const big5 = big5List.find(v => v.label === value?.big5) || ''
      const perfis = ls.get('perfis')

      setCallPlanData({
        ...callPlanData,
        empresa: value?.empresa,
        tipo_negocio: tipo_negocio?.value,
        setor: setor?.value,
        segmento: segmento?.value,
        ranking: big5?.value,
        distribuidor: perfis[0]?.value,
        vendedor: selectedSeller?.value
      })
    }
  }

  const updateSelect = (value, field) => {
    setCallPlanData({ ...callPlanData, [field]: value })
  }

  const handleChange = (e, sendId, date) => {
    setCallPlanData({
      ...callPlanData,
      [sendId ? sendId : e.target.id]: date ? e : e.target.value
    })
  }

  const getCompanies = async () => {
    const perfis = ls.get('perfis')
    const vendedor = ls.get('codigo_vededor')

    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_EMPRESA`,
      {
        distribuidor: perfis[0].value,
        vendedor: vendedor,
        supervisor: ''
      }
    )

    if (callPlanMode === 'new') {
      const minutes =
        Number(moment(schedule.start).format('mm')) < 30 ? '00' : 30
      const selectedHour = `${moment(schedule.start).format('HH')}:${minutes}`
      setCallPlanData({
        ...callPlanData,
        distribuidor: perfis[0].value,
        id: '',
        vendedor: vendedor,
        data_prevista: schedule.start,
        hora_inicio: horarioList.find(
          h => String(h.value) === String(selectedHour)
        )
      })
    }

    if (!result.data.message) setCompanies(result.data)
  }

  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line
  }, [])

  function getMonday(d) {
    d = new Date(d)
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    return new Date(d.setDate(diff))
  }

  return (
    <>
      <Box className='flex gap-2 mb-3 mt-4'>
        <div className='w-1/2 ml-2'>
          {callPlanMode === 'new' ? (
            <Autocomplete
              fullWidth
              options={companies}
              getOptionLabel={option => option?.empresa || ''}
              onChange={companyChange}
              defaultValue={callPlanData.empresa}
              id='empresa'
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Empresa'
                  placeholder='Selecione'
                  margin='normal'
                  fullWidth
                  className={classes.companyInput}
                />
              )}
              noOptionsText={
                <Button
                  variant='outlined'
                  color='secondary'
                  style={{ width: '100%' }}
                  onMouseDown={() => {
                    setOpenInsertDialog(true)
                  }}
                  startIcon={<PostAdd />}
                >
                  Nenhum resultado encontrado! Clique aqui para criar uma nova
                  empresa.
                </Button>
              }
            />
          ) : (
            <FormLabel label='Empresa' value={callPlanData.empresa} />
          )}
        </div>
        <div className='w-1/2'>
          <FormLabel label='Tipo Negócio' value={callPlanData.tipo_negocio} />
        </div>
      </Box>
      <Box className='flex gap-2 mb-3'>
        <div className='w-2/12'>
          <FormLabel label='Setor' value={callPlanData.setor} />
        </div>
        <div className='w-2/12'>
          <FormLabel label='Segmento' value={callPlanData.segmento} />
        </div>
        <div className='w-2/12'>
          <FormLabel label='BIG5/TOP5' value={callPlanData.ranking} />
        </div>
        <div className='w-2/12'>
          <InputForm
            onChange={handleChange}
            value={callPlanData.volume_estimativa}
            label='Estimativa Volume Anual'
            id='volume_estimativa'
            disabled={disabledFields}
          />
        </div>
        <div className='w-4/12'>
          <InputForm
            onChange={handleChange}
            value={callPlanData.contato}
            label='Contato'
            id='contato'
            disabled={disabledFields}
          />
        </div>
      </Box>
      <Divider />
      <Box className='flex gap-2 mb-3'>
        <div className='w-2/12'>
          <DateInput
            label='Data Prevista'
            id='data_prevista'
            selected={callPlanData.data_prevista}
            minDate={moment(getMonday(new Date())).toDate()}
            onChange={e => {
              handleChange(e, 'data_prevista', true)
            }}
            disabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'hora_inicio')
            }}
            value={callPlanData.hora_inicio}
            label='Horário Início'
            id='hora_inicio'
            options={horarioList}
            maxMenuHeight={200}
            placeholder='Selecione...'
            isDisabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'hora_fim')
            }}
            value={callPlanData.hora_fim}
            label='Horário Fim'
            id='hora_fim'
            options={horarioList}
            maxMenuHeight={200}
            placeholder='Selecione...'
            isDisabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_visita')
            }}
            value={callPlanData.tipo_visita}
            label='Tipo da Visita'
            id='tipo_visita'
            options={tipoVisitaList}
            maxMenuHeight={200}
            placeholder='Selecione...'
            isDisabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'realizado')
            }}
            value={realizadoList.find(t => t.value === callPlanData.realizado)}
            label='Realizado'
            id='realizado'
            options={realizadoList}
            maxMenuHeight={200}
            placeholder='Selecione...'
            isDisabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'motivo_naorealizado')
            }}
            value={motivoNaoRealizacaoList.find(
              t => t.value === callPlanData.motivo_naorealizado
            )}
            label='Motivo não realização'
            id='motivo_naorealizado'
            options={motivoNaoRealizacaoList}
            maxMenuHeight={200}
            placeholder='Selecione...'
            isDisabled={disabledFields}
          />
        </div>
        <div className='w-2/12'>
          <DateInput
            label='Data Conclusão'
            id='data_conclusao'
            selected={callPlanData.data_conclusao}
            onChange={e => {
              handleChange(e, 'data_conclusao', true)
            }}
            disabled={disabledFields}
          />
        </div>
      </Box>
      <Box className='flex gap-2 mb-3'>
        <Box display='flex' flexDirection='column' className='w-1/2'>
          <div>
            <TextAreaForm
              onChange={handleChange}
              value={callPlanData.proposito}
              rows={2}
              label='Proposito'
              id='proposito'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.objetivo}
              label='Objetivo'
              id='objetivo'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.premissa}
              label='Premissa'
              id='premissa'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.estrategia}
              label='Estrategia'
              id='estrategia'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.antecipar}
              label='Antecipar'
              id='antecipar'
              disabled={disabledFields}
            />
          </div>
        </Box>
        <Box display='flex' flexDirection='column' className='w-1/2'>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.notas}
              label='Anotacoes'
              id='notas'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.informacao_adicional}
              label='Informações Adicionais'
              id='informacao_adicional'
              disabled={disabledFields}
            />
          </div>
          <div>
            <TextAreaForm
              onChange={handleChange}
              rows={2}
              value={callPlanData.conclusao}
              label='Conclusão'
              id='conclusao'
              disabled={disabledFields}
            />
          </div>
        </Box>
      </Box>
      {loading && (
        <Box
          mt={4}
          mb={4}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
          <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ml-4'>
            Aguarde...
          </label>
        </Box>
      )}
      <Divider />
      <Box className='flex gap-2 mb-3'>
        <div className='w-1/2'>
          <RelatedOportunity
            {...{ selectedOportunity, setSelectedOportunity }}
          />
        </div>
        <div className='w-1/2'>
          <RelatedFiles />
        </div>
      </Box>
      <Box className='flex gap-2 mb-3 justify-between'>
        <Button
          variant='contained'
          color='primary'
          startIcon={<Save />}
          onClick={addCallPlan}
          disabled={disabledFields}
        >
          Salvar
        </Button>
        <Button
          variant='contained'
          color='primary'
          startIcon={<FileCopy />}
          onClick={() => setShowConfirm(true)}
        >
          Duplicar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<Delete />}
          onClick={() => setShowRemove(true)}
          disabled={!callPlanData.id || callPlanData.expired}
        >
          Excluir
        </Button>
      </Box>
      <AddCompanyDialog
        open={openInsertDialog}
        setOpen={setOpenInsertDialog}
        tipoNegocioList={tipoNegocioList}
        setorList={setorList}
        segmentoList={segmentoList}
        big5List={big5List}
      />
      <ConfirmDialog
        open={showConfirm}
        title='Atenção'
        text='Tem certeza que deseja duplicar este callplan?'
        onOK={() => {
          processNewCallPlan()

          setShowConfirm(false)
        }}
        onCancel={() => {
          setShowConfirm(false)
        }}
      />
      <ConfirmDialog
        open={showRemove}
        title='Atenção'
        text='Tem certeza que deseja remover este callplan? Esta ação é irreversível.'
        onOK={() => {
          processRemoveCallPlan()
          setShowRemove(false)
        }}
        onCancel={() => {
          setShowRemove(false)
        }}
      />
    </>
  )
}
