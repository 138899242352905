
import { useAtom } from 'jotai'
import React, { Fragment } from 'react'
import { newSpancopResponsesAtom } from '../Atoms'
import QuestionResponseItem from './SpancopQuestionResponseItem'
import { SpancopCoachingQuestions as questions } from '../../../constData/constData.js'
 
export default function SpancopCoachingNewResponsesForm({ show }) {
    const [newResponses, setNewResponses] = useAtom(newSpancopResponsesAtom)

    const handleCoachingResponse = (key, e) => {
      const { value } = e.target
      setNewResponses({ ...newResponses, [key]: value })
    
    } 

    const questionsSection1 = questions.slice(0, 1)
    const questionsSection2 = questions.slice(2)
  

  return (
    <div>
      {show ? (
        <Fragment> 
          <div className='flex flex-col'>
            <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-6'>
              {questions[0].question}
            </div>
            <div className='flex flex-row justify-between'>
              {questionsSection1.map(question => {
                return (
                  <QuestionResponseItem
                    item={question} 
                    key={question.key}
                    handleCoachingResponse={handleCoachingResponse} 
                    newResponses={newResponses}
                  />
                )
              })} 
            </div>
          </div>

          <div className='flex flex-col'>
            <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-6'>
              {questions[1].question}
            </div>
            <div className='flex flex-row justify-between'>
              {questionsSection2.map(question => {
                return (
                  <QuestionResponseItem
                    item={question} 
                    key={question.key}
                    handleCoachingResponse={handleCoachingResponse} 
                    newResponses={newResponses}
                  />
                )
              })} 
            </div>
          </div>

        </Fragment>
      ) : (
        <div>Selecione o cliente para continuar</div>
      )}
    </div>
  )
}