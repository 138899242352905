import React from 'react'
import { useAtom } from 'jotai'

import { researchAtoms } from './Atoms'
import { renderButtons } from './functions'

export default function RangeQuestion(props) {
  const { id, question, question_extended, number } = props.question
  const [research, setResearch] = useAtom(researchAtoms)

  const setNewValue = e => {
    const { id, value } = e.target
    setResearch({ ...research, [id]: Number(value) })
  }

  const buttonValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const buttons = renderButtons(buttonValues, research, id, setNewValue)

  return (
    <div className='px-6 py-4'>
      <div className='text-md mb-2 text-left font-semibold'>
        <b>{number} </b>
        {question}
      </div>
      <div className='text-sm mb-2 text-left font-light text-gray-700'>
        {question_extended}
      </div>

      <div className='inline-flex' role='group' aria-label='Button group'>
        {buttons}
      </div>
    </div>
  )
}
