import React from 'react'

export default function InputForm(props) {
  return (
    <React.Fragment>
      <label
        className={`block uppercase tracking-wide ${
          props.labelcolor ? props.labelcolor : 'text-gray-700'
        } text-xs font-bold mb-2`}
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <input
        disabled={props.disabledinput === 'true' ? true : false}
        {...props}
        className={`appearance-none block w-full ${
          !props.bg || props.bg !== '' ? props.bg : 'bg-white'
        } text-gray-700 border  rounded py-1 px-2 h-10 leading-tight focus:outline-none border-gray-400 text-xs`}
        type={props.type ? props.type : 'text'}
      />
    </React.Fragment>
  )
}
