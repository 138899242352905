import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core'
import { useAtom } from 'jotai'
import React from 'react'
import { selectedSellerAtom } from '../../_global/Atoms'
import CloseIcon from '@material-ui/icons/Close'
import CoachingForm from './CoachingForm'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function CoachingDialog({ open, handleClose }) {
  const [selectedSeller] = useAtom(selectedSellerAtom)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className='relative'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' className='ml-2'>
            COACHING - {selectedSeller.label}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <CoachingForm />
      </DialogContent>
    </Dialog>
  )
}
