import { useAtom } from 'jotai'
import React, { Fragment, useEffect, useState } from 'react'
import { monthSelect } from '../../../constData/constData'
import ReactSelect from '../../Inputs/ReactSelect'
import {
  filterDataAtom,
  indicadorAtom,
  selectedSupervisorMonthAtom,
  supervisorRowsAtom
} from '../../_global/Atoms'
import { indicatorsListAtom, objetivoOptionAtom, rawDataAtom } from './Atoms'
import c3 from 'c3'

const KPIHodometer = () => {
  const [indicador] = useAtom(indicadorAtom)
  const [indicatorData, setIndicatorData] = useState(false)
  const [rows] = useAtom(supervisorRowsAtom)
  const [rawData] = useAtom(rawDataAtom)
  const [totals, setTotals] = useState({ objetivo: 0, realizado: 0 })
  const [selectMonthOptions, setSelectMonthOptions] = useState([])
  const [selectedMonth, setSelectedMonth] = useAtom(selectedSupervisorMonthAtom)
  const [objetivoOption, setObjetivoOption] = useAtom(objetivoOptionAtom)
  const [disabledSelect, setDisabledSelect] = useState(false)
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [indicatorsList] = useAtom(indicatorsListAtom)

  useEffect(() => {
    const ind = indicatorsList.find(i => i.key === indicador)
    setIndicatorData(ind)
    //eslint-disable-next-line
  }, [indicador])

  useEffect(() => {
    if (rawData.length > 0 && indicatorData) {
      let reduceObjetivo = 0
      let reduceRealizado = 0

      rawData.forEach(d => {
        const reducedObjItem = d.alvos
          .filter(item => item.descricao === indicatorData.indicador)
          .reduce((acc, item) => acc + item.objetivo_mes, 0)
        reduceObjetivo += reducedObjItem

        const reduceRealItem = d.alvos
          .filter(item => item.descricao === indicatorData.indicador)
          .reduce((acc, item) => acc + item.real_mes, 0)
        reduceRealizado += reduceRealItem
      })

      setTotals({ objetivo: reduceObjetivo, realizado: reduceRealizado })
    }
  }, [rawData, indicatorData])

  useEffect(() => {
    const months = monthSelect()
    setSelectMonthOptions(months)
  }, [])

  const selectObjetivoOptions = [
    { key: 1, label: 'Objetivo mês', value: 1 },
    { key: 2, label: 'Objetivo proporcional até a data', value: 2 }
  ]

  useEffect(() => {
    !objetivoOption &&
      setObjetivoOption({ key: 1, label: 'Objetivo mês', value: 1 })
    //eslint-disable-next-line
  }, [objetivoOption])

  const updatePeriod = v => {
    setSelectedMonth(v)
    setObjetivoOption({ key: 1, label: 'Objetivo mês', value: 1 })
    setFilterData({
      ...filterData,
      data_inicial: v.value.inicioFull,
      data_final: v.value.terminoFull
    })
    const now = new Date()
    if (
      v.value.inicioFull.toLocaleDateString('pt-BR') !==
      new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString('pt-BR')
    ) {
      setDisabledSelect(true)
    } else {
      setDisabledSelect(false)
    }
  }

  const updateProportional = v => {
    setObjetivoOption(v)
  }

  const renderGaugeGraph = percentual => {
    c3.generate({
      bindto: '#graph',
      data: {
        columns: [['realizado %', percentual]],
        type: 'gauge'
      },
      gauge: {
        label: {
          format: function (value, ratio) {
            return value //returning here the value and not the ratio
          }
        },
        min: 0,
        max: percentual > 100 ? percentual : 100
      },
      color: {
        pattern: ['red', 'yellow', 'green'],
        threshold: {
          values: [90, 100]
        }
      }
    })
  }

  useEffect(() => {
    const percentual = Math.round((totals.realizado / totals.objetivo) * 100)
    renderGaugeGraph(percentual)
  }, [totals, rows])

  return (
    <div className='w-3/12 border border-gray-400 p-2'>
      {indicador ? (
        <Fragment>
          <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
            {indicatorData && indicatorData.indicador}
          </div>
          {rows.length > 0 && (
            <div className='flex justify-center'>
              <div id='graph'></div>
              {/* {indicador && (
                <Chart
                  height={180}
                  chartType='Gauge'
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Label', 'Value'],
                    [
                      '% Volume',
                      Math.round((totals.realizado / totals.objetivo) * 100)
                    ]
                  ]}
                  options={{
                    redFrom: 0,
                    redTo: 90,
                    yellowFrom: 90,
                    yellowTo: 100,
                    greenFrom: 100,
                    greenTo: (totals.realizado / totals.objetivo) * 100,
                    minorTicks: 5
                  }}
                />
              )} */}
            </div>
          )}
          <div className='text-xs text-gray-700'>
            Periodo apurado:{' '}
            {selectedMonth.value &&
              `${selectedMonth.value.inicio} à ${selectedMonth.value.termino}`}
          </div>
          <div>
            <ReactSelect
              onChange={v => {
                updatePeriod(v)
              }}
              value={selectedMonth}
              id='month'
              options={selectMonthOptions}
              placeholder='Selecione...'
              maxMenuHeight={200}
              menuPlacement='auto'
            />
          </div>
          <div>
            <ReactSelect
              onChange={v => {
                updateProportional(v)
              }}
              value={objetivoOption}
              id='obj_option'
              options={selectObjetivoOptions}
              placeholder='Selecione...'
              maxMenuHeight={200}
              isDisabled={disabledSelect}
              menuPlacement='auto'
            />
          </div>
        </Fragment>
      ) : (
        'Selecione um indicador.'
      )}
    </div>
  )
}

export default KPIHodometer
