import { Button } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import { useAtom } from 'jotai'
import React, { Fragment, useState, useEffect } from 'react'
import ReactSelect from '../Inputs/ReactSelect'
import Header from '../_global/Header'
import {
  grupoProdutoListAtom,
  KPIAlvosAtom,
  KPIOportunitiesAtom,
  selectedSellerAtom,
  selectedTipoNegocioAtom,
  selectedYearAtom
} from './Atoms'
import Resumo from './Resumo'
import Oportunidades from './Oportunidades'
import Indicadores from './Indicadores'
import Celulas from './Celulas'
import Relatorios from './Relatorios'
import CallPlan from './CallPlan'
import Coaching from './Coaching'
import ls from 'local-storage'
import Axios from 'axios'

const yearsList = () => {
  const now = new Date()
  const current = now.getFullYear()

  return [
    { label: current, value: current },
    { label: current + 1, value: current + 1 },
    { label: 'Geral', value: '0' }
  ]
}

const tipoNegocioList = [
  { label: 'TODOS', value: '' },
  { label: 'Broker', value: 'Broker' },
  { label: 'Franquia', value: 'Franquia' },
  { label: 'FWS SubFornecimento', value: 'FWS SubFornecimento' }
]

const buttons = [
  { label: 'Resumo', component: <Resumo />, icon: <Star /> },
  { label: 'Oportunidades', component: <Oportunidades />, icon: <Star /> },
  { label: 'Indicadores', component: <Indicadores />, icon: <Star /> },
  { label: 'Células', component: <Celulas />, icon: <Star /> },
  { label: 'Relatórios', component: <Relatorios />, icon: <Star /> },
  { label: 'CallPlan', component: <CallPlan />, icon: <Star /> },
  { label: 'Coaching', component: <Coaching />, icon: <Star /> }
]

const getSellersList = () => {
  const sellers = ls.get('sellers')
  const tipo = ls.get('tipo')
  const todos = { label: 'TODOS', value: '' }
  const formattedSellers = sellers.map(seller => {
    return {
      label: seller.nome_vededor,
      value: seller.codigo_vededor
    }
  })

  if (tipo === 'VENDEDOR') return formattedSellers

  return [todos, ...formattedSellers]
}

export default function Index() {
  const [selectedSeller, setSelectedSeller] = useAtom(selectedSellerAtom)
  const [selectedYear, setSelectedYear] = useAtom(selectedYearAtom)
  const [selectedTipoNegocio, setSelectedTipoNegocio] = useAtom(
    selectedTipoNegocioAtom
  )
  const [loadedComponent, setLoadedComponent] = useState(false)
  const [sellerList, setSellerList] = useState([])
  const [, setGrupoProdutoList] = useAtom(grupoProdutoListAtom)
  const [, setKPIOportunities] = useAtom(KPIOportunitiesAtom)
  const [, setKPIAlvos] = useAtom(KPIAlvosAtom)

  useEffect(() => {
    setSellerList(getSellersList())
  }, [])

  const getData = async () => {
    const perfis = ls.get('perfis')
    const tipo = ls.get('tipo')

    const json = {
      distribuidor: perfis[0].value,
      vendedor: selectedSeller.value || '',
      supervisor: tipo === 'SUPERVISOR' ? ls.get('codigo_vededor') : '',
      ano: Number(selectedYear.value),
      tipo_negocio: selectedTipoNegocio.value || ''
    }

    const result1 = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_KPI_OPORTUNIDADES`,
      json
    )

    const result2 = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_KPI_ALVOS`,
      json
    )

    setKPIOportunities(result1.data[0])
    setKPIAlvos(result2.data[0])
  }

  useEffect(() => {
    selectedYear.value && getData()
    // eslint-disable-next-line
  }, [selectedSeller, selectedYear, selectedTipoNegocio])

  const getGrupoProdutoList = async () => {
    const perfis = ls.get('perfis')

    const { data } = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_GRUPOPRODUTOS`,
      { distribuidor: perfis[0].value }
    )

    const results = data.map(d => {
      return {
        value: d.descricao,
        label: d.descricao
      }
    })

    setGrupoProdutoList(results)
  }

  useEffect(() => {
    getGrupoProdutoList()
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <div className='w-full h-full'>
        <Header
          label='BackOffice Shell - SPANCOP'
          title='Shell BackOffice - SPANCOP'
        />

        <div className='flex flex-wrap  mb-4'>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3s'>
            <ReactSelect
              onChange={v => {
                setSelectedSeller(v)
              }}
              value={selectedSeller}
              label='Consultor *'
              id='consultor'
              options={sellerList}
              allowSelectAll={false}
              placeholder='Selecione...'
              maxMenuHeight={200}
            />
          </div>

          <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
            <ReactSelect
              onChange={v => {
                setSelectedYear(v)
              }}
              value={selectedYear}
              label='Ano *'
              id='ano'
              options={yearsList()}
              allowSelectAll={false}
              placeholder='Selecione...'
              maxMenuHeight={200}
            />
          </div>

          <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
            <ReactSelect
              onChange={v => {
                setSelectedTipoNegocio(v)
              }}
              value={selectedTipoNegocio}
              label='Tipo Negócio *'
              id='tipo_negocio'
              options={tipoNegocioList}
              allowSelectAll={false}
              placeholder='Selecione...'
              maxMenuHeight={200}
            />
          </div>
        </div>

        <div className='flex gap-2 justify-around'>
          {buttons.map(button => (
            <Button
              key={Math.random()}
              variant='contained'
              color={
                loadedComponent === button.component ? 'secondary' : 'primary'
              }
              onClick={() => setLoadedComponent(button.component)}
              disabled={
                !selectedSeller || !selectedYear || !selectedTipoNegocio
              }
            >
              {button.label}
            </Button>
          ))}
        </div>
        <div className='mt-2 border-gray-400 border rounded-sm p-5'>
          {loadedComponent}
        </div>
      </div>
    </Fragment>
  )
}
