import { Button, makeStyles, TextField } from '@material-ui/core'
import { Cancel, Delete, FileCopy, PostAdd, Save } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { DateInput } from '../../Inputs/DateInput'
import InputForm from '../../Inputs/InputForm'
import ReactSelect from '../../Inputs/ReactSelect'
import { alertParamsAtom } from '../../_global/Atoms'
import ConfirmDialog from '../../_global/ConfirmDialog'
import {
  companiesAtom,
  selectedOportunityAtom,
  selectedSellerAtom,
  productsAtom,
  grupoProdutoListAtom,
  segmentListAtom
} from '../Atoms'
import ls from 'local-storage'
import LostOpportunityDialog from './LostOpportunityDialog'
import AddCompanyDialog from './AddCompanyDialog'
import moment from 'moment'
import {
  big5List,
  possibilidadeNegocioList,
  setorList,
  tipoNegocioList,
  tipoOportunidadeList
} from './constData'

const useStyles = makeStyles({
  companyInput: {
    width: '100%'
  }
})

export default function Form({ setOpen }) {
  const [selectedOportunity, setSelectedOportunity] = useAtom(
    selectedOportunityAtom
  )
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [lostDialogOpen, setLostDialogOpen] = useState(false)
  const [companies, setCompanies] = useAtom(companiesAtom)
  // eslint-disable-next-line
  const [typedCompany, setTypedCompany] = useState('')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [openInsertDialog, setOpenInsertDialog] = useState(false)
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [products] = useAtom(productsAtom)
  const [showRemove, setShowRemove] = useState(false)
  const [grupoProdutoList] = useAtom(grupoProdutoListAtom)
  const [segmentList, setSegmentList] = useAtom(segmentListAtom)

  const listProducts = () => {
    return products.map(p => {
      return {
        label: `${p.sku} - ${p.descricao}`,
        value: `${p.sku} - ${p.descricao}`
      }
    })
  }

  const getSegmentList = async setor => {
    const perfis = ls.get('perfis')

    const { data } = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_SEGMENTOS`,
      {
        distribuidor: perfis[0].value,
        setor: setor
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    const results = data.map(d => {
      return {
        value: d.descricao,
        label: d.descricao
      }
    })

    setSegmentList(results)
  }

  const getCompanies = async () => {
    const perfis = ls.get('perfis')
    const vendedor = ls.get('codigo_vededor')

    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_EMPRESA`,
      {
        distribuidor: perfis[0].value,
        vendedor: vendedor,
        supervisor: ''
      }
    )
    if (!result.data.message) setCompanies(result.data)
  }

  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line
  }, [])

  const processDate = date => {
    if (date === '') return null
    if (typeof date === 'object') return date
    const splitDate = date.split('/')
    return new Date('20' + splitDate[2], splitDate[1] - 1, splitDate[0])
  }

  const classes = useStyles()

  const updateSelect = (value, field) => {
    if (field === 'propabilidade_negocio') {
      if (value.value === 'perdido') {
        setLostDialogOpen(true)
        /**
         * TODO: Limpar as datas PANCOP
         *
         *
         */
      }
    }
    setSelectedOportunity({ ...selectedOportunity, [field]: value })
  }

  const handleChange = (e, sendId, date) => {
    if (sendId === 'data_prospeccao') {
      if (
        selectedOportunity.data_primeiraordem === '' ||
        selectedOportunity.volume_estimativa === '' ||
        selectedOportunity.ranking === ''
      ) {
        setAlertParams({
          open: true,
          text: 'Preencha a data da primeira ordem, a estimativa de volume anual e a opção BIG5/TOP5 para selecionar a data neste campo',
          onClick: () => setAlertParams({ ...alertParams, open: false })
        })
        return false
      }

      /**
       * TODO: Verificar se já existem 5 oportunidades abertas em BIG5/TOP5. Exibir erro se houver.
       * 
       *  setAlertParams({
          open: true,
          text: 'Já existem 5 oportunidades classificadas como BIG5/TOP5.',
          onClick: () => setAlertParams({ ...alertParams, open: false })
        })
       * 
       */
    }

    setSelectedOportunity({
      ...selectedOportunity,
      [sendId ? sendId : e.target.id]: date ? e : e.target.value
    })
  }

  const duplicateOportunity = () => {
    // TODO: Possibilidade de Duplicar a oportunidade. Campos que devem vir preenchidos: CLIENTE, TIPO DE NEGÓCIO, SETOR, SEGMENTO, TIPO DE OPORTUNIDADE
  }

  const processRemoveOportunity = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      id_oportunidade: selectedOportunity.id
    }

    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_EXCLUI_OPORTUNIDADE`,
      json
    )

    setAlertParams({
      open: true,
      text: result.data.retorno,
      onClick: () => {
        setAlertParams({ ...alertParams, open: false })
        setOpen(false)
      }
    })
  }

  const testData = data => {
    if (!data) return ''
    if (typeof data === 'string') return data
    return moment(data).format('DD/MM/YYYY')
  }

  const normalizeJSON = () => {
    const {
      tipo_negocio,
      setor,
      ranking,
      tipo_oportunidade,
      grupo_produto,
      segmento,
      propabilidade_negocio,
      data_primeiraordem,
      data_suspeito,
      data_analise,
      data_contrato,
      data_lost,
      data_negociacao,
      data_ordem,
      data_pagamento,
      data_prospeccao,
      data_proximo,
      produto,
      volume_real
    } = selectedOportunity
    const sendJSON = {
      ...selectedOportunity,
      tipo_negocio: tipo_negocio.value ? tipo_negocio.value : tipo_negocio,
      produto: produto?.value ? produto?.value : produto,
      setor: setor.value ? setor.value : setor,
      ranking: ranking?.value ? ranking?.value : ranking,
      tipo_oportunidade: tipo_oportunidade?.value
        ? tipo_oportunidade?.value
        : tipo_oportunidade,
      propabilidade_negocio: propabilidade_negocio?.value
        ? propabilidade_negocio?.value
        : propabilidade_negocio,
      grupo_produto: grupo_produto?.value
        ? grupo_produto?.value
        : grupo_produto,
      segmento: segmento?.value ? segmento?.value : segmento,
      volume_estimativa: Number(selectedOportunity.volume_estimativa),
      data_primeiraordem: testData(data_primeiraordem),
      data_suspeito: testData(data_suspeito),
      data_analise: testData(data_analise),
      data_contrato: testData(data_contrato),
      data_lost: testData(data_lost),
      data_negociacao: testData(data_negociacao),
      data_ordem: testData(data_ordem),
      data_pagamento: testData(data_pagamento),
      data_prospeccao: testData(data_prospeccao),
      data_proximo: testData(data_proximo),
      volume_real: Number(volume_real)
    }

    return sendJSON
  }

  const addOportunity = async () => {
    const { empresa, tipo_negocio, setor, segmento, grupo_produto } =
      selectedOportunity
    if (
      empresa === '' ||
      tipo_negocio === '' ||
      setor === '' ||
      segmento === '' ||
      grupo_produto === ''
    ) {
      setAlertParams({
        open: true,
        text: 'Para iniciar a oportunidade é necessário preencher: Empresa, Tipo do Negócio, Setor, Segmento e Grupo de Produtos.',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    /**
     * TODO: Processar o restante da oportunidade
     */

    const json = normalizeJSON()
    const perfis = ls.get('perfis')
    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_GRAVA_OPORTUNIDADE`,
      json,
      {
        headers: {
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    setAlertParams({
      open: true,
      text: result.data.retorno,
      onClick: () => {
        setAlertParams({ ...alertParams, open: false })
        if (result.data.id) {
          setOpen(false)
        }
      }
    })
  }

  const companyChange = (event, value) => {
    setSelectedCompany(value)
    if (value !== '') {
      const tipo_negocio = tipoNegocioList.find(
        v => v.label === value.tipo_negocio
      )
      const setor = setorList.find(v => v.label === value.setor)
      const segmento = segmentList.find(v => v.label === value.segmento)
      const big5 = big5List.find(v => v.label === value.big5)
      const perfis = ls.get('perfis')

      setSelectedOportunity({
        ...selectedOportunity,
        empresa: value.empresa,
        tipo_negocio,
        setor,
        segmento,
        ranking: big5,
        distribuidor: perfis[0].value,
        vendedor: selectedSeller.value
      })
    }

    // TODO
  }

  useEffect(() => {
    const findCompany = companies.find(
      c => c.empresa === selectedOportunity.empresa
    )
    setSelectedCompany(findCompany)
    // eslint-disable-next-line
  }, [companies])

  useEffect(() => {
    const setor = selectedOportunity?.setor?.value
    if (setor) {
      getSegmentList(setor)
    }
    // eslint-disable-next-line
  }, [selectedOportunity.setor])

  //return <div />

  return (
    <div className='mt-5'>
      <div className='flex gap-4 w-full mb-2'>
        {selectedOportunity.empresa ? (
          <>
            <div>{selectedOportunity.empresa}</div>
            <div>
              <Button
                variant='contained'
                color='secondary'
                onClick={() =>
                  setSelectedOportunity({ ...selectedOportunity, empresa: '' })
                }
              >
                Alterar
              </Button>
            </div>
          </>
        ) : (
          <Autocomplete
            fullWidth
            options={companies}
            s
            getOptionLabel={option => option?.empresa || ''}
            onChange={companyChange}
            onInputChange={e => setTypedCompany(e?.target?.value || '')}
            defaultValue={selectedCompany}
            id='empresa'
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label='Empresa'
                placeholder='Selecione'
                margin='normal'
                fullWidth
                className={classes.companyInput}
              />
            )}
            noOptionsText={
              <Button
                variant='outlined'
                color='secondary'
                style={{ width: '100%' }}
                onMouseDown={() => {
                  setOpenInsertDialog(true)
                }}
                startIcon={<PostAdd />}
              >
                Nenhum resultado encontrado! Clique aqui para criar uma nova
                empresa.
              </Button>
            }
          />
        )}
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_negocio')
            }}
            value={selectedOportunity.tipo_negocio}
            label='Tipo de Negócio'
            id='tipo_negocio'
            options={tipoNegocioList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'setor')
            }}
            value={selectedOportunity.setor}
            label='Setor'
            id='setor'
            options={setorList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'ranking')
            }}
            value={selectedOportunity.ranking}
            label='BIG5/TOP5'
            id='ranking'
            options={big5List}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_oportunidade')
            }}
            value={selectedOportunity.tipo_oportunidade}
            label='Tipo de Oportunidade'
            id='tipo_oportunidade'
            options={tipoOportunidadeList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'grupo_produto')
            }}
            value={grupoProdutoList.find(
              g => g.value === selectedOportunity.grupo_produto
            )}
            label='Grupo de Produto'
            id='grupo_produto'
            options={grupoProdutoList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/4'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'segmento')
            }}
            value={segmentList.find(
              g => g.value === selectedOportunity.segmento
            )}
            label='Segmento'
            id='segmento'
            options={segmentList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-2/12'>
          <DateInput
            label='Data Estimada da 1a Ordem'
            id='data_primeiraordem'
            selected={processDate(selectedOportunity.data_primeiraordem)}
            onChange={e => {
              handleChange(e, 'data_primeiraordem', true)
            }}
          />
        </div>
        <div className='w-2/12'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'propabilidade_negocio')
            }}
            value={selectedOportunity.propabilidade_negocio}
            label='Possibilidade Negócio'
            id='propabilidade_negocio'
            options={possibilidadeNegocioList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-2/12'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.volume_estimativa}
            label='Estimativa Volume Anual'
            id='volume_estimativa'
          />
        </div>
      </div>
      <div className='mb-2'>
        <InputForm
          onChange={handleChange}
          value={selectedOportunity.razoes_chave}
          label='Razões chave para ganhar o negócio (ou motivo porque perdeu)'
          id='razoes_chave'
        />
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-96'>
          <DateInput
            label='S (Suspect)'
            id='data_suspeito'
            selected={processDate(selectedOportunity.data_suspeito)}
            maxDate={new Date()}
            popperPlacement='bottom-start'
            onChange={e => {
              handleChange(e, 'data_suspeito', true)
            }}
          />
        </div>

        <div className='w-96'>
          <DateInput
            label='P (Prospect)'
            id='data_prospeccao'
            selected={processDate(selectedOportunity.data_prospeccao)}
            onChange={e => {
              handleChange(e, 'data_prospeccao', true)
            }}
          />
        </div>

        <div className='w-96'>
          <DateInput
            label='A (Analysis)'
            id='data_analise'
            selected={processDate(selectedOportunity.data_analise)}
            onChange={e => {
              handleChange(e, 'data_analise', true)
            }}
          />
        </div>
        <div className='w-96'>
          <DateInput
            label='N (Negociation)'
            id='data_negociacao'
            selected={processDate(selectedOportunity.data_negociacao)}
            onChange={e => {
              handleChange(e, 'data_negociacao', true)
            }}
          />
        </div>
        <div className='w-96'>
          <DateInput
            label='C (Contract)'
            id='data_contrato'
            selected={processDate(selectedOportunity.data_contrato)}
            onChange={e => {
              handleChange(e, 'data_contrato', true)
            }}
          />
        </div>
        <div className='w-96'>
          <DateInput
            label='O (Order)'
            id='data_ordem'
            selected={processDate(selectedOportunity.data_ordem)}
            onChange={e => {
              handleChange(e, 'data_ordem', true)
            }}
          />
        </div>
        <div className='w-96'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.volume_real}
            label='Volume Real'
            id='volume_real'
            disabled={!selectedOportunity.data_ordem}
          />
        </div>
        <div className='w-96'>
          <DateInput
            label='P (Payment)'
            id='data_pagamento'
            selected={processDate(selectedOportunity.data_pagamento)}
            onChange={e => {
              handleChange(e, 'data_pagamento', true)
            }}
          />
        </div>
      </div>
      <div className='flex  w-full mb-4'>
        <div
          className={`${
            selectedOportunity.data_suspeito !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_prospeccao !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_analise !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_negociacao !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_contrato !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_ordem !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_ordem !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
        <div
          className={`${
            selectedOportunity.data_pagamento !== '' && 'bg-yellow-600 h-2'
          }`}
          style={{ width: '14%' }}
        ></div>
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'produto')
            }}
            value={selectedOportunity.produto}
            label='Produto Ofertado'
            id='produto'
            options={listProducts()}
            allowSelectAll={true}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/3'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.fornecedor_atual}
            label='Fornecedor Atual'
            id='fornecedor_atual'
          />
        </div>
        <div className='w-1/3'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.produto_atual}
            label='Produto Atual'
            id='produto_atual'
          />
        </div>
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/2'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.requisitos_especiais}
            label='Requisitos Especiais'
            id='requisitos_especiais'
          />
        </div>
        <div className='w-1/2'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.valor_entregue}
            label='Valor Entregue ao Cliente'
            id='valor_entregue'
          />
        </div>
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <InputForm
          onChange={handleChange}
          value={selectedOportunity.notas}
          label='Notas'
          id='notas'
        />
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/3'>
          <DateInput
            label='Data Próximo Passo'
            id='data_proximo'
            selected={processDate(selectedOportunity.data_proximo)}
            onChange={e => {
              handleChange(e, 'data_proximo', true)
            }}
          />
        </div>
        <div className='w-2/3'>
          <InputForm
            onChange={handleChange}
            value={selectedOportunity.proximos_passo}
            label='Próximo Passo'
            id='proximos_passo'
          />
        </div>
      </div>
      <div className='flex gap-4 w-full justify-between'>
        <Button
          variant='contained'
          color='primary'
          startIcon={<Save />}
          onClick={addOportunity}
        >
          Salvar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<Cancel />}
          onClick={() => setOpen(false)}
          disabled
        >
          Cancelar
        </Button>
        <Button
          variant='contained'
          color='primary'
          startIcon={<FileCopy />}
          onClick={duplicateOportunity}
          disabled
        >
          Duplicar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<Delete />}
          onClick={() => setShowRemove(true)}
          disabled={!selectedOportunity.id}
        >
          Excluir
        </Button>
      </div>

      <LostOpportunityDialog
        open={lostDialogOpen}
        setOpen={setLostDialogOpen}
      />
      <AddCompanyDialog
        open={openInsertDialog}
        setOpen={setOpenInsertDialog}
        tipoNegocioList={tipoNegocioList}
        setorList={setorList}
        segmentoList={segmentList}
        big5List={big5List}
      />
      <ConfirmDialog
        open={showRemove}
        title='Atenção'
        text='Tem certeza que deseja excluir essa oportunidade? Essa ação é irreversível.'
        onOK={() => {
          processRemoveOportunity()
          setShowRemove(false)
        }}
        onCancel={() => {
          setShowRemove(false)
        }}
      />
    </div>
  )
}
