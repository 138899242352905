import React, { Fragment, useEffect } from 'react'
import {
  filterDataAtom,
  indicadorAtom,
  loadedModuleAtom,
  selectedSupervisorMonthAtom,
  supervisorRowsAtom
} from '../../_global/Atoms'
import Header from '../../_global/Header'
import KPIGraphs from './KPIGraphs'
import KPIHodometer from './KPIHodometer'
import KPITable from './KPITable'
import SellerTable from './SellerTable'
import { useAtom } from 'jotai'
import {
  getCurrentMonth,
  processSupervisorDataTable,
  retrieveData
} from '../../../functions/functions'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { indicatorsListAtom, rawDataAtom } from './Atoms'

const ControlPanel = () => {
  // TODO: buscar dados do servidor

  // eslint-disable-next-line
  const [rows, setRows] = useAtom(supervisorRowsAtom)
  const [indicador, setIndicador] = useAtom(indicadorAtom)
  // eslint-disable-next-line
  const [selectedMonth, setSelectedMonth] = useAtom(selectedSupervisorMonthAtom)
  const [filterData] = useAtom(filterDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  // eslint-disable-next-line
  const [indicatorsList, setIndicatorsList] = useAtom(indicatorsListAtom)
  const [rawData, setRawData] = useAtom(rawDataAtom)
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    const currentMonth = getCurrentMonth()
    setSelectedMonth(currentMonth)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getData = async () => {
      let data = []
      if (filterData.distribuidor?.length !== 0) {
        // eslint-disable-next-line
        data = await trackPromise(retrieveData(filterData, loadedModule))
      }

      if (data.length > 0) {
        const newIndicators = makeIndicators(data[0].alvos)
        setIndicatorsList(newIndicators)
        setRawData(data)
        setIndicador(5)
      }
    }
    getData()

    // eslint-disable-next-line
  }, [filterData])

  const makeIndicators = data => {
    return data.map(item => {
      const mx = item.descricao.split('-')
      return {
        indicador: item.descricao,
        key: Number(mx[0])
      }
    })
  }

  useEffect(() => {
    if (rawData.length > 0 && indicador) {
      const newRows = processSupervisorDataTable(rawData, indicador)
      setRows(newRows)
    }
    //eslint-disable-next-line
  }, [rawData, indicador])

  return (
    <Fragment>
      <Header
        label='Painel de Controle'
        title='Shell BackOffice - Supervisor'
        exit={false}
      />
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <div className='flex flex-row gap-1 mb-2'>
            <SellerTable />
            <KPITable />
          </div>
          <div className='flex flex-row gap-1'>
            <KPIGraphs />
            <KPIHodometer />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ControlPanel
