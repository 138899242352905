import React from "react"

export default function Button({ handleClick }) {
  return (
    <button
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-11/12'
      type='button'
      onClick={handleClick}
    >
      Entrar
    </button>
  )
}
