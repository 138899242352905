import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React from 'react'

export default function SelectCampaignRanges({
  value,
  handleCampaignRange,
  label,
  id
}) {
  return (
    <div className='flex flex-col items-center justify-center'>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor='faixas'
      >
        {label}
      </label>
      <ToggleButtonGroup
        value={value}
        exclusive
        size='small'
        onChange={handleCampaignRange}
        id={id}
      >
        <ToggleButton value='SIM'>SIM</ToggleButton>
        <ToggleButton value='NAO'>NÃO</ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
