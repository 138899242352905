import React from 'react'
import Logo from '../../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import { logout } from '../../functions/functions'
import ls from 'local-storage'
import { useEffect } from 'react'

const Header = props => {
  const { title, label, user, exit } = props
  useEffect(() => {
    document.title = title
    if (!ls.get('session') || ls.get('session') === '') {
      window.location = '/'
    }
  })

  return (
    <nav className='bg-blue-500 p-2 mb-4'>
      <div className='flex flex-wrap content-start text-white'>
        <div className='mr-4 w-6'>
          <img src={Logo} width='50px' height='3%' alt='logo' />
        </div>
        <div className='flex-1 text-left'>
          <span className='text-left font-semibold text-xl tracking-tight'>
            {label}{' '}
            {user ? (
              <span className='font-hairline text-gray-300'>{user}</span>
            ) : (
              ''
            )}
          </span>
        </div>
        {exit && (
          <div className='flex-1 text-right cursor-pointer' onClick={logout}>
            Sair <FontAwesomeIcon icon={faSignOutAlt} />{' '}
          </div>
        )}
      </div>
    </nav>
  )
}

export default Header
