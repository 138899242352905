import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
//eslint-disable-next-line
import { convertStringBRDate } from '../../../../functions/functions'
import {
  filterDataAtom,
  reportDataAtom,
  selectedRowsAtom
} from '../../../_global/Atoms'
import DataTable from '../DataTable'

const columnsLevel1 = [
  { name: 'tipo_produto', title: 'Tipo do Produto' },
  { name: 'pedidos', title: 'No. Notas' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' },
  { name: 'prazo_medio', title: 'Prazo Medio' }
]

const columnsLevel2 = [
  { name: 'fornecedor', title: 'Fornecedor' },
  { name: 'codigo', title: 'Código' },
  { name: 'pedidos', title: 'No. Notas' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume_total', title: 'Volume' },
  { name: 'prazo_medio', title: 'Prazo Medio' }
]

const columnsLevel3 = [
  { name: 'classificacao', title: 'Classificação do Produto' },
  { name: 'pedidos', title: 'No. Notas' },
  { name: 'total', title: 'Total R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' },
  { name: 'prazo_medio', title: 'Prazo Medio' }
]

//eslint-disable-next-line
const columnsLevel4 = [
  { name: 'nome_cliente', title: 'Cliente' },
  { name: 'emissao', title: 'Emitido em' },
  { name: 'data_inclusao_erp', title: 'Data Inclusão no ERP' },
  { name: 'nome_vendedor', title: 'Vendedor' },
  { name: 'numero', title: 'No. Notas' },
  { name: 'status', title: 'Status do Pedido' },
  { name: 'total', title: 'R$' },
  { name: 'quantidade', title: 'Quantidade' },
  { name: 'volume', title: 'Volume' }
]

export default function RevenuesByClassification() {
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [currentColumns, setCurrentColumns] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [filterData] = useAtom(filterDataAtom)
  const [selectedRows] = useAtom(selectedRowsAtom)
  const [dataLevel1, setDataLevel1] = useState([])
  const [dataLevel2, setDataLevel2] = useState([])
  const [dataLevel3, setDataLevel3] = useState([])

  // Trazer os dados do relatório geral e reduzir para o relatório por dia
  //const resumeDataColumns = () => {}

  useEffect(() => {
    //console.log(reportData)
    if (reportData.length > 0) {
      const level1 = jsonLevel1()
      setDataLevel1(level1)
    } else {
      setDataLevel1([])
    }
    // eslint-disable-next-line
  }, [reportData])

  useEffect(() => {
    if (Object.keys(selectedRows.level1).length > 0) {
      const level2 = jsonLevel2()
      setDataLevel2(level2)
    }

    if (Object.keys(selectedRows.level2).length > 0) {
      const level3 = jsonLevel3()
      setDataLevel3(level3)
    }
    // eslint-disable-next-line
  }, [selectedRows])

  const jsonLevel1 = () => {
    const newArray = []
    let tipo_produto = ''
    let numero
    const prazo_produto = []

    const filtered = reportData.filter(item => item.tipo === 'NF')

    filtered.forEach(row => {
      row.itens.forEach(item => {
        const pedidos =
          numero !== row.numero ? 1 : item.tipo_produto !== tipo_produto ? 1 : 0

        const findIndex = newArray.findIndex(
          arrItem => arrItem.tipo_produto === item.tipo_produto
        )

        const findIndexProduto = prazo_produto.findIndex(
          arrItem => arrItem.tipo_produto === item.tipo_produto
        )

        if (item.tipo_produto !== tipo_produto) {
          if (findIndex !== -1) {
            if (pedidos === 1) {
              prazo_produto[findIndexProduto].prazo_medio_total +=
                row.prazo_medio
              prazo_produto[findIndexProduto].pedidos += pedidos
            }

            newArray[findIndex].pedidos += pedidos
            newArray[findIndex].total += item.valor_produto
            newArray[findIndex].quantidade += Number(item.qtde_produto)
            newArray[findIndex].volume += item.volume_produto
          } else {
            prazo_produto.push({
              tipo_produto: item.tipo_produto,
              prazo_medio_total: row.prazo_medio,
              pedidos: 1
            })

            newArray.push({
              tipo_produto: item.tipo_produto,
              pedidos: 1,
              total: item.valor_produto,
              quantidade: item.qtde_produto,
              volume: item.volume_produto
            })
          }
        } else {
          if (pedidos === 1) {
            prazo_produto[findIndexProduto].prazo_medio_total += row.prazo_medio
            prazo_produto[findIndexProduto].pedidos += pedidos
          }

          newArray[findIndex].pedidos += pedidos
          newArray[findIndex].total += item.valor_produto
          newArray[findIndex].quantidade += Number(item.qtde_produto)
          newArray[findIndex].volume += item.volume_produto
        }

        numero = row.numero
        tipo_produto = item.tipo_produto
      })
    })

    const newArrayWithPrazo = newArray.map(arr => {
      const findedPrazo = prazo_produto.find(
        p => p.tipo_produto === arr.tipo_produto
      )
      return {
        ...arr,
        prazo_medio: Math.round(
          findedPrazo.prazo_medio_total / findedPrazo.pedidos
        )
      }
    })
    return newArrayWithPrazo
  }

  const jsonLevel2 = () => {
    const newArray = []
    const { tipo_produto } = selectedRows.level1.row
    let numero = ''

    const filtered = reportData
      .filter(row => row.tipo === 'NF')
      .filter(item => item.itens.find(i => i.tipo_produto === tipo_produto))

    filtered.forEach(row => {
      row.itens.forEach(orderItem => {
        const findIndex = newArray.findIndex(
          arrItem => arrItem.fornecedor === orderItem.nome_fornecedor
        )

        if (numero !== row.numero) {
          if (findIndex !== -1) {
            newArray[findIndex].pedidos += 1
            newArray[findIndex].total += row.valor
            newArray[findIndex].quantidade += row.itens.reduce(
              (acc, item) =>
                acc + Number(item.qtde_produto ? item.qtde_produto : 0),
              0
            )
            newArray[findIndex].volume_total += row.volume
            newArray[findIndex].prazo_medio_total += row.prazo_medio
          } else {
            newArray.push({
              fornecedor: orderItem.nome_fornecedor,
              codigo: orderItem.codigo_fornecedor,
              pedidos: 1,
              total: row.valor,
              quantidade: row.itens.reduce(
                (acc, item) =>
                  acc + Number(item.qtde_produto ? item.qtde_produto : 0),
                0
              ),
              volume_total: row.volume,
              prazo_medio_total: row.prazo_medio
            })
          }
          numero = row.numero
        }
      })
    })

    const newArrayWithPrazo = newArray.map(item => {
      const prazo_medio = Math.round(item.prazo_medio_total / item.pedidos)
      return {
        ...item,
        prazo_medio: prazo_medio
      }
    })

    return newArrayWithPrazo
  }

  const jsonLevel3 = () => {
    const { tipo_produto } = selectedRows.level1.row
    const { codigo_fornecedor } = selectedRows.level2.row
    const filtered = reportData
      .filter(row => row.tipo === 'NF')
      .filter(item =>
        item.itens.find(
          i =>
            i.tipo_produto === tipo_produto &&
            i.codigo_fornecedor === codigo_fornecedor
        )
      )

    const newArray = filtered.map(row => {
      const quantidade = row.itens
        .filter(item => item.tipo_produto === tipo_produto)
        .reduce((acc, item) => acc + item.qtde_produto, 0)
      return {
        nome_cliente: row.nome_cliente,
        emissao: row.emissao,
        data_inclusao_erp: '',
        nome_vendedor: row.nome_vendedor,
        numero: row.numero,
        status: row.status,
        total: row.valor,
        quantidade: quantidade,
        volume: row.volume
      }
    })

    return newArray
  }

  useEffect(() => {
    switch (filterData.report_level) {
      case 1:
        setCurrentColumns(columnsLevel1)
        setCurrentData(dataLevel1)
        break
      case 2:
        setCurrentColumns(columnsLevel2)
        setCurrentData(dataLevel2)
        break
      case 3:
        setCurrentColumns(columnsLevel3)
        setCurrentData(dataLevel3)
        break
      default:
        return false
    }
    // eslint-disable-next-line
  }, [filterData.report_level, dataLevel1])

  return (
    <div className='mt-2'>
      <DataTable
        columns={currentColumns}
        rows={currentData}
        title='Faturamento por Classificação'
        report='revenuesByClassification'
      />
    </div>
  )
}
