import c3 from 'c3'

export default arrayMedias => {
  c3.generate({
    bindto: '#pastPeriods',
    data: {
      x: 'Item',
      columns: arrayMedias,
      type: 'bar'
    },
    axis: {
      x: {
        type: 'category' // this needed to load string x value
      }
    }
  })
}
