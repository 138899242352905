import React, { useEffect } from 'react'
import { useAtom } from 'jotai'

import { approveExtraHideAll, loadedModuleAtom } from '../_global/Atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ls from 'local-storage'
const user = ls.get('user')

let x = 0
const HeaderItems = props => {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [hideAll, setHideAll] = useAtom(approveExtraHideAll)

  useEffect(() => {
    setHideAll(true)
  }, [setHideAll])

  const handleCheckbox = e => {
    const { checked } = e.target
    var checkboxes = document.querySelectorAll('.checkbox')
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = checked
    }
  }

  const handleAllOpened = () => {
    setHideAll(!hideAll)
  }

  return (
    <tr>
      {loadedModule === 'ApproveExtra' &&
      (user === 'regional.sul' ||
        user === 'regional.norte' ||
        user === 'pricing') ? (
        <th className='px-2 py-1 border bg-blue-200 border-white text-xs'>
          <input type='checkbox' onClick={handleCheckbox} />
        </th>
      ) : (
        <th className='px-2 py-1 border bg-blue-200 border-white text-xs'></th>
      )}
      {props.data.map((entry, index) => {
        return (
          <th
            className='px-2 py-1 border bg-blue-200 border-white text-xs'
            key={++x}
          >
            {index === 0 && loadedModule === 'ApproveExtra' ? (
              <FontAwesomeIcon
                icon={hideAll ? faPlusSquare : faMinusSquare}
                onClick={handleAllOpened}
              />
            ) : (
              entry
            )}
          </th>
        )
      })}
    </tr>
  )
}

export default HeaderItems
