import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import ReactSelect from '../../Inputs/ReactSelect'
import Header from '../../_global/Header'

import CoachingItem from './CoachingItem'
import {
  alertParamsAtom,
  coachingDialogTypeAtom,
  filterDataAtom,
  openCoachingDialogAtom,
  selectedSellerAtom
} from '../../_global/Atoms'
import ls from 'local-storage'
import { useAtom } from 'jotai'
import { Search } from '@material-ui/icons'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { distributors } from '../../../constData/urls'
import Axios from 'axios'
import { newCoachingTypeAtom } from './Atoms'
import CoachingDialog from './CoachingDialog'
import SelectCheckbox from '../../Inputs/SelectCheckbox'

const sellers = ls.get('sellers')

export default function Index() {
  const [selectedSeller, setSelectedSeller] = useAtom(selectedSellerAtom)
  const [processed, setProcessed] = useState(false)
  const [openCoachingDialog, setOpenCoachingDialog] = useAtom(
    openCoachingDialogAtom
  )
  //eslint-disable-next-line
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [newDisabled, setNewDisabled] = useState(false)
  //eslint-disable-next-line
  const [coachingDialogType, setCoachingDialogType] = useAtom(
    coachingDialogTypeAtom
  )
  //eslint-disable-next-line
  const [newCoachingType, setNewCoachingType] = useAtom(newCoachingTypeAtom)
  const [sellersList, setSellersList] = useState([])
  const [filterData] = useAtom(filterDataAtom)
  const [coachingList, setCoachingList] = useState([])
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    const array = []
    let key = 1
    array.push({ label: 'Todos', value: '', key: key })
    sellers.forEach(seller => {
      key++
      array.push({
        label: seller.nome_vededor,
        value: seller.codigo_vededor
      })
    })
    setSellersList(array)
  }, [])

  const openNewCoaching = type => {
    if (!selectedSeller) {
      setAlertParams({
        open: true,
        text: 'Selecione um vendedor.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    if (selectedSeller.value === '' || selectedSeller.value === 'Todos') {
      setAlertParams({
        open: true,
        text: 'Selecione um vendedor.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    const openedCoachings = coachingList.filter(
      coaching =>
        coaching.data_fechamento === '' && coaching.data_cancelamento === ''
    )

    if (openedCoachings.length > 0) {
      setAlertParams({
        open: true,
        text: 'Vendedor possui coaching em aberto. Finalize o coaching aberto para abrir um novo.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    const openedToday = coachingList.filter(
      coaching =>
        coaching.data_abertura === moment(new Date()).format('DD/MM/YY')
    )

    if (openedToday.length > 0) {
      setAlertParams({
        open: true,
        text: 'Um coaching já foi aberto para este vendedor na data de hoje.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    setCoachingDialogType('new')
    setNewCoachingType(type)
    setOpenCoachingDialog(true)
  }

  useEffect(() => {
    const openedCoaching = coachingList.find(cl => cl.fechamento === '')
    if (openedCoaching) {
      setNewDisabled(true)
    } else {
      setNewDisabled(false)
    }
    // eslint-disable-next-line
  }, [coachingList])

  const loadingCoachingList = async () => {
    const codigo_supervisor = ls.get('codigo_vededor')

    if (!selectedSeller || filterData.dates.length === 0) {
      setAlertParams({
        open: true,
        text: 'Preencha o vendedor e os meses desejados para continuar',
        onClick: () => setAlertParams({ open: false })
      })
      return false
    }

    const promises = filterData.dates?.map(async date => {
      const mx = date.value.split('-')
      const inicio = new Date(mx[0], mx[1] - 1, 1)
      const termino = new Date(mx[0], mx[1], 0)

      const json = {
        distribuidor: filterData.distribuidor[0].value,
        supervisor: codigo_supervisor,
        vendedor: selectedSeller.value,
        data_inicial: moment(inicio).format('DD/MM/YYYY'),
        data_fim: moment(termino).format('DD/MM/YYYY'),
        com_respostas: 'N'
      }

      const distributorData = distributors.find(
        distributor =>
          distributor.distribuidor === filterData.distribuidor[0].value
      )

      const result = await Axios.post(
        `${distributorData.url}INT_SHELL_BUSCA_COACHING`,
        json,
        {
          headers: {
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept-Language': 'pt-BR,en;q=0.9'
          }
        }
      )

      return result
    })

    let promiseData

    await trackPromise(
      Promise.all(promises.map(p => p.catch(e => e)))
        .then(results => {
          const errors = []
          const data = []
          results.forEach(r => {
            if (r instanceof Error) {
              errors.push(r)
            } else {
              data.push(r)
            }
          })

          promiseData = { results: data, errors: errors }
        })
        .catch(e => console.log(e))
    )

    let getData = []

    if (promiseData.results[0]?.status !== 204) {
      getData = promiseData.results
        .filter(
          row =>
            row.data.length !== 0 &&
            row.data.message !==
              'The request has been fulfilled and resulted in a new resource being created.' &&
            row.data !== ''
        )
        .reduce((arr, row) => arr.concat(row.data), [])
    }

    let ordered

    if (getData.length > 0) {
      ordered = getData.sort((a, b) => {
        const mx = a.data_abertura.split('/')
        const my = b.data_abertura.split('/')

        const dataA = new Date('20' + mx[2], mx[1], mx[0])
        const dataB = new Date('20' + my[2], my[1], my[0])

        return Date.parse(dataB) - Date.parse(dataA)
      })
    } else {
      ordered = getData
    }

    setCoachingList(ordered)
    setProcessed(true)
  }

  return (
    <Fragment>
      <Header
        label='Coaching'
        title='Shell BackOffice - Supervisor'
        exit={false}
      />

      <div className='flex items-center'>
        <div className='w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 px-3'>
          <ReactSelect
            onChange={v => {
              setSelectedSeller(v)
              setProcessed(false)
              setCoachingList([])
            }}
            value={selectedSeller}
            id='vendedor'
            options={sellersList}
            placeholder='Selecione...'
            label='Vendedor'
            maxMenuHeight={200}
            menuPlacement='auto'
          />
        </div>
        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <SelectCheckbox
            id='dates'
            value={filterData.dates}
            label='Selecione o(s) mês(es)'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Search />}
            onClick={loadingCoachingList}
          >
            Buscar
          </Button>
        </div>
      </div>
      <div className='mt-2 border rounded border-gray-500 p-2 overflow-auto h-96'>
        <Fragment>
          {processed ? (
            <div className='text-3xl border-b-2 border-gray-400 mb-2 pb-2 font-light '>
              Coaching - {selectedSeller.label}
            </div>
          ) : (
            <Alert severity='info'>
              Selecione os dados desejados acima e clique em buscar
            </Alert>
          )}

          {promiseInProgress ? (
            <LoadingIndicator />
          ) : coachingList.length > 0 ? (
            <div>
              {coachingList.map(coaching => (
                <CoachingItem data={coaching} key={coaching.id_coaching} />
              ))}
            </div>
          ) : (
            processed && (
              <Alert severity='info'>
                Nenhum dado encontrado com os filtros selecionados.
              </Alert>
            )
          )}
        </Fragment>
      </div>
      {processed && selectedSeller.value !== '' && (
        <div className='flex my-3 gap-2'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => openNewCoaching('REVENDA')}
            disabled={newDisabled}
          >
            + Revenda
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => openNewCoaching('VAREJO')}
            disabled={newDisabled}
          >
            + Varejo
          </Button>
        </div>
      )}
      <CoachingDialog
        open={openCoachingDialog}
        handleClose={() => {
          setOpenCoachingDialog(false)
        }}
      />
    </Fragment>
  )
}
