import { IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { currentCampaignAtom } from '../_global/Atoms'
import CampaignDataSection from './CampaignDataSection'
import GoalDataSection from './GoalDataSection'
import SKUGoalDataSection from './SKUGoalDataSection'

export default function CampaignHeaderSection({
  activeFilter,
  setActiveFilter
}) {
  const [currentCampaign] = useAtom(currentCampaignAtom)
  const [hide, setHide] = useState(false)
  return (
    <div>
      <div className='flex justify-center w-full mb-4 tracking-wider text-gray-700 uppercase items-center'>
        Dados da Campanha{' '}
        <IconButton onClick={() => setHide(!hide)}>
          {hide ? (
            <VisibilityOff size='small' />
          ) : (
            <Visibility size='small' color='primary' />
          )}
        </IconButton>
      </div>

      {!hide && (
        <div className='w-full flex flex-row text-sm'>
          <CampaignDataSection
            currentCampaign={currentCampaign}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
          <GoalDataSection
            currentCampaign={currentCampaign}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
          <SKUGoalDataSection currentCampaign={currentCampaign} />
        </div>
      )}
    </div>
  )
}
