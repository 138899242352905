import React from 'react'
import CoachingSpancop from './CoachingSpancop'

export default function Coaching() {
  return (
    <div>
      <p>Coaching</p>
      <CoachingSpancop></CoachingSpancop>
    </div>)
}
