import Select from 'react-select'
import { useAtom } from 'jotai'
import moment from 'moment'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { filterDataAtom } from '../_global/Atoms'
import { components } from 'react-select'

const loadMonths = () => {
  const now = new Date()
  const months = []

  for (let i = 0; i <= 11; i++) {
    const date = new Date(now.getFullYear(), now.getMonth() - i, 1)
    months.push({
      label: moment(date).format('YYYY-MM'),
      value: moment(date).format('YYYY-MM')
    })
  }

  return months
}

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

export default function SelectCheckbox(props) {
  const { id, value, label } = props
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [months, setMonths] = useState([])

  useEffect(() => {
    const loadedMonths = loadMonths()
    setMonths(loadedMonths)
    setFilterData({
      ...filterData,
      dates: [loadedMonths[0]]
    })
    //eslint-disable-next-line
  }, [])

  const handleChange = e => {
    setFilterData({ ...filterData, dates: e })
  }

  return (
    <Fragment>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={id}
      >
        {label}
      </label>

      <Select
        className='appearance-none block w-full text-gray-700 rounded mb-3 leading-tight focus:outline-none focus:bg-white text-xs'
        id={id}
        multiple
        value={value}
        isMulti
        onChange={handleChange}
        options={months}
        components={{ Option, MultiValue }}
      />
    </Fragment>
  )
}
