import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom } from '../_global/Atoms'

import ReactSelect from '../Inputs/ReactSelect'
import { listTipoProduto } from '../../constData/constData'

import { updateSelect, getDistributors } from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex mb-4'>
        <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            maxMenuHeight={200}
            isMulti
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_produto', filterData, setFilterData)
            }}
            value={
              filterData.tipo_produto
                ? filterData.tipo_produto
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Tipo de Produto *'
            id='tipo_produto'
            options={listTipoProduto()}
            placeholder='Selecione...'
          />
        </div>
      </div>
    </form>
  )
}
