import { useAtom } from 'jotai'
import React, { Fragment, useEffect, useState } from 'react'
import { listCampaignMonths, unmaskCnpj } from '../../../functions/functions'
import { currentCampaignAtom, customersAtom } from '../../_global/Atoms'
import {
  financeData3MonthsAtom,
  financeDataAtom,
  financeDataLastYearAtom
} from '../CampaignAtoms'
import { Button } from '@material-ui/core'

import 'c3/c3.css'
import renderVolumeAtingido from './graphs/renderVolumeAtingido'
import renderAtingiramAlvo from './graphs/renderAtingiramAlvo'
import renderGraphByMonth from './graphs/renderGraphByMonth'
import renderGoalVersusReach from './graphs/renderGoalVersusReach'
import renderVolumeByMonth from './graphs/renderVolumeByMonth'
import renderPastPeriods from './graphs/renderPastPeriods'
import DialogList from './DialogList'
// import { CampaignResults } from '../CampaignResults'

const updatedCampaign = currentCampaign => {
  const { data_inicial, data_final } = currentCampaign
  return {
    ...currentCampaign,
    data_inicial: data_inicial,
    data_final: data_final
  }
}

const getMedia = (alvoCustomers, financeData, meses) => {
  let volumeTotal = 0

  alvoCustomers.forEach(alvo => {
    const currentCNPJ = unmaskCnpj(alvo.cnpj)
    const alvo_realizado = financeData
      .filter(data => data.cnpj === currentCNPJ)
      .reduce((acc, item) => acc + Number(item.vol_total), 0)
    volumeTotal += alvo_realizado
  })

  return Math.round(volumeTotal / meses)
}

export default function ChartSection() {
  const [currentCampaign] = useAtom(currentCampaignAtom)
  const [financeData] = useAtom(financeDataAtom)
  const [financeData3Months] = useAtom(financeData3MonthsAtom)
  const [financeDataLastYear] = useAtom(financeDataLastYearAtom)

  //eslint-disable-next-line
  const [volumeTotal, setVolumeTotal] = useState(0)
  //eslint-disable-next-line
  const [volumeRealizado, setVolumeRealizado] = useState(0)
  const [percentualRealizado, setPercentualRealizado] = useState(0)
  const [customers] = useAtom(customersAtom)

  const [resultsByMonth, setResultsByMonth] = useState([])
  const [arrayByMonth, setArrayByMonth] = useState([])
  const [arrayTotals, setArrayTotals] = useState([])
  const [arrayMedias, setArrayMedias] = useState([])
  const [volumeByMonth, setVolumeByMonth] = useState([])

  const [clientes, setClientes] = useState({ realizado: 0, naoRealizado: 0 })
  const [dialogListData, setDialogListData] = useState({
    open: false,
    type: '',
    month: ''
  })

  // const [openCampaignResults, setOpenCampaignResults] = useState(false)

  const createGroupArray = alvoCustomers => {
    const groups = []
    let currentGroup = ''
    const completeData = alvoCustomers
      .map(alvo => {
        const currentCNPJ = unmaskCnpj(alvo.cnpj)
        const customerData = customers.find(f => f.cnpj === currentCNPJ)

        return {
          ...alvo,
          grupo_descricao: alvo.grupo
            ? alvo.grupo
            : customerData.grupo_descricao
        }
      })
      .sort((a, b) => {
        if (a.grupo_descricao > b.grupo_descricao) {
          return 1
        }
        if (a.grupo_descricao < b.grupo_descricao) {
          return -1
        }
        return 0
      })
    completeData.forEach(c => {
      if (currentGroup !== c.grupo_descricao) {
        groups.push(c.grupo_descricao)
        currentGroup = c.grupo_descricao
      }
    })

    return groups
  }

  const getGroupChartData = () => {
    let totalGeral = 0
    let realizadoGeral = 0
    let countRealizado = 0
    let countNaoRealizado = 0

    const alvoCustomers = currentCampaign?.alvos[0]
    const groups = createGroupArray(alvoCustomers)

    const alvoMonths = listCampaignMonths(updatedCampaign(currentCampaign))

    const monthsResults = alvoMonths.map((item, index) => {
      return {
        month: item.month,
        atingiramList: [],
        naoAtingiramList: [],
        volume_mes: 0
      }
    })

    groups.forEach(group => {
      const cnpjsGroup = alvoCustomers.filter(alvo => alvo.grupo === group)

      const alvo_total = currentCampaign.alvos
        .map(cc => cc.find(item => item.grupo === group))
        .reduce((acc, item) => acc + Number(item.alvo), 0)

      const alvo_realizado = cnpjsGroup
        .map(fi => {
          const faturado = financeData
            .filter(f => f.cnpj === fi.cnpj)
            .reduce((acc, item) => acc + Number(item.vol_total), 0)
          return { ...fi, faturado: faturado }
        })
        .reduce((acc, item) => acc + Number(item.faturado), 0)

      if (alvo_realizado >= alvo_total) {
        countRealizado++
      } else {
        countNaoRealizado++
      }

      totalGeral += alvo_total
      realizadoGeral += alvo_realizado

      const { data_inicial } = currentCampaign

      currentCampaign.alvos.forEach((alvo, i) => {
        let date
        if (typeof data_inicial === 'object') {
          date = data_inicial
        } else {
          const mx = data_inicial.split('/')
          date = new Date(mx[2], mx[1], mx[0])
        }

        const inicio = new Date(date.getFullYear(), date.getMonth() + i, 1)
        const termino = new Date(
          date.getFullYear(),
          date.getMonth() + (i + 1),
          0
        )

        const alvoMes = alvo
          .filter(item => item.grupo === group)
          .reduce((acc, item) => acc + Number(item.alvo), 0)

        const reduceFaturado = cnpjsGroup
          .map(fi => {
            const faturado = financeData
              .filter(f => f.cnpj === fi.cnpj)
              .filter(f => {
                const mx = f.emissao.split('/')
                const emissaoDateObject = new Date(
                  `20${mx[2]}`,
                  mx[1] - 1,
                  mx[0]
                )
                return (
                  Date.parse(emissaoDateObject) >= Date.parse(inicio) &&
                  Date.parse(emissaoDateObject) <= Date.parse(termino)
                )
              })
              .reduce((acc, item) => acc + Number(item.vol_total), 0)
            return { ...fi, faturado: faturado }
          })
          .reduce((acc, item) => acc + Number(item.faturado), 0)

        const jsonGroup = {
          grupo: group,
          alvo: alvoMes,
          faturado: Math.round(reduceFaturado)
        }

        if (reduceFaturado >= alvoMes) {
          monthsResults[i].atingiramList.push(jsonGroup)
        } else {
          monthsResults[i].naoAtingiramList.push(jsonGroup)
        }

        monthsResults[i].volume_mes += Math.round(reduceFaturado)
      })
    })

    const threeMonthsMedia = getMedia(alvoCustomers, financeData3Months, 3)
    const lastYearMedia = getMedia(
      alvoCustomers,
      financeDataLastYear,
      currentCampaign.alvos.length
    )
    const currentMedia = getMedia(
      alvoCustomers,
      financeData,
      currentCampaign.alvos.length
    )

    setArrayMedias([
      ['Item', 'Vol. Médio'],
      ['Campanha Atual', currentMedia],
      ['3 ultimos meses', threeMonthsMedia],
      ['Mesmo período ano anterior', lastYearMedia]
    ])

    setResultsByMonth(monthsResults)
    setArrayTotals([
      ['Item', 'Vol. Total'],
      ['Alvo', Math.round(totalGeral)],
      ['Realizado', Math.round(realizadoGeral)]
    ])
    setPercentualRealizado(Math.round((realizadoGeral / totalGeral) * 100))
    setClientes({
      realizado: countRealizado,
      naoRealizado: countNaoRealizado
    })
  }

  const getChartData = () => {
    const alvoCustomers = currentCampaign?.alvos[0]

    let totalGeral = 0
    let realizadoGeral = 0
    let countRealizado = 0
    let countNaoRealizado = 0

    const alvoMonths = listCampaignMonths(updatedCampaign(currentCampaign))

    const monthsResults = alvoMonths.map((item, index) => {
      return {
        month: item.month,
        atingiramList: [],
        naoAtingiramList: [],
        volume_mes: 0
      }
    })

    alvoCustomers.forEach(alvo => {
      const currentCNPJ = unmaskCnpj(alvo.cnpj)
      const alvo_total = currentCampaign.alvos
        .map(cc => cc.find(item => item.cnpj === currentCNPJ))
        .reduce((acc, item) => acc + parseInt(item.alvo), 0)

      const alvo_realizado = financeData
        .filter(data => data.cnpj === currentCNPJ)
        .reduce((acc, item) => acc + parseInt(item.vol_total), 0)

      totalGeral += alvo_total
      realizadoGeral += alvo_realizado

      if (alvo_realizado >= alvo_total) {
        countRealizado++
      } else {
        countNaoRealizado++
      }

      const { data_inicial } = currentCampaign

      const campaignAlvos = currentCampaign.alvos.map(cc =>
        cc.find(item => item.cnpj === currentCNPJ)
      )

      campaignAlvos.forEach((alvo, i) => {
        let date
        if (typeof data_inicial === 'object') {
          date = data_inicial
        } else {
          const mx = data_inicial.split('/')
          date = new Date(mx[2], mx[1], mx[0])
        }

        const inicio = new Date(date.getFullYear(), date.getMonth() + i, 1)
        const termino = new Date(
          date.getFullYear(),
          date.getMonth() + (i + 1),
          0
        )

        const dataByCNPJ = financeData.filter(f => f.cnpj === currentCNPJ)
        const reduceFaturado = dataByCNPJ
          .filter(f => {
            const mx = f.emissao.split('/')
            const emissaoDateObject = new Date(`20${mx[2]}`, mx[1] - 1, mx[0])
            return (
              Date.parse(emissaoDateObject) >= Date.parse(inicio) &&
              Date.parse(emissaoDateObject) <= Date.parse(termino)
            )
          })
          .reduce((acc, current) => acc + parseInt(current.vol_total), 0)

        const intAlvo = parseInt(alvo.alvo)
        const customerData = customers.find(c => c.cnpj === currentCNPJ)

        const jsonCustomer = {
          ...dataByCNPJ[0],
          ...customerData,
          cnpj: currentCNPJ,
          alvo: intAlvo,
          faturado: Math.round(reduceFaturado)
        }

        if (reduceFaturado >= intAlvo) {
          monthsResults[i].atingiramList.push(jsonCustomer)
        } else {
          monthsResults[i].naoAtingiramList.push(jsonCustomer)
        }

        monthsResults[i].volume_mes += Math.round(reduceFaturado)
      })

      const threeMonthsMedia = getMedia(alvoCustomers, financeData3Months, 3)
      const lastYearMedia = getMedia(
        alvoCustomers,
        financeDataLastYear,
        currentCampaign.alvos.length
      )
      const currentMedia = getMedia(
        alvoCustomers,
        financeData,
        currentCampaign.alvos.length
      )

      setArrayMedias([
        ['Item', 'Vol. Médio'],
        ['Campanha Atual', currentMedia],
        ['3 ultimos meses', threeMonthsMedia],
        ['Mesmo período ano anterior', lastYearMedia]
      ])

      setArrayTotals([
        ['Item', 'Vol. Total'],
        ['Alvo', Math.round(totalGeral)],
        ['Realizado', Math.round(realizadoGeral)]
      ])

      setResultsByMonth(monthsResults)
      setVolumeTotal(totalGeral)
      setVolumeRealizado(realizadoGeral)
      setPercentualRealizado(Math.round((realizadoGeral / totalGeral) * 100))
      setClientes({
        realizado: countRealizado,
        naoRealizado: countNaoRealizado
      })
    })
  }

  const getArrayByMonth = () => {
    const array = [['x'], ['Atingiram'], ['Não Atingiram']]

    resultsByMonth.forEach(result => {
      array[0].push(result.month)
      array[1].push(result.atingiramList.length)
      array[2].push(result.naoAtingiramList.length)
    })

    setArrayByMonth(array)
  }

  const getVolumeByMonth = () => {
    const array = [['x'], ['Volume']]

    resultsByMonth.forEach(result => {
      array[0].push(result.month)
      array[1].push(result.volume_mes)
    })

    setVolumeByMonth(array)
  }

  useEffect(() => {
    if (currentCampaign?.alvos?.length > 0 && financeData.length > 0) {
      if (currentCampaign.chave?.value === 'cnpj') {
        getChartData()
      } else {
        getGroupChartData()
      }
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getArrayByMonth()
    getVolumeByMonth()
    //eslint-disable-next-line
  }, [resultsByMonth])

  useEffect(() => {
    renderVolumeAtingido(percentualRealizado)
  }, [percentualRealizado])

  useEffect(() => {
    renderAtingiramAlvo(clientes)
  }, [clientes])

  useEffect(() => {
    renderGraphByMonth(arrayByMonth)
  }, [arrayByMonth])

  useEffect(() => {
    renderGoalVersusReach(arrayTotals)
  }, [arrayTotals])

  useEffect(() => {
    renderVolumeByMonth(volumeByMonth)
  }, [volumeByMonth])

  useEffect(() => {
    renderPastPeriods(arrayMedias)
  }, [arrayMedias])

  const handleDialogList = (month, type) => {
    setDialogListData({
      ...dialogListData,
      open: true,
      type: type,
      month: month,
      setOpen: setDialogListData
    })
  }

  return (
    <Fragment>
      <div className='flex justify-center w-full mb-4 tracking-wider text-gray-700 uppercase'>
        Informações
      </div>
      {/* <div className='flex justify-center w-full mb-4 tracking-wider text-gray-700 uppercase'>
        <Button onClick={() => setOpenCampaignResults(currentCampaign.id)}>
          Ver todos os dados da campanha
        </Button>
      </div> */}
      <div className='flex my-4'>
        <div className='flex w-full bg-gray-200 rounded border border-gray-400 flex-row'>
          <div className='flex w-1/2 items-center flex-col'>
            <div id='atingiramAlvo'></div>
            <div className=' w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
              PERÍODO - TOTAL DE{' '}
              {currentCampaign.chave.value === 'cnpj' ? 'CLIENTES' : 'GRUPOS'}{' '}
              QUE ATINGIRAM O ALVO
            </div>
          </div>

          <div className='flex w-1/2 flex-col items-center'>
            <div id='volumeAtingido'></div>
            <div className='w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
              PERÍODO - % VOLUME ATINGIDO
            </div>
          </div>
        </div>
      </div>
      <div className='flex my-4'>
        <div className='flex w-full bg-gray-200 rounded border border-gray-400 flex-row justify-center text-center'>
          <div className='w-1/2'>
            <div id='graphByMonth'></div>
            <div>
              <div className='w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
                QUANTIDADE{' '}
                {currentCampaign.chave.value === 'cnpj' ? 'CLIENTES' : 'GRUPOS'}{' '}
                - ATINGIMENTO POR MÊS
              </div>
              <div>
                {resultsByMonth.map(result => {
                  return (
                    <div
                      className='flex gap-2 mb-2 justify-center'
                      key={Math.random()}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          handleDialogList(result.month, 'atingiram')
                        }
                      >
                        Atingiram em {result.month}
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() =>
                          handleDialogList(result.month, 'nao_atingiram')
                        }
                      >
                        Nao Atingiram em {result.month}
                      </Button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='w-1/2'>
            <div id='goalVersusReach'></div>

            <div className='w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
              VOLUME - ALVO X REALIZADO PERÍODO
            </div>
          </div>
        </div>
      </div>
      <div className='flex my-4'>
        <div className='flex w-full bg-gray-200 rounded border border-gray-400 flex-row justify-center text-center'>
          <div className='w-1/2'>
            <div id='volumeByMonth'></div>
            <div className='w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
              VOLUME POR MÊS
            </div>
          </div>
          <div className='w-1/2'>
            <div id='pastPeriods'></div>
            <div className='w-full mt-4 mb-4 tracking-wider text-gray-700 uppercase text-center'>
              VOLUMES MÉDIOS - PERÍODOS ANTERIORES
            </div>
          </div>
        </div>
      </div>
      <DialogList data={dialogListData} resultsByMonth={resultsByMonth} />
      {/* <CampaignResults
        open={openCampaignResults ? true : false}
        id={openCampaignResults}
        setOpen={setOpenCampaignResults}
      /> */}
    </Fragment>
  )
}
