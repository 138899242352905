import React from 'react'
import { useAtom } from 'jotai'

import { questionsAtoms, researchAtoms } from './Atoms'
import { renderButtons } from './functions'

const buttonValues = ['Sim', 'Não']

export default function YesNoQuestion(props) {
  const { id, question, question_extended, number } = props.question
  const [research, setResearch] = useAtom(researchAtoms)
  const [questions, setQuestions] = useAtom(questionsAtoms)

  const newRowsQuestions = [
    'question_2_1',
    'question_2_2',
    'question_3_1',
    'question_3_2',
    'question_3_2_1',
    'question_3_3',
    'question_4_1',
    'question_5_1',
    'question_5_2'
  ]

  const openNewRowsQuestions = [
    { row: 'question_2_1', newQuestionRow: 'question_2_1_1' },
    { row: 'question_2_2', newQuestionRow: 'question_2_2_1' },
    { row: 'question_3_1', newQuestionRow: 'question_3_1_1' },
    { row: 'question_3_2', newQuestionRow: 'question_3_2_1' },
    { row: 'question_3_2_1', newQuestionRow: 'question_3_2_2' },
    { row: 'question_3_3', newQuestionRow: 'question_3_3_1' },
    { row: 'question_4_1', newQuestionRow: 'question_4_1_1' },
    { row: 'question_5_1', newQuestionRow: 'question_5_1_1' },
    { row: 'question_5_2', newQuestionRow: 'question_5_2_1' }
  ]

  const setNewValue = e => {
    let newResearch = {}
    const { id, value } = e.target
    // retorna o array de questoes
    const arrayQuestions = Object.assign([], questions)

    // encontra o campo que deve ser aberto ao clicar em não
    const findNewQuestion = openNewRowsQuestions.find(o => o.row === id)

    // Se não achar questão a ser aberta, apernas define o valor do campo
    if (!findNewQuestion) {
      newResearch = Object.assign({}, research)
      setNewStates(arrayQuestions, newResearch, id, value)
      return false
    }

    const newQuestionRow = findNewQuestion.newQuestionRow

    // Encontra o indice do campo que devera ser aberto caso clique em não
    const questionIndex = arrayQuestions.findIndex(
      question => question.id === newQuestionRow
    )

    // Verifica se o campo que foi clicado está no array de campos elegiveis para abrir outros
    const findedNewRowQuestion = newRowsQuestions.find(n => n === id)

    if (id === findedNewRowQuestion) {
      if (value === 'Não') {
        arrayQuestions[questionIndex].visible = true
        newResearch = Object.assign({}, research)
      } else {
        arrayQuestions[questionIndex].visible = false
        newResearch = { ...research, [newQuestionRow]: '' }
      }
    } else {
      newResearch = Object.assign({}, research)
    }

    setNewStates(arrayQuestions, newResearch, id, value)
  }

  const setNewStates = (arrayQuestions, newResearch, id, value) => {
    setQuestions(arrayQuestions)
    setResearch({ ...newResearch, [id]: value })
  }

  const buttons = renderButtons(buttonValues, research, id, setNewValue)

  return (
    <div className='px-6 py-4'>
      <div className='text-md mb-2 text-left font-semibold'>
        <b>{number} </b>
        {question}
      </div>
      <div className='text-sm mb-2 text-left font-light text-gray-700'>
        {question_extended}
      </div>

      <div className='inline-flex' role='group' aria-label='Button group'>
        {buttons}
      </div>
    </div>
  )
}
