import { Button, Paper } from '@material-ui/core'
import {
  ExportPanel,
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../Inputs/ReactSelect'
import { setorList } from '../Opportunities/constData'
import {
  segmentListAtom,
  selectedSellerAtom,
  selectedTipoNegocioAtom,
  selectedYearAtom
} from '../Atoms'
import { useAtom } from 'jotai'
import ls from 'local-storage'
import Axios from 'axios'
import { alertParamsAtom } from '../../_global/Atoms'
import { Alert } from '@material-ui/lab'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { useRef } from 'react'
import { useCallback } from 'react'
import saveAs from 'file-saver'

const TypeCustomerTable = ({ reportData }) => {
  const [defaultColumnWidths] = useState([
    { columnName: 'tipo_cliente', width: 300 },
    { columnName: 'total_registros', width: 50 },
    { columnName: 'percentual', width: 50 }
  ])
  const exporterRef = useRef()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'RelatorioPorTipo.xlsx'
      )
    })
  }

  const columns = [
    { name: 'tipo_cliente', title: 'Tipo do Cliente' },
    { name: 'total_registros', title: 'No.' },
    { name: 'percentual', title: '%' }
  ]

  return (
    <Paper>
      <Grid rows={reportData?.tipo || []} columns={columns}>
        <Table />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
        <Toolbar />
        <ExportPanel startExport={startExport} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={columns}
        rows={reportData?.tipo || []}
        onSave={onSave}
      />
    </Paper>
  )
}

const SectorSegmentTable = ({ reportData }) => {
  const [defaultColumnWidths] = useState([
    { columnName: 'setor_segmento', width: 280 },
    { columnName: 'total_registros', width: 50 },
    { columnName: 'volume', width: 80 }
  ])
  const exporterRef = useRef()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'RelatorioPorSetor.xlsx'
      )
    })
  }

  const columns = [
    { name: 'setor_segmento', title: 'Setor/Segmento' },
    { name: 'total_registros', title: 'No.' },
    { name: 'volume', title: 'VOLUME' }
  ]

  return (
    <Paper>
      <Grid rows={reportData?.setor || []} columns={columns}>
        <Table />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
        <Toolbar />
        <ExportPanel startExport={startExport} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={columns}
        rows={reportData?.setor || []}
        onSave={onSave}
      />
    </Paper>
  )
}

const ProductFamily = ({ reportData }) => {
  const [defaultColumnWidths] = useState([
    { columnName: 'grupo_produto', width: 280 },
    { columnName: 'total_registros', width: 50 },
    { columnName: 'volume', width: 80 }
  ])

  const exporterRef = useRef()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'RelatorioPorSetor.xlsx'
      )
    })
  }

  const columns = [
    { name: 'grupo_produto', title: 'Família de Produto' },
    { name: 'total_registros', title: 'No.' },
    { name: 'volume', title: 'VOLUME' }
  ]

  return (
    <Paper>
      <Grid rows={reportData?.familia || []} columns={columns}>
        <Table />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
        <Toolbar />
        <ExportPanel startExport={startExport} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={columns}
        rows={reportData?.familia || []}
        onSave={onSave}
      />
    </Paper>
  )
}

const FilterForm = ({
  setor,
  setSetor,
  segmento,
  setSegmento,
  setReportData
}) => {
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [selectedTipoNegocio] = useAtom(selectedTipoNegocioAtom)
  const [selectedYear] = useAtom(selectedYearAtom)
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)

  const getSegmentList = async setor => {
    if (setor.value !== '*') {
      const perfis = ls.get('perfis')

      const { data } = await Axios.post(
        `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_SEGMENTOS`,
        {
          distribuidor: perfis[0].value,
          setor: setor?.value
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'pt-BR,en;q=0.9'
          }
        }
      )

      const results = data.map(d => {
        return {
          value: d.descricao,
          label: d.descricao
        }
      })

      setSegmentList(results)
    } else {
      setSegmentList([
        {
          value: '*',
          label: 'Todos'
        }
      ])
    }
  }

  const getData = async () => {
    if (!setor.value || !segmento.value) {
      setAlertParams({
        open: true,
        text: 'Preencha setor e segmento.',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    const perfis = ls.get('perfis')
    const codigo_vededor = ls.get('codigo_vededor')
    const codigo_supervisor = ls.get('codigo_supervisor')
    const tipo = ls.get('tipo')

    const json = {
      distribuidor: perfis[0].label,
      vendedor: selectedSeller.value,
      supervisor: tipo === 'SUPERVISOR' ? codigo_vededor : codigo_supervisor,
      ano: selectedYear.value,
      tipo_negocio: selectedTipoNegocio.value,
      setor: setor.value === '*' ? '' : setor.value,
      segmento: segmento.value === '*' ? '' : segmento.value
    }

    const { data } = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_KPI_RESULTADOS`,
      json,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    setReportData(data)
  }

  useEffect(() => {
    getSegmentList(setor)
    // eslint-disable-next-line
  }, [setor])

  const [segmentList, setSegmentList] = useAtom(segmentListAtom)

  return (
    <div className='flex flex-col'>
      <ReactSelect
        onChange={v => {
          setSetor(v)
        }}
        value={setor || ''}
        label='Setor'
        id='sector'
        options={setorList}
        allowSelectAll={false}
        placeholder='Selecione...'
        maxMenuHeight={200}
      />
      <ReactSelect
        onChange={v => {
          setSegmento(v)
        }}
        value={segmento || ''}
        label='Segmento'
        id='segment'
        options={segmentList}
        allowSelectAll={false}
        placeholder='Selecione...'
        maxMenuHeight={200}
      />
      <Button variant='contained' color='primary' onClick={getData}>
        Buscar
      </Button>
    </div>
  )
}

export default function Results() {
  const [setor, setSetor] = useState(false)
  const [segmento, setSegmento] = useState(false)
  const [reportData, setReportData] = useState([])
  return (
    <div className='flex gap-2'>
      <div className='flex flex-col w-1/2'>
        <div className='mb-2'>
          {reportData?.tipo ? (
            <TypeCustomerTable {...{ reportData }} />
          ) : (
            <Alert severity='info'>Sem dados</Alert>
          )}
        </div>
        <div>
          {reportData?.setor ? (
            <SectorSegmentTable {...{ reportData }} />
          ) : (
            <Alert severity='info'>Sem dados</Alert>
          )}
        </div>
      </div>
      <div className='flex flex-col  w-1/2'>
        <div className='mb-2'>
          <FilterForm
            {...{ setor, setSetor, segmento, setSegmento, setReportData }}
          />
        </div>
        <div>
          {reportData?.familia ? (
            <ProductFamily {...{ reportData }} />
          ) : (
            <Alert severity='info'>Sem dados</Alert>
          )}
        </div>
      </div>
    </div>
  )
}
