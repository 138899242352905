import React, { Fragment, useEffect, useState } from 'react'
import { returnBIUrl } from '../constData/constData'
import Button from '../components/Inputs/Button'
import ls from 'local-storage'

export default function Dashboard() {
  const [load, setLoad] = useState(false)
  const [urls, setUrls] = useState([])
  const [src, setSrc] = useState('')
  const user = ls.get('user')
  const perfil = ls.get('perfil')

  useEffect(() => {
    const bi = returnBIUrl(user, perfil)

    if (bi.urls && bi.urls.length > 0) {
      setUrls(bi.urls)
    } else {
      setLoad(true)
      setSrc(bi.single)
    }
  }, [user, perfil])

  const Iframe = () => {
    return (
      <iframe
        style={{
          position: 'absolute',
          top: 50,
          left: 0,
          width: '100%',
          height: '100%'
        }}
        src={src}
        frameBorder='0'
        title='test'
      />
    )
  }

  return (
    <Fragment>
      {urls.length !== 0 && (
        <div className='text-center mt-2 '>
          {urls.map(url => {
            return (
              <Button
                color={src === url.url ? 'red' : 'blue'}
                label={url.distribuidor}
                textColor='white'
                click={() => {
                  setSrc(url.url)
                  setLoad(true)
                }}
              />
            )
          })}
        </div>
      )}
      {load ? (
        <Iframe />
      ) : (
        <div className='bg-gray-400 text-black w-full text-center mt-2'>
          Clique no distribuidor para ver seu dashboard
        </div>
      )}
    </Fragment>
  )
}
