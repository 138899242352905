import React, { useState, useEffect } from 'react'

import { distributors } from '../constData/urls.js'
import InputForm from './Inputs/InputForm'
import Button from './Login/Button'
import Footer from './Login/Footer'
import Header from './Login/Header'
import ls from 'local-storage'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Alert from '@material-ui/lab/Alert'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// comentario
import api from '../api/api'

const Login = () => {
  const [authData, setAuthData] = useState({
    user: '',
    pwd: '',
    error: false,
    session: ''
  })
  const [loginError, setLoginError] = useState({ error: false, msg: '' })
  const [success, setSuccess] = useState(false)
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    document.title = 'Backoffice Shell - Login'
  }, [])

  const handleClick = async e => {
    console.log('linpando dados de login')
    setLoginError({ error: false, msg: '' })
    setAuthData({ ...authData, error: false, session: ls.get('session') })
    let data
    try {
      console.log('buscando dados...')
      const result = await trackPromise(api.login(authData))
      data = result.data
    } catch (err) {
      console.log('erro ao autenticar...')
      if (err && err.response.status === 401) {
        setLoginError({ error: true, msg: 'Usuário ou senha inválidos' })
        return false
      }
    }

    if (data.userOK) {
      console.log('usuario autenticado')
      setSuccess(true)
      await ls.set('user', data._doc.user)
      await ls.set('user_id', data.user_id)
      await ls.set('session', data.session_id)
      console.log('sessoes iniciais ok')

      const perfis = distributors.filter(d => {
        return d.access_profiles.find(p => p === data._doc.perfil)
      })
      console.log('distribuidores filtrados')
      await ls.set('perfil', data._doc.perfil)
      await ls.set('perfis', perfis)
      await ls.set('mkt_allowed', data._doc.mkt_allowed)
      await ls.set('mkt_profile', data._doc.mkt_profile)
      await ls.set('mkt_segments', JSON.stringify(data._doc.mkt_segments))
      await ls.set('mkt_dfbans', JSON.stringify(data._doc.mkt_dfbans))
      await ls.set('seller_id', data._doc.seller_id || '')
      console.log('sessoes finais ok')

      setTimeout(() => {
        setSuccess(false)
        switch (data._doc.perfil) {
          case 'supervisor':
            window.location = '/supervisor'
            break
          default:
            window.location = '/Home'
            break
        }
      }, 2000)
    } else {
      setLoginError({ error: true, msg: 'Usuário ou senha inválidos' })
      setAuthData({ user: '', pwd: '', error: true })
      return false
    }
  }

  const handleField = e => {
    setAuthData({ ...authData, [e.target.id]: e.target.value })
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <React.Fragment>
      <div className='flex flex-col items-center justify-center h-screen'>
        <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96'>
          <Header title='Entrar no Sistema' />
          <hr />
          <div className='mb-4 mt-3'>
            <InputForm
              id='user'
              label='Usuário'
              placeholder='Digite seu usuário'
              value={authData.user}
              onChange={handleField}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className='mb-6'>
            <InputForm
              id='pwd'
              label='Senha'
              type='password'
              placeholder='******************'
              value={authData.pwd}
              onChange={handleField}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className='flex items-center justify-center'>
            <Button handleClick={handleClick} />
          </div>
          <div className='mt-4 p-4 text-xs'>
            {promiseInProgress && (
              <Alert severity='info'>
                <FontAwesomeIcon icon={faCircleNotch} spin /> Aguarde...
              </Alert>
            )}
            {success && (
              <Alert severity='success'>
                Login realizado com sucesso! Entrando no sistema...
              </Alert>
            )}
            {loginError && loginError.error && (
              <Alert severity='error'>{loginError.msg}</Alert>
            )}
          </div>
        </form>
        <a
          href='/supervisor'
          className='text-md text-blue-400 hover:text-blue-600'
        >
          Ir para Login Módulo Supervisor / SPANCOP
        </a>
      </div>

      <Footer />
    </React.Fragment>
  )
}

export default Login
