import c3 from 'c3'

export default arrayByMonth => {
  c3.generate({
    bindto: '#graphByMonth',
    data: {
      x: 'x',
      columns: arrayByMonth,
      type: 'bar',
      groups: [['Atingiram', 'Não Atingiram']]
    },
    axis: {
      x: {
        type: 'category'
      }
    }
  })
}
