import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { useAtom } from 'jotai'
import React from 'react'
import { selectedCallPlanDataAtom } from '../Atoms'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image
} from '@react-pdf/renderer'
import moment from 'moment'

export default function PrintCallPlan({ openPDF, setOpenPDF }) {
  const [callPlanData] = useAtom(selectedCallPlanDataAtom)

  //Font.register({ family: 'Roboto' })

  const styles = StyleSheet.create({
    page: {
      //fontFamily: 'Roboto',
      padding: '10px',
      fontSize: '12px'
    },
    view: {
      marginBottom: '5px'
    },
    title: {
      color: '#4a5568',
      marginBottom: '3px'
    },
    text: {
      color: 'black',
      marginBottom: '5px'
    }
  })

  return (
    <Dialog open={openPDF} fullWidth>
      <DialogContent style={{ height: '700px' }}>
        <PDFViewer height='700px' width='500px'>
          <Document>
            <Page size='A4' style={styles.page}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '15px',
                  alignItems: 'center',
                  borderBottom: '1px solid #4a5568 '
                }}
              >
                <Image
                  src='/logo192Shell.png'
                  style={{ width: '50px', height: '50px' }}
                />
                <Text style={{ color: '#4a5568', fontSize: '16px' }}>
                  Relatório CallPlan
                </Text>
              </View>

              <View
                style={{
                  marginBottom: '15px'
                }}
              >
                <Text style={styles.title}>Empresa</Text>
                <Text style={styles.text}>{callPlanData?.empresa}</Text>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                    borderBottom: '1px solid #4a5568 '
                  }}
                >
                  <View>
                    <Text style={styles.title}>Tipo Negócio</Text>
                    <Text style={styles.text}>
                      {callPlanData?.tipo_negocio}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Estimativa Volume Anual</Text>
                    <Text style={styles.text}>
                      {callPlanData?.volume_estimativa}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Contato</Text>
                    <Text style={styles.text}>{callPlanData?.contato}</Text>
                  </View>
                </View>
                <hr />
              </View>

              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '15px'
                  }}
                >
                  <View>
                    <Text style={styles.title}>Data Prevista</Text>
                    <Text style={styles.text}>
                      {callPlanData?.data_prevista
                        ? moment(callPlanData?.data_prevista).format(
                            'DD/MM/YYYY'
                          )
                        : ''}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Hora Início</Text>
                    <Text style={styles.text}>
                      {callPlanData?.hora_inicio?.value}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Hora Fim</Text>
                    <Text style={styles.text}>
                      {callPlanData?.hora_fim?.value}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Tipo da Visita</Text>
                    <Text style={styles.text}>
                      {callPlanData?.tipo_visita?.value}
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                    borderBottom: '1px solid #4a5568 '
                  }}
                >
                  <View>
                    <Text style={styles.title}>Realizado</Text>
                    <Text style={styles.text}>{callPlanData?.realizado}</Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Motivo Não Realização</Text>
                    <Text style={styles.text}>
                      {callPlanData?.motivo_naorealizado}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.title}>Data Conclusão</Text>
                    <Text style={styles.text}>
                      {callPlanData?.data_conclusao
                        ? moment(callPlanData?.data_conclusao).format(
                            'DD/MM/YYYY'
                          )
                        : ''}
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                <Text style={styles.title}>Proposito</Text>
                <Text style={styles.text}>{callPlanData?.proposito}</Text>
              </View>
              <View>
                <Text style={styles.title}>Objetivo</Text>
                <Text style={styles.text}>{callPlanData?.objetivo}</Text>
              </View>
              <View>
                <Text style={styles.title}>Premissa</Text>
                <Text style={styles.text}>{callPlanData?.premissa}</Text>
              </View>
              <View>
                <Text style={styles.title}>Estratégia</Text>
                <Text style={styles.text}>{callPlanData?.estrategia}</Text>
              </View>
              <View>
                <Text style={styles.title}>Antecipar</Text>
                <Text style={styles.text}>{callPlanData?.antecipar}</Text>
              </View>
              <View>
                <Text style={styles.title}>Anotações</Text>
                <Text style={styles.text}>{callPlanData?.notas}</Text>
              </View>
              <View>
                <Text style={styles.title}>Informações Adiciomais</Text>
                <Text style={styles.text}>
                  {callPlanData?.informacao_adicional}
                </Text>
              </View>
              <View>
                <Text style={styles.title}>Conclusão</Text>
                <Text style={styles.text}>{callPlanData?.conclusao}</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setOpenPDF(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
