import { Button, Paper } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import {
  loadMarketingColumns,
  loadMarketingWidths
} from '../../../functions/functions'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import saveAs from 'file-saver'
import { Fragment } from 'react'
import GroupGridWithValues from './GroupGridWithValues'
import GroupGridWithoutValues from './GroupGridWithoutValues'
import IndividualGrid from './IndividualGrid'
import ls from 'local-storage'
import { tableColumnsAtom } from '../../_global/Atoms'
import { useAtom } from 'jotai'
import { campaignMonthsAtom } from '../CampaignAtoms'

export default function IndividualTable({
  data,
  currentCampaign,
  alvoHasId,
  rawData
}) {
  const [sorting, setSorting] = useState([
    { columnName: 'perc_total', direction: 'desc' }
  ])
  const [filters, setFilters] = useState([])
  const [grouping, setGrouping] = useState([])
  const [groupSummaryItems, setGroupSummaryItems] = useState([])
  const [columnWidths, setColumnWidths] = useState([])
  const [columns, setColumns] = useAtom(tableColumnsAtom)
  const [leftColumns] = useState([
    'grupo',
    'municipio',
    'estado',
    'dealer_name'
  ])
  const [withGroupValues, setWithGroupValues] = useState(true)
  const [itemsData, setItemsData] = useState([])
  const [mkt_profile] = useState(ls.get('mkt_profile'))
  const [seeAllCompany, setSeeAllCompany] = useState(false)
  const [gridData, setGridData] = useState(data)
  const [campaignMonths] = useAtom(campaignMonthsAtom)

  const exporterRef = useRef()
  const startExport = options => {
    exporterRef.current.exportGrid(options)
  }

  useEffect(() => {
    setGridData(data)
  }, [data])

  useEffect(() => {
    const items = []
    if (currentCampaign?.chave?.value === 'grupo') {
      if (gridData.length > 0) {
        gridData.forEach(d => d.items.forEach(item => items.push(item)))
        setItemsData(items)
      }
    } else {
      setItemsData(items)
    }
  }, [gridData, currentCampaign])

  useEffect(() => {
    const filterData = () => {
      if (mkt_profile !== 'root' && mkt_profile !== 'mainlevel') {
        let transformedData
        let filteredData
        if (currentCampaign?.chave?.value === 'grupo') {
          if (seeAllCompany) {
            transformedData = data.map(item => {
              return { ...item, items: item.rawItems }
            })
          } else {
            transformedData = data.map(item => {
              return { ...item, items: item.items }
            })
          }

          filteredData = transformedData.filter(data => data.items.length > 0)
        } else {
          filteredData = seeAllCompany ? rawData : data
        }

        setGridData(filteredData)
      }
    }
    filterData()
  }, [seeAllCompany, rawData, mkt_profile, data, currentCampaign])

  useEffect(() => {
    setColumnWidths(getWidths)
    getGrouping()
    // eslint-disable-next-line
  }, [currentCampaign, alvoHasId])

  const getGrouping = () => {
    if (currentCampaign?.tipo_agrupamento?.value === 'categoria') {
      setGrouping([{ columnName: 'categoria' }])
      setGroupSummaryItems([{ columnName: 'categoria', type: 'count' }])
    }

    if (currentCampaign?.chave?.value === 'grupo') {
      setGrouping([{ columnName: 'grupo' }])
    }
  }

  useEffect(() => {
    const loadedColumns = loadMarketingColumns(
      alvoHasId,
      currentCampaign,
      seeAllCompany,
      mkt_profile,
      campaignMonths
    )
    setColumns(loadedColumns)
    // eslint-disable-next-line
  }, [currentCampaign, seeAllCompany, alvoHasId, campaignMonths])

  const getWidths = loadMarketingWidths(
    alvoHasId,
    currentCampaign,
    campaignMonths
  )

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx'
      )
    })
  }

  const ButtonChangeGridGroup = ({ changeTo }) => {
    return (
      <Button
        variant='contained'
        style={{
          background: `${
            changeTo === 'true'
              ? withGroupValues
                ? '#fc8181'
                : '#7f9cf5'
              : withGroupValues
              ? '#7f9cf5'
              : '#fc8181'
          } `,
          color: 'white'
        }}
        onClick={
          changeTo === 'true'
            ? () => {
                setWithGroupValues(true)
              }
            : () => {
                setWithGroupValues(false)
              }
        }
      >
        {changeTo === 'true' ? 'Totais por grupo' : 'Detalhe por Cliente'}
      </Button>
    )
  }

  const ButtonChangeGridDfban = ({ changeTo }) => {
    return seeAllCompany ? (
      <Button onClick={() => setSeeAllCompany(false)}>
        Ver minhas empresas
      </Button>
    ) : (
      <Button onClick={() => setSeeAllCompany(true)}>
        Ver Todas as empresas
      </Button>
    )
  }

  return (
    <Fragment>
      <div className='flex justify-center gap-2'>
        {currentCampaign?.chave?.value === 'grupo' && (
          <Fragment>
            <ButtonChangeGridGroup changeTo={'true'} />
            <ButtonChangeGridGroup changeTo={'false'} />
          </Fragment>
        )}
        {mkt_profile === 'dfbam' && <ButtonChangeGridDfban />}
      </div>

      <Paper>
        {currentCampaign?.chave?.value === 'grupo' ? (
          <Fragment>
            {withGroupValues ? (
              <GroupGridWithValues
                data={gridData}
                columns={columns}
                columnWidths={columnWidths}
                setColumnWidths={setColumnWidths}
                startExport={startExport}
                leftColumns={leftColumns}
              />
            ) : (
              <GroupGridWithoutValues
                columns={columns}
                data={itemsData}
                sorting={sorting}
                setSorting={setSorting}
                grouping={grouping}
                startExport={startExport}
              />
            )}
          </Fragment>
        ) : (
          <IndividualGrid
            columns={columns}
            data={gridData}
            sorting={sorting}
            setSorting={setSorting}
            filters={filters}
            setFilters={setFilters}
            grouping={grouping}
            groupSummaryItems={groupSummaryItems}
            columnWidths={columnWidths}
            setColumnWidths={setColumnWidths}
            startExport={startExport}
            leftColumns={leftColumns}
          />
        )}
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={withGroupValues ? data : itemsData}
          onSave={onSave}
        />
      </Paper>
    </Fragment>
  )
}
