import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useAtom } from 'jotai'
import { reportDataAtom } from './Atoms'
import { CheckCircle } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import ptBR from 'date-fns/locale/pt-BR'
import Alert from '@material-ui/lab/Alert'

const ModalDataContent = () => {
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [inicio, setInicio] = useState('')
  const [termino, setTermino] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleSave = () => {
    setError('')
    if (inicio === '' || termino === '') {
      setError('Preencha as duas datas para continuar.')
      return false
    }

    const newData = reportData.map(item => {
      return {
        ...item,
        dataini: moment(inicio).format('DD/MM/YYYY'),
        datafim: moment(termino).format('DD/MM/YYYY'),
        alter_datas: true
      }
    })

    setReportData(newData)
    setSuccess(true)
  }

  return (
    <div className='h-96 rounded overflow-hidden shadow-lg text-center'>
      <div className='px-6 py-4'>
        <div className='font-bold text-xl mb-2'>Edição de datas em lote</div>
      </div>
      <hr />
      <div className='flex flex-row px-6 py-4'>
        <div className='w-full px-3'>
          <label
            className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
            htmlFor='dt-inicial-changed'
          >
            Data Inicial
          </label>
          <DatePicker
            selected={inicio}
            locale={ptBR}
            dateFormat='dd/MM/yyyy'
            onChange={e => setInicio(e)}
            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
            id='dt-inicial-changed'
            placeholder='Data Inicial'
          />
        </div>

        <div className='w-full px-3'>
          <label
            className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
            htmlFor='dt-final-changed'
          >
            Data Final
          </label>
          <DatePicker
            selected={termino}
            locale={ptBR}
            dateFormat='dd/MM/yyyy'
            onChange={e => setTermino(e)}
            className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
            id='dt-final-changed'
            placeholder='Data Final'
          />
        </div>
      </div>
      <hr />
      <div className='px-6 py-4'>
        <Button
          variant='contained'
          color='primary'
          startIcon={<CheckCircle />}
          onClick={handleSave}
        >
          Confirmar e Salvar
        </Button>
      </div>
      <div>
        {error !== '' && <Alert severity='error'>{error}</Alert>}
        {success && (
          <Alert severity='success'>
            Datas alteradas temporariamente! Nao se esqueca de enviar os dados!
          </Alert>
        )}
      </div>
    </div>
  )
}

export default ModalDataContent
