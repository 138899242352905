import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {
  alertParamsAtom,
  campaignAtom,
  loadedModuleAtom,
  tempCampaignListAtom
} from '../_global/Atoms'
import { default as Heading } from '../_global/Header'
import ls from 'local-storage'

import DialogEdit from './DialogEdit'
import ListTable from './Tables/ListTable'
import { CampaignResults } from './CampaignResults'
import Axios from 'axios'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import { transformData } from '../../functions/campaign/functions'
import { loadLabelBase } from '../../functions/functions'
import ConfirmDialog from '../_global/ConfirmDialog'
import AlertDialog from '../_global/AlertDialog'

export default function List() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [campaignList, setCampaignList] = useAtom(tempCampaignListAtom)
  const [openEdit, setOpenEdit] = useState(false)
  // eslint-disable-next-line
  const [campaign, setCampaign] = useAtom(campaignAtom)
  const [openCampaignResults, setOpenCampaignResults] = useState(false)
  // eslint-disable-next-line
  const [rawData, setRawData] = useState([])
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [showConfirm, setShowConfirm] = useState(false)
  const [currentId, setCurrentId] = useState(false)
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    const getData = async () => {
      const json = {
        id: '',
        usuario: ''
      }
      const { data } = await trackPromise(
        Axios.post(
          'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_BUSCA_TRACKING',
          json,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
      )
      setRawData(data)
      setCampaignList(transformData(data))
    }
    getData()

    // eslint-disable-next-line
  }, [])

  const deleteCampaign = id => {
    setCurrentId(id)
    setShowConfirm(true)
  }

  const processDelete = async () => {
    const finded = campaignList.find(campaign => campaign.id === currentId)
    const user = ls.get('user')
    const mkt_profile = ls.get('mkt_profile')

    if (mkt_profile !== 'root' && finded.usuario !== user) {
      setAlertParams({
        open: true,
        text: 'Você não pode excluir campanha criada por outro usuário',
        onClick: () => setAlertParams({ ...alertParams, open: false })
      })
      return false
    }

    const { data } = await trackPromise(
      Axios.post(
        `https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_EXCLUI_TRACKING`,
        { id: currentId }
      )
    )

    if (data.retorno === 'Exclusão realizada com sucesso') {
      const filtered = campaignList.filter(
        campaign => campaign.id !== currentId
      )
      setCampaignList(filtered)
    }

    setAlertParams({
      open: true,
      text: data.retorno,
      onClick: () => setAlertParams({ ...alertParams, open: false })
    })
  }

  const labels = loadLabelBase(loadedModule)
  return (
    <div>
      <Heading label={labels.label} title={labels.title} />
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <ListTable
          campaignList={campaignList}
          setCampaignList={setCampaignList}
          setCampaign={setCampaign}
          setOpenEdit={setOpenEdit}
          setOpenCampaignResults={setOpenCampaignResults}
          deleteCampaign={deleteCampaign}
        />
      )}
      <DialogEdit open={openEdit} setOpen={setOpenEdit} />
      <CampaignResults
        open={openCampaignResults ? true : false}
        id={openCampaignResults}
        setOpen={setOpenCampaignResults}
      />
      <ConfirmDialog
        open={showConfirm}
        title='Atenção'
        text='Tem certeza que deseja excluir essa campanha? Essa ação é irreversível.'
        onOK={() => {
          processDelete()
          setShowConfirm(false)
        }}
        onCancel={() => {
          setShowConfirm(false)
        }}
      />
      <AlertDialog
        open={alertParams.open}
        text={alertParams.text}
        onClick={alertParams.onClick}
      />
    </div>
  )
}
