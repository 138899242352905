import { Button } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import ModalInsertContent from './ModalInsertContent'

export default function AddDataDialog({ open, setOpen }) {
  return (
    <Dialog open={open}>
      <DialogContent>
        <ModalInsertContent />
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          onClick={() => setOpen(false)}
          startIcon={<Close />}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
