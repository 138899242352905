import React, { useEffect } from 'react'
import InputForm from '../../Inputs/InputForm'
import ReactSelect from '../../Inputs/ReactSelect'
import ls from 'local-storage'
import Axios from 'axios'
import { useAtom } from 'jotai'
import { segmentListAtom } from '../Atoms'

const AddCompanyForm = ({
  insertCompanyData,
  setInsertCompanyData,
  tipoNegocioList,
  setorList,
  big5List
}) => {
  const handleChange = e => {
    const { id, value } = e.target

    setInsertCompanyData({ ...insertCompanyData, [id]: value })
  }

  const updateSelect = (value, field) => {
    setInsertCompanyData({ ...insertCompanyData, [field]: value })
  }

  const getSegmentList = async setor => {
    const perfis = ls.get('perfis')

    const { data } = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_SEGMENTOS`,
      {
        distribuidor: perfis[0].value,
        setor: setor?.value
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    const results = data.map(d => {
      return {
        value: d.descricao,
        label: d.descricao
      }
    })

    setSegmentList(results)
  }

  useEffect(() => {
    getSegmentList(insertCompanyData?.setor)
    // eslint-disable-next-line
  }, [insertCompanyData?.setor])

  const [segmentList, setSegmentList] = useAtom(segmentListAtom)

  return (
    <div style={{ height: '500px' }}>
      <InputForm
        onChange={handleChange}
        value={insertCompanyData?.empresa}
        label='empresa'
        id='empresa'
      />
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/2'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_negocio')
            }}
            value={insertCompanyData?.tipo_negocio}
            label='Tipo de Negócio'
            id='tipo_negocio'
            options={tipoNegocioList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-1/2'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'setor')
            }}
            value={insertCompanyData?.setor}
            label='Setor'
            id='setor'
            options={setorList}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>
      </div>
      <div className='flex gap-4 w-full mb-2'>
        <div className='w-1/2'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'segmento')
            }}
            value={insertCompanyData?.segmento}
            label='Segmento'
            id='segmento'
            options={segmentList}
            allowSelectAll={true}
            placeholder='Selecione...'
          />
        </div>
        {/*<div className='w-1/2'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'big5')
            }}
            value={insertCompanyData.big5}
            label='BIG5/TOP5'
            id='big5'
            options={big5List}
            allowSelectAll={true}
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
          </div>*/}
      </div>
    </div>
  )
}

export default AddCompanyForm
