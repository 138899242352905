import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import {
  apiErrorAtom,
  apiErrorListAtom,
  approveExtraAtom,
  filterDataAtom,
  loadedModuleAtom,
  rawApproveDataAtom,
  errorMessageAtom
} from '../_global/Atoms'
import { DateInput } from '../Inputs/DateInput'

import { changeFilterField } from '../../functions/functions'
import Button from '../Inputs/Button'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Axios from 'axios'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
// eslint-disable-next-line
import worker from 'workerize-loader!../../functions/worker'
import ls from 'local-storage'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  // eslint-disable-next-line
  const [rawApproveData, setRawApproveData] = useAtom(rawApproveDataAtom)
  // eslint-disable-next-line
  const [approveExtraData, setApproveExtraData] = useAtom(approveExtraAtom)
  // eslint-disable-next-line
  const [loadedModule, setLoadedModule] = useAtom(loadedModuleAtom)
  // eslint-disable-next-line
  const [apiError, setApiError] = useAtom(apiErrorAtom)
  // eslint-disable-next-line
  const [apiErrorList, setApiErrorList] = useAtom(apiErrorListAtom)
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)
  Axios.defaults.timeout = 60000
  const user = ls.get('user')

  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    if (apiErrorList && apiErrorList.length !== 0) {
      const message = getErrorMessage()
      setErrorMessage(message)
    }
    // eslint-disable-next-line
  }, [apiErrorList])

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  const handleClick = async e => {
    e.preventDefault()
    const perfis = ls.get('perfis')
    const promises = []
    const urlErrors = []
    const excludedDistributors = [
      'EURO / PA',
      'EURO / MA',
      'EURO / AP',
      'EURO / AM',
      'EURO / RR'
    ]

    perfis
      .filter(perfil => !urlErrors.includes(perfil.distribuidor))
      .filter(perfil => !excludedDistributors.includes(perfil.distribuidor))
      .forEach(perfil => {
        if (perfil.url !== '') {
          promises.push(
            Axios.get(
              `${perfil.url}INT_SHELL_LIMPCS?distribuidor=${
                perfil.distribuidor
              }&sku=&dt_inicial=${moment(filterData.dt_inicial).format(
                'DD/MM/YYYY'
              )}&dt_final=${moment(filterData.dt_final).format(
                'DD/MM/YYYY'
              )}&meses_suges=&perc_suges=&descri&granel=`
            )
          )
        }
      })

    let promiseData = {}
    let getData

    await trackPromise(
      Promise.all(promises.map(p => p.catch(e => e)))
        .then(results => {
          const errors = []
          const data = []
          results.forEach(r => {
            if (r instanceof Error) {
              errors.push(r)
            } else {
              data.push(r)
            }
          })

          promiseData = { results: data, errors: errors }
          let promiseErrors

          if (promiseData.errors.length > 0) {
            const errors = promiseData.errors.map(err => {
              return {
                error: err.message,
                url: err.config.url,
                distribuidor: err.config.url
              }
            })
            promiseErrors = errors
          }

          getData = promiseData.results.reduce(function (arr, row) {
            let filteredData

            if (row.statusText === 'OK') {
              if (user === 'regional.sul' || user === 'regional.norte') {
                filteredData = row.data.filter(
                  f => f.aprova === '1' || f.aprova === '2'
                )
                return arr.concat(filteredData)
              } else if (user === 'pricing') {
                filteredData = row.data.filter(
                  f => f.aprova === '2' || f.aprova === '3'
                )
                return arr.concat(filteredData)
              } else {
                filteredData = row.data
              }
            }

            return arr.concat(filteredData)
          }, [])

          let newData = []

          getData = getData.filter(g => g !== undefined)

          if (getData) {
            let id = 0
            newData = getData.map((g, i) => {
              ++id
              return {
                ...g,
                id: id
              }
            })
          }

          setRawApproveData(newData)
          setLoadedModule('ApproveExtra')
          ls.set('loadedModule', 'ApproveExtra')
          setApiErrorList(promiseErrors)
        })
        .catch(e => console.log(e))
    )
  }

  const getErrorMessage = () => {
    let message = 'As urls abaixo apresentaram erro:<br><br>'
    apiErrorList.forEach(err => {
      message += `<b>URL:</b> ${err.url}<br><b>Error:</b> ${err.error}<br><br>`
    })
    message += '<b>Aguarde enquanto os demais dados são gerados...</b>'
    return message
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap'>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Data Inicial'
            id='dt_inicial'
            selected={filterData.dt_inicial}
            onChange={e => {
              handleChange(e, 'dt_inicial')
            }}
            popperPlacement='bottom-start'
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Data Final'
            id='dt_final'
            selected={filterData.dt_final}
            onChange={e => {
              handleChange(e, 'dt_final')
            }}
          />
        </div>
        <Button
          label={promiseInProgress ? 'Aguarde, buscando...' : 'Buscar'}
          color='blue'
          textColor='white'
          icon={faSearch}
          padding='p-2 h-10 mt-4'
          click={handleClick}
        />
      </div>
    </form>
  )
}
