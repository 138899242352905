import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'

const LostOpportunityDialog = ({ open, setOpen }) => (
  <Dialog open={open}>
    <DialogTitle>Atenção</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Você marcou essa oportunidade como perdida. As datas PANCOP serão
        apagadas após a sua confirmação e essa oportunidade será mantida em S.
        Caso tenha certeza que deseja movê-la para Perdida, preencha o motivo, o
        campo LOST e clique em confirmar.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        variant='contained'
        color='primary'
        onClick={() => setOpen(false)}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

export default LostOpportunityDialog
