import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { useAtom } from 'jotai'
import { filterDataAtom } from '../_global/Atoms'

export default function ReactSelect(props) {
  const [filterData] = useAtom(filterDataAtom)

  const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      padding: 0,
      minHeight: 35
    })
  }

  const CustomClearText = () => 'limpar'
  const ClearIndicator = props => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps }
    } = props
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles('clearIndicator', props)}
      >
        <div style={{ padding: '0px 2px' }}>{children}</div>
      </div>
    )
  }
  const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: 'pointer',
    color: state.isFocused ? 'gray' : 'black'
  })

  if (props.allowSelectAll) {
    return (
      <React.Fragment>
        <label
          className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
          htmlFor={props.id}
        >
          {props.label}
        </label>
        <Select
          className={`appearance-none block w-full text-gray-700 rounded mb-3 leading-tight focus:outline-none focus:bg-white text-xs`}
          components={{ ClearIndicator }}
          styles={{ clearIndicator: ClearIndicatorStyles, customStyles }}
          options={[props.allOption, ...props.options]}
          {...props}
          onChange={selected => {
            if (
              selected !== null &&
              selected.length > 0 &&
              selected[selected.length - 1].value === props.allOption.value
            ) {
              return props.onChange(props.options)
            }
            return props.onChange(selected)
          }}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={props.id}
      >
        {props.label}
      </label>

      <Select
        className='appearance-none block w-full text-gray-700 rounded mb-3 leading-tight focus:outline-none focus:bg-white text-xs'
        {...props}
        defaultValue={filterData.distribuidor}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles, customStyles }}
      />
    </React.Fragment>
  )
  //}
}

ReactSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
}

ReactSelect.defaultProps = {
  allOption: {
    label: 'Selecionar todos',
    value: '*'
  }
}
