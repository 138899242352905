import React from 'react'
import Loader from 'react-loader-spinner'

export const LoadingIndicator = props => {
  return (
    <div
      style={{
        width: '100%',
        height: '100',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loader type='ThreeDots' color='#60A5FA' height='50' width='50' />
      &nbsp;&nbsp;&nbsp;
      <div className='text-blue-400 tracking-widest text-sm'>
        AGUARDE, CARREGANDO DADOS...
      </div>
    </div>
  )
}
