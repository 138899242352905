import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {
  filterDataAtom,
  loadedModuleAtom,
  reportDataAtom
} from '../../_global/Atoms'
import * as Forms from '../../Forms'
import { Button } from '@material-ui/core'
import OrdersByDay from './Orders/OrdersByDay'
import OrdersByProduct from './Orders/OrdersByProducts'
import OrdersCut from './Orders/OrdersCut'
import OrdersByStatus from './Orders/OrdersByStatus'
import RevenuesByDay from './Revenues/RevenuesByDay'
import RevenuesByProduct from './Revenues/RevenuesByProduct'
import RevenuesBySeller from './Revenues/RevenuesBySeller'
import VisitPlan from './Visit/VisitPlan'
import RevenuesByClassification from './Revenues/RevenuesByClassification'
import MarginTracker from './MarginTracker/MarginTracker'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { usePromiseTracker } from 'react-promise-tracker'
import Coaching from './Coaching/Coaching'

export default function Filter() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [buttons, setButtons] = useState([])
  const { promiseInProgress } = usePromiseTracker()
  const [reportData] = useAtom(reportDataAtom)

  useEffect(() => {
    const loadButtons = loadReportButtons()
    setButtons(loadButtons)
    // eslint-disable-next-line
  }, [loadedModule])

  const setLoadedReport = report => {
    setFilterData({ ...filterData, loaded_report: report, report_level: 1 })
  }

  const formComponents = {
    FormSupervisorOrderReports: Forms.FormSupervisorOrderReports,
    FormSupervisorRevenueReports: Forms.FormSupervisorOrderReports,
    FormSupervisorCoachingReports: Forms.FormSupervisorOrderReports,
    FormVisitReports: Forms.FormSupervisorOrderReports,
    FormMarginTracker: Forms.FormSupervisorOrderReports
  }

  const loadReportButtons = () => {
    switch (loadedModule) {
      case 'SupervisorOrderReports':
        return [
          { name: 'OrdersByDay', label: 'Pedidos por dia', key: 1 },
          { name: 'OrdersByProduct', label: 'Pedidos por produto', key: 2 },
          { name: 'OrdersCut', label: 'Corte de Pedidos', key: 3 },
          { name: 'OrdersByStatus', label: 'Pedidos por Status', key: 4 }
        ]
      case 'SupervisorRevenueReports':
        return [
          { name: 'RevenuesByDay', label: 'Faturamento por dia', key: 1 },
          {
            name: 'RevenuesByClassification',
            label: 'Faturamento por classificação',
            key: 2
          },
          {
            name: 'RevenuesByProduct',
            label: 'Faturamento por Produto',
            key: 3
          },
          {
            name: 'RevenuesBySeller',
            label: 'Faturamento por Vendedor',
            key: 4
          }
        ]
      case 'VisitReports':
        return [{ name: 'VisitPlan', label: 'Plano de Visitas', key: 1 }]
      case 'MarginTracker':
        return [
          {
            name: 'MarginTrackerReport',
            label: 'Visualizar Acompanhamento de Margem',
            key: 1
          }
        ]
      case 'SupervisorCoachingReports':
        return [
          { name: 'ResellerCoaching', label: 'Revenda', key: 1 },
          {
            name: 'VarejoCoaching',
            label: 'Varejo',
            key: 2
          }
        ]
      default:
        return []
    }
  }

  const LoadedForm = formComponents['Form' + loadedModule]

  const LoadedReport = () => {
    const { loaded_report } = filterData

    switch (loaded_report) {
      case 'OrdersByDay':
        return <OrdersByDay />
      case 'OrdersByProduct':
        return <OrdersByProduct />
      case 'OrdersCut':
        return <OrdersCut />
      case 'OrdersByStatus':
        return <OrdersByStatus />
      case 'RevenuesByDay':
        return <RevenuesByDay />
      case 'RevenuesByClassification':
        return <RevenuesByClassification />
      case 'RevenuesByProduct':
        return <RevenuesByProduct />
      case 'RevenuesBySeller':
        return <RevenuesBySeller />
      case 'VisitPlan':
        return <VisitPlan />
      case 'MarginTrackerReport':
        return <MarginTracker />
      case 'ResellerCoaching':
        return <Coaching tipo='REVENDA' />
      case 'VarejoCoaching':
        return <Coaching tipo='VAREJO' />
      default:
        return false
    }
  }

  return (
    <div>
      <div
        className='w-full rounded overflow-hidden shadow-lg m-auto mt-8 border border-solid border-gray-400 bg-white'
        style={{ minHeight: 400 }}
      >
        <div className='px-6 py-4'>
          <div className='text-xl mb-2'>
            Parâmetros{' '}
            <span className='text-red-600 text-xs'>
              Campos com * são obrigatórios
            </span>
          </div>

          <hr />
          {loadedModule && <LoadedForm />}
          <div className='flex flex-row gap-2 justify-center mb-2'>
            {buttons.map(btn => {
              return (
                <Button
                  key={btn.key}
                  variant='contained'
                  color={`${
                    filterData.loaded_report === btn.name
                      ? 'secondary'
                      : 'primary'
                  }`}
                  onClick={() => setLoadedReport(btn.name)}
                  disabled={reportData.length > 0 ? false : true}
                >
                  {btn.label}
                </Button>
              )
            })}
          </div>
          <hr />
          {promiseInProgress ? (
            <LoadingIndicator />
          ) : filterData.loaded_report === '' ? (
            reportData.length > 0 ? (
              <div className='text-blue-400 tracking-widest text-sm text-center mt-4'>
                DADOS CARREGADOS! SELECIONE UM RELATORIO ACIMA.
              </div>
            ) : (
              <div className='text-red-400 tracking-widest text-sm text-center mt-4'>
                NENHUM DADO ENCONTRADO OU FILTRO NAO REALIZADO
              </div>
            )
          ) : (
            <LoadedReport />
          )}
        </div>
      </div>
    </div>
  )
}
