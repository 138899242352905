import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui'
import {
  PagingState,
  IntegratedPaging,
  EditingState
} from '@devexpress/dx-react-grid'
import { Tabs, Tab, AppBar, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { listCampaignMonths } from '../../functions/functions'

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
)

const getRowId = row => row.id

const columns = [
  { name: 'cnpj', title: 'CNPJ' },
  { name: 'razao', title: 'Razão Social' },
  { name: 'alvo', title: 'Alvo' },
  { name: 'categoria', title: 'Categoria' },
  { name: 'custom_id', title: 'ID' },
  { name: 'grupo_descricao', title: 'Grupo' },
  { name: 'nome_segmento1', title: 'Segmento' }
]

export default function TargetsTable({ campaign, commitChanges }) {
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedAlvo, setSelectedAlvo] = useState([])
  const [editingStateColumnExtensions] = useState([
    { columnName: 'cnpj', editingEnabled: false },
    { columnName: 'categoria', editingEnabled: false },
    { columnName: 'grupo_descricao', editingEnabled: false },
    { columnName: 'custom_id', editingEnabled: false },
    { columnName: 'nome_segmento1', editingEnabled: false }
  ])
  const [pageSizes] = useState([5, 10, 15, 0])

  useEffect(() => {
    const setData = () => {
      const rows = campaign.alvos[selectedTab]
      setSelectedAlvo(rows)
    }
    campaign.alvos?.length > 0 && setData()
  }, [selectedTab, campaign])

  const handleTabChange = (e, v) => {
    setSelectedTab(v)
  }

  return (
    <div>
      <AppBar position='static'>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {campaign.alvos?.map((alvo, index) => {
            const months = listCampaignMonths(campaign)
            const tabCount = index + 1
            return (
              <Tab label={`Alvo mês ${months[index].month}`} key={tabCount} />
            )
          })}
        </Tabs>
      </AppBar>

      <Paper>
        <Grid rows={selectedAlvo} columns={columns} getRowId={getRowId}>
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <PagingState defaultCurrentPage={0} defaultPageSize={5} />
          <IntegratedPaging />
          <Table cellComponent={FocusableCell} />
          <TableHeaderRow />
          <PagingPanel
            pageSizes={pageSizes}
            messages={{ rowsPerPage: 'Linhas p/página' }}
          />
          <TableInlineCellEditing startEditAction='click' />
        </Grid>
      </Paper>
    </div>
  )
}
