import React from 'react'
import DataTable from '../DataTable'

const columns = [
  { title: 'Vendedor', name: 'vendedor' },
  { title: 'Código', name: 'codigo' },
  { title: 'Receita', name: 'receita' },
  { title: 'Volume', name: 'volume' },
  { title: 'Margem Reposição', name: 'margem_reposicao' }
]

const values = [
  {
    vendedor: 'MARCO AURELIO MORAIS MENDES',
    codigo: '01009',
    receita: 27540.27,
    volume: 1114,
    margem_reposicao: 13.73
  }
]

export default function MarginTracker() {
  return (
    <div className='mt-2'>
      <DataTable
        columns={columns}
        rows={values}
        title='Acompanhamento de Margem'
        report='marginTracker'
      />
    </div>
  )
}
