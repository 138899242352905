import React from 'react'
import {
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  SortingState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  ExportPanel,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import { PercentCell } from '../PercentCell'

export default function GroupGridWithoutValues({
  columns,
  data,
  sorting,
  setSorting,
  grouping,
  startExport
}) {
  return (
    <Grid columns={columns} rows={data}>
      <SortingState sorting={sorting} onSortingChange={setSorting} />
      <GroupingState grouping={grouping} />
      <IntegratedSorting />
      <IntegratedGrouping />
      <Table
        cellComponent={PercentCell}
        messages={{ noData: 'Nenhum dado encontrado' }}
      />
      <TableHeaderRow
        messages={{ sortingHint: 'Ordenar por' }}
        showSortingControls
      />
      <Toolbar />
      <ExportPanel
        startExport={startExport}
        messages={{ exportAll: 'Exportar todos os dados' }}
      />
      <TableGroupRow showColumnsWhenGrouped />
    </Grid>
  )
}
