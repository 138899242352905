import { useAtom } from 'jotai'
import React from 'react'
import { getDistributors } from '../../../constData/constData'
import { updateSelect } from '../../../functions/functions'
import ReactSelect from '../../Inputs/ReactSelect'
import { filterDataAtom, indicadorAtom } from '../../_global/Atoms'
import { indicatorsListAtom } from './Atoms'

const KPITable = () => {
  const [indicador, setIndicador] = useAtom(indicadorAtom)
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [indicatorsList] = useAtom(indicatorsListAtom)

  return (
    <div className='w-3/12'>
      <div className='p-2 border border-gray-400 mb-1'>
        {' '}
        <ReactSelect
          onChange={v => {
            updateSelect(v, 'distribuidor', filterData, setFilterData)
          }}
          value={filterData.distribuidor}
          label='Distribuidor *'
          id='distribuidor'
          options={getDistributors()}
          allowSelectAll={true}
          isMulti
          placeholder='Selecione...'
          maxMenuHeight={200}
        />
      </div>
      <div className='p-2 border border-gray-400'>
        <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>
          Indicadores
        </div>

        {indicatorsList.map(row => {
          return (
            <div
              key={row.key}
              onClick={() => setIndicador(row.key)}
              className={`text-sm ${
                indicador === row.key ? 'text-red-400' : 'text-gray-600'
              } cursor-pointer hover:text-red-400`}
            >
              - {row.indicador}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default KPITable
