import { atom } from 'jotai'

export const filterDataAtom = atom({
  distribuidor: '',
  sku: '',
  dt_inicial: '',
  dt_final: '',
  armazem: '',
  meses_suges: '',
  perc_suges: '',
  granel: '',
  descri: '',
  date_range: [null, null]
})

export const reportDataAtom = atom([])

export const changedDatesAtom = atom({
  dt_inicial_changed: '',
  dt_final_changed: ''
})

export const modalDataAtom = atom(false)
export const modalInsertAtom = atom(false)
export const okAlertAtom = atom(false)

export const updateDataAtom = atom({
  distribuidor: '',
  sku: '',
  descricao: '',
  dataini: '',
  datafim: '',
  armazem: '',
  limite: '',
  pedido: '',
  atingido: ''
})

export const promptUpdateTodasDatasAtom = atom(false)
export const promptSendAllAtom = atom(false)
export const sendErrorAtom = atom(false)
export const sendSuccessAtom = atom(false)
