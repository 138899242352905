import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom } from '../_global/Atoms'
import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'

import {
  changeFilterField,
  updateSelect,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap mb-4'>
        <div className='w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            maxMenuHeight={200}
            isMulti
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp de *'
            id='dt_inicial'
            selected={filterData.dt_inicial}
            onChange={e => {
              handleChange(e, 'dt_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp ate *'
            id='dt_final'
            selected={filterData.dt_final}
            onChange={e => {
              handleChange(e, 'dt_final')
            }}
          />
        </div>
      </div>
    </form>
  )
}
