import React from "react"

export default function Footer() {
  return (
    <p className='text-center text-gray-500 text-xs'>
      &copy;2020{" "}
      <a
        href='http://www.nexusconsultoriams.com.br/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Nexus Consultoria em Sistemas ERP
      </a>
    </p>
  )
}
