import { useAtom } from 'jotai'
import React, { Fragment } from 'react'
import { newResponsesAtom } from '../../_global/Atoms'
import QuestionResponseItem from './QuestionResponseItem'
import { coachingQuestions as questions } from '../../../constData/constData.js'

export default function CoachingNewResponsesForm({ show }) {
  const [newResponses, setNewResponses] = useAtom(newResponsesAtom)

  const handleCoachingResponse = (key, e) => {
    const { value } = e.target
    setNewResponses({ ...newResponses, [key]: value })
  }

  const questionsSection1 = questions.slice(1, 4)
  const questionsSection2 = questions.slice(5)

  return (
    <div>
      {show ? (
        <Fragment>
          <div className='flex flex-col'>
            <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-6'>
              {questions[0].question}
            </div>
            <div className='flex flex-row justify-between'>
              {questionsSection1.map(question => {
                return (
                  <QuestionResponseItem
                    item={question}
                    key={question.key}
                    handleCoachingResponse={handleCoachingResponse}
                    newResponses={newResponses}
                  />
                )
              })}
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='block uppercase tracking-wide text-gray-700 text-xs font-bold my-2'>
              {questions[4].question}
            </div>
            <div className='flex flex-row flex-wrap justify-between my-2'>
              {questionsSection2.map(question => {
                return (
                  <QuestionResponseItem
                    item={question}
                    key={question.key}
                    handleCoachingResponse={handleCoachingResponse}
                    newResponses={newResponses}
                  />
                )
              })}
            </div>
          </div>
        </Fragment>
      ) : (
        <div>Selecione o cliente para continuar</div>
      )}
    </div>
  )
}
