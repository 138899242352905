import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { reportDataAtom } from './Atoms'
import InputForm from '../Inputs/InputForm'
import ReactSelect from '../Inputs/ReactSelect'
import { listWarehouse } from '../../constData/constData'
import { Button } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'

const ModalInsertContent = ({ clickSave, clickClose }) => {
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [newData, setNewData] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const list = listWarehouse()
    const filtered = list.filter(item => item.key !== 1)
    setWarehouses(filtered)
  }, [])

  useEffect(() => {
    const baseData = reportData[0]
    const newBaseData = {
      ...baseData,
      sku: '',
      descricao: '',
      limite: 0,
      pedido: 0,
      extra: 0,
      atingido: 0,
      new_item: true,
      alter_datas: false,
      alter_limite: false,
      alter_extra: false,
      imported: false,
      id: 0
    }
    setNewData(newBaseData)
  }, [reportData])

  const changeNewData = e => {
    const { id, value } = e.target
    setNewData({ ...newData, [id]: value })
  }

  const updateSelect = (value, field) => {
    setNewData({ ...newData, [field]: value })
  }

  const handleSave = () => {
    if (newData.sku === '' || newData.armazem === '' || newData.limite === '') {
      setError(true)
      return false
    }

    const normalizedJSON = { ...newData, armazem: newData.armazem.value }

    const allData = [normalizedJSON, ...reportData]
    let id = 0

    const reordedData = allData.map(item => {
      return { ...item, id: id++ }
    })
    setReportData(reordedData)
    setSuccess(true)
  }

  return (
    <div className='w-120 h-full rounded overflow-hidden shadow-lg text-center'>
      <div className='px-6 py-4'>
        <div className='font-bold text-xl mb-2'>Inserir Dados</div>
      </div>
      <hr />
      <div className='px-6 py-4'>
        <div className='flex flex-wrap -mx-3 mb-6'>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <InputForm
              onChange={changeNewData}
              value={newData.sku}
              label='SKU'
              id='sku'
            />
          </div>
          <div className='w-full md:w-1/2 px-3'>
            <ReactSelect
              onChange={v => {
                updateSelect(v, 'armazem')
              }}
              value={newData.armazem}
              label='Armazém'
              id='armazem'
              options={warehouses}
              placeholder='Selecione...'
            />
          </div>
          <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
            <InputForm
              onChange={changeNewData}
              value={newData.limite}
              label='Qtd. Trava (embalagem)'
              id='limite'
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='px-6 py-4'>
        <Button
          variant='contained'
          color='primary'
          startIcon={<CheckCircle />}
          onClick={handleSave}
        >
          Confirmar e Salvar
        </Button>
      </div>
      {error && <Alert severity='error'>Erro ao adicionar SKU.</Alert>}
      {success && (
        <Alert severity='success'>
          Adicionado com sucesso! Nao se esqueca de enviar os dados!
        </Alert>
      )}
    </div>
  )
}

export default ModalInsertContent
