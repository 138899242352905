import React from 'react'
import c3 from 'c3'
import { useEffect } from 'react'
import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import { Paper } from '@material-ui/core'
import Chart from 'react-google-charts'
import { useAtom } from 'jotai'
import { KPIOportunitiesAtom, KPIAlvosAtom } from './Atoms'
import './resumocss.css'

const resumeSpancopColumns = [
  { name: 'tipo', title: 'Tipo' },
  { name: 's', title: 'S' },
  { name: 'pr', title: 'Pr' },
  { name: 'a', title: 'A' },
  { name: 'n', title: 'N' },
  { name: 'c', title: 'C' },
  { name: 'o', title: 'O' },
  { name: 'pa', title: 'Pa' },
  { name: 'lost', title: 'Lost' }
]

const GaugeTitle = ({ title, subtitle }) => (
  <div className='flex flex-col justify-center align-center'>
    <div className='tracking-widest'>{title}</div>
    <div className='tracking-widest italic text-xs'>{subtitle}</div>
  </div>
)

export default function Resumo() {
  // graficos construidos com c3. Documentação em https://c3js.org/reference.html, exemplos em https://c3js.org/examples.html
  const [KPIOportunities] = useAtom(KPIOportunitiesAtom)
  const [KPIAlvos] = useAtom(KPIAlvosAtom)

  const {
    volume_suspect,
    volume_prospect,
    volume_analise,
    volume_negociacao,
    volume_contrato,
    volume_ordem,
    volume_pagamento,
    volume_lost,
    registros_suspect,
    registros_prospect,
    registros_analise,
    registros_negociacao,
    registros_contrato,
    registros_ordem,
    registros_pagamento,
    registros_lost
  } = KPIOportunities

  const resumeSpancopData = [
    {
      tipo: 'Volume',
      s: volume_suspect,
      pr: volume_prospect,
      a: volume_analise,
      n: volume_negociacao,
      c: volume_contrato,
      o: volume_ordem,
      pa: volume_pagamento,
      lost: volume_lost
    },
    {
      tipo: 'Oportunidades',
      s: registros_suspect,
      pr: registros_prospect,
      a: registros_analise,
      n: registros_negociacao,
      c: registros_contrato,
      o: registros_ordem,
      pa: registros_pagamento,
      lost: registros_lost
    }
  ]

  const renderSpancopBarChart = async () => {
    const {
      volume_suspect,
      volume_prospect,
      volume_analise,
      volume_negociacao,
      volume_contrato,
      volume_ordem,
      volume_pagamento,
      volume_lost,
      registros_suspect,
      registros_prospect,
      registros_analise,
      registros_negociacao,
      registros_contrato,
      registros_ordem,
      registros_pagamento,
      registros_lost
    } = KPIOportunities

    c3.generate({
      bindto: '#barChart',

      data: {
        x: 'x',
        columns: [
          [
            'x',
            'Suspeito',
            'Prospecção',
            'Análise',
            'Negociação',
            'Contrato',
            'Ordem',
            'Pagamento',
            'Lost'
          ],
          [
            'volume',
            volume_suspect,
            volume_prospect,
            volume_analise,
            volume_negociacao,
            volume_contrato,
            volume_ordem,
            volume_pagamento,
            volume_lost
          ],
          [
            'oportunidades',
            registros_suspect,
            registros_prospect,
            registros_analise,
            registros_negociacao,
            registros_contrato,
            registros_ordem,
            registros_pagamento,
            registros_lost
          ]
        ],
        axes: {
          oportunidades: 'y2',
          volume: 'y'
        },
        type: 'line',
        types: {
          volume: 'bar'
        },
        labels: true,
        color: function (color, d) {
          switch (d.x) {
            case 0:
              return '#3DA5DF'
            case 1:
              return '#F2D000'
            case 2:
              return '#E3430F'
            case 3:
              return '#D3D4D4'
            case 4:
              return '#494949'
            case 5:
              return '#43F4B1'
            case 6:
              return '#744AE5'
            case 7:
              return '#2FB8A9'
            default:
              return color
          }
        }
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          show: true,
          label: {
            text: 'volume',
            position: 'left'
          }
        },
        y2: {
          show: true,
          label: {
            text: 'oportunidades',
            position: 'right'
          },
          max: 200,
          padding: { bottom: 50 }
        }
      }
    })
  }

  useEffect(() => {
    renderSpancopBarChart()
    //eslint-disable-next-line
  }, [KPIOportunities])

  const {
    entregue_target1,
    entregue_target2,
    entregue_target3,
    entregue_valor,
    ciclo_target1,
    ciclo_target2,
    ciclo_target3,
    ciculo_valor,
    sucesso_target1,
    sucesso_target2,
    sucesso_target3,
    sucesso_valor,
    tubo_target1,
    tubo_target2,
    tubo_target3,
    tubo_valor
  } = KPIAlvos

  return (
    <div>
      <div className='flex flex-col mr-5'>
        <div id='barChart'></div>
        <div>
          <Paper>
            <Grid rows={resumeSpancopData} columns={resumeSpancopColumns}>
              <Table />
              <TableHeaderRow />
            </Grid>
          </Paper>
        </div>
      </div>

      <div className='mt-4 flex gap-1 justify-between'>
        <div className='flex flex-col align-center'>
          <GaugeTitle
            title='ALVO ENTREGUE'
            subtitle={`Target Delivered (> 1)`}
          />
          <div>
            <Chart
              chartType='Gauge'
              width={250}
              loader={<div>Loading Chart</div>}
              data={[
                ['Label', ''],
                ['', entregue_valor]
              ]}
              options={{
                redFrom: 0,
                redTo: entregue_target1,
                yellowFrom: entregue_target1,
                yellowTo: entregue_target2,
                greenFrom: entregue_target2,
                greenTo: entregue_target3,
                max: entregue_target3
              }}
            />
          </div>
        </div>
        <div className='flex flex-col align-center'>
          <GaugeTitle
            title='CICLO DE VENDAS'
            subtitle='Cycle Time (30-90 dias)'
          />
          <div>
            <Chart
              chartType='Gauge'
              width={250}
              loader={<div>Loading Chart</div>}
              data={[
                ['Label', 'Value'],
                ['', ciculo_valor]
              ]}
              options={{
                redFrom: ciclo_target2,
                redTo: ciclo_target3,
                yellowFrom: ciclo_target1,
                yellowTo: ciclo_target2,
                greenFrom: 0,
                greenTo: ciclo_target1,
                max: ciclo_target3
              }}
            />
          </div>
        </div>

        <div className='flex flex-col align-center'>
          <GaugeTitle title='TAXA DE SUCESSO' subtitle={`Hit Rate (> 20%)`} />
          <div>
            {' '}
            <Chart
              chartType='Gauge'
              width={250}
              loader={<div>Loading Chart</div>}
              data={[
                ['Label', 'Value'],
                ['', sucesso_valor]
              ]}
              options={{
                redFrom: 0,
                redTo: sucesso_target1,
                yellowFrom: sucesso_target1,
                yellowTo: sucesso_target2,
                greenFrom: sucesso_target2,
                greenTo: sucesso_target3,
                max: sucesso_target3
              }}
            />
          </div>
        </div>
        <div className='flex flex-col align-center'>
          <GaugeTitle
            title='Força do Tubo'
            subtitle={`Pipeline Strength (> 5)`}
          />
          <div>
            <Chart
              chartType='Gauge'
              width={250}
              loader={<div>Loading Chart</div>}
              data={[
                ['Label', 'Value'],
                ['', tubo_valor]
              ]}
              options={{
                redFrom: 0,
                redTo: tubo_target1,
                yellowFrom: tubo_target1,
                yellowTo: tubo_target2,
                greenFrom: tubo_target2,
                greenTo: tubo_target3,
                max: tubo_target3
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
