import {
  AppBar,
  IconButton,
  Typography,
  Toolbar as MUIToolbar
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

export default function TopBar({ title, onClick }) {
  return (
    <AppBar>
      <MUIToolbar>
        <IconButton
          edge='start'
          color='inherit'
          onClick={onClick}
          aria-label='close'
        >
          <Close />
        </IconButton>
        <Typography variant='h6'>{title}</Typography>
      </MUIToolbar>
    </AppBar>
  )
}
