import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { loadedModuleAtom } from '../_global/Atoms'
import Filter from '../_global/Filter'
import Header from '../_global/Header'
import ls from 'local-storage'

export default function FeeEstoque() {
  // eslint-disable-next-line no-unused-vars
  const [loadedModule, setLoadedModule] = useAtom(loadedModuleAtom)

  useEffect(() => {
    setLoadedModule('FeeEstoque')
    ls.set('loadedModule', 'ListCampaign')
  })

  return (
    <div>
      <Header
        label='Relatório Fee Estoque'
        title='Shell - Relatório Fee Estoque'
      />
      <Filter />
    </div>
  )
}
