import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import {
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Cancel, ControlPoint, Delete, Save } from '@material-ui/icons'
import ReactSelect from '../Inputs/ReactSelect'
import InputForm from '../Inputs/InputForm'
import ls from 'local-storage'
import Axios from 'axios'
import { defaultCellAtom, selectedCellAtom, selectedSellerAtom } from './Atoms'
import { useAtom } from 'jotai'
import { alertParamsAtom } from '../_global/Atoms'

const columns = [
  { name: 'nome_vendedor', title: 'Vendedor' },
  { name: 'franquia_anoatual', title: 'Franquia - Volume Ano Atual' },
  { name: 'franquia_anoanterior', title: 'Franquia - Volume Ano Posterior' },
  { name: 'franquia_churn', title: 'Franquia - Churn (%)' },
  { name: 'franquia_incremental', title: 'Franquia - Alvo Incremental' },
  { name: 'broker_anoatual', title: 'Broker Volume Ano Atual' },
  { name: 'broker_anoanterior', title: 'Broker Volume Ano Posterior' },
  { name: 'broker_churn', title: 'Broker - Churn (%)' },
  { name: 'broker_incremental', title: 'Broker - Alvo Incremental' },
  { name: 'fws_anoatual', title: 'FWS Volume Ano Atual' },
  { name: 'fws_anoanterior', title: 'FWS Volume Ano Posterior' },
  { name: 'fws_churn', title: 'FWS - Churn (%)' },
  { name: 'fws_incremental', title: 'FWS - Alvo Incremental' }
]

const getSellersList = () => {
  const sellers = ls.get('sellers')
  const formattedSellers = sellers.map(seller => {
    return {
      label: seller.nome_vededor,
      value: seller.codigo_vededor
    }
  })
  return formattedSellers
}

const SectionTitle = ({ value }) => (
  <div className='tracking-widest text-md mt-4'>{value}</div>
)

const DialogAddCell = ({ open, setOpen, type, getCelulas }) => {
  const [selectedCell, setSelectedCell] = useAtom(selectedCellAtom)
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)

  const addCell = async () => {
    const perfis = ls.get('perfis')
    const json = {
      broker_anoanterior: Number(selectedCell.broker_anoanterior),
      broker_anoatual: Number(selectedCell.broker_anoatual),
      broker_churn: Number(selectedCell.broker_churn),
      broker_incremental: Number(selectedCell.broker_incremental),
      franquia_anoanterior: Number(selectedCell.franquia_anoanterior),
      franquia_anoatual: Number(selectedCell.franquia_anoatual),
      franquia_churn: Number(selectedCell.franquia_churn),
      franquia_incremental: Number(selectedCell.franquia_incremental),
      fws_anoanterior: Number(selectedCell.fws_anoanterior),
      fws_anoatual: Number(selectedCell.fws_anoatual),
      fws_churn: Number(selectedCell.fws_churn),
      fws_incremental: Number(selectedCell.fws_incremental),
      distribuidor: perfis[0].value,
      vendedor: selectedCell.vendedor.value
    }

    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_GRAVA_CELULA`,
      json
    )

    setTimeout(() => {
      setAlertParams({
        open: true,
        text: result.data.retorno,
        onClick: () => {
          getCelulas()
          setOpen(false)
          setAlertParams({ ...alertParams, open: false })
        }
      })
    })
  }

  const removeCell = async () => {
    const perfis = ls.get('perfis')
    const json = {
      distribuidor: perfis[0].value,
      vendedor: selectedCell.vendedor.value
    }

    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_EXCLUI_CELULA`,
      json
    )

    setTimeout(() => {
      setAlertParams({
        open: true,
        text: result.data.retorno,
        onClick: () => {
          getCelulas()
          setOpen(false)
          setAlertParams({ ...alertParams, open: false })
        }
      })
    })
  }

  const updateValues = e => {
    if (e.target) {
      setSelectedCell({
        ...selectedCell,
        [e.target.id]: e.target.value
      })
    } else {
      setSelectedCell({ ...selectedCell, vendedor: e })
    }
  }

  const tipo = ls.get('tipo')

  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle>
        {type === 'new' ? 'Adicionar novo' : 'Consultar'}
      </DialogTitle>
      <DialogContent>
        <div className='w-full'>
          <ReactSelect
            onChange={updateValues}
            value={selectedCell.vendedor}
            label='Vendedor *'
            id='vendedor'
            options={getSellersList()}
            allowSelectAll={false}
            placeholder='Selecione...'
            maxMenuHeight={200}
            isDisabled={type === 'edit' && tipo !== 'SUPERVISOR'}
          />
        </div>
        <SectionTitle value='FRANQUIA' />
        <div className='w-full flex flex-row gap-3'>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.franquia_anoanterior}
              label='Vol Ano Anterior'
              id='franquia_anoanterior'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.franquia_anoatual}
              label='Vol Ano Atual'
              id='franquia_anoatual'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.franquia_churn}
              label='Churn (%)'
              id='franquia_churn'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.franquia_incremental}
              label='Alvo Incremental'
              id='franquia_incremental'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
        </div>
        <SectionTitle value='BROKER' />
        <div className='w-full flex flex-row gap-3'>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.broker_anoanterior}
              label='Vol Ano Anterior'
              id='broker_anoanterior'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.broker_anoatual}
              label='Vol Ano Atual'
              id='broker_anoatual'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.broker_churn}
              label='Churn (%)'
              id='broker_churn'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.broker_incremental}
              label='Alvo Incremental'
              id='broker_incremental'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
        </div>
        <SectionTitle value='FWS' />
        <div className='w-full flex flex-row gap-3'>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.fws_anoanterior}
              label='Vol Ano Anterior'
              id='fws_anoanterior'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.fws_anoatual}
              label='Vol Ano Atual'
              id='fws_anoatual'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.fws_churn}
              label='Churn (%)'
              id='fws_churn'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
          <div className='w-1/4'>
            <InputForm
              onChange={updateValues}
              value={selectedCell.fws_incremental}
              label='Alvo Incremental'
              id='fws_incremental'
              disabled={type === 'edit' && tipo !== 'SUPERVISOR'}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {type === 'new' && (
          <Button variant='contained' color='primary' onClick={addCell}>
            <Save /> Salvar
          </Button>
        )}
        {type === 'edit' && ls.get('tipo') === 'SUPERVISOR' && (
          <>
            <Button variant='contained' color='primary' onClick={addCell}>
              <Save /> Salvar
            </Button>
            <Button variant='outlined' color='secondary' onClick={removeCell}>
              <Delete /> Excluir
            </Button>
          </>
        )}
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setOpen(false)}
        >
          <Cancel /> Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function Celulas() {
  const [openDialog, setOpenDialog] = useState(false)
  const [typeDialog, setTypeDialog] = useState('')
  const [data, setData] = useState([])
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [, setSelectedCell] = useAtom(selectedCellAtom)
  const [defaultCell] = useAtom(defaultCellAtom)

  const [defaultColumnWidths] = useState([
    { columnName: 'nome_vendedor', width: 260 },
    { columnName: 'franquia_anoatual', width: 180 },
    { columnName: 'franquia_anoanterior', width: 180 },
    { columnName: 'franquia_churn', width: 180 },
    { columnName: 'franquia_incremental', width: 180 },
    { columnName: 'broker_anoatual', width: 180 },
    { columnName: 'broker_anoanterior', width: 180 },
    { columnName: 'broker_churn', width: 180 },
    { columnName: 'broker_incremental', width: 180 },
    { columnName: 'fws_anoatual', width: 180 },
    { columnName: 'fws_anoanterior', width: 180 },
    { columnName: 'fws_churn', width: 180 },
    { columnName: 'fws_incremental', width: 180 }
  ])

  const getCelulas = async () => {
    const perfis = ls.get('perfis')
    const codigo_vededor = ls.get('codigo_vededor')
    const tipo = ls.get('tipo')
    const result = await Axios.post(
      `${perfis[0].url}/INT_SHELL_SPANCOP_BUSCA_CELULA`,
      {
        distribuidor: perfis[0].label,
        vendedor: tipo === 'SUPERVISOR' ? '' : selectedSeller.value,
        supervisor: tipo === 'SUPERVISOR' ? codigo_vededor : ''
      }
    )

    if (result.data.message) {
      setData([])
    } else {
      setData(result.data)
    }
  }

  useEffect(() => {
    getCelulas()
    //eslint-disable-next-line
  }, [])

  const editCell = row => {
    const filteredCell = data.find(
      d => d.supervisor === row.supervisor && d.vendedor === row.vendedor
    )

    const vendedor = {
      label: filteredCell.nome_vendedor,
      value: filteredCell.vendedor
    }
    setSelectedCell({ ...row, vendedor: vendedor })
    setTypeDialog('edit')
    setOpenDialog(true)
  }

  const RowComponent = ({ row, ...restProps }) => {
    return (
      <Table.Row
        {...restProps}
        // eslint-disable-next-line no-alert
        onClick={() => editCell(row)}
        key={Math.random()}
        style={{
          cursor: 'pointer'
        }}
      />
    )
  }

  return (
    <div>
      <div>
        <Paper>
          <Grid rows={data} columns={columns}>
            <Table rowComponent={RowComponent} />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow />
          </Grid>
        </Paper>
      </div>
      {ls.get('tipo') === 'SUPERVISOR' && (
        <div className='mt-4'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setSelectedCell(defaultCell)
              setTypeDialog('new')
              setOpenDialog(true)
            }}
          >
            <ControlPoint /> Adicionar
          </Button>
        </div>
      )}
      <DialogAddCell
        open={openDialog}
        setOpen={setOpenDialog}
        type={typeDialog}
        getCelulas={getCelulas}
      />
    </div>
  )
}
