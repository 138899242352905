import React from 'react'

export const renderButtons = (values, state, id, handler) => {
  const buttons = []
  const stateId = values.includes('Sim') ? state[id] : Number(state[id])

  values.forEach(i => {
    const btnClassExtended =
      i === stateId
        ? i === 'Sim' || i === 'Não'
          ? 'bg-red-700 hover:bg-red-800'
          : 'bg-gray-700 hover:bg-gray-800'
        : i < 7
        ? 'bg-red-700 hover:bg-red-800'
        : i >= 7 && i < 9
        ? 'bg-yellow-700 hover:bg-yellow-800'
        : i === 'Sim' || i === 'Não'
        ? 'bg-blue-700 hover:bg-blue-800'
        : 'bg-green-700 hover:bg-green-800'
    const btnClass = `h-10 px-4 m-1 text-indigo-100 transition-colors duration-150 rounded-md focus:shadow-outline ${btnClassExtended}`
    buttons.push(
      <button
        className={btnClass}
        value={i}
        id={id}
        onClick={handler}
        key={id + i}
      >
        {i}
      </button>
    )
  })

  return buttons
}
