import React from 'react'

import Logo from '../../assets/logo.png'

export default function Header() {
  return (
    <div>
      <nav className='bg-blue-500 p-2 mb-4'>
        <div className='flex flex-wrap content-start text-white'>
          <div className='mr-4 w-7'>
            <img src={Logo} width='50px' height='10%' alt='logo' />
          </div>
          <div className='flex-1 text-left'>
            <span className='text-left font-semibold text-3xl tracking-tight mr-2'>
              Shell & Parceiros
            </span>

            <span className='font-thin text-2xl'>Lig@dos em você</span>
          </div>
        </div>
      </nav>
    </div>
  )
}
