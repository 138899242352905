import { Button, Card } from '@material-ui/core'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { researchAtoms } from './Atoms'
import DistributorInfo from './DistributorInfo'
import Header from './Header'
import { distributors } from '../../constData/urls'
import Alert from '@material-ui/lab/Alert'
import Axios from 'axios'

export default function Unsubscribe(props) {
  const [research, setResearch] = useAtom(researchAtoms)
  const [status, setStatus] = useState('')

  const pathname = props.location.pathname.split('/')
  // eslint-disable-next-line
  const distributorId = pathname[2]
  const pesquisaId = pathname[3]

  useEffect(() => {
    document.title = 'Pesquisa de Satisfação'
  }, [])

  useEffect(() => {
    const getData = () => {
      const distributor = distributors.find(
        d => d.id === parseInt(distributorId)
      )
      const data = distributor.distribuidor.split(' / ')
      setResearch({
        ...research,
        id: distributorId,
        cancelamento: 'S',
        distributor_name: data[0],
        id_pesquisa: pesquisaId
      })
    }
    distributors && getData()
    // eslint-disable-next-line
  }, [distributors])

  const handleUnsubscribe = async () => {
    setStatus('processing')
    const { url } = distributors.find(
      distributor => distributor.id === Number(research.id)
    )

    const { data } = await Axios.post(
      `${url}INT_SHELL_RETORNO_PESQUISA`,
      research,
      {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
    )

    setTimeout(() => {
      setStatus(data.retorno)
    }, 2000)
  }

  return (
    <div>
      <Header />
      <Card>
        <DistributorInfo />
        <div className='px-6 py-4 flex justify-center'>
          Lamentamos que não queira mais receber nossos emails. Para prosseguir
          com o descadastramento, por favor, clique no botão abaixo.
        </div>
        <div className='px-6 py-4 flex justify-center'>
          <Button
            variant='contained'
            color='primary'
            onClick={handleUnsubscribe}
            className='w-9/12'
          >
            Me descadastre, por favor.
          </Button>
        </div>
        <div className='px-6 py-4 flex justify-center'>
          {status === 'processing' && (
            <Alert severity='warning'>Aguarde...</Alert>
          )}

          {status && status !== 'processing' && (
            <Alert severity='info'>{status}</Alert>
          )}
        </div>
      </Card>
    </div>
  )
}
