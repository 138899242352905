import { faCheck, faFileExcel, faSave } from '@fortawesome/free-solid-svg-icons'
import { useAtom } from 'jotai'
import React from 'react'
import { Fragment } from 'react'
import Button from '../Inputs/Button'
import { viewErrorsAtom } from '../_global/Atoms'
import ButtonImportExcel from '../_global/ButtonImportExcel'

export default function Buttons({
  user,
  loading,
  handleSave,
  handleCancel,
  handleExportClick,
  handleApprove
}) {
  // eslint-disable-next-line
  const [viewErrors, setViewErrors] = useAtom(viewErrorsAtom)

  return (
    <div className='mt-2 text-right'>
      {user === 'planejamento' ? (
        <Fragment>
          <Button
            label='Salvar'
            color='blue'
            textColor='white'
            icon={faSave}
            click={handleSave}
          />

          {/*<Button
            label='Cancelar'
            color='gray'
            textColor='white'
            click={handleCancel}
            icon={faTimesCircle}
          />*/}
          <Button
            label={
              loading
                ? 'Aguarde enquanto geramos o relatório...'
                : 'Exportar para Excel'
            }
            color='green'
            textColor='white'
            click={handleExportClick}
            icon={faFileExcel}
          />

          <ButtonImportExcel />
        </Fragment>
      ) : (
        <Button
          label='Aprovar'
          color='blue'
          textColor='white'
          icon={faCheck}
          click={handleApprove}
        />
      )}
    </div>
  )
}
