import React, { Fragment, useEffect } from 'react'
import { listMenuItems } from '../../constData/constData'
import Header from '../_global/Header'
import ls from 'local-storage'
import Accordion from '../_global/Accordion'
import New from './Form'
import { useAtom } from 'jotai'
import {
  customersAtom,
  customersLoadedAtom,
  loadedModuleAtom
} from '../_global/Atoms'
import List from './List'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import { loadTrackingCustomers } from '../../functions/functions'
import { distributors } from '../../constData/urls'
import DashboardCampaign from './Dashboard/Index'

const menuItems = listMenuItems(ls.get('user'), ls.get('perfil'))

const loadModule = loadedModule => {
  let module = null
  switch (loadedModule) {
    case 'NewCampaign':
      module = <New />
      break
    case 'ListCampaign':
      module = <List />
      break
    case 'ShowDashboard':
      module = <DashboardCampaign />
      break
    default:
      console.log('default')
      break
  }
  return module
}

export default function Index() {
  const user = ls.get('user')
  const [loadedModule] = useAtom(loadedModuleAtom)
  // eslint-disable-next-line
  const [customers, setCustomers] = useAtom(customersAtom)
  const [customersLoaded, setCustomersLoaded] = useAtom(customersLoadedAtom)
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    const localCustomers = ls.get('localCustomers')
    const getData = async () => {
      if (!localCustomers || localCustomers.length === 0) {
        const filteredDistributors = distributors.filter(
          distributor => distributor.url !== ''
        )
        const result = await trackPromise(
          loadTrackingCustomers(filteredDistributors)
        )

        const rst = result.data
          .filter(
            row =>
              row.data.length !== 0 &&
              row.data.message !==
                'The request has been fulfilled and resulted in a new resource being created.'
          )
          .reduce((arr, row) => arr.concat(row.data), [])

        setCustomers(rst)
        setCustomersLoaded(true)
        await ls.set('localCustomers', rst)
      } else {
        setCustomers(localCustomers)
        setCustomersLoaded(true)
      }
    }

    !customersLoaded && getData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='w-full h-full'>
      {!loadedModule && promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <Header
            label='BackOffice Shell - Marketing'
            title='Shell BackOffice - Marketing'
            user={user}
            exit={true}
          />
          <div className='m-2'>
            <div className='grid gap-2 grid-cols-12'>
              <div className='col-span-2 border-gray-400 border-2 rounded p-2 text-sm'>
                {menuItems.map(item => (
                  <Accordion
                    key={item.name}
                    title={item.label}
                    items={item.items}
                  />
                ))}
              </div>
              <div className='col-span-10 border-gray-400 border-2 rounded p-2 w-full'>
                {loadedModule && loadModule(loadedModule)}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}
