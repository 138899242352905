import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useState } from 'react'
import xlsxParser from 'xlsx-parse-json'
import { useAtom } from 'jotai'

import { rawApproveDataAtom, approveExtraAtom } from './Atoms'
import ConfirmDialog from './ConfirmDialog'

export default function ButtonImportExcel() {
  const [showConfirm, setShowConfirm] = useState(false)
  // eslint-disable-next-line
  const [process, setProcess] = useState(false)
  const [file, setFile] = useState('')
  // eslint-disable-next-line
  const [rawApproveData, setRawApproveData] = useAtom(rawApproveDataAtom)
  // eslint-disable-next-line
  const [approveExtraData, setApproveExtraData] = useAtom(approveExtraAtom)

  const handleChange = e => {
    setFile(e.target.files[0])
    setShowConfirm(true)
  }

  const parseFile = () => {
    xlsxParser.onFileSelection(file).then(data => processParsedData(data))
  }

  const processParsedData = data => {
    setShowConfirm(false)
    setRawApproveData([])
    const sheetKey = Object.keys(data)[0]
    const newData = data[sheetKey]

    const processData = newData.map((d, index) => {
      return {
        ...d,
        id: Number(d.id),
        limite: Number(d.limite),
        pedido: Number(d.pedido),
        extra: Number(d.extra),
        nova_trava: Number(d.nova_trava),
        subiu: 'S'
      }
    })

    setRawApproveData(processData)
  }

  return (
    <Fragment>
      <label
        htmlFor='file'
        className='bg-orange-500 hover:bg-yellow-700 text-white py-2 px-4 rounded text-sm mr-2 cursor-pointer'
      >
        <FontAwesomeIcon icon={faFileImport} /> Importar Excel
      </label>
      <input type='file' id='file' className='hidden' onChange={handleChange} />

      <ConfirmDialog
        open={showConfirm}
        title='Atenção'
        text={`Deseja processar o arquivo ${file.name}?`}
        onOK={() => {
          parseFile()
        }}
        onCancel={() => {
          setShowConfirm(false)
          setProcess(false)
        }}
      />
    </Fragment>
  )
}
