import React, { Redirect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Provider } from 'jotai'

import Login from './components/Login'
import Home from './components/Home'

import './assets/main.css'
import 'react-datepicker/dist/react-datepicker.css'
import Research from './components/Research/Research'
import ApproveExtra from './components/ApproveExtra/ApproveExtra'
import FeeEstoque from './components/FeeEstoque/FeeEstoque'
import Dashboard from './components/Dashboard.js'
import ls from 'local-storage'
import 'fontsource-roboto'
import Unsubscribe from './components/Research/Unsubscribe'
import Campaign from './components/Campaign/Index'
import LoginSupervisor from './components/LoginSupervisor'
import SupervisorMain from './components/Supervisor/SupervisorMain'
import PrintCallPlan from './components/Spancop/CallPlan/PrintCallPlan'

const isAuth = () => {
  const session = ls.get('session')

  if (!session) {
    window.location = '/'
    return false
  }

  return true
}

ReactDOM.render(
  <Provider>
    <BrowserRouter>
      <Switch>
        <Route path='/' exact={true} component={Login} />
        <Route
          path='/pesquisa/:distributor/:id'
          exact={true}
          component={Research}
        />
        <Route
          path='/unsubscribe-pesquisa/:distributor/:id'
          exact={true}
          component={Unsubscribe}
        />
        <Route
          path='/aprovacaoextra'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(ApproveExtra, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
        <Route
          path='/Home'
          render={props =>
            isAuth() ? React.createElement(Home, props) : <Redirect to='/' />
          }
        />
        <Route
          path='/feeestoque'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(FeeEstoque, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
        <Route
          path='/dashboard'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(Dashboard, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
        <Route
          path='/campaign'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(Campaign, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
        <Route
          path='/supervisor'
          exact={true}
          render={props => React.createElement(LoginSupervisor, props)}
        />
        <Route
          path='/supervisor/Home'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(SupervisorMain, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
        <Route
          path='/spancop/callplan/print/:id'
          exact={true}
          render={props =>
            isAuth() ? (
              React.createElement(PrintCallPlan, props)
            ) : (
              <Redirect to='/' />
            )
          }
        />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
