import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import {
  filterDataAtom,
  reportDataAtom,
  selectedRowsAtom
} from '../../../_global/Atoms'
import DataTable from '../DataTable'
import { distributors } from '../../../../constData/urls'
import Axios from 'axios'
import moment from 'moment'
import { secondLevelAtom } from './Atoms'

const columnsLevel1 = [
  { name: 'codigo_vededor', title: 'Cod' },
  { name: 'nome_vededor', title: 'Vendedor' },
  { name: 'base_clientes', title: 'Base de clientes no banco de dados' },
  { name: 'plano_visitas', title: 'Plano de Visitas' },
  { name: 'visitas_roteiro', title: 'Visitas realizadas no roteiro' },
  {
    name: 'perc_cump_plano_visitas',
    title: '% Cumprimento do plano de visitas'
  },
  { name: 'visitas_comvendas', title: 'Visitas com venda no roteiro' },
  {
    name: 'perc_ef_visitas_venda_roteiro',
    title: '% Efetividade de visitas no roteiro'
  },
  {
    name: 'visitas_naorealizadas',
    title: 'Visitas não realizadas no roteiro'
  },
  {
    name: 'visitas_foraroteiro',
    title: 'Visitas realizadas fora do roteiro'
  },
  { name: 'total_visitas_realizadas', title: 'Total visitas realizadas' },
  { name: 'pedidos', title: 'Total pedidos' },
  { name: 'penetracao_base', title: '% penetração na base' }
]

const columnsLevel2 = [
  { name: 'date', title: 'Data' },
  { name: 'clientes_roteiro', title: 'Clientes no Roteiro' },
  { name: 'visitados_roteiro', title: 'Visitados no Roteiro' },
  { name: 'efetivos_roteiro', title: 'Efetivos no Roteiro' },
  { name: 'visitados_fora_roteiro', title: 'Visitados fora do Roteiro' },
  { name: 'total_visitados', title: 'Total Visitados' },
  { name: 'total_efetivos', title: 'Total Efetivos' }
]

const columnsLevel3 = [
  { name: 'nome_cliente', title: 'Cliente' },
  { name: 'cnpj_cliente', title: 'CNPJ' },
  { name: 'codigo_cliente', title: 'Código Cliente' },
  { name: 'tipo_cliente', title: 'Tipo de Cliente' },
  { name: 'cidade_cliente', title: 'Cidade' },
  { name: 'uf_cliente', title: 'UF' },
  { name: 'status_visita', title: 'Status da Visita' },
  { name: 'data_inicial', title: 'Data do Início' },
  { name: 'data_final', title: 'Data da conclusão' },
  { name: 'tempo_visita', title: 'Tempo de Visita' },
  { name: 'distancia_km', title: 'Distância (KM)' },
  { name: 'resumo', title: 'Resumo' },
  { name: 'proximas_acoes', title: 'Próximas Ações' },
  { name: 'observacoes', title: 'Observações' }
]

export default function VisitPlan() {
  const [currentColumns, setCurrentColumns] = useState([])
  const [filterData] = useAtom(filterDataAtom)
  const [currentData, setCurrentData] = useState([])
  const [reportData] = useAtom(reportDataAtom)
  // eslint-disable-next-line
  const [secondLevelReportData, setSecondLevelReportData] = useState([])
  const [secondLevelProcessedData, setSecondLevelProcessedData] =
    useAtom(secondLevelAtom)
  const [selectedRows] = useAtom(selectedRowsAtom)

  const processLevel1 = data => {
    const newData = data.map(item => {
      const perc_cump_plano_visitas = Math.round(
        (item.visitas_roteiro / item.plano_visitas) * 100
      )
      const perc_ef_visitas_venda_roteiro = Math.round(
        (item.visitas_comvendas / item.plano_visitas) * 100
      )
      const total_visitas_realizadas =
        item.visitas_roteiro + item.visitas_foraroteiro
      return {
        ...item,
        perc_cump_plano_visitas: perc_cump_plano_visitas,
        perc_ef_visitas_venda_roteiro: perc_ef_visitas_venda_roteiro,
        total_visitas_realizadas: total_visitas_realizadas
      }
    })
    setCurrentData(newData)
  }

  const processLevel2 = async () => {
    const { codigo_vededor } = selectedRows.level1.row
    const { data_inicial, data_final, distribuidor } = filterData

    const distributorData = distributors.find(
      item => item.distribuidor === distribuidor[0].value
    )

    const { data } = await Axios.get(
      `${distributorData.url}INT_SHELL_APP_DETVISITAS?distribuidor=${
        distribuidor[0].value
      }&codigo_vend=${codigo_vededor}&data_inicial=${moment(
        data_inicial
      ).format('DD/MM/YYYY')}&data_final=${moment(data_final).format(
        'DD/MM/YYYY'
      )}`
    )

    setSecondLevelReportData(data)
    processReportLevel2(data)
  }

  const processReportLevel2 = data => {
    const newReportData = []
    data.forEach(item => {
      const findedIndex = newReportData.findIndex(
        d => d.date === item.data_inicial
      )
      if (findedIndex === -1) {
        newReportData.push({ date: item.data_inicial })
        const findedIndex = newReportData.findIndex(
          d => d.date === item.data_inicial
        )
        newReportData[findedIndex].values = []
        newReportData[findedIndex].values.push(item)
      } else {
        newReportData[findedIndex].values.push(item)
      }
    })

    const summarizeData = newReportData.map(item => {
      const clientes_roteiro = item.values
      const visitas_roteiro = item.values.filter(
        val => val.status_visita === 'Visita no roteiro'
      )
      const vendas_roteiro = item.values.filter(
        val => val.status_visita === 'Venda no roteiro'
      )
      const visitas_fora_roteiro = item.values.filter(
        val => val.status_visita === 'Fora de rota'
      )
      const vendas_fora_roteiro = item.values.filter(
        val => val.status_visita === 'Venda fora de rota'
      )

      return {
        date: item.date,
        clientes_roteiro: clientes_roteiro.length,
        visitados_roteiro: visitas_roteiro.length + vendas_roteiro.length,
        efetivos_roteiro: vendas_roteiro.length,
        visitados_fora_roteiro:
          visitas_fora_roteiro.length + vendas_fora_roteiro.length,
        total_visitados:
          visitas_roteiro.length +
          vendas_roteiro.length +
          visitas_fora_roteiro.length +
          vendas_fora_roteiro.length,
        total_efetivos: vendas_roteiro.length + vendas_fora_roteiro.length
      }
    })

    setCurrentData(summarizeData)
    setSecondLevelProcessedData(newReportData)
  }

  const processLevel3 = data => {
    const { date } = selectedRows.level2.row
    const reportItems = data.find(item => item.date === date)

    setCurrentData(reportItems.values)
  }

  useEffect(() => {
    switch (filterData.report_level) {
      case 1:
        setCurrentColumns(columnsLevel1)
        processLevel1(reportData)
        break
      case 2:
        setCurrentColumns(columnsLevel2)
        processLevel2()
        break
      case 3:
        setCurrentColumns(columnsLevel3)
        processLevel3(secondLevelProcessedData)
        break
      default:
        return false
    }
    // eslint-disable-next-line
  }, [filterData.report_level])
  return (
    <div className='mt-2'>
      <DataTable
        columns={currentColumns}
        rows={currentData}
        title='Plano de Visitas'
        report='visitPlan'
      />
    </div>
  )
}
