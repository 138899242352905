import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import Axios from 'axios'
import React, { useState } from 'react'
import AddCompanyForm from './AddCompanyForm'
import ls from 'local-storage'
import { companiesAtom, selectedSellerAtom } from '../Atoms'
import { useAtom } from 'jotai'
import { alertParamsAtom } from '../../_global/Atoms'
import { Cancel, Save } from '@material-ui/icons'

const AddCompanyDialog = ({
  open,
  setOpen,
  tipoNegocioList,
  setorList,
  segmentoList,
  big5List
}) => {
  const [insertCompanyData, setInsertCompanyData] = useState({})
  const [selectedSeller] = useAtom(selectedSellerAtom)
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [, setCompanies] = useAtom(companiesAtom)
  const insertCompany = async () => {
    const perfis = ls.get('perfis')

    await Axios.post(`${perfis[0].url}/INT_SHELL_SPANCOP_GRAVA_EMPRESA`, {
      distribuidor: perfis[0].value,
      vendedor: selectedSeller.value,
      nome: insertCompanyData.empresa,
      cnpj: '',
      tipo_negocio: insertCompanyData?.tipo_negocio?.value || '',
      setor: insertCompanyData?.setor.value || '',
      segmento: insertCompanyData?.segmento.value || '',
      big5: insertCompanyData?.big5?.value || ''
    })

    setAlertParams({
      open: true,
      text: 'Cliente Inserido!',
      onClick: () => {
        getCompanies()
        setTimeout(() => {
          setAlertParams({ ...alertParams, open: false })
          setOpen(false)
        }, 1000)
      }
    })
  }

  const getCompanies = async () => {
    const perfis = ls.get('perfis')
    const vendedor = ls.get('codigo_vededor')

    const result = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_EMPRESA`,
      {
        distribuidor: perfis[0].value,
        vendedor: vendedor,
        supervisor: ''
      }
    )
    if (!result.data.message) setCompanies(result.data)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Cadastro de Nova Empresa</DialogTitle>
      <DialogContent>
        <AddCompanyForm
          {...{
            insertCompanyData,
            setInsertCompanyData,
            tipoNegocioList,
            setorList,
            segmentoList,
            big5List
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setOpen(false)}
          startIcon={<Cancel />}
        >
          Cancelar
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={insertCompany}
          startIcon={<Save />}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCompanyDialog
