import { Table } from '@devexpress/dx-react-grid-material-ui'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai'
import React from 'react'
import { groupRowAtom, openGroupTargetsAtom } from '../_global/Atoms'

export const PercentCell = ({ onClick, ...restProps }) => {
  // eslint-disable-next-line
  const [openGroupTargets, setOpenGroupTargets] = useAtom(openGroupTargetsAtom)
  // eslint-disable-next-line
  const [groupRow, setGroupRow] = useAtom(groupRowAtom)
  const { column, value, row } = restProps

  const PercentSpan = ({ value }) => (
    <span>
      <FontAwesomeIcon
        icon={faCircle}
        className={
          value < 90
            ? 'text-red-400'
            : value >= 90 && value < 100
            ? 'text-yellow-400'
            : 'text-green-400'
        }
      />{' '}
      {value} %
    </span>
  )

  return (
    <Table.Cell
      {...restProps}
      onClick={
        column.name === 'grupo_clientes'
          ? () => {
              setGroupRow(row)
              setOpenGroupTargets(true)
            }
          : () => console.log('default')
      }
    >
      {column.name.includes('perc_') ? (
        <PercentSpan value={value} />
      ) : column.name === 'grupo_clientes' ? (
        <div className='hover:text-red-400 cursor-pointer'>{value}</div>
      ) : (
        value
      )}
    </Table.Cell>
  )
}
