import { bi } from './urls.js'
import ls from 'local-storage'
import { distributors } from '../constData/urls'
import _ from 'lodash'

export const getDistributors = () => {
  let distributors = []
  const perfisList = ls.get('perfis')
  const tipo = ls.get('tipo')
  const loadedModule = ls.get('loadedModule')

  let perfis
  if (
    loadedModule !== 'BOSpancopCallPlan' &&
    loadedModule !== 'OportunidadesSpancop'
  ) {
    perfis = perfisList.filter(perfil => perfil.id !== 86 && perfil.id !== 87)
  } else {
    perfis = perfisList
  }

  if (loadedModule !== 'BOFinancas') {
    perfis = perfis.filter(perfil => perfil.id !== 28)
  }

  if (tipo === 'SUPERVISOR') {
    distributors = perfis
  } else {
    let x = 0
    perfis.forEach(perfil => {
      const exp = perfil.distribuidor
        ? perfil.distribuidor.split('/')
        : perfil.label.split('/')

      perfil.distribuidor
        ? distributors.push({
            value: perfil.distribuidor,
            label: perfil.distribuidor,
            key: ++x,
            sortColumn: exp[0].trim() + exp[1].trim()
          })
        : distributors.push(perfil)
    })
  }

  const distributorsSorted = _.sortBy(distributors, [distr => distr.sortColumn])
  return distributorsSorted
}

export const getDistributorsWithoutFiliais = () => {
  let distributorsArray = []
  const perfis = distributors.filter(
    item => item.url !== '' && item.envelope_fee !== 'EURO'
  )
  let currentDistributor = ''

  perfis.forEach(perfil => {
    const exp = perfil.distribuidor.split('/')
    if (exp[0] !== currentDistributor) {
      distributorsArray.push({
        value: exp[0],
        label: exp[0],
        key: perfil.id,
        url: perfil.url,
        sortColumn: exp[0].trim()
      })
      currentDistributor = exp[0]
    }
  })

  const distributorsSorted = _.sortBy(distributorsArray, [
    distr => distr.sortColumn
  ])

  return distributorsSorted
}

export const getEnvelopes = () => {
  const perfis = ls.get('perfis')
  const distributors = []
  let x = 0
  let envelope = ''
  const sorted = _.sortBy(perfis, [perfil => perfil.envelope_fee])
  sorted.forEach(perfil => {
    if (envelope !== perfil.envelope_fee) {
      distributors.push({
        value: perfil.envelope_fee,
        label: perfil.envelope_fee,
        key: ++x
      })

      envelope = perfil.envelope_fee
    }
  })

  const distributorsSorted = _.sortBy(distributors, [distr => distr.value])

  return distributorsSorted
}

export const getTiposCliente = loadedModule => {
  const array = []
  const user = ls.get('user')

  if (
    user === 'marcos.donizete' ||
    user === 'frederico.petrucelli' ||
    user === 'guilherme.takamine'
  ) {
    array.push({ value: 'FWS', label: 'FWS', key: 4 })
  } else {
    if (
      loadedModule !== 'BONovo' &&
      loadedModule !== 'BONovo2' &&
      loadedModule !== 'BOPadrao' &&
      loadedModule !== 'BOFinancas' &&
      loadedModule !== 'BOFee' &&
      loadedModule !== 'FeeResumo' &&
      loadedModule !== 'FeeEstoque'
    ) {
      array.push({ value: '', label: 'Todos', key: 0 })
    }

    array.push(
      { value: 'B2B', label: 'B2B', key: 1 },
      { value: 'B2C', label: 'B2C', key: 2 },
      { value: 'VAREJO', label: 'VAREJO', key: 3 },
      { value: 'FWS', label: 'FWS', key: 4 },
      { value: 'FWS NN', label: 'FWS NN', key: 5 },
      { value: 'B2BJD', label: 'B2BJD', key: 6 }
    )
  }

  return array
}

export const getTiposMatrizDesempenho = () => {
  return [
    { value: '', label: 'Todos', key: 0 },
    { value: 'REVENDA', label: 'Revenda', key: 1 },
    { value: 'Varejo', label: 'Varejo', key: 2 }
  ]
}

export const getTiposNF = () => {
  return [
    { value: '', label: 'Todas', key: 1 },
    { value: 'Somente Bonificação', label: 'Somente Bonificação', key: 2 },
    { value: 'Exceto Bonificação', label: 'Exceto Bonificação', key: 3 }
  ]
}

export const listMonths = () => {
  return [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]
}

export const listDays = () => {
  return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
}

export const listSegments = () => {
  return [{ value: '', label: 'Todos', key: 1 }]
}

export const listStatusCliente = () => {
  return [
    { value: '', label: 'Todos', key: 1 },
    { value: 'Aberto', label: 'Aberto', key: 2 },
    { value: 'Fechado', label: 'Fechado', key: 3 }
  ]
}

export const listTipoClienteCoaching = () => {
  return [
    { value: '', label: 'Todos', key: 1 },
    { value: 'B2B', label: 'B2B', key: 2 },
    { value: 'REVENDA', label: 'Revenda', key: 3 },
    { value: 'VAREJO', label: 'Varejo', key: 4 }
  ]
}

export const listTipoProduto = () => {
  return [
    { value: '', label: 'Todos', key: 1 },
    { value: 'SH', label: 'SH', key: 2 },
    { value: 'AG', label: 'AG', key: 3 }
  ]
}

export const listMenuItems = (user, perfil) => {
  const mkt_allowed = ls.get('mkt_allowed')
  const mkt_profile = ls.get('mkt_profile')
  const menuItems = []
  const location = window.location.href.split('/').pop()

  if (location === 'Home') {
    if (
      user !== 'pricing' &&
      user !== 'planejamento' &&
      user !== 'rodolfo.godoy' &&
      user !== 'marcos.donizete' &&
      user !== 'frederico.petrucelli' &&
      user !== 'guilherme.takamine' &&
      perfil !== 'marketing'
    ) {
      menuItems.push(
        {
          name: 'dashboardPowerBI',
          label: 'Dashboard',
          items: [{ name: 'dashboard', label: 'Dashboard Gerencial' }]
        },
        {
          name: 'backoffice',
          label: 'BackOffice',
          items: [
            { name: 'BONovo2', label: 'BO Novo 2' },
            { name: 'BOScore', label: 'Score Varejo' },
            { name: 'ChecklistMotoClube', label: 'Checklist MotoClube' },
            {
              name: 'ChecklistClubeProfissional',
              label: 'Checklist Clube Profissional'
            },
            { name: 'CheckListVarejo', label: 'Checklist Varejo' },
            { name: 'BOCliente', label: 'Cliente' },
            { name: 'BOCoaching', label: 'Coaching' },
            { name: 'BOColetas', label: 'Coletas' },
            { name: 'BOCombo', label: 'Combo' },
            { name: 'BODashboardClube', label: 'Dashboard Clube *' },
            {
              name: 'BOMatinalVespertina',
              label: 'Det. Matinal Vespertina'
            },
            { name: 'BOFinancas', label: 'Finanças' },
            { name: 'BOHSSE', label: 'HSSE' },
            { name: 'BOJustificativaColeta', label: 'Justificativa Coleta' },

            { name: 'BOMatrizDesempenho', label: 'Matriz Desempenho' },
            { name: 'BOMovInternos', label: 'Movimentos Internos' },
            { name: 'BONovo', label: 'Vendas Novo' },
            { name: 'BOPadrao', label: 'Vendas Padrão' },
            { name: 'BOPedidos', label: 'Pedidos' },
            { name: 'BOPedidosPorSKU', label: 'Pedidos por SKU' },
            { name: 'BOPesquisaSatisfacao', label: 'Pesquisa Satisfação' },
            { name: 'BOProduto', label: 'Produtos' },
            { name: 'BOPromocoes', label: 'Promoções' },
            { name: 'BOSpancopCallPlan', label: 'Spancop CallPlan' },
            { name: 'BOTrocaOleo', label: 'Troca de Óleo' },
            { name: 'BOVisitaBPS', label: 'Visita BPS' },
            { name: 'OportunidadesSpancop', label: 'Oportunidades Spancop' }
          ]
        },
        {
          name: 'reports',
          label: 'Relatórios',
          items: [
            { name: 'RLEstoque', label: 'Estoque' },
            { name: 'RLTitulos', label: 'Títulos' }
          ]
        }
      )
    }

    if (user === 'rodolfo.godoy') {
      menuItems.push(
        {
          name: 'backoffice',
          label: 'BackOffice',
          items: [
            { name: 'BONovo2', label: 'BO Novo 2' },
            { name: 'BOCliente', label: 'Cliente' },
            { name: 'BOCoaching', label: 'Coaching' },
            { name: 'BOCombo', label: 'Combo' },
            { name: 'BOColetas', label: 'Coletas' },
            { name: 'BODashboardClube', label: 'Dashboard Clube *' },
            {
              name: 'BOMatinalVespertina',
              label: 'Det. Matinal Vespertina'
            },
            { name: 'BOFinancas', label: 'Finanças' },
            { name: 'BOHSSE', label: 'HSSE' },
            { name: 'BOJustificativaColeta', label: 'Justificativa Coleta' },
            { name: 'BOMatrizDesempenho', label: 'Matriz Desempenho' },
            { name: 'BOMovInternos', label: 'Movimentos Internos' },
            { name: 'BONovo', label: 'Vendas Novo' },
            { name: 'BOPadrao', label: 'Vendas Padrão' },
            { name: 'BOPedidos', label: 'Pedidos' },
            { name: 'BOPedidosPorSKU', label: 'Pedidos por SKU' },
            { name: 'BOPesquisaSatisfacao', label: 'Pesquisa Satisfação' },
            { name: 'BOProduto', label: 'Produtos' },
            { name: 'BOPromocoes', label: 'Promoções' },
            { name: 'BOSpancopCallPlan', label: 'Spancop CallPlan' },
            { name: 'BOTrocaOleo', label: 'Troca de Óleo' },
            { name: 'BOVisitaBPS', label: 'Visita BPS' },
            { name: 'OportunidadesSpancop', label: 'Oportunidades Spancop' }
          ]
        },
        {
          name: 'reports',
          label: 'Relatórios',
          items: [{ name: 'RLEstoque', label: 'Estoque' }]
        }
      )
    }

    if (
      user === 'marcos.donizete' ||
      user === 'frederico.petrucelli' ||
      user === 'guilherme.takamine'
    ) {
      menuItems.push(
        {
          name: 'backoffice',
          label: 'BackOffice',
          items: [
            { name: 'BONovo2', label: 'BO Novo 2' },
            { name: 'BOColetas', label: 'Coletas' },
            { name: 'BOFinancas', label: 'Finanças' },
            { name: 'BOJustificativaColeta', label: 'Justificativa Coleta' },
            { name: 'BOPedidos', label: 'Pedidos' },
            { name: 'BOPromocoes', label: 'Promoções' },
            { name: 'BOTrocaOleo', label: 'Troca de Óleo' }
          ]
        },
        {
          name: 'reports',
          label: 'Relatórios',
          items: [{ name: 'RLEstoque', label: 'Estoque' }]
        }
      )
    }

    if (user === 'regional.sul' || user === 'regional.norte') {
      menuItems.push({
        name: 'limitePC',
        label: 'Limite PC',
        items: [{ name: 'ApproveExtra', label: 'Aprovação' }]
      })
    }

    if (
      user === 'master' ||
      user === 'pricing' ||
      user === 'beatriz.coutinho' ||
      user === 'andrea.neder' ||
      user === 'flavio.costa' ||
      user === 'vanessa.felix'
    ) {
      menuItems.push({
        name: 'limitePC',
        label: 'Limite PC',
        items: [
          { name: 'LimitePC', label: 'Limite PC' },
          { name: 'ApproveExtra', label: 'Aprovação' }
        ]
      })
    }

    if (user === 'planejamento') {
      menuItems.push({
        name: 'approveExtra',
        label: 'Compras Extras',
        items: [{ name: 'ApproveExtra', label: 'Aprovação' }]
      })
    }

    if (perfil === 'master') {
      if (user === 'master') {
        menuItems.push({
          name: 'research',
          label: 'Pesquisa',
          items: [{ name: 'Research', label: 'Pesquisa' }]
        })
      }
      menuItems.push({
        name: 'feeestoque',
        label: 'Fee Estoque',
        items: [
          { name: 'BOFee', label: 'BO Fee' },
          { name: 'FeeEstoque', label: 'Fee Estoque' },
          { name: 'FeeResumo', label: 'Fee Resumo' }
        ]
      })
    }

    if (mkt_allowed) {
      menuItems.push({
        name: 'campaignModule',
        label: 'Campanhas',
        items: [
          { name: 'campaignModuleItem', label: 'Módulo de Campanhas' },
          { name: 'uploadFWS', label: 'Upload FWS' }
        ]
      })
    }

    if (user === 'master') {
      menuItems.push({
        name: 'usercontrol',
        label: 'Usuários',
        items: [{ name: 'UserControl', label: 'Controle de Usuários' }]
      })
    }
  }

  if (location === 'campaign') {
    if (mkt_allowed) {
      if (mkt_profile === 'root' || mkt_profile === 'mainlevel') {
        menuItems.push(
          {
            name: 'dashboardTracking',
            label: 'Dashboard Campanhas',
            items: [{ name: 'ShowDashboard', label: 'Dashboard' }]
          },
          {
            name: 'campaignMenu',
            label: 'Campanhas',
            items: [
              { name: 'NewCampaign', label: 'Nova Campanha' },
              { name: 'ListCampaign', label: 'Listar Campanhas' }
            ]
          }
        )
      }

      if (user === 'icam') {
        menuItems.push({
          name: 'limitePC',
          label: 'Limite PC',
          items: [{ name: 'LimitePC', label: 'Limite PC' }]
        })
      }

      if (user === 'planejamento') {
        menuItems.push({
          name: 'approveExtra',
          label: 'Compras Extras',
          items: [{ name: 'ApproveExtra', label: 'Aprovação' }]
        })
      }
      if (mkt_profile === 'sales') {
        menuItems.push({
          name: 'campaignMenu',
          label: 'Campanhas',
          items: [
            { name: 'NewCampaign', label: 'Nova Campanha' },
            { name: 'ListCampaign', label: 'Listar Campanhas' }
          ]
        })
      }

      if (mkt_profile === 'icam' || mkt_profile === 'dfbam') {
        menuItems.push({
          name: 'campaignMenu',
          label: 'Campanhas',
          items: [{ name: 'ListCampaign', label: 'Listar Campanhas' }]
        })
      }
    }
  }

  return menuItems
}

export const listSupervisorMenuItems = (tipo, segmento) => {
  const menuItems = []

  if (tipo === 'SUPERVISOR') {
    menuItems.push(
      {
        name: 'menuPanel',
        label: 'Painel',
        items: [
          { name: 'ControlPanel', label: 'Painel de Controle' },
          { name: 'Coaching', label: 'Coaching' }
        ]
      },
      {
        name: 'reportPanel',
        label: 'Relatórios',
        items: [
          { name: 'SupervisorOrderReports', label: 'Pedidos' },
          { name: 'SupervisorRevenueReports', label: 'Faturamento' },
          { name: 'SupervisorCoachingReports', label: 'Coaching' },
          // { name: 'MarginTracker', label: 'Acompanhamento Margem' },
          { name: 'VisitReports', label: 'Visitas' }
          // { name: 'SellerCollecting', label: 'Colatas por Vendedor' }
        ]
      }
    )
  }

  if (segmento === 'B2B' || segmento === 'FWS') {
    menuItems.push({
      name: 'spancopPanel',
      label: 'Spancop',
      items: [{ name: 'SpancopIndex', label: 'Spancop' }]
    })
  }
  return menuItems
}

export const listManagers = () => {
  return [{ value: '', label: 'Todos', key: 1 }]
}

export const listSuberbs = () => {
  return [{ value: '', label: 'Todos', key: 1 }]
}

export const listSellers = () => {
  return [{ value: '', label: 'Todos', key: 1 }]
}

export const listWarehouse = () => {
  const user = ls.get('user')
  if (
    user === 'rodolfo.godoy' ||
    user === 'marcos.donizete' ||
    user === 'guilherme.takamine'
  ) {
    return [{ value: '20', label: '20', key: 3 }]
  } else {
    return [
      { value: '', label: 'Todos', key: 1 },
      { value: '01', label: '01', key: 2 },
      { value: '20', label: '20', key: 3 }
    ]
  }
}

export const listSituation = () => {
  return [
    { value: 'A pagar', label: 'A pagar', key: 1 },
    { value: 'Vencido', label: 'Vencido', key: 2 },
    { value: 'Pago', label: 'Pago', key: 3 }
  ]
}

export const returnBIUrl = (user, perfil) => {
  let url = ''
  const urls = []
  switch (user) {
    case 'master':
      url = bi.master
      break
    case 'dellas':
      url = bi.dellas
      break
    case 'quite':
      url = bi.quite
      break
    case 'portolub':
      url = bi.portolub
      break
    case 'agricopel':
      url = bi.agricopel
      break
    case 'boanova':
      url = bi.boanova
      break
    case 'brazmax':
      url = bi.brazmax
      break
    case 'formula':
      url = bi.formula
      break
    case 'gamma':
      url = bi.gamma
      break
    case 'gasoleo':
      url = bi.gasoleo
      break
    case 'lubpar':
      url = bi.lubpar
      break
    case 'lubtrol':
      url = bi.lubtrol
      break
    case 'nacional':
      url = bi.nacional
      break
    case 'euro':
      url = bi.euro
      break
    case 'spm':
      url = bi.spm
      break
    case 'wesley.junior':
      urls.push(
        {
          url: bi.boanova,
          distribuidor: 'BOA NOVA'
        },
        {
          url: bi.nacional,
          distribuidor: 'NACIONAL'
        }
      )
      break
    case 'andre.oliveira':
      url = bi.andre_oliveira
      break
    case 'cleber.freitas':
      url = bi.andre_oliveira
      break
    case 'thomaz.santos':
      url = bi.thomaz_santos
      break
    case 'eduardo.moraes':
      urls.push(
        {
          url: bi.brazmax,
          distribuidor: 'BRAZMAX'
        },
        {
          url: bi.gasoleo,
          distribuidor: 'GASOLEO'
        },
        {
          url: bi.lubtrol,
          distribuidor: 'LUBTROL'
        },
        {
          url: bi.spm,
          distribuidor: 'SPM'
        }
      )
      break
    case 'rodolfo.godoy':
      urls.push(
        {
          url: bi.quite,
          distribuidor: 'QUITE'
        },
        {
          url: bi.spm,
          distribuidor: 'SPM'
        }
      )
      break
    case 'sandro.cattozzi':
      url = bi.sandro_cattozzi
      break
    case 'paulo.freitas':
      url = bi.master
      break
    case 'filipe.rodrigues':
      url = bi.filipe_rodrigues
      break
    case 'marcelo.faro':
      url = bi.marcelo_faro
      break
    case 'rodrigo.santos':
      urls.push(
        {
          url: bi.gasoleo,
          distribuidor: 'GASOLEO'
        },
        {
          url: bi.lubtrol,
          distribuidor: 'LUBTROL'
        }
      )
      break
    case 'ricardo.junior':
      urls.push({
        url: bi.agricopel,
        distribuidor: 'AGRICOPEL'
      })
      break
    default:
      url = perfil === 'master' ? bi.master : ''
  }

  return { single: url, urls }
}

export const tiposApuracao = [
  { value: 'bl', label: 'Bateu-Levou', key: 1 },
  { value: 'r', label: 'Ranking', key: 2 }
]

export const tiposAgrupamento = [
  { value: 'individual', label: 'Individual', key: 1 },
  { value: 'categoria', label: 'Categoria', key: 2 }
]

export const chave = [
  { value: 'cnpj', label: 'CNPJ', key: 1 },
  { value: 'grupo', label: 'Grupo de Clientes', key: 2 }
]

export const acompanhamentoPor = [
  { value: 'faturamento', label: 'Faturamento', key: 1 },
  { value: 'pedidos', label: 'Pedidos', key: 2 }
]

export const getSellerTableColumns = (indicador, objetivoOption) => {
  let columns = [
    { name: 'percentage', title: '%' },
    { name: 'vendedor', title: 'Cod' },
    { name: 'nome_vendedor', title: 'Vendedor' }
  ]

  if (!objetivoOption || objetivoOption.key === 1) {
    columns.push(
      { name: 'objetivo_mes', title: 'Objetivo Mês' },
      { name: 'real_mes', title: 'Realizado Mês' }
    )
  } else {
    columns.push(
      { name: 'objetivo_proporcional', title: 'Objetivo Proporcional' },
      { name: 'real_proporcional', title: 'Realizado Proporcional' }
    )
  }

  columns.push({ name: 'objetivo_dia', title: 'Realizar' })

  switch (indicador) {
    case 3:
      columns.push(
        { name: 'acordado_volume', title: 'Acordo Dia' },
        { name: 'real_dia', title: 'Realizado Dia' },
        { name: 'status_vendedor', title: 'Status Vendedor' },
        { name: 'vespertina_volume', title: 'Vespertina Volume' }
      )
      break
    case 4:
      columns.push(
        { name: 'acordado_premium', title: 'Acordo Dia' },
        { name: 'real_dia', title: 'Realizado Dia' },
        { name: 'status_vendedor', title: 'Status Vendedor' },
        { name: 'vespertina_premium', title: 'Vespertina Premium' }
      )
      break
    case 5:
      columns.push(
        { name: 'acordado_efetivos', title: 'Acordo Dia' },
        { name: 'real_dia', title: 'Realizado Dia' },
        { name: 'status_vendedor', title: 'Status Vendedor' },
        { name: 'vespertina_efetivos', title: 'Vespertina Efetivos' }
      )
      break
    default:
      columns.push({ name: 'real_dia', title: 'Realizado Dia' })
      break
  }

  return columns
}

export const indicatorsList = [
  {
    indicador: '001 - PLANO DE VISITAS',
    key: 1
  },
  {
    indicador: '002 - COLETAS DE PREÇOS',
    key: 2
  },
  {
    indicador: '003 - CLIENTE EFETIVOS SHELL',
    key: 3
  },
  {
    indicador: '006 - VOLUME PREMIUM SHELL',
    key: 6
  },
  {
    indicador: '007 - VOLUME TOTAL SHELL',
    key: 7
  }
]

export const monthSelect = () => {
  const now = new Date()

  const periods = []

  let j = 10

  for (let i = 0; i <= 11; i++) {
    const initialDate = new Date(now.getFullYear(), now.getMonth() - i, 1)
    const finalDate = new Date(now.getFullYear(), now.getMonth() - (i - 1), 0)
    const period = initialDate.toLocaleDateString('pt-BR', {
      month: 'long',
      year: 'numeric'
    })

    const formattedInitialDate = initialDate.toLocaleDateString('pt-BR')
    const formattedFinalDate = finalDate.toLocaleDateString('pt-BR')

    periods.push({
      value: {
        inicio: formattedInitialDate,
        inicioFull: initialDate,
        termino: formattedFinalDate,
        terminoFull: finalDate,
        periodo: period
      },
      label: `${period} - ${formattedInitialDate} a ${formattedFinalDate}`,
      key: j
    })
    j--
  }

  return periods
}

export const graphColumnParams = [
  {
    indicador: 3,
    fields: { objetivo: 'objetivo_efetivos', realizado: 'realizado_efetivos' }
  },
  {
    indicador: 4,
    fields: { objetivo: 'objetivo_premium', realizado: 'realizado_premium' }
  },
  {
    indicador: 7,
    fields: { objetivo: 'objetivo_volume', realizado: 'realizado_volume' }
  }
]

export const coachingQuestions = [
  {
    key: 'section_1',
    question: '1 - Execução no PDV - Visão do Consumidor',
    resp: []
  },
  {
    key: 'mercha',
    question: '1.1 - Merchandising',
    resp: []
  },
  {
    key: 'exposi',
    question: '1.2 - Exposição',
    resp: []
  },
  {
    key: 'precif',
    question: '1.3 - Precificação',
    resp: []
  },
  {
    key: 'section_2',
    question: '2 - Coaching 7V - Rotina Vendedor',
    resp: []
  },
  {
    key: 'planej',
    question: '2.1 - Verificar Planejamento PDV',
    resp: []
  },
  {
    key: 'verifi',
    question: '2.2 - Verificar Merchandise',
    resp: []
  },
  {
    key: 'verexp',
    question: '2.3 - Verificar Exposição',
    resp: []
  },
  {
    key: 'relchk',
    question: '2.4 - Verificar Check List',
    resp: []
  },
  {
    key: 'verest',
    question: '2.5 - Verificar Estoque',
    resp: []
  },
  {
    key: 'verprc',
    question: '2.6 - Verificar Preços',
    resp: []
  },
  {
    key: 'verpri',
    question: '2.7 - Vender Produtos Indicados',
    resp: []
  }
]

export const emptyCoachingResponses = {
  distribuidor: '',
  supervisor: '',
  vendedor: '',
  data_coaching: '',
  id_coaching: '',
  tipo: '',
  codigo_cliente: '',
  loja_cliente: '',
  mercha: '',
  exposi: '',
  precif: '',
  planej: '',
  verifi: '',
  verexp: '',
  relchk: '',
  verest: '',
  verprc: '',
  verpri: ''
}

export const SpancopCoachingQuestions = [
  {
    key: 'qualidade',
    question: '1 - Qualidade de Preparação (POPSA)',
    resp: []
  },
  {
    key: 'section_2',
    question: '2 - Utilização de Ferramentas de Venda de Valor (agrupamento)',
    resp: []
  },
  {
    key: 'folha',
    question: '2.1 - Folha de Descoberta (S/N/NA)',
    resp: []
  },
  {
    key: 'calculo',
    question: '2.2 -  Calculadora de Valor (S/N/NA)',
    resp: []
  },
  {
    key: 'proposta',
    question: '2.3 - Proposta de Valor Estruturada (S/N/NA)',
    resp: []
  },
  {
    key: 'dvrs',
    question: '2.4 -  DVRs (S/N/NA)',
    resp: []
  }
]

export const emptySpancopCoachingResponses = {
  distribuidor: '',
  supervisor: '',
  vendedor: '',
  data_coaching: '',
  id_coaching: '',
  tipo: '',
  codigo_cliente: '',
  loja_cliente: '',
  qualidade: '',
  //  utiliza: '',
  folha: '',
  calculo: '',
  proposta: '',
  dvrs: ''
}
