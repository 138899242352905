import { IconButton } from '@material-ui/core'
import { ZoomIn } from '@material-ui/icons'
import React from 'react'

const RankingFilterButton = ({ field, activeFilter, setActiveFilter }) => (
  <IconButton
    size='small'
    onClick={() => {
      if (activeFilter?.ranking === field) {
        setActiveFilter(false)
      } else {
        setActiveFilter(false)
        setActiveFilter({ ranking: field })
      }
    }}
  >
    <ZoomIn
      className={`${
        activeFilter?.ranking === field
          ? 'text-red-400 hover:text-gray-300'
          : 'text-gray-300 hover:text-red-400'
      }`}
    />
  </IconButton>
)

export default RankingFilterButton
