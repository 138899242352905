import { useAtom } from 'jotai'
import React, { Fragment } from 'react'
import Header from '../../_global/Header'
import { loadedModuleAtom } from '../../_global/Atoms'
import Filter from './Filter'

const loadHeader = module => {
  let label
  switch (module) {
    case 'SupervisorOrderReports':
      label = 'Gerencial: Pedidos'
      break
    case 'SupervisorRevenueReports':
      label = 'Gerencial: Faturamento'
      break
    case 'SupervisorCoachingReports':
      label = 'Gerencial: Coaching'
      break
    case 'VisitReports':
      label = 'Gerencial: Visitas'
      break
    default:
      label = ''
      break
  }

  return (
    <Header label={label} title='Shell BackOffice - Supervisor' exit={false} />
  )
}

export default function Index() {
  const [loadedModule] = useAtom(loadedModuleAtom)

  return (
    <Fragment>
      {loadHeader(loadedModule)}
      <Filter />
    </Fragment>
  )
}
