import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom, loadedModuleAtom } from '../_global/Atoms'
import InputForm from '../Inputs/InputForm'
import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'
import {
  listTipoProduto,
  listWarehouse,
  getTiposCliente
} from '../../constData/constData'

import {
  changeFilterField,
  updateSelect,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap mb-4'>
        <div className='w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            isMulti
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp de *'
            id='data_inicial'
            selected={filterData.data_inicial}
            onChange={e => {
              handleChange(e, 'data_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp ate *'
            id='data_final'
            selected={filterData.data_final}
            onChange={e => {
              handleChange(e, 'data_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_cliente', filterData, setFilterData)
            }}
            value={filterData.tipo_cliente}
            label='Tipo Cliente *'
            id='tipo_cliente'
            isMulti
            options={getTiposCliente(loadedModule)}
            placeholder='Selecione...'
            maxMenuHeight={150}
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <InputForm
            onChange={handleChange}
            value={filterData.cnpjs}
            label='CNPJ'
            id='cnpjs'
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'armazem', filterData, setFilterData)
            }}
            value={
              filterData.armazem
                ? filterData.armazem
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Armazém *'
            id='armazem'
            options={listWarehouse()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_prod', filterData, setFilterData)
            }}
            value={
              filterData.tipo_prod
                ? filterData.tipo_prod
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Tipo de Produto *'
            id='tipo_prod'
            options={listTipoProduto()}
            placeholder='Selecione...'
          />
        </div>
      </div>
    </form>
  )
}
