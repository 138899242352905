import {
  DataTypeProvider,
  EditingState,
  IntegratedSummary,
  SummaryState
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableInlineCellEditing,
  ExportPanel,
  Toolbar,
  TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui'
import { Button, Chip, Input, MenuItem, Paper, Select } from '@material-ui/core'
import { useAtom } from 'jotai'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { getSellerTableColumns } from '../../../constData/constData'
import {
  alertParamsAtom,
  filterDataAtom,
  indicadorAtom,
  loadedModuleAtom,
  supervisorRowsAtom
} from '../../_global/Atoms'
import SaveIcon from '@material-ui/icons/Save'
import AlertDialog from '../../_global/AlertDialog'
import ls from 'local-storage'
import Axios from 'axios'
import { distributors } from '../../../constData/urls'
import { indicatorsListAtom, objetivoOptionAtom, rawDataAtom } from './Atoms'
import { trackPromise } from 'react-promise-tracker'
import { retrieveData } from '../../../functions/functions'
import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'

const getRowId = row => row.vendedor

const FocusableCell = ({ onClick, ...restProps }) => {
  const { column } = restProps
  if (column.name !== 'vespertina' || column.name !== 'status_vendedor') {
    return (
      <Table.Cell
        {...restProps}
        tabIndex={0}
        onFocus={onClick}
        className='text-xs'
      />
    )
  }
}

const VespertinaFormatter = ({ value }) => (
  <Chip className='cursor-pointer' label={value ? value : 'Alterar'} />
)

const VespertinaEditor = ({ value, onValueChange, onBlur }) => (
  <Select
    input={<Input />}
    value={value}
    onChange={event => {
      onValueChange(event.target.value)
      setTimeout(() => {
        onBlur()
      }, 500)
    }}
    style={{ width: '100%' }}
  >
    <MenuItem value=''>Selecione</MenuItem>
    <MenuItem value='OK'>OK</MenuItem>
    <MenuItem value='NAO OK'>NÃO OK</MenuItem>
  </Select>
)

const VespertinaTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={VespertinaFormatter}
    editorComponent={VespertinaEditor}
    {...props}
  />
)

const StatusFormatter = ({ value }) => (
  <Chip className='cursor-pointer' label={value ? value : 'Alterar'} />
)

const StatusEditor = ({ value, onValueChange, onBlur }) => (
  <Select
    input={<Input />}
    value={value}
    onChange={event => {
      onValueChange(event.target.value)
      setTimeout(() => {
        onBlur()
      }, 500)
    }}
    style={{ width: '100%' }}
  >
    <MenuItem value='PRESENTE'>PRESENTE</MenuItem>
    <MenuItem value='AUSENTE'>AUSENTE</MenuItem>
    <MenuItem value='FERIAS'>FERIAS</MenuItem>
  </Select>
)

const StatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={StatusFormatter}
    editorComponent={StatusEditor}
    {...props}
  />
)

const onSave = workbook => {
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'DataGrid.xlsx'
    )
  })
}

const SellerTable = () => {
  const [indicador] = useAtom(indicadorAtom)
  const [columns, setColumns] = useState(false)
  const [rows, setRows] = useAtom(supervisorRowsAtom)
  const [defaultColumnWidths] = useState([
    { columnName: 'percentage', width: 100 },
    { columnName: 'vendedor', width: 80 },
    { columnName: 'nome_vendedor', width: 300 },
    { columnName: 'objetivo_mes', width: 70 },
    { columnName: 'objetivo_proporcional', width: 70 },
    { columnName: 'real_mes', width: 70 },
    { columnName: 'real_proporcional', width: 70 },
    { columnName: 'objetivo_dia', width: 70 },
    { columnName: 'acordado_volume', width: 100 },
    { columnName: 'acordado_premium', width: 100 },
    { columnName: 'acordado_efetivos', width: 100 },
    { columnName: 'real_dia', width: 110 },
    { columnName: 'status_vendedor', width: 100 },
    { columnName: 'vespertina_volume', width: 150 },
    { columnName: 'vespertina_premium', width: 150 },
    { columnName: 'vespertina_efetivos', width: 150 }
  ])
  const [editingStateColumnExtensions] = useState([
    { columnName: 'percentage', editingEnabled: false },
    { columnName: 'vendedor', editingEnabled: false },
    { columnName: 'nome_vendedor', editingEnabled: false },
    { columnName: 'objetivo_mes', editingEnabled: false },
    { columnName: 'objetivo_proporcional', editingEnabled: false },
    { columnName: 'real_mes', editingEnabled: false },
    { columnName: 'real_proporcional', editingEnabled: false },
    { columnName: 'objetivo_dia', editingEnabled: false },
    { columnName: 'real_dia', editingEnabled: false }
  ])
  const [vespertinaColumn] = useState([
    'vespertina_volume',
    'vespertina_premium',
    'vespertina_efetivos'
  ])
  const [statusColumn] = useState(['status_vendedor'])
  const [alertOpen, setAlertOpen] = useState(false)
  const [filterData] = useAtom(filterDataAtom)
  // eslint-disable-next-line
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [objetivoOption] = useAtom(objetivoOptionAtom)
  // eslint-disable-next-line
  const [indicatorsList, setIndicatorsList] = useAtom(indicatorsListAtom)
  // eslint-disable-next-line
  const [rawData, setRawData] = useAtom(rawDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [totalSummaryItems] = useState([
    { columnName: 'objetivo_mes', type: 'sum' },
    { columnName: 'objetivo_proporcional', type: 'sum' },
    { columnName: 'real_mes', type: 'sum' },
    { columnName: 'real_proporcional', type: 'sum' },
    { columnName: 'objetivo_dia', type: 'sum' },
    { columnName: 'acordado_volume', type: 'sum' },
    { columnName: 'acordado_premium', type: 'sum' },
    { columnName: 'acordado_efetivos', type: 'sum' },
    { columnName: 'real_dia', type: 'sum' }
  ])

  const sendData = async () => {
    const distributor = filterData.distribuidor
    const json = rows.map(row => {
      const codigo_vededor = ls.get('codigo_vededor')
      return {
        distribuidor: distributor[0].value,
        supervisor: codigo_vededor,
        vendedor: row.vendedor,
        acordado_volume: parseInt(row.acordado_volume),
        acordado_premium: parseInt(row.acordado_premium),
        acordado_efetivos: parseInt(row.acordado_efetivos),
        status_vendedor: row.status_vendedor ? row.status_vendedor : '',
        vespertina: row.vespertina ? row.vespertina : '',
        vespertina_volume: row.vespertina_volume ? row.vespertina_volume : '',
        vespertina_premium: row.vespertina_premium
          ? row.vespertina_premium
          : '',
        vespertina_efetivos: row.vespertina_efetivos
          ? row.vespertina_efetivos
          : ''
      }
    })

    const distributorData = distributors.find(
      item => item.distribuidor === distributor[0].value
    )
    const url = distributorData.url

    const { data } = await Axios.post(`${url}INT_SHELL_GRAVA_MATINAL`, json, {
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })

    let error = ''
    let success = ''
    data &&
      data.forEach(d => {
        if (d.retorno.search('sucesso') === -1) {
          error = 'Algumas linhas não foram gravadas.'
        } else {
          success = d.retorno
        }
      })

    if (error !== '') {
      setAlertParams({
        open: true,
        text: error,
        onClick: () => {
          setAlertParams(false)
        }
      })
      return false
    }

    setAlertParams({
      open: true,
      text: success,
      onClick: () => {
        setAlertParams(false)
        updateTable()
      }
    })
  }

  const makeIndicators = data => {
    return data.map(item => {
      const mx = item.descricao.split('-')
      return {
        indicador: item.descricao,
        key: Number(mx[0])
      }
    })
  }

  const updateTable = async () => {
    let data = []
    if (filterData.distribuidor?.length !== 0) {
      // eslint-disable-next-line
      data = await trackPromise(retrieveData(filterData, loadedModule))
    }

    if (data.length > 0) {
      const newIndicators = makeIndicators(data[0].alvos)
      setIndicatorsList(newIndicators)
      setRawData(data)
      // setIndicador(5)
    }
  }

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows

    if (changed) {
      changedRows = rows.map(row =>
        changed[row.vendedor] ? { ...row, ...changed[row.vendedor] } : row
      )
      setRows(changedRows)
    }

    // console.log(changed)
  }

  useEffect(() => {
    setColumns(getSellerTableColumns(indicador, objetivoOption))
  }, [indicador, objetivoOption])

  const exporterRef = useRef(null)

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const summaryItem = ({ value }) => {
    return value
  }

  return (
    <div className='w-9/12 border-gray-500 p-1'>
      {indicador ? (
        rows &&
        columns && (
          <Fragment>
            <Paper>
              <Grid rows={rows} columns={columns} getRowId={getRowId}>
                <SummaryState totalItems={totalSummaryItems} />
                <IntegratedSummary />
                <VespertinaTypeProvider for={vespertinaColumn} />
                <StatusTypeProvider for={statusColumn} />
                <EditingState
                  onCommitChanges={commitChanges}
                  columnExtensions={editingStateColumnExtensions}
                />
                <Table
                  cellComponent={FocusableCell}
                  messages={{ noData: 'Nenhum dado encontrado.' }}
                />
                <TableColumnResizing
                  defaultColumnWidths={defaultColumnWidths}
                />
                <TableHeaderRow />
                <TableSummaryRow itemComponent={summaryItem} />
                <Toolbar />
                <ExportPanel
                  startExport={startExport}
                  messages={{ exportAll: 'Exportar todos os dados' }}
                />
                <TableInlineCellEditing />
              </Grid>
              <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                onSave={onSave}
              />
            </Paper>
            {(indicador === 3 || indicador === 4 || indicador === 5) && (
              <div className='w-full mt-2 flex justify-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='w-3/4'
                  startIcon={<SaveIcon />}
                  onClick={sendData}
                >
                  Salvar Alterações
                </Button>
              </div>
            )}
          </Fragment>
        )
      ) : (
        <div>Selecione um indicador ao lado.</div>
      )}
      <AlertDialog
        open={alertOpen}
        text='Alterações salvas com sucesso.'
        onClick={() => setAlertOpen(false)}
      />
    </div>
  )
}

export default SellerTable
