import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import MuiDialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const DialogContentText = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 12
  }
}))(MuiDialogContentText)

export default function AlertDialog({ open, text, onClick }) {
  return (
    <Dialog open={open}>
      <DialogTitle>Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{ __html: text }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
