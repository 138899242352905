export const bi = {
  master:
    'https://app.powerbi.com/view?r=eyJrIjoiN2Y0M2RlNWEtZmI4ZS00ZTQ5LWEwMGQtOTNiNWQyYjIwMDA2IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  dellas:
    'https://app.powerbi.com/view?r=eyJrIjoiMTBiY2RmZjktZWNiOC00MmRkLTliNjEtYjEyMzdlNzcwNDhiIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  quite:
    'https://app.powerbi.com/view?r=eyJrIjoiZDM2MWNjZjEtMDRlNC00YzZlLTljZjktMTM5NDUwYjI2YmNlIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  portolub:
    'https://app.powerbi.com/view?r=eyJrIjoiZDJmYmQxMzYtZmYyMi00M2QzLTkwMzEtZWM2NWYwNDI3NWRjIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  agricopel:
    'https://app.powerbi.com/view?r=eyJrIjoiYWQ5NzdmMWEtODY3YS00NDUyLWFmMjgtYjhiY2M2YTlmN2JiIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  boanova:
    'https://app.powerbi.com/view?r=eyJrIjoiNzI4ZTliZDAtMTVmMi00NDhhLThjZDMtNTlhN2FhOWRjYWI4IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  brazmax:
    'https://app.powerbi.com/view?r=eyJrIjoiMDA1NzZiYzItOGFlZC00ZTUyLWI0YzEtZjYwZTJiMTNhNDNkIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  formula:
    'https://app.powerbi.com/view?r=eyJrIjoiMmU2Nzg2NDktMDllZi00MTMzLWFkNzAtMjcyNzlhYjViMzBmIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  gamma:
    'https://app.powerbi.com/view?r=eyJrIjoiMDEyYTkwMTUtN2ExOS00MTkwLWE5NTUtNmUwNWU5ZTg1ZTRiIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  gasoleo:
    'https://app.powerbi.com/view?r=eyJrIjoiOGRkMWMzMzktNzdiNy00Zjg1LTkyMjctNDM0N2YzNTA4ZmQxIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  lubpar:
    'https://app.powerbi.com/view?r=eyJrIjoiNGIzMjI3NmUtYjM0NC00YWM2LWEyYjktMTNmNjBhZTljYjVlIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  lubtrol:
    'https://app.powerbi.com/view?r=eyJrIjoiOWQ1MWM0ZmMtNmFiZS00NzIwLWEwMTQtMGNkZjM5MjdlMWI5IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  nacional:
    'https://app.powerbi.com/view?r=eyJrIjoiODY4NmMyMmEtYzliNS00OTY0LWIwZmYtZjJjNTQ2MDE2MTYwIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  felipe_rocha:
    'https://app.powerbi.com/view?r=eyJrIjoiYWQ5NzdmMWEtODY3YS00NDUyLWFmMjgtYjhiY2M2YTlmN2JiIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  andre_oliveira:
    'https://app.powerbi.com/view?r=eyJrIjoiN2Y0M2RlNWEtZmI4ZS00ZTQ5LWEwMGQtOTNiNWQyYjIwMDA2IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  // thomaz_santos = master
  thomaz_santos:
    'https://app.powerbi.com/view?r=eyJrIjoiN2Y0M2RlNWEtZmI4ZS00ZTQ5LWEwMGQtOTNiNWQyYjIwMDA2IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  sandro_cattozzi:
    'https://app.powerbi.com/view?r=eyJrIjoiZDM2MWNjZjEtMDRlNC00YzZlLTljZjktMTM5NDUwYjI2YmNlIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  paulo_freitas:
    'https://app.powerbi.com/view?r=eyJrIjoiMTBiY2RmZjktZWNiOC00MmRkLTliNjEtYjEyMzdlNzcwNDhiIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  filipe_rodrigues:
    'https://app.powerbi.com/view?r=eyJrIjoiN2Y0M2RlNWEtZmI4ZS00ZTQ5LWEwMGQtOTNiNWQyYjIwMDA2IiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  marcelo_faro:
    'https://app.powerbi.com/view?r=eyJrIjoiNGIzMjI3NmUtYjM0NC00YWM2LWEyYjktMTNmNjBhZTljYjVlIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9',
  euro: '',
  spm: 'https://app.powerbi.com/view?r=eyJrIjoiMzA5ZTUwNWMtNzhlNC00ZjZjLTk3YjctZDYxMTQ1ODZmYjFmIiwidCI6IjExMDM3MDQ3LWI1MDQtNGQyMi1hZjhhLTFhZTRjOTIyZjI3NyJ9'
}

export const distributors = [
  {
    distribuidor: 'AGRICOPEL / SC',
    envelope_fee: 'AGRICOPEL',
    id: 68,
    url: 'https://protheus.agricopel.com.br:1778/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'agricopel',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'BOA NOVA / SP',
    envelope_fee: 'BOA NOVA',
    id: 76,
    url: 'https://boanova-prd-protheus.totvscloud.com.br:13529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'boanova',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'wesley.junior',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'BRAZMAX / BA',
    envelope_fee: 'BRAZMAX',
    id: 61,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'brazmax',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'BRAZMAX / BA / LEM',
    envelope_fee: 'BRAZMAX',
    id: 63,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'brazmax',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'BRAZMAX / SE',
    envelope_fee: 'BRAZMAX',
    id: 62,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'brazmax',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'DELLAS / MG',
    envelope_fee: 'DELLAS',
    id: 12,
    url: 'https://dellas-prd-protheus.totvscloud.com.br:13827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'dellas',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'DELLAS / MG / CONTAGEM',
    envelope_fee: 'DELLAS',
    id: 11,
    url: 'https://dellas-prd-protheus.totvscloud.com.br:13827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'dellas',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'NACIONAL / ES',
    envelope_fee: 'NACIONAL',
    id: 81,
    url: 'https://nacional-prd-protheus.totvscloud.com.br:12529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'nacional',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'wesley.junior',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'FORMULA MATRIZ / DF',
    id: 31,
    url: '',
    access_profiles: []
  },
  {
    distribuidor: 'FORMULA / DF',
    envelope_fee: 'FORMULA / DF',
    id: 32,
    url: 'https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'formula',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'FORMULA / TO',
    envelope_fee: 'FORMULA / TO',
    id: 33,
    url: 'https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'formula',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'FORMULA / TO / ARMAZEM',
    id: 37,
    url: '',
    access_profiles: []
  },

  {
    distribuidor: 'FORMULA / MT',
    envelope_fee: 'FORMULA / MT',
    id: 34,
    url: 'https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'formula',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'FORMULA / GO',
    envelope_fee: 'FORMULA / GO',
    id: 35,
    url: 'https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'formula',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'FORMULA / MS',
    envelope_fee: 'FORMULA / MS',
    id: 36,
    url: 'https://goiaslub-prd-protheus.totvscloud.com.br:15809/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'formula',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'GAMMA / PR',
    envelope_fee: 'GAMMA',
    id: 66,
    url: 'https://gamma-prd-protheus.totvscloud.com.br:11824/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'gamma',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'GASOLEO / AL',
    envelope_fee: 'GASOLEO',
    id: 74,
    url: 'https://gasoleo-prd-protheus.totvscloud.com.br:11529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'gasoleo',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'GASOLEO / PE',
    envelope_fee: 'GASOLEO',
    id: 71,
    url: 'https://gasoleo-prd-protheus.totvscloud.com.br:11529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'gasoleo',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'GASOLEO / PB',
    envelope_fee: 'GASOLEO',
    id: 72,
    url: 'https://gasoleo-prd-protheus.totvscloud.com.br:11529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'gasoleo',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'GASOLEO / RN',
    envelope_fee: 'GASOLEO',
    id: 73,
    url: 'https://gasoleo-prd-protheus.totvscloud.com.br:11529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'gasoleo',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBPAR / SP',
    envelope_fee: 'LUBPAR / SP',
    id: 43,
    url: 'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubpar',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBPAR / SP / GRANEL',
    envelope_fee: 'LUBPAR / SP',
    id: 41,
    url: 'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubpar',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBPAR / RJ',
    envelope_fee: 'LUBPAR / SP',
    id: 45,
    url: 'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubpar',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBPAR / ES',
    envelope_fee: 'LUBPAR / SP',
    id: 46,
    url: 'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubpar',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBTROL / CE',
    envelope_fee: 'LUBTROL',
    id: 21,
    url: 'https://lubtrol-prd-protheus.totvscloud.com.br:12829/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubtrol',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBTROL / PI',
    envelope_fee: 'LUBTROL',
    id: 22,
    url: 'https://lubtrol-prd-protheus.totvscloud.com.br:12829/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubtrol',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'LUBTROL / FILIAL / PI',
    envelope_fee: 'LUBTROL',
    id: 23,
    url: 'https://lubtrol-prd-protheus.totvscloud.com.br:12829/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'lubtrol',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'rodrigo.santos',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'PORTOLUB / RS',
    envelope_fee: 'PORTOLUB',
    id: 26,
    url: 'https://portolub-prd-protheus.totvscloud.com.br:17824/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'portolub',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'QUITE / SP',
    envelope_fee: 'QUITE',
    id: 16,
    url: 'https://quite-prd-protheus.totvscloud.com.br:14808/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'quite',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },

  {
    distribuidor: 'SPM / FILIAL / AP',
    envelope_fee: 'SPM AM / AP / RR',
    url: 'https://pmz-prd-protheus.totvscloud.com.br:14529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'spm',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'SPM / MATRIZ / AM',
    envelope_fee: 'SPM AM / AP / RR',
    id: 91,
    url: 'https://pmz-prd-protheus.totvscloud.com.br:14529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'spm',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'SPM / MATRIZ / PA',
    envelope_fee: 'SPM PA / MA',
    id: 92,
    url: 'https://pmz-prd-protheus.totvscloud.com.br:14529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'spm',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'SPM / MA',
    envelope_fee: 'SPM PA / MA',
    url: 'https://pmz-prd-protheus.totvscloud.com.br:14529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'spm',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'SPM / RR',
    envelope_fee: 'SPM AM / AP / RR',
    url: 'https://pmz-prd-protheus.totvscloud.com.br:14529/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'spm',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'marketing',
      'tracking'
    ]
  },
  {
    distribuidor: 'EURO / PA',
    envelope_fee: 'EURO',
    id: 51,
    url: 'https://kwg47a-prd-protheus.totvscloud.com.br:16827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine'
    ]
  },
  {
    distribuidor: 'EURO / MA',
    envelope_fee: 'EURO',
    id: 52,
    url: 'https://kwg47a-prd-protheus.totvscloud.com.br:16827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine'
    ]
  },
  {
    distribuidor: 'EURO / AP',
    envelope_fee: 'EURO',
    id: 53,
    url: 'https://kwg47a-prd-protheus.totvscloud.com.br:16827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine'
    ]
  },
  {
    distribuidor: 'EURO / AM',
    envelope_fee: 'EURO',
    id: 54,
    url: 'https://kwg47a-prd-protheus.totvscloud.com.br:16827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine'
    ]
  },
  {
    distribuidor: 'EURO / RR',
    envelope_fee: 'EURO',
    id: 55,
    url: 'https://kwg47a-prd-protheus.totvscloud.com.br:16827/rest/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine'
    ]
  },
  {
    distribuidor: 'CAIRU / CAIRU',
    id: 28,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: ['master']
  },
  {
    distribuidor: 'BIOAR / BIOAR',
    envelope_fee: 'BIOAR',
    id: 86,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'tracking'
    ]
  },
  {
    distribuidor: 'HIDROCARB / HIDROCARB',
    envelope_fee: 'HIDROCARB',
    id: 87,
    url: 'https://rq6hue-prd-protheus.totvscloud.com.br:18828/rest_prd/',
    access_profiles: [
      'master',
      'rodolfo.godoy',
      'planejamento',
      'pricing',
      'paulo.freitas',
      'eduardo.moraes',
      'marcos.donizete',
      'frederico.petrucelli',
      'guilherme.takamine',
      'tracking'
    ]
  }
]
