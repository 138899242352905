import c3 from 'c3'

export default volumeByMonth => {
  c3.generate({
    bindto: '#volumeByMonth',
    data: {
      x: 'x',
      columns: volumeByMonth,
      type: 'bar'
    },
    axis: {
      x: {
        type: 'category'
      }
    }
  })
}
