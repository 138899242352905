import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import {
  listCampaignMonths,
  loadFinanceData,
  unmaskCnpj
} from '../../functions/functions'
import {
  currentCampaignAtom,
  customersAtom,
  tempCampaignListAtom
} from '../_global/Atoms'
import ls from 'local-storage'
import TransitionComponent from './TransitionComponent'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import TopBar from './TopBar'
import { LoadingIndicator } from '../_global/LoadingIndicator'
import CampaignHeaderSection from './CampaignHeaderSection'
import { Fragment } from 'react'
import { Alert } from '@material-ui/lab'
import IndividualTable from './Tables/IndividualTable'
import { campaignMonthsAtom } from './CampaignAtoms'
import { Close } from '@material-ui/icons'

const MonthsSection = ({ campaignMonths, handleMonthChange }) => {
  return (
    <div className='border border-gray-300 rounded-md flex justify-between p-2 m-4 items-center'>
      <div>Meses</div>
      {campaignMonths?.map(({ month, checked }, index) => (
        <div key={Math.random()}>
          {month}{' '}
          <Checkbox
            checked={checked}
            id={`alvo_${index}`}
            onChange={handleMonthChange}
          />
        </div>
      ))}
    </div>
  )
}

const ErrorsDialog = ({ open, setOpen, errors }) => (
  <Dialog open={open}>
    <DialogTitle>Lista de endpoints com erro</DialogTitle>
    <DialogContent>
      {errors.map(error => {
        const jsonError = JSON.parse(error.config.data)
        return (
          <Alert severity='error' className='my-1' key={Math.random()}>
            <div>Distribuidor: {jsonError.distribuidor}</div>
            <div>URL: {error.config.url}</div>
            <div>Erro: {error.message}</div>
          </Alert>
        )
      })}
    </DialogContent>
    <DialogActions>
      <Button
        color='secondary'
        startIcon={<Close />}
        onClick={() => setOpen(false)}
      ></Button>
    </DialogActions>
  </Dialog>
)

export const CampaignResults = ({ open, setOpen, id }) => {
  const [campaignList] = useAtom(tempCampaignListAtom)
  const [currentCampaign, setCurrentCampaign] = useAtom(currentCampaignAtom)
  const [financeData, setFinanceData] = useState([])
  const [resultData, setResultData] = useState([])
  const [filteredResultData, setFilteredResultData] = useState([])
  const [error, setError] = useState(false)
  const { promiseInProgress } = usePromiseTracker()
  const [activeFilter, setActiveFilter] = useState(false)
  const [alvoHasId, setAlvoHasId] = useState(false)
  const [rawData, setRawData] = useState([])
  const [customers] = useAtom(customersAtom)
  const [campaignMonths, setCampaignMonths] = useAtom(campaignMonthsAtom)
  const [errors, setErrors] = useState([])
  const [viewErrors, setViewErrors] = useState(false)

  useEffect(() => {
    if (open) {
      setCurrentCampaign({})
      setTimeout(() => {
        const findedCampaign = campaignList.find(campaign => campaign.id === id)
        setCurrentCampaign(findedCampaign)
        loadMonths(findedCampaign)
      }, 500)
    }
    // eslint-disable-next-line
  }, [open])

  const loadMonths = campaign => {
    if (Object.keys(campaign).length > 0) {
      const months = listCampaignMonths(campaign)
      const newMonths = months.map((item, index) => {
        return { ...item, checked: true, key: index }
      })
      setCampaignMonths(newMonths)
    }
  }

  const handleMonthChange = e => {
    const { id, checked } = e.target
    const index = parseInt(id.split('_')[1])
    const updatedMonths = [...campaignMonths]
    updatedMonths[index].checked = checked
    setCampaignMonths(updatedMonths)
  }

  useEffect(() => {
    if (!activeFilter) {
      setFilteredResultData(resultData)
    } else {
      let newFilter

      if (activeFilter.faixa) {
        newFilter = resultData.filter(
          item => item.perc_total >= activeFilter.faixa
        )
        setFilteredResultData(newFilter)
      }

      if (activeFilter.ranking) {
        if (currentCampaign.tipo_agrupamento.value === 'categoria') {
          const sortedData = _.orderBy(
            resultData,
            ['categoria', 'perc_total'],
            ['desc', 'desc']
          )

          const categoriesArray = sortedData.map(s => s.categoria)

          const uniqueCategories = categoriesArray.filter(
            (v, i, a) => a.indexOf(v) === i
          )

          const unionArray = []
          uniqueCategories.forEach(unique => {
            const topData = sortedData
              .filter(sorted => sorted.categoria === unique)
              .slice(0, parseInt(activeFilter.ranking))

            topData.forEach(t => unionArray.push(t))
          })

          newFilter = unionArray
        } else {
          newFilter = resultData
            .sort((a, b) => b.perc_total - a.perc_total)
            .slice(0, parseInt(activeFilter.ranking))
        }
        setFilteredResultData(newFilter)
      }
    }
    // eslint-disable-next-line
  }, [activeFilter])

  useEffect(() => {
    Object.keys(currentCampaign).length > 0 && getFinanceData()
    // eslint-disable-next-line
  }, [currentCampaign])

  const getGroupData = () => {
    const currentAlvo = currentCampaign?.alvos ? currentCampaign.alvos[0] : null
    // const groupValues = currentAlvo
    //   ?.map(alvo => {
    //     const currentCNPJ = unmaskCnpj(alvo.cnpj)
    //     const findedCustomer = customers.find(c => c.cnpj === currentCNPJ)

    //     return (
    //       `${findedCustomer?.grupo_codigo} - ${findedCustomer?.grupo_descricao}` ||
    //       ''
    //     )
    //   })
    //   .filter((v, i, a) => a.indexOf(v) === i)
    const groupValues = currentAlvo?.map(alvo => {
      return alvo?.grupo
    })
    return groupValues
  }

  const makeItemsJSON = alvo => {
    const currentCNPJ = unmaskCnpj(alvo.cnpj)
    const findedCustomer = customers.find(c => c.cnpj === currentCNPJ)
    const findedLine = financeData.find(f => f.cnpj === currentCNPJ)

    const campaignAlvos = currentCampaign.alvos.map(cc =>
      cc.find(item => item.cnpj === currentCNPJ)
    )

    const findId = currentCampaign?.alvos?.find(alvo =>
      alvo?.find(item => item.custom_id !== '')
    )

    setAlvoHasId(findId?.length > 0)
    const json = {
      distribuidor: findedCustomer?.distribuidor,
      cod_vend1: findedLine?.cod_vend1,
      nome_vend1: findedLine?.nome_vend1,
      cnpj: currentCNPJ,
      ibm_shell: findedLine?.ibm_shell
    }

    if (findId?.length > 0) {
      json['custom_id'] = alvo.custom_id
    }

    json['municipio'] = findedCustomer?.cidade
    json['estado'] = findedCustomer?.estado
    json['dealer_name'] = findedCustomer?.razao_social || ''
    json['grupo'] = alvo.grupo

    const { data_inicial, tipo_agrupamento } = currentCampaign

    if (tipo_agrupamento?.value === 'categoria') {
      json['categoria'] = alvo?.categoria
    }

    let alvo_total = 0
    let faturado_total = 0

    campaignAlvos.forEach((alvo, i) => {
      const inicio = new Date(
        data_inicial.getFullYear(),
        data_inicial.getMonth() + i,
        1
      )
      const termino = new Date(
        data_inicial.getFullYear(),
        data_inicial.getMonth() + (i + 1),
        0
      )

      const dataByCNPJ = financeData.filter(f => f.cnpj === currentCNPJ)
      const reduceFaturado = dataByCNPJ
        .filter(f => {
          const mx = f.emissao.split('/')
          const emissaoDateObject = new Date(`20${mx[2]}`, mx[1] - 1, mx[0])
          return (
            Date.parse(emissaoDateObject) >= Date.parse(inicio) &&
            Date.parse(emissaoDateObject) <= Date.parse(termino)
          )
        })
        .reduce((acc, current) => acc + current.vol_total, 0)

      const intAlvo = parseInt(alvo.alvo)
      json[`alvo_${i}`] = intAlvo
      json[`faturado_${i}`] = Math.round(reduceFaturado)
      json[`perc_${i}`] =
        reduceFaturado === 0 ? 0 : Math.round((reduceFaturado / intAlvo) * 100)

      alvo_total += intAlvo
      faturado_total += reduceFaturado
    })

    json['alvo_total'] = alvo_total
    json['faturado_total'] = faturado_total
    json['perc_total'] =
      faturado_total === 0 ? 0 : Math.round((faturado_total / alvo_total) * 100)

    if (currentCampaign?.sku === 'SIM') {
      const reduceSKU = financeData
        .filter(f => f.cnpj === currentCNPJ)
        .filter(f => f.produto === currentCampaign?.sku_number)
        .reduce((acc, current) => acc + current.quantidade, 0)

      json['sku_ok'] = reduceSKU
    }

    return json
  }

  const rankItems = array => {
    let jsonRanking

    if (
      currentCampaign?.tipo_apuracao
        ?.filter(tipo => tipo)
        .find(t => t.value === 'r')
    ) {
      let i = 0
      jsonRanking = array
        .sort((a, b) => b.perc_total - a.perc_total)
        .map(j => {
          i++
          return {
            ...j,
            ranking_pos: i
          }
        })
    } else {
      jsonRanking = array
    }

    return jsonRanking
  }

  useEffect(() => {
    if (_.isArray(financeData) && financeData.length > 0 && currentCampaign) {
      if (currentCampaign.chave?.value === 'grupo') {
        const groups = getGroupData()

        const groupValues = groups.map(group => {
          const mainJSON = {
            municipio: '',
            estado: '',
            dealer_name: '',
            grupo: group,
            distribuidor: '',
            cod_vend1: '',
            nome_vend1: '',
            cnpj: '',
            ibm_shell: '',
            alvo_total: 0,
            faturado_total: 0,
            perc_total: 0,
            custom_id: '',
            categoria: '',
            items: [],
            rawItems: []
          }

          let alvoTotal = 0
          let faturadoTotal = 0

          const alvoCustomers = currentCampaign?.alvos[0]

          const filteredItems = alvoCustomers
            .map((alvo, i) => {
              const processedJSON = makeItemsJSON(alvo)
              return processedJSON
            })
            .filter(item => item.grupo === group)

          currentCampaign.alvos.forEach((alvo, i) => {
            const findedMonth = campaignMonths.find(item => item.key === i)
            if (!findedMonth?.checked) {
              return
            }
            const alvoItem = filteredItems.reduce(
              (acc, item) => acc + item[`alvo_${i}`],
              0
            )
            const faturadoItem = filteredItems.reduce(
              (acc, item) => acc + item[`faturado_${i}`],
              0
            )
            mainJSON[`alvo_${i}`] = alvoItem
            mainJSON[`faturado_${i}`] = faturadoItem
            mainJSON[`perc_${i}`] = Math.round((faturadoItem / alvoItem) * 100)
            alvoTotal += alvoItem
            faturadoTotal += faturadoItem
          })

          mainJSON['alvo_total'] = alvoTotal
          mainJSON['faturado_total'] = faturadoTotal
          mainJSON['perc_total'] = Math.round((faturadoTotal / alvoTotal) * 100)

          const jsonRanking = rankItems(filteredItems)

          jsonRanking.forEach(item => {
            const mkt_profile = ls.get('mkt_profile')
            const mkt_dfbans = JSON.parse(ls.get('mkt_dfbans'))
            const seller_id = ls.get('seller_id')
            switch (mkt_profile) {
              case 'dfbam':
                item.cod_vend1 === seller_id && mainJSON.items.push(item)
                break
              case 'icam':
                mkt_dfbans.includes(item.cod_vend1) && mainJSON.items.push(item)
                break
              default:
                mainJSON.items.push(item)
                break
            }

            mainJSON.rawItems.push(item)
          })

          return mainJSON
        })

        const jsonRanking = rankItems(groupValues)

        setResultData(jsonRanking || [])
        setFilteredResultData(jsonRanking || [])
        setRawData(jsonRanking || [])
      } else {
        const currentAlvo = currentCampaign.alvos
          ? currentCampaign.alvos[0]
          : null

        const findId = currentCampaign?.alvos?.find(alvo =>
          alvo?.find(item => item.custom_id !== '')
        )

        setAlvoHasId(findId?.length > 0)

        const cnpjValues = currentAlvo?.map(alvo => {
          const currentCNPJ = unmaskCnpj(alvo.cnpj)
          const findedLine = financeData.find(f => f.cnpj === currentCNPJ)

          const findedCustomer = customers.find(
            c => c.cnpj === unmaskCnpj(alvo.cnpj)
          )

          const campaignAlvos = currentCampaign.alvos.map(cc =>
            cc.find(item => item.cnpj === currentCNPJ)
          )

          const json = {
            distribuidor: findedCustomer?.distribuidor,
            cod_vend1: findedLine?.cod_vend1,
            nome_vend1: findedLine?.nome_vend1,
            cnpj: currentCNPJ,
            ibm_shell: findedLine?.ibm_shell
          }

          if (findId?.length > 0) {
            json['custom_id'] = alvo.custom_id
          }

          json['municipio'] = findedCustomer?.cidade
          json['estado'] = findedCustomer?.estado
          json['dealer_name'] = findedCustomer?.razao_social || ''
          json['grupo'] = findedCustomer?.grupo_descricao

          const { data_inicial, tipo_agrupamento } = currentCampaign

          if (tipo_agrupamento?.value === 'categoria') {
            json['categoria'] = alvo?.categoria
          }

          let alvo_total = 0
          let faturado_total = 0
          campaignAlvos.forEach((alvo, i) => {
            const findedMonth = campaignMonths.find(item => item.key === i)
            if (!findedMonth?.checked) {
              return
            }
            const inicio = new Date(
              data_inicial.getFullYear(),
              data_inicial.getMonth() + i,
              1
            )
            const termino = new Date(
              data_inicial.getFullYear(),
              data_inicial.getMonth() + (i + 1),
              0
            )

            const dataByCNPJ = financeData.filter(f => f.cnpj === currentCNPJ)
            const reduceFaturado = dataByCNPJ
              .filter(f => {
                const mx = f.emissao.split('/')
                const emissaoDateObject = new Date(
                  `20${mx[2]}`,
                  mx[1] - 1,
                  mx[0]
                )
                return (
                  Date.parse(emissaoDateObject) >= Date.parse(inicio) &&
                  Date.parse(emissaoDateObject) <= Date.parse(termino)
                )
              })
              .reduce((acc, current) => acc + current.vol_total, 0)

            const intAlvo = parseInt(alvo.alvo)
            json[`alvo_${i}`] = intAlvo
            json[`faturado_${i}`] = Math.round(reduceFaturado)
            json[`perc_${i}`] =
              reduceFaturado === 0
                ? 0
                : Math.round((reduceFaturado / intAlvo) * 100)

            alvo_total += intAlvo
            faturado_total += reduceFaturado
          })

          json['alvo_total'] = alvo_total
          json['faturado_total'] = faturado_total
          json['perc_total'] =
            faturado_total === 0
              ? 0
              : Math.round((faturado_total / alvo_total) * 100)

          if (currentCampaign?.sku === 'SIM') {
            const reduceSKU = financeData
              .filter(f => f.cnpj === currentCNPJ)
              .filter(f => f.produto === currentCampaign?.sku_number)
              .reduce((acc, current) => acc + current.quantidade, 0)

            json['sku_ok'] = reduceSKU
          }

          return json
        })

        let result

        if (
          currentCampaign?.tipo_apuracao
            ?.filter(tipo => tipo)
            .find(t => t.value === 'r')
        ) {
          let jsonRanking
          let i = 0
          let currentCategory = ''
          if (currentCampaign?.tipo_agrupamento?.value === 'categoria') {
            const sortedData = _.orderBy(
              cnpjValues,
              ['categoria', 'perc_total'],
              ['desc', 'desc']
            )

            jsonRanking = sortedData.map(j => {
              if (currentCategory !== j.categoria) {
                i = 0
                currentCategory = j.categoria
              }
              i++
              return {
                ...j,
                ranking_pos: i
              }
            })
          } else {
            jsonRanking = rankItems(cnpjValues)
          }

          result = jsonRanking || []
        } else {
          result = cnpjValues || []
        }

        //const user = ls.get('user')
        const mkt_profile = ls.get('mkt_profile')
        const mkt_dfbans = JSON.parse(ls.get('mkt_dfbans'))
        const seller_id = ls.get('seller_id')
        setRawData(result)

        switch (mkt_profile) {
          case 'dfbam':
            result = result.filter(
              r => r?.cod_vend1 && r?.cod_vend1 === seller_id
            )
            break
          case 'icam':
            result = result.filter(
              r => r.cod_vend1 && mkt_dfbans.includes(r.cod_vend1)
            )
            break
          default:
            console.log('default')
            break
        }

        setResultData(result || [])
        setFilteredResultData(result || [])
      }
    }
    // eslint-disable-next-line
  }, [financeData, campaignMonths])

  const getFinanceData = async () => {
    setError(false)
    const result = await trackPromise(
      loadFinanceData(
        currentCampaign.data_inicial,
        currentCampaign.data_final,
        currentCampaign.acompanhamento_por
      )
    )
    setErrors(result.errors)
    setFinanceData(result.data)
  }

  return (
    <>
      <Dialog open={open} fullScreen TransitionComponent={TransitionComponent}>
        <TopBar
          onClick={() => setOpen(false)}
          title={`Apuração de Campanha: ${currentCampaign?.name}`}
        />
        <DialogContent>
          {promiseInProgress ? (
            <div className='mt-16 border border-gray-300 rounded-lg p-4'>
              <LoadingIndicator />
            </div>
          ) : (
            <div className='mt-16 border border-gray-300 rounded-lg p-4'>
              <CampaignHeaderSection
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
              <MonthsSection {...{ campaignMonths, handleMonthChange }} />
              {error && (
                <Fragment>
                  <Alert severity='error'>{error}</Alert>
                </Fragment>
              )}
              {errors.length > 0 && (
                <div className='mt-2 mb-2'>
                  <Alert severity='error'>
                    Alguns endpoints apresentaram erro ao buscar os dados.{' '}
                    {viewErrors ? (
                      <span
                        onClick={() => setViewErrors(false)}
                        className='cursor-pointer hover:underline'
                      >
                        Esconder Lista
                      </span>
                    ) : (
                      <span
                        onClick={() => setViewErrors(true)}
                        className='cursor-pointer hover:underline'
                      >
                        Ver Lista
                      </span>
                    )}
                  </Alert>
                </div>
              )}
              {activeFilter && (
                <div className='mx-2 my-3 flex justify-center bg-red-400 text-white p-1 rounded-md'>
                  Filtro ativo
                </div>
              )}
              <div className='mt-4'>
                {Object.keys(currentCampaign).length > 0 && (
                  <IndividualTable
                    data={filteredResultData}
                    rawData={rawData}
                    currentCampaign={currentCampaign}
                    alvoHasId={alvoHasId}
                  />
                )}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <ErrorsDialog open={viewErrors} setOpen={setViewErrors} errors={errors}/>
    </>
  )
}
