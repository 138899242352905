import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'

import ptBR from 'date-fns/locale/pt-BR'
// import { useAtom } from 'jotai'
// import { campaignAtom, filterDataAtom } from '../_global/Atoms'
registerLocale('ptBR', ptBR)

export const DateInput = props => {
  // const [filterData, setFilterData] = useAtom(filterDataAtom)
  // const [campaign, setCampaign] = useAtom(campaignAtom)

  // const clearDate = () => {
  //   setFilterData({ ...filterData, [props.id]: '' })
  //   setCampaign({ ...campaign, [props.id]: '' })
  // }
  return (
    <React.Fragment>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <div className='relative flex w-full flex-wrap items-stretch mb-3'>
        <DatePicker
          {...props}
          popperPlacement={
            props.popperPlacement ? props.popperPlacement : 'bottom-end'
          }
          placeholder={props.label}
          locale={ptBR}
          dateFormat='dd/MM/yyyy'
          autoComplete='off'
          className='appearance-none block w-full bg-white text-gray-700 border  rounded py-1 px-2 h-10 leading-tight focus:outline-none border-gray-400 text-xs'
          isClearable={!props.disabled}
        />
        {/* {props.selected !== '' && (
          <span className='z-10 h-full leading-snug font-normal absolute text-center text-red-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3 cursor-pointer'>
            <FontAwesomeIcon icon={faTimes} onClick={clearDate} />
          </span>
        )} */}
      </div>
    </React.Fragment>
  )
}
