import React from 'react'
import { useAtom } from 'jotai'

import { researchAtoms } from './Atoms'

export default function TextQuestion(props) {
  const { id, question, question_extended, number } = props.question
  const [research, setResearch] = useAtom(researchAtoms)

  const setNewValue = e => {
    setResearch({ ...research, [e.target.id]: e.target.value })
  }

  return (
    <div className='px-6 py-4'>
      <div className='text-md mb-2 text-left font-semibold'>
        <b>{number} </b>
        {question}
      </div>
      <div className='text-sm mb-2 text-left font-light text-gray-700'>
        {question_extended}
      </div>

      <div>
        <input
          type='text'
          id={id}
          onChange={setNewValue}
          className='border border-gray-500 rounded w-full'
          value={research[id]}
        />
      </div>
    </div>
  )
}
