import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Toolbar as MUIToolbar,
  Typography
} from '@material-ui/core'
import React from 'react'
import TransitionComponent from './TransitionComponent'
import EditComponent from './Form'

export default function DialogEdit({ open, setOpen }) {
  return (
    <Dialog open={open} fullScreen TransitionComponent={TransitionComponent}>
      <AppBar>
        <MUIToolbar>
          <Typography variant='h6'>Editando Campanha</Typography>
        </MUIToolbar>
      </AppBar>
      <DialogContent>
        <EditComponent setOpen={setOpen} type='edit' />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpen(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
