import {
  DataTypeProvider,
  TableColumnResizing
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from '@material-ui/core'
import React, { useState } from 'react'

const DialogLabel = ({ children }) => (
  <div className='text-xs text-yellow-500'>{children}</div>
)

const CurrencyFormatter = ({ value }) => (
  <b style={{ color: 'darkblue' }}>
    {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
  </b>
)

const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
)

const ordersItemsColumns = [
  { name: 'cod_produto', title: 'Codigo' },
  { name: 'descricao_produto', title: 'Descrição' },
  { name: 'qtde_produto', title: 'Qtd Vendida' },
  { name: 'quantidade_pendente', title: 'Qtd Pendente' },
  { name: 'tipo_produto', title: 'Tipo' },
  { name: 'preco_unitario', title: 'R$ (unit)' },
  { name: 'valor_produto', title: 'R$' },
  { name: 'volume_produto', title: 'Volume' },
  { name: 'status_item', title: 'Status' }
]

export default function OrderDialog({
  open,
  currentOrder,
  setOpenOrderDialog
}) {
  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'cod_produto', width: 100 },
    { columnName: 'descricao_produto', width: 300 },
    { columnName: 'qtde_produto', width: 100 },
    { columnName: 'quantidade_pendente', width: 120 },
    { columnName: 'tipo_produto', width: 100 },
    { columnName: 'preco_unitario', width: 100 },
    { columnName: 'valor_produto', width: 100 },
    { columnName: 'volume_produto', width: 100 },
    { columnName: 'status_item', width: 200 }
  ])

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Detalhes do Pedido</DialogTitle>
      <DialogContent>
        <div className='my-3 text-md text-blue-500'>CABEÇALHO</div>
        <div className='flex flex-col gap-2 w-full'>
          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>Cliente</DialogLabel>
              <div>{currentOrder.nome_cliente}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Status de Pedido</DialogLabel>
              <div>{currentOrder.status}</div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>No Pedido</DialogLabel>
              <div>{currentOrder.numero}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Vendedor</DialogLabel>
              <div>{currentOrder.nome_vendedor}</div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>No Pedido ERP</DialogLabel>
              <div>{currentOrder.numero}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Data de Emissão</DialogLabel>
              <div>{currentOrder.emissao}</div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>Filial</DialogLabel>
              <div>{currentOrder.distribuidor}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Condição de Pagamento</DialogLabel>
              <div>{currentOrder.cond_pgto}</div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>Tipo de Pedido</DialogLabel>
              <div>{currentOrder.tipo_pedido}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Valor</DialogLabel>
              <div>
                {currentOrder &&
                  currentOrder.valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
              </div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>Vendedor 2</DialogLabel>
              <div>{currentOrder.codigo_vendedor2}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Observações</DialogLabel>
              <div>{currentOrder.obs_pedido}</div>
            </div>
          </div>

          <div className='flex flex-row'>
            <div className='w-1/2'>
              <DialogLabel>Data Conclusão</DialogLabel>
              <div>{currentOrder.emissao}</div>
            </div>
            <div className='w-1/2'>
              <DialogLabel>Motivo Rejeição</DialogLabel>
              <div>{currentOrder.motivo_rejeicao}</div>
            </div>
          </div>
        </div>
        <hr />
        <div className='my-3 text-md text-blue-500'>ITENS</div>
        <div>
          <Paper>
            <Grid rows={currentOrder.itens} columns={ordersItemsColumns}>
              <CurrencyTypeProvider for={['valor_produto', 'preco_unitario']} />
              <Table />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
              />
              <TableHeaderRow />
            </Grid>
          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpenOrderDialog(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
