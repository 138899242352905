import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import {
  filterDataAtom,
  loadedModuleAtom,
  reportDataAtom
} from '../_global/Atoms'

import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'

import {
  changeFilterField,
  retrieveData,
  updateSelect
} from '../../functions/functions'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import ls from 'local-storage'
import { Button } from '@material-ui/core'
import { Search } from '@material-ui/icons'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [listSellers, setListSellers] = useState([])
  const { promiseInProgress } = usePromiseTracker()

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  const doFilter = async () => {
    const { distribuidor, data_inicial, data_final } = filterData
    if (distribuidor.length !== 0 && data_inicial !== '' && data_final !== '') {
      console.log('buscando resultados...')
      const results = await trackPromise(
        retrieveData(
          filterData,
          loadedModule,
          loadedModule === 'SupervisorCoachingReports' ? false : true
        )
      )
      setReportData(results)
    }
  }

  useEffect(() => {
    const sellers = ls.get('sellers')
    let key = 1
    const arraySellers = []
    arraySellers.push({ label: 'Todos', value: '', key: 1 })
    sellers.forEach(seller => {
      key++
      arraySellers.push({
        label: seller.nome_vededor,
        value: seller.codigo_vededor,
        key: key
      })
    })
    setListSellers(arraySellers)
  }, [])

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap  mb-4 items-center'>
        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={[]}
            allowSelectAll={false}
            isMulti
            placeholder='Selecione...'
            maxMenuHeight={200}
            isDisabled={true}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Data Inicial *'
            id='data_inicial'
            selected={filterData.data_inicial}
            onChange={e => {
              handleChange(e, 'data_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Data Final *'
            id='data_final'
            selected={filterData.data_final}
            onChange={e => {
              handleChange(e, 'data_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'vendedor', filterData, setFilterData)
            }}
            value={
              filterData.vendedor
                ? filterData.vendedor
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Vendedor *'
            id='vendedor'
            options={listSellers}
            placeholder='Selecione...'
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Search />}
            onClick={() => doFilter()}
          >
            {promiseInProgress ? 'Aguarde' : 'Filtrar'}
          </Button>
        </div>
      </div>
    </form>
  )
}
