import React from 'react'

const NewTab = ({ url }) => {
  const getWindow = async () => {
    const windowReference = await window.open(url)
    return windowReference
  }

  getWindow()

  return <div>Aberto em uma nova janela</div>
}

export default NewTab
