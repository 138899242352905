import React, { useEffect } from 'react'

import Card from './Card'
import DistributorInfo from './DistributorInfo'
import Header from './Header'
import RangeQuestion from './RangeQuestion'
import YesNoQuestion from './YesNoQuestion'

import { QUESTIONS } from './questions'
import TextQuestion from './TextQuestion'
import TextAreaQuestion from './TextAreaQuestion'
import { questionsAtoms, researchAtoms } from './Atoms'
import { useAtom } from 'jotai'
import SendContainer from './SendContainer'
import { distributors } from '../../constData/urls'

export default function Research(props) {
  // eslint-disable-next-line
  const [questions, setQuestions] = useAtom(questionsAtoms)
  const [research, setResearch] = useAtom(researchAtoms)

  const pathname = props.location.pathname.split('/')
  // eslint-disable-next-line
  const distributorId = pathname[2]
  const pesquisaId = pathname[3]

  useEffect(() => {
    document.title = 'Pesquisa de Satisfação'
    questions.length === 0 && setQuestions(QUESTIONS)
  }, [questions, setQuestions])

  useEffect(() => {
    const getData = () => {
      const distributor = distributors.find(
        d => d.id === parseInt(distributorId)
      )
      const data = distributor.distribuidor.split(' / ')
      setResearch({
        ...research,
        id: distributorId,
        cancelamento: 'N',
        distributor_name: data[0],
        id_pesquisa: pesquisaId
      })
    }
    distributors && getData()
    // eslint-disable-next-line
  }, [distributors])

  return (
    <React.Fragment>
      <Header />
      <Card>
        <DistributorInfo />
      </Card>
      {questions.map(question => {
        if (question.visible === false) {
          return false
        }
        return (
          <React.Fragment key={question.id}>
            <span className='m-2' />
            {question.type === 'range' && (
              <Card>
                <RangeQuestion question={question} />
              </Card>
            )}
            {question.type === 'yesno' && (
              <Card>
                <YesNoQuestion question={question} />
              </Card>
            )}
            {question.type === 'textarea' && (
              <Card>
                <TextAreaQuestion question={question} />
              </Card>
            )}
            {question.type === 'text' && (
              <Card>
                <TextQuestion question={question} />
              </Card>
            )}
          </React.Fragment>
        )
      })}
      <Card>
        <SendContainer distributorId={distributorId} />
      </Card>
    </React.Fragment>
  )
}
