import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { coachingQuestions as questions } from '../../../../constData/constData.js'
import { reportDataAtom, selectedRowsAtom } from '../../../_global/Atoms.js'
import DataTable from '../DataTable.js'

const columns = [
  { title: 'Relatório', name: 'relatorio' },
  { title: 'Sim', name: 'sim' },
  { title: '%Sim', name: 'perc_sim' },
  { title: 'Não', name: 'nao' },
  { title: '%Não', name: 'perc_nao' },
  { title: 'N/A', name: 'na' },
  { title: '%N/A', name: 'perc_na' },
  { title: 'Total', name: 'total_coaching' }
]

const DialogCoaching = ({ openDialogCoaching, setOpenDialogCoaching }) => {
  const [reportData] = useAtom(reportDataAtom)
  const [selectedRows] = useAtom(selectedRowsAtom)
  const [values, setValues] = useState([])

  const columns = [
    { title: 'Cliente', name: 'nome_cliente' },
    { title: 'Sim', name: 'sim' },
    { title: 'Não', name: 'nao' },
    { title: 'NA', name: 'na' }
  ]

  const processData = () => {
    const clientesArray = []
    reportData.forEach(rpt => {
      clientesArray.push(...rpt.clientes)
    })

    const filteredItems = clientesArray.map(cliente => {
      return {
        nome_cliente: cliente.nome_cliente,
        sim: cliente[selectedRows.level1.key] === 'Sim' ? 'X' : '',
        nao: cliente[selectedRows.level1.key] === 'Não' ? 'X' : '',
        na: cliente[selectedRows.level1.key] === 'NA' ? 'X' : ''
      }
    })
    setValues(filteredItems)
  }

  useEffect(() => {
    processData()
    // eslint-disable-next-line
  }, [selectedRows])

  return (
    <Dialog open={openDialogCoaching}>
      <DialogContent>
        <DataTable
          columns={columns}
          rows={values}
          title={`${selectedRows.level1.relatorio}`}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setOpenDialogCoaching(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function Coaching({ tipo }) {
  const [values, setValues] = useState([])
  const [reportData] = useAtom(reportDataAtom)
  //const [selectedRows] = useAtom(selectedRowsAtom)
  const [openDialogCoaching, setOpenDialogCoaching] = useState(false)

  const makeValuesJSON = () => {
    const questionsSection1 = questions.slice(1, 4)
    const questionsSection2 = questions.slice(5)
    const jsonValues = []

    const header1 = {
      relatorio: '1 - Execução no PDV* - Visão do Consumidor',
      key: '',
      sim: '',
      perc_sim: '',
      nao: '',
      perc_nao: '',
      na: '',
      perc_na: '',
      total_coaching: ''
    }

    const dataLevel1 = questionsSection1.map(item => {
      const arrSim = []
      const arrNao = []
      const arrNA = []
      reportData
        .filter(rpt => rpt.tipo === tipo)
        .forEach(rpt => {
          const filteredSim = rpt.clientes.filter(r => r[item.key] === 'Sim')
          const filteredNao = rpt.clientes.filter(r => r[item.key] === 'Não')
          const filteredNA = rpt.clientes.filter(r => r[item.key] === 'NA')
          arrSim.push(...filteredSim)
          arrNao.push(...filteredNao)
          arrNA.push(...filteredNA)
        })

      const arrTotal = []
      reportData
        .filter(rpt => rpt.tipo === tipo)
        .forEach(rpt => {
          const filtered = rpt.clientes
          arrTotal.push(...filtered)
        })

      return {
        relatorio: item.question,
        key: item.key,
        sim: arrSim.length,
        perc_sim:
          arrSim.length === 0
            ? 0
            : ((arrSim.length / arrTotal.length) * 100).toFixed(2),
        nao: arrNao.length,
        perc_nao:
          arrNao.length === 0
            ? 0
            : ((arrNao.length / arrTotal.length) * 100).toFixed(2),
        na: arrNA.length,
        perc_na:
          arrNA.length === 0
            ? 0
            : ((arrNA.length / arrTotal.length) * 100).toFixed(2),
        total_coaching: arrTotal.length
      }
    })

    const header2 = {
      relatorio: '2 - Coaching 7V* - Rotina Vendedor',
      key: '',
      sim: '',
      perc_sim: '',
      nao: '',
      perc_nao: '',
      na: '',
      perc_na: '',
      total_coaching: ''
    }

    const dataLevel2 = questionsSection2.map(item => {
      const arrSim = []
      const arrNao = []
      const arrNA = []
      reportData
        .filter(rpt => rpt.tipo === tipo)
        .forEach(rpt => {
          const filteredSim = rpt.clientes.filter(r => r[item.key] === 'Sim')
          const filteredNao = rpt.clientes.filter(r => r[item.key] === 'Não')
          const filteredNA = rpt.clientes.filter(r => r[item.key] === 'NA')
          arrSim.push(...filteredSim)
          arrNao.push(...filteredNao)
          arrNA.push(...filteredNA)
        })

      const arrTotal = []
      reportData
        .filter(rpt => rpt.tipo === tipo)
        .forEach(rpt => {
          const filtered = rpt.clientes
          arrTotal.push(...filtered)
        })

      return {
        relatorio: item.question,
        key: item.key,
        sim: arrSim.length,
        perc_sim:
          arrSim.length === 0
            ? 0
            : ((arrSim.length / arrTotal.length) * 100).toFixed(2),
        nao: arrNao.length,
        perc_nao:
          arrNao.length === 0
            ? 0
            : ((arrNao.length / arrTotal.length) * 100).toFixed(2),
        na: arrNA.length,
        perc_na:
          arrNA.length === 0
            ? 0
            : ((arrNA.length / arrTotal.length) * 100).toFixed(2),
        total_coaching: arrTotal.length
      }
    })

    jsonValues.push(header1)
    jsonValues.push(...dataLevel1)
    jsonValues.push(header2)
    jsonValues.push(...dataLevel2)
    setValues(jsonValues)
  }

  useEffect(() => {
    makeValuesJSON()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='mt-2'>
      <DataTable
        columns={columns}
        rows={values}
        title={`Coaching ${tipo}`}
        report='coaching'
        setOpenDialogCoaching={setOpenDialogCoaching}
      />
      <DialogCoaching {...{ openDialogCoaching, setOpenDialogCoaching }} />
    </div>
  )
}
