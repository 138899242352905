import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import MuiDialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const DialogContentText = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 12
  }
}))(MuiDialogContentText)

export default function ConfirmDialog({ open, text, onOK, onCancel }) {
  return (
    <Dialog open={open}>
      <DialogTitle>Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOK} color='primary'>
          OK
        </Button>
        <Button onClick={onCancel} color='primary'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
