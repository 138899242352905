export const QUESTIONS = [
  {
    number: '1',
    id: 'question_1',
    question:
      'Qual sua avaliação quanto ao Distribuidor como seu fornecedor de produtos Shell?',
    question_extended:
      'Usando uma escala de 1 (muito insatisfeito) a 10 (muito satisfeito), qual sua avaliação quanto ao Distribuidor como seu fornecedor de produtos Shell?',
    type: 'range',
    visible: true
  },
  {
    number: '2',
    id: 'question_2',
    question:
      'Qual sua avaliação geral quanto ao atendimento fornecido pelo Distribuidor?',
    question_extended:
      'Usando uma escala de 1 (muito insatisfeito) a 10(muito satisfeito), qual sua avaliação geral quanto ao atendimento fornecido pelo Distribuidor?',
    type: 'range',
    visible: true
  },
  {
    number: '2.1',
    id: 'question_2_1',
    question:
      'O atendimento do distribuidor via telefone atende suas expectativas?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '2.1.1',
    id: 'question_2_1_1',
    question:
      'Principais fatores de sua insatisfação com o atendimento telefônico',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '2.2',
    id: 'question_2_2',
    question: 'O Atendimento do consultor atende suas expectativas?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '2.2.1',
    id: 'question_2_2_1',
    question: 'Fatores de sua insatisfação com o consultor:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '3',
    id: 'question_3',
    question:
      'Qual a sua avaliação quanto à experiência relativa a entrega/coleta de produtos Shell?',
    question_extended:
      'Usando uma escala de 1 (muito insatisfeito) a 10 (muito satisfeito), qual a sua avaliação quanto à experiência relativa a entrega/coleta de produtos Shell?',
    type: 'range',
    visible: true
  },
  {
    number: '3.1',
    id: 'question_3_1',
    question:
      'Suas entregas tem sido completas conforme produtos e quantidades solicitadas?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },

  {
    number: '3.1.1',
    id: 'question_3_1_1',
    question: 'Quais os fatores de sua insatisfação com a Entrega/Coleta?',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '3.2',
    id: 'question_3_2',
    question:
      'Seu produto foi entregue conforme o tempo esperado e/ou comunicado pelo seu consultor?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '3.2.1',
    id: 'question_3_2_1',
    question:
      'Foi comunicado quanto ao atraso e/ou causa do atraso da entrega?',
    question_extended: '',
    type: 'yesno',
    visible: false
  },
  {
    number: '3.2.2',
    id: 'question_3_2_2',
    question: 'Motivos identificados para o atraso e/ou causa do atraso:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '3.3',
    id: 'question_3_3',
    question: 'Seus produtos chegaram em boas condições?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },

  {
    number: '3.3.1',
    id: 'question_3_3_1',
    question: 'Informe por favor, os problemas encontrados com os produtos:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '4',
    id: 'question_4',
    question: 'Qual a sua avaliação geral quanto às Promoções/Incentivos?',
    question_extended:
      'Usando uma escala de 1 (muito insatisfeito) a 10 (muito satisfeito), qual a sua avaliação geral quanto às Promoções/Incentivos?',
    type: 'range',
    visible: true
  },
  {
    number: '4.1',
    id: 'question_4_1',
    question:
      'Tem recebido informações sobre promoções ou incentivos relevantes para o seu negócio?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '4.1.1',
    id: 'question_4_1_1',
    question:
      'Por favor, identifique os problemas quanto às promoções/incentivos:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '5',
    id: 'question_5',
    question:
      'Qual a sua avaliação geral quanto ao Treinamento/Suporte Técnico?',
    question_extended:
      'Usando uma escala de 1 (muito insatisfeito) a 10 (muito satisfeito), qual a sua avaliação geral quanto ao Treinamento/Suporte Técnico?',
    type: 'range',
    visible: true
  },
  {
    number: '5.1',
    id: 'question_5_1',
    question: 'Você tem recebido treinamentos adequados?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '5.1.1',
    id: 'question_5_1_1',
    question: 'Por favor, identifique seu problema com os treinamentos:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '5.2',
    id: 'question_5_2',
    question: 'Você tem recebido suporte técnico adequado?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '5.2.1',
    id: 'question_5_2_1',
    question:
      'Por favor, identifique seus problemas com relação ao suporte técnico:',
    question_extended: '',
    type: 'textarea',
    visible: false
  },
  {
    number: '6',
    id: 'question_6',
    question: 'Você nos autoriza como seu fornecedor a contatá-lo?',
    question_extended:
      'Você nos autoriza como seu fornecedor a contatá-lo com a finalidade de obter maior compreensão quanto ao seu feedback e possibilidade de melhora de nossos serviços quanto a sua insatisfação?',
    type: 'yesno',
    visible: true
  },
  {
    number: '6.1',
    id: 'question_6_1',
    question: 'Pode nos confirmar seus dados de contato?',
    question_extended: '',
    type: 'yesno',
    visible: true
  },
  {
    number: '6.1.1',
    id: 'question_6_1_1',
    question: 'Por favor, informe seu nome:',
    question_extended: '',
    type: 'text',
    visible: true
  },
  {
    number: '6.1.2',
    id: 'question_6_1_2',
    question: 'Telefone',
    question_extended: '',
    type: 'text',
    visible: true
  },
  {
    number: '6.1.3',
    id: 'question_6_1_3',
    question: 'Email',
    question_extended: '',
    type: 'text',
    visible: true
  },
  {
    number: '7',
    id: 'question_7',
    question: 'Outros comentários que gostaria de fazer',
    question_extended: '',
    type: 'textarea',
    visible: true
  }
]
