import React from 'react'
import HeaderItems from '../_global/HeaderItems'
import TDItems from './TDItems'

import { useAtom } from 'jotai'
import { reportDataAtom } from '../_global/Atoms'

export default function DataTable({ headerItems }) {
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  let i = 0
  let values
  if (reportData.length > 0) {
    values = reportData.slice(0, 50)
  } else {
    values = []
  }
  return (
    <div className='overflow-x-auto w-full mt-4'>
      <table className='table-auto responsive hover w-full '>
        <thead>
          <HeaderItems data={headerItems} />
        </thead>
        <tbody>
          {values.length > 0 &&
            values.map(item => {
              ++i
              return <TDItems item={item} key={i} j={i} />
            })}
          {values.length === 0 && (
            <tr>
              <td colSpan={headerItems.length} className='text-red-700 text-sm'>
                Não há dados.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
