import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import React from 'react'

const QuestionResponseItem = ({
  item,
  handleCoachingResponse,
  newResponses
}) => (
  <div className='border border-gray-300 p-2 rounded mb-2'>
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{item.question}</FormLabel>
      <RadioGroup
        aria-label={item.key}
        id={item.key}
        value={newResponses[item.key]}
        onChange={e => handleCoachingResponse(item.key, e)}
      >
        <FormControlLabel value='Sim' control={<Radio />} label='Sim' />
        <FormControlLabel value='Não' control={<Radio />} label='Não' />
        {item.key !== 'planej' && item.key !== 'verpri' && (
          <FormControlLabel value='NA' control={<Radio />} label='NA' />
        )}
      </RadioGroup>
    </FormControl>
  </div>
)

export default QuestionResponseItem
