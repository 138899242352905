import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DialogActions } from '@material-ui/core'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { PlaylistAddCheck } from '@material-ui/icons'
import React from 'react'
import { Fragment } from 'react'
import TargetsTable from './TargetsTable'

const calculateCampaignMonths = campaign => {
  const { data_inicial, data_final } = campaign
  const diff =
    data_inicial &&
    data_final &&
    (Date.parse(data_final) - Date.parse(data_inicial)) / 1000 / 60 / 60 / 24
  const diffMonths = Math.round(diff / 30)
  return diffMonths
}

export default function TargetDialog({
  open,
  setOpen,
  handleFile,
  campaign,
  parseFile,
  handleChange,
  file,
  setCampaign,
  setFile,
  setSaved
}) {
  const months = calculateCampaignMonths(campaign)

  const commitChanges = ({ changed }) => {
    const changedRows = campaign.alvos?.map(row => {
      const keys = Object.keys(changed)
      const id = keys[0]
      const newRows = row.map(r => {
        return +id === r.id ? { ...r, ...changed[id] } : r
      })
      return newRows
    })

    setCampaign({ ...campaign, alvos: changedRows })
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Alvos da Campanha</DialogTitle>
      <DialogContent>
        <div className='flex flex-col'>
          <div className='flex flex-row justify-center mb-2'>
            <label
              htmlFor='file'
              className='bg-orange-500 hover:bg-yellow-700 text-white py-2 px-4 rounded text-sm mr-2 cursor-pointer'
            >
              <FontAwesomeIcon icon={faFileImport} /> SELECIONAR PLANILHA DE
              ALVOS
            </label>
            <input
              type='file'
              id='file'
              className='hidden'
              onChange={handleFile}
            />
            {months > 1 && (
              <Fragment>
                <input
                  type='checkbox'
                  id='replicate_target'
                  defaultChecked={campaign.replicate_target}
                  onChange={handleChange}
                />
                <span className='text-xs'>
                  &nbsp;Replicar alvo para todos os meses
                </span>
              </Fragment>
            )}
          </div>
          <div className='flex flex-col justify-center'>
            <Button
              variant='contained'
              color='primary'
              onClick={parseFile}
              startIcon={<PlaylistAddCheck />}
              disabled={file === '' || campaign.alvos?.length > 0}
            >
              Carregar Planilha
            </Button>
          </div>
        </div>
        {campaign.alvos?.length > 0 && (
          <div className='mt-4'>
            <TargetsTable campaign={campaign} commitChanges={commitChanges} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {campaign.alvos?.length > 0 && (
          <Fragment>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setOpen(false)
                setSaved(true)
              }}
            >
              Salvar
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                setOpen(false)
                setCampaign({ ...campaign, tipo_agrupamento: '', alvos: [] })
                setSaved(false)
                setFile('')
              }}
            >
              Limpar todos os dados e cancelar
            </Button>
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  )
}
