import {
  ExportPanel,
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui'
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import { Button, Paper } from '@material-ui/core'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import {
  filterDataAtom,
  reportDataAtom,
  selectedRowsAtom
} from '../../_global/Atoms'
import { useAtom } from 'jotai'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import OrderDialog from './Orders/OrderDialog'
import saveAs from 'file-saver'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { usePromiseTracker } from 'react-promise-tracker'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { visitSellerAtom } from './Atoms'

const CurrencyFormatter = ({ value }) => (
  <b style={{ color: 'darkblue' }}>
    {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
  </b>
)

const RounderFormatter = ({ value }) => <span>{value?.toFixed(2)}</span>

const RounderTypeProvider = props => (
  <DataTypeProvider formatterComponent={RounderFormatter} {...props} />
)

const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
)

const onSave = workbook => {
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'Relatório.xlsx'
    )
  })
}

export default function DataTable({
  columns,
  rows,
  title,
  report,
  setOpenDialogCoaching
}) {
  const [sorting, setSorting] = useState([])
  const [reportData] = useAtom(reportDataAtom)
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [selectedRows, setSelectedRows] = useAtom(selectedRowsAtom)
  const [currentOrder, setCurrentOrder] = useState(false)
  const [currencyColumns] = useState(['total'])
  const [rounderColumns] = useState([
    'volume',
    'volume_total',
    'volume_sh',
    'volume_ag'
  ])
  const [visitSeller, setVisitSeller] = useAtom(visitSellerAtom)
  const [openOrderDialog, setOpenOrderDialog] = useState(false)
  const exporterRef = useRef(null)

  const [defaultVisitColumnsWidths] = useState([
    { columnName: 'name', width: 180 },
    { columnName: 'gender', width: 180 },
    { columnName: 'city', width: 180 },
    { columnName: 'car', width: 240 },
    { columnName: 'codigo_vededor', width: 180 },
    { columnName: 'nome_vededor', width: 180 },
    { columnName: 'base_clientes', width: 180 },
    { columnName: 'plano_visitas', width: 180 },
    { columnName: 'visitas_roteiro', width: 180 },
    {
      columnName: 'perc_cump_plano_visitas',
      width: 180
    },
    { columnName: 'visitas_comvendas', width: 180 },
    {
      columnName: 'perc_ef_visitas_venda_roteiro',
      width: 180
    },
    {
      columnName: 'visitas_naorealizadas',
      width: 180
    },
    {
      columnName: 'visitas_foraroteiro',
      width: 180
    },
    { columnName: 'total_visitas_realizadas', width: 180 },
    { columnName: 'pedidos', width: 180 },
    { columnName: 'penetracao_base', width: 180 },
    { columnName: 'date', width: 180 },
    { columnName: 'clientes_roteiro', width: 180 },
    { columnName: 'visitados_roteiro', width: 180 },
    { columnName: 'efetivos_roteiro', width: 180 },
    { columnName: 'visitados_fora_roteiro', width: 180 },
    { columnName: 'total_visitados', width: 180 },
    { columnName: 'total_efetivos', width: 180 },
    { columnName: 'nome_cliente', width: 180 },
    { columnName: 'cnpj_cliente', width: 180 },
    { columnName: 'codigo_cliente', width: 180 },
    { columnName: 'tipo_cliente', width: 180 },
    { columnName: 'cidade_cliente', width: 180 },
    { columnName: 'uf_cliente', width: 180 },
    { columnName: 'status_visita', width: 180 },
    { columnName: 'data_inicial', width: 180 },
    { columnName: 'data_final', width: 180 },
    { columnName: 'tempo_visita', width: 180 },
    { columnName: 'distancia_km', width: 180 },
    { columnName: 'resumo', width: 180 },
    { columnName: 'proximas_acoes', width: 180 },
    { columnName: 'observacoes', width: 180 },
    { columnName: 'fornecedor', width: 240 }
  ])

  const [defaultCoachingColumnsWidths] = useState([
    { columnName: 'relatorio', width: 300 },
    { columnName: 'sim', width: 100 },
    { columnName: 'perc_sim', width: 100 },
    { columnName: 'nao', width: 100 },
    { columnName: 'perc_nao', width: 100 },
    { columnName: 'na', width: 100 },
    { columnName: 'perc_na', width: 100 },
    { columnName: 'total_coaching', width: 100 }
  ])

  const { promiseInProgress } = usePromiseTracker()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const TableRow = ({ tableRow, ...restProps }) => {
    const clickAction = () => {
      if (report === 'marginTracker') {
        return false
      }

      if (report === 'coaching') {
        const { row } = tableRow
        setSelectedRows({ ...selectedRows, level1: row })
        setOpenDialogCoaching(true)
        return false
      }

      if (filterData.report_level === 3) {
        let filtered
        if (report === 'visitPlan') {
          console.log('no more levels')
        } else {
          filtered = reportData.find(row => row.numero === tableRow.row.numero)
          setCurrentOrder(filtered)
          setOpenOrderDialog(true)
        }
      } else {
        setFilterData({
          ...filterData,
          report_level: filterData.report_level + 1
        })

        if (report === 'visitPlan' && filterData.report_level === 1) {
          setVisitSeller(tableRow.row.nome_vededor)
        }

        switch (filterData.report_level) {
          case 1:
            setSelectedRows({ ...selectedRows, level1: tableRow })
            break
          case 2:
            setSelectedRows({ ...selectedRows, level2: tableRow })
            break
          case 3:
            setSelectedRows({ ...selectedRows, level3: tableRow })
            break
          default:
            setSelectedRows({ ...selectedRows })
        }
      }
    }

    return (
      <Table.Row
        {...restProps}
        onClick={clickAction}
        className='cursor-pointer'
      />
    )
  }

  const loadHeaderLabels = () => {
    const level1Length = Object.keys(selectedRows.level1).length
    const level2Length = Object.keys(selectedRows.level2).length
    const periodoLabel = `${moment(filterData.data_inicial).format(
      'DD/MM/YYYY'
    )} a ${moment(filterData.data_final).format('DD/MM/YYYY')}`
    const statusLabel =
      level1Length > 0 ? ` - Status: ${selectedRows.level1.row.status}` : ''
    const tipoProdutoLabel =
      level2Length > 0
        ? ` - Tipo Produto: ${selectedRows.level2.row.tipo_produto}`
        : ''
    const { vendedor } = filterData
    const vendedorLabel = vendedor ? ` - Vendedor: ${vendedor.label}` : ''

    const vendedorVisitLabel = visitSeller ? ` - Vendedor: ${visitSeller}` : ''

    const emissaoLabel = level1Length > 0 ? selectedRows.level1.row.emissao : ''
    const produtoLabel =
      level2Length > 0
        ? `Produto: ${selectedRows.level2.row.cod_produto} - ${selectedRows.level2.row.descricao_produto}`
        : ''
    return {
      periodoLabel,
      statusLabel,
      tipoProdutoLabel,
      vendedorLabel,
      emissaoLabel,
      produtoLabel,
      vendedorVisitLabel
    }
  }

  const header = {
    ordersByDay: () => {
      const labels = loadHeaderLabels()
      const { periodoLabel, tipoProdutoLabel, vendedorLabel, emissaoLabel } =
        labels
      return filterData.report_level === 1
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 2
        ? `${emissaoLabel}${vendedorLabel}`
        : filterData.report_level === 3
        ? `${emissaoLabel}${tipoProdutoLabel}${vendedorLabel}`
        : ''
    },
    ordersByProduct: () => {
      const labels = loadHeaderLabels()
      const { periodoLabel, tipoProdutoLabel, vendedorLabel, produtoLabel } =
        labels
      return filterData.report_level === 1
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 2
        ? `${periodoLabel}${vendedorLabel}${tipoProdutoLabel}`
        : filterData.report_level === 3
        ? `${periodoLabel}${vendedorLabel}${tipoProdutoLabel}${produtoLabel}`
        : ''
    },
    ordersCut: () => {
      const labels = loadHeaderLabels()
      const { periodoLabel, tipoProdutoLabel, vendedorLabel } = labels
      return filterData.report_level === 1
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 2
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 3
        ? `${periodoLabel}${vendedorLabel}${tipoProdutoLabel}`
        : ''
    },
    ordersByStatus: () => {
      const labels = loadHeaderLabels()
      const { periodoLabel, tipoProdutoLabel, vendedorLabel, statusLabel } =
        labels
      return filterData.report_level === 1
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 2
        ? `${periodoLabel}${statusLabel}${vendedorLabel}`
        : filterData.report_level === 3
        ? `${periodoLabel}${statusLabel}${tipoProdutoLabel}${vendedorLabel}`
        : ''
    },
    revenuesByClassification: () => {
      const labels = loadHeaderLabels()
      const { periodoLabel, tipoProdutoLabel, vendedorLabel, statusLabel } =
        labels
      return filterData.report_level === 1
        ? `${periodoLabel}${vendedorLabel}`
        : filterData.report_level === 2
        ? `${periodoLabel}${tipoProdutoLabel}`
        : filterData.report_level === 3
        ? `${periodoLabel}${statusLabel}${tipoProdutoLabel}${vendedorLabel}`
        : ''
    },
    visitPlan: () => {
      const labels = loadHeaderLabels()
      // eslint-disable-next-line
      const { periodoLabel, vendedorVisitLabel } = labels
      return filterData.report_level === 1
        ? `${periodoLabel}`
        : filterData.report_level === 2
        ? `${periodoLabel}${vendedorVisitLabel}`
        : filterData.report_level === 3
        ? `${periodoLabel}${vendedorVisitLabel}`
        : ''
    }
  }

  return (
    <Fragment>
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <div className='mb-2'>
            <Button
              variant='contained'
              color='primary'
              disabled={filterData.report_level === 1 ? true : false}
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                setFilterData({
                  ...filterData,
                  report_level: filterData.report_level - 1
                })

                switch (filterData.report_level) {
                  case 1:
                    setSelectedRows({ ...selectedRows, level2: {}, level1: {} })
                    break
                  case 2:
                    setSelectedRows({ ...selectedRows, level3: {} })
                    break
                  default:
                    setSelectedRows({ ...selectedRows })
                }
              }}
            >
              Voltar
            </Button>
          </div>
          <hr />
          <div className='p-2 my-2 bg-yellow-400 rounded'>
            <Fragment>
              <div>
                {title} -{' '}
                {report === 'ordersByProduct' && header.ordersByProduct()}
                {(report === 'ordersByDay' || report === 'revenuesByDay') &&
                  header.ordersByDay()}
                {(report === 'ordersCut' || report === 'revenuesBySeller') &&
                  header.ordersCut()}
                {report === 'ordersByStatus' && header.ordersByStatus()}
                {report === 'revenuesByClassification' &&
                  header.revenuesByClassification()}
                {report === 'visitPlan' && header.visitPlan()}
              </div>
            </Fragment>
          </div>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <CurrencyTypeProvider for={currencyColumns} />
              <RounderTypeProvider for={rounderColumns} />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <Table rowComponent={TableRow} />
              {report === 'visitPlan' && (
                <TableColumnResizing
                  defaultColumnWidths={defaultVisitColumnsWidths}
                />
              )}
              {report === 'coaching' && (
                <TableColumnResizing
                  defaultColumnWidths={defaultCoachingColumnsWidths}
                />
              )}
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <ExportPanel
                startExport={startExport}
                messages={{ exportAll: 'Exportar para excel' }}
              />
            </Grid>
            <GridExporter
              ref={exporterRef}
              rows={rows}
              columns={columns}
              onSave={onSave}
            />
          </Paper>
          <OrderDialog
            open={openOrderDialog}
            currentOrder={currentOrder}
            setOpenOrderDialog={setOpenOrderDialog}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
