import React, { useState, useRef } from 'react'
import Chevron from './Chevron'
import { useAtom } from 'jotai'
import {
  loadedModuleAtom,
  filterDataAtom,
  reportDataAtom
} from '../_global/Atoms'
import { loadDefaultFilter } from '../../functions/functions'
import ls from 'local-storage'

import './Accordion.css'

export default function Accordion(props) {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [setRotate, setRotateState] = useState('accordion__icon')

  const [loadedModule, setLoadedModule] = useAtom(loadedModuleAtom)
  // eslint-disable-next-line
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)

  const handleClickItem = item => {
    setLoadedModule(null)
    setTimeout(() => {
      setLoadedModule(item.name)
      ls.set('loadedModule', item.name)
      setFilterData(loadDefaultFilter(item.name))
      setReportData([])
    }, 500)
  }

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    )
  }

  return (
    <div className='accordion__section'>
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className='accordion__title'>{props.title}</p>
        <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
      </button>
      {props.items &&
        props.items.length > 0 &&
        props.items.map(item => (
          <div
            key={item.name}
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className='accordion__content '
          >
            <div
              className={`accordion__text ${
                loadedModule === item.name ? 'text-red-600' : 'text-gray-600'
              }`}
              style={{ cursor: 'pointer' }}
              dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;' + item.label }}
              onClick={() => handleClickItem(item)}
            />
          </div>
        ))}
    </div>
  )
}
