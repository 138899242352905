import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { indicadorAtom, supervisorRowsAtom } from '../../_global/Atoms'
import { indicatorsListAtom, rawDataAtom } from './Atoms'

const KPIGraphs = () => {
  const [rows] = useAtom(supervisorRowsAtom)
  const [rawData] = useAtom(rawDataAtom)
  const [graphData, setGraphData] = useState([])
  const [indicador] = useAtom(indicadorAtom)
  const [indicatorsList] = useAtom(indicatorsListAtom)

  useEffect(() => {
    if (rawData.length > 0) {
      const data = []
      const headers = [
        'Indicador',
        'Realizado',
        { role: 'annotation' },
        'Faltou para Objetivo'
      ]

      data.push(headers)

      indicatorsList.forEach(indicator => {
        let reduceObjetivo = 0
        let reduceRealizado = 0
        rawData.forEach(d => {
          const reducedObjItem = d.alvos
            .filter(item => item.descricao === indicator.indicador)
            .reduce((acc, item) => acc + item.objetivo_mes, 0)
          reduceObjetivo += reducedObjItem

          const reduceRealItem = d.alvos
            .filter(item => item.descricao === indicator.indicador)
            .reduce((acc, item) => acc + item.real_mes, 0)
          reduceRealizado += reduceRealItem
        })

        data.push([
          String(indicator.indicador).padStart(3, 0),
          reduceRealizado,
          ((reduceRealizado / reduceObjetivo) * 100).toFixed(1),
          reduceObjetivo - reduceRealizado < 0
            ? 0
            : reduceObjetivo - reduceRealizado
        ])
      })

      setGraphData(data)
    }
    // eslint-disable-next-line
  }, [rawData, indicador])

  return (
    <div className='w-9/12 border border-gray-400'>
      {rows.length > 0 ? (
        indicador ? (
          <Chart
            height={'300px'}
            chartType='ColumnChart'
            loader={<div>Loading Chart</div>}
            data={graphData}
            options={{
              isStacked: 'percent',
              legend: 'none',
              vAxis: { textPosition: 'none' },
              series: {
                0: { color: 'orange' },
                1: { color: 'red' }
              }
            }}
          />
        ) : (
          'Selecione um indicador ao lado.'
        )
      ) : (
        <div>Nenhum dado encontrado</div>
      )}
    </div>
  )
}

export default KPIGraphs
