import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom } from '../_global/Atoms'
import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'
import { getTiposCliente, getTiposNF } from '../../constData/constData'

import {
  updateSelect,
  changeFilterField,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap mb-4'>
        <div className='w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            isMulti
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp de *'
            id='data_inicial'
            selected={filterData.data_inicial}
            onChange={e => {
              handleChange(e, 'data_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Ult comp ate *'
            id='data_final'
            selected={filterData.data_final}
            onChange={e => {
              handleChange(e, 'data_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_cliente', filterData, setFilterData)
            }}
            value={
              filterData.tipo_cliente
                ? filterData.tipo_cliente
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Tipo Cliente *'
            id='tipo_cliente'
            options={getTiposCliente()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'tipo_nf', filterData, setFilterData)
            }}
            value={
              filterData.tipo_nf
                ? filterData.tipo_nf
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Tipo de NF *'
            id='tipo_nf'
            options={getTiposNF()}
            placeholder='Selecione...'
          />
        </div>
      </div>
    </form>
  )
}
