import React, { useRef, useState } from 'react'
import { useAtom } from 'jotai'
import { reportDataAtom } from './Atoms'
import { Paper } from '@material-ui/core'
import {
  ExportPanel,
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import {
  EditingState,
  IntegratedPaging,
  PagingState
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import saveAs from 'file-saver'
import ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const getRowId = row => row.id

const FocusableCell = ({ onClick, ...restProps }) => {
  const { value, column, row } = restProps
  const user = ls.get('user')
  return (
    <Table.Cell
      {...restProps}
      tabIndex={0}
      onFocus={
        user === 'icam'
          ? column.name === 'extra'
            ? onClick
            : () => console.log('default')
          : column.name === 'limite' || column.name === 'extra'
          ? onClick
          : () => console.log('default')
      }
    >
      {value}{' '}
      {user === 'icam'
        ? column.name === 'extra' && <FontAwesomeIcon icon={faEdit} />
        : (column.name === 'limite' || column.name === 'extra') && (
            <FontAwesomeIcon icon={faEdit} />
          )}
      <br />
      {column.name === 'limite' && row.alter_limite && (
        <span className='inline-block bg-yellow-600 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
          alterado
        </span>
      )}
      {column.name === 'extra' && row.alter_extra && (
        <span className='inline-block bg-yellow-600 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
          alterado
        </span>
      )}
      {column.name === 'extra' && user === 'icam' && row.imported && (
        <span className='inline-block bg-teal-400 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
          importado
        </span>
      )}
      {column.name === 'distribuidor' && row.new_item && (
        <span className='inline-block bg-teal-600 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
          novo
        </span>
      )}
      {column.name === 'distribuidor' && row.imported && user === 'pricing' && (
        <span className='inline-block bg-teal-400 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
          importado
        </span>
      )}
      {(column.name === 'dataini' || column.name === 'datafim') &&
        row.alter_datas && (
          <span className='inline-block bg-yellow-400 text-black text-xs pr-2 pl-2 ml-2 rounded-full'>
            alterado
          </span>
        )}
    </Table.Cell>
  )
}

const DataTable = () => {
  const [reportData, setReportData] = useAtom(reportDataAtom)
  const [editingCells, setEditingCells] = useState([])
  const [pageSizes] = useState([25, 50, 100, 0])
  const [editingStateColumnExtensions] = useState([
    { columnName: 'distribuidor', editingEnabled: false },
    { name: 'descricao', editingEnabled: false },
    { name: 'dataini', editingEnabled: false },
    { name: 'datafim', editingEnabled: false },
    { name: 'armazem', editingEnabled: false },
    { name: 'pedido', editingEnabled: false },
    { name: 'atingido', editingEnabled: false }
  ])
  const user = ls.get('user')

  const commitChanges = ({ changed, deleted }) => {
    let changedRows

    if (changed) {
      changedRows = reportData.map(row => {
        if (!changed[row.id]) {
          return row
        }

        const field = Object.keys(changed[row.id])[0]

        if (user === 'pricing') {
          if (field !== 'limite' && field !== 'extra') {
            return row
          }
        } else {
          if (field !== 'extra') {
            return row
          }
        }

        let altered = {}

        if (changed[row.id] && changed[row.id].limite) {
          altered = { alter_limite: true }
        }

        if (changed[row.id] && changed[row.id].extra) {
          altered = { alter_extra: true }
        }

        return changed[row.id]
          ? { ...row, ...changed[row.id], ...altered }
          : row
      })
    }
    if (deleted) {
      const deletedSet = new Set(deleted)
      changedRows = reportData.filter(row => !deletedSet.has(row.id))
    }

    setReportData(changedRows)
  }

  const addEmptyRow = () => commitChanges({ added: [{}] })

  const columns = [
    { name: 'distribuidor', title: 'Distribuidor' },
    { name: 'sku', title: 'SKU' },
    { name: 'armazem', title: 'Armazem' },
    { name: 'descricao', title: 'Descrição' },
    { name: 'dataini', title: 'Data Inicial' },
    { name: 'datafim', title: 'Data Final' },
    { name: 'limite', title: 'Qtd. Trava' },
    { name: 'pedido', title: 'Qtd. Comprada' },
    { name: 'extra', title: 'Qtd. Extra' },
    { name: 'atingido', title: '% Atingido' }
  ]

  const exporterRef = useRef()

  const startExport = options => {
    exporterRef.current.exportGrid(options)
  }

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'limites.xlsx'
      )
    })
  }

  return (
    <Paper>
      <Grid columns={columns} rows={reportData} getRowId={getRowId}>
        <EditingState
          onCommitChanges={commitChanges}
          editingCells={editingCells}
          onEditingCellsChange={setEditingCells}
          addedRows={[]}
          onAddedRowsChange={addEmptyRow}
          columnExtensions={editingStateColumnExtensions}
        />
        <PagingState defaultCurrentPage={0} defaultPageSize={25} />
        <IntegratedPaging />
        <Table
          cellComponent={FocusableCell}
          messages={{ noData: 'Nenhum dado.' }}
        />

        <TableHeaderRow />
        <Toolbar />
        <ExportPanel
          startExport={startExport}
          messages={{ exportAll: 'Exportar registros' }}
        />
        <PagingPanel
          pageSizes={pageSizes}
          messages={{ rowsPerPage: 'Registros por pagina' }}
        />
        <TableInlineCellEditing selectTextOnEditStart />
        {user !== 'icam' && (
          <TableEditColumn
            showDeleteCommand
            messages={{ deleteCommand: 'EXCLUIR' }}
          />
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={columns}
        rows={reportData}
        onSave={onSave}
      />
    </Paper>
  )
}

export default DataTable
