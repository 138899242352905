import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom } from '../_global/Atoms'

import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'
import {
  listManagers,
  listSuberbs,
  listSellers
} from '../../constData/constData'

import {
  changeFilterField,
  updateSelect,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap mb-4'>
        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <DateInput
            label='De *'
            id='data_inicial'
            selected={filterData.data_inicial}
            onChange={e => {
              handleChange(e, 'data_inicial')
            }}
            popperPlacement='bottom-start'
          />
        </div>
        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <DateInput
            label='Ate *'
            id='data_final'
            selected={filterData.data_final}
            onChange={e => {
              handleChange(e, 'data_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            maxMenuHeight={200}
            allowSelectAll={true}
            isMulti
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'gerente', filterData, setFilterData)
            }}
            value={
              filterData.gerente
                ? filterData.gerente
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Gerente *'
            id='gerente'
            options={listManagers()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'supervisor', filterData, setFilterData)
            }}
            value={
              filterData.supervisor
                ? filterData.supervisor
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Supervisor *'
            id='supervisor'
            options={listSuberbs()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'vendedor', filterData, setFilterData)
            }}
            value={
              filterData.vendedor
                ? filterData.vendedor
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Vendedor *'
            id='vendedor'
            options={listSellers()}
            placeholder='Selecione...'
          />
        </div>
      </div>
    </form>
  )
}
