import { useAtom } from 'jotai'
import React from 'react'
import { researchAtoms } from './Atoms'

export default function DistributorInfo() {
  const [research] = useAtom(researchAtoms)
  return (
    <div className='px-6 py-4'>
      <div className='text-md mb-2 text-left font-semibold'>
        Dados da Pesquisa
      </div>
      <hr />
      <div className='text-md mb-2 mt-2 text-left'>
        Distribuidor: {research.distributor_name}
      </div>
    </div>
  )
}
