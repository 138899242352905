import { Button, DialogActions, DialogContent } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import ModalDataContent from './ModalDataContent'

export default function UpdateDataDialog({ open, setOpen }) {
  return (
    <Dialog open={open}>
      <DialogContent>
        <ModalDataContent />
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          onClick={() => setOpen(false)}
          startIcon={<Close />}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
