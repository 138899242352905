import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Button({
  label,
  color,
  textColor,
  click,
  icon,
  padding
}) {
  const defaultPadding = 'px-4 py-2'
  const buttonClass = `bg-${color}-500 hover:bg-${color}-700 text-${textColor} rounded text-sm mr-2 ${
    padding ? padding : defaultPadding
  }`
  return (
    <button className={buttonClass} onClick={click}>
      {icon && <FontAwesomeIcon icon={icon} />} {label}
    </button>
  )
}
