import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import InputForm from '../../Inputs/InputForm'
import { DateInput } from '../../Inputs/DateInput'
import {
  coachingDialogTypeAtom,
  filterDataAtom,
  selectedCoachingAtom,
  alertParamsAtom,
  selectedSellerAtom
} from '../../_global/Atoms'
import { Button } from '@material-ui/core'
import CoachingTable from './CoachingTable'
import { distributors } from '../../../constData/urls'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Axios from 'axios'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { Fragment } from 'react'
import moment from 'moment'
import {
  allResponsesAtom,
  coachingDataAtom,
  newCoachingTypeAtom
} from './Atoms'
import { Cancel, Save } from '@material-ui/icons'
import ConfirmDialog from '../../_global/ConfirmDialog'
import ls from 'local-storage'
import { completeCoachingResponses } from '../../../functions/functions'
import Alert from '@material-ui/lab/Alert'

export default function CoachingForm() {
  const [selectedCoaching, setSelectedCoaching] = useAtom(selectedCoachingAtom)
  const [coachingData, setCoachingData] = useAtom(coachingDataAtom)
  const [coachingDialogType, setCoachingDialogType] = useAtom(
    coachingDialogTypeAtom
  )

  const [filterData] = useAtom(filterDataAtom)
  const [promptCoaching, setPromptCoaching] = useState(false)
  const [confirmCloseCoaching, setConfirmCloseCoaching] = useState(false)
  // eslint-disable-next-line
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)
  const [newCoachingType] = useAtom(newCoachingTypeAtom)
  const [selectedSeller] = useAtom(selectedSellerAtom)
  // eslint-disable-next-line
  const [allResponses, setAllResponses] = useAtom(allResponsesAtom)
  const { promiseInProgress } = usePromiseTracker()

  const defaultCoachingData = {
    distribuidor: filterData.distribuidor[0].value,
    supervisor: ls.get('codigo_vededor'),
    vendedor: selectedSeller.value,
    objetivo: '',
    id_coaching: '',
    atualiza: 'N',
    tipo: newCoachingType,
    data_fechamento: '',
    data_cancelamento: '',
    feedback: '',
    acoes: '',
    observacao: ''
  }

  useEffect(() => {
    if (coachingDialogType === 'show') {
      loadCoachingData()
    } else {
      setCoachingData(defaultCoachingData)
    }

    // eslint-disable-next-line
  }, [coachingDialogType])

  const handleChange = (e, field) => {
    if (!field) {
      const { id, value } = e.target
      setCoachingData({ ...coachingData, [id]: value })
    } else {
      setCoachingData({ ...coachingData, [field]: e })
    }
  }

  const loadCoachingData = async () => {
    const { supervidor, vendedor, id_coaching, tipo } = selectedCoaching

    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervidor,
      vendedor: vendedor,
      objetivo: '',
      id_coaching: id_coaching,
      atualiza: 'N',
      tipo: tipo,
      data_fechamento: '',
      data_cancelamento: '',
      feedback: '',
      acoes: '',
      observacao: ''
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_GRAVA_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      })
    )

    let realizacao
    if (data.data_realizacao === '') {
      realizacao = data.data_realizacao
    } else {
      const mx = data.data_realizacao.split('/')
      realizacao = new Date(mx[2], mx[1] - 1, mx[0])
    }

    const newData = { ...data, data_realizacao: realizacao }

    setAllResponses(completeCoachingResponses(data.clientes))
    setCoachingData(newData)
  }

  const cancelCoaching = async () => {
    const { supervidor, vendedor, id_coaching, tipo } = selectedCoaching
    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervidor,
      vendedor: vendedor,
      objetivo: '',
      id_coaching: id_coaching,
      atualiza: 'S',
      tipo: tipo,
      data_fechamento: '',
      data_cancelamento: moment(new Date()).format('DD/MM/YYYY'),
      feedback: '',
      acoes: '',
      observacao: ''
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_GRAVA_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      })
    )

    if (data.data_cancelamento) {
      setAlertParams({
        open: true,
        text: 'Coaching cancelado com sucesso.',
        onClick: () => setAlertParams({ open: false })
      })
      setPromptCoaching(false)
      loadCoachingData()
    }
  }

  const closeCoaching = async () => {
    const { supervidor, vendedor, id_coaching, tipo } = selectedCoaching
    const {
      objetivo,
      data_fechamento,
      feedback,
      acoes,
      observacao,
      data_realizacao
    } = coachingData

    if (feedback === '' || acoes === '' || observacao === '') {
      setConfirmCloseCoaching(false)
      setAlertParams({
        open: true,
        text: 'Os campos de feedback devem ester preenchidos antes do coaching ser fechado. Preencha os campos e tente novamente.',
        onClick: () => setAlertParams({ open: false })
      })
      return false
    }

    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervidor,
      vendedor: vendedor,
      objetivo: objetivo,
      id_coaching: id_coaching,
      atualiza: 'S',
      tipo: tipo,
      data_fechamento:
        data_fechamento === ''
          ? moment(new Date()).format('DD/MM/YYYY')
          : moment(data_fechamento).format('DD/MM/YYYY'),
      data_cancelamento: '',
      data_realizacao: data_realizacao
        ? moment(data_realizacao).format('DD/MM/YYYY')
        : moment(new Date()).format('DD/MM/YYYY'),
      feedback: feedback,
      acoes: acoes,
      observacao: observacao
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_GRAVA_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      })
    )

    setConfirmCloseCoaching(false)
    if (data.data_fechamento) {
      setAlertParams({
        open: true,
        text: 'Coaching fechado com sucesso.',
        onClick: () => setAlertParams({ open: false })
      })

      loadCoachingData()
    }
  }

  const startCoaching = async () => {
    const { objetivo } = coachingData

    if (objetivo === '') {
      setAlertParams({
        open: true,
        text: 'Digite um objetivo para iniciar o coaching.',
        onClick: () => {
          setAlertParams(false)
        }
      })
      return false
    }

    const supervisor = ls.get('codigo_vededor')

    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: supervisor,
      vendedor: selectedSeller.value,
      objetivo: objetivo,
      id_coaching: '',
      atualiza: 'S',
      tipo: newCoachingType,
      data_fechamento: '',
      data_cancelamento: '',
      feedback: '',
      acoes: '',
      observacao: ''
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const { data } = await trackPromise(
      Axios.post(`${distributorData.url}INT_SHELL_GRAVA_COACHING`, json, {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      })
    )

    setCoachingData(data)
    setSelectedCoaching(data)
    setCoachingDialogType('show')
  }

  /**
   * TODO:
   * - load selected coaching data
   * - load seller's customers
   */

  return (
    <div className='mt-16'>
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <div className='text-yellow-700'>OBJETIVO DO COACHING</div>
          <div className='flex flex-row gap-2'>
            <InputForm
              id='objetivo'
              label='Quais são os objetivos específicos de coaching (competência e comportamentos) para esta sessão que ajudarão o indivíduo a melhorar seu desempenho? Por favor, garantir que eles sejam Relevantes e Específicos.'
              defaultValue={coachingData?.objetivo}
              onChange={handleChange}
              bg={
                coachingData.id_coaching === ''
                  ? ''
                  : coachingDialogType === 'new'
                  ? ''
                  : 'bg-gray-300'
              }
              disabled={
                coachingData.id_coaching === ''
                  ? false
                  : coachingDialogType === 'new'
                  ? false
                  : true
              }
            />
            {coachingDialogType === 'new' && (
              <Button
                variant='contained'
                color='primary'
                onClick={startCoaching}
              >
                Iniciar
              </Button>
            )}
          </div>

          <div className={`${coachingDialogType === 'new' && 'hidden'}`}>
            <InputForm
              id='ultima_acao'
              label='Ações específicas acordadas para abordar os objetivos do coaching da última sessão:'
              defaultValue={coachingData?.ultima_acao}
              bg={coachingData.id_coaching === '' ? '' : 'bg-gray-300'}
              disabled={coachingData.id_coaching === '' ? false : true}
            />

            <div className='text-yellow-700 my-2'>
              Pontos de observação (CheckList)
            </div>
            {coachingData && <CoachingTable />}

            <div className='text-yellow-700 my-2'>
              FEEDBACK E AÇÕES DE COACHING
            </div>
            <InputForm
              id='feedback'
              label='De sua sessão, o que o indivíduo melhorou ou fez bem em relação aos objetivos de coaching?'
              defaultValue={coachingData?.feedback}
              onChange={handleChange}
              bg={
                coachingData.data_fechamento !== ''
                  ? 'bg-gray-300'
                  : coachingData.data_cancelamento === ''
                  ? ''
                  : 'bg-gray-300'
              }
              disabled={
                coachingData.data_fechamento !== ''
                  ? true
                  : coachingData.data_cancelamento === ''
                  ? false
                  : true
              }
            />
            <InputForm
              id='acoes'
              label='Quais ações o indivíduo acordou como resultado desta sessão de coaching (ações específicas e quando)?'
              defaultValue={coachingData?.acoes}
              onChange={handleChange}
              bg={
                coachingData.data_fechamento !== ''
                  ? 'bg-gray-300'
                  : coachingData.data_cancelamento === ''
                  ? ''
                  : 'bg-gray-300'
              }
              disabled={
                coachingData.data_fechamento !== ''
                  ? true
                  : coachingData.data_cancelamento === ''
                  ? false
                  : true
              }
            />
            <InputForm
              id='observacao'
              label='Observações a respeito do coaching:'
              defaultValue={coachingData?.observacao}
              onChange={handleChange}
              bg={
                coachingData.data_fechamento !== ''
                  ? 'bg-gray-300'
                  : coachingData.data_cancelamento === ''
                  ? ''
                  : 'bg-gray-300'
              }
              disabled={
                coachingData.data_fechamento !== ''
                  ? true
                  : coachingData.data_cancelamento === ''
                  ? false
                  : true
              }
            />
            <DateInput
              label='Data de realização'
              id='data_realizacao'
              maxDate={new Date()}
              selected={
                coachingData.data_realizacao === ''
                  ? new Date()
                  : coachingData.data_realizacao
              }
              onChange={e => {
                handleChange(e, 'data_realizacao')
              }}
              bg={
                coachingData.data_fechamento !== ''
                  ? 'bg-gray-300'
                  : coachingData.data_cancelamento === ''
                  ? ''
                  : 'bg-gray-300'
              }
              disabled={
                coachingData.data_fechamento !== ''
                  ? true
                  : coachingData.data_cancelamento === ''
                  ? false
                  : true
              }
              popperPlacement='bottom-start'
            />
            <div className='flex gap-2 p-2 my-2'>
              {coachingDialogType === 'show' &&
              coachingData.data_fechamento !== '' ? (
                <div className='w-full'>
                  <Alert severity='info'>Coaching Finalizado</Alert>
                </div>
              ) : coachingData.data_cancelamento === '' ? (
                <Fragment>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Save />}
                    onClick={() => setConfirmCloseCoaching(true)}
                  >
                    Finalizar
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<Cancel />}
                    onClick={() => setPromptCoaching(true)}
                  >
                    Cancelar
                  </Button>
                </Fragment>
              ) : (
                <div className='w-full'>
                  <Alert severity='info'>Coaching Cancelado</Alert>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
      <ConfirmDialog
        open={promptCoaching}
        text='Tem certeza que deseja cancelar esse coaching?'
        onOK={() => {
          cancelCoaching()
        }}
        onCancel={() => {
          setPromptCoaching(false)
        }}
      />
      <ConfirmDialog
        open={confirmCloseCoaching}
        text='Tem certeza que deseja fechar esse coaching?'
        onOK={() => {
          closeCoaching()
        }}
        onCancel={() => {
          setConfirmCloseCoaching(false)
        }}
      />
    </div>
  )
}
