import { Paper } from '@material-ui/core'
import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import React, { useEffect, useState } from 'react'
import ReactSelect from '../../Inputs/ReactSelect'
import ls from 'local-storage'
import Axios from 'axios'
import { useAtom } from 'jotai'
import { segmentListAtom } from '../Atoms'
import { setorList } from '../Opportunities/constData'

const TypeCustomerTable = () => {
  const columns = [
    { name: 'tipo_cliente', title: 'Tipo do Cliente' },
    { name: 'oportunidades', title: 'Oportunidades' },
    { name: 'call_plans', title: 'Call Plans' },
    { name: 'perc', title: '%' }
  ]

  const data = [
    {
      tipo_cliente: 'Aumento de participação',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    { tipo_cliente: 'Cross-sell', oportunidades: 0, call_plans: 0, perc: 0 },
    { tipo_cliente: 'Novo Cliente', oportunidades: 0, call_plans: 0, perc: 0 },
    { tipo_cliente: 'Novo Site', oportunidades: 0, call_plans: 0, perc: 0 },
    { tipo_cliente: 'Up-Sell', oportunidades: 0, call_plans: 0, perc: 0 }
  ]

  return (
    <Paper>
      <Grid rows={data} columns={columns}>
        <Table />
        <TableHeaderRow />
      </Grid>
    </Paper>
  )
}

const SectorSegmentTable = () => {
  const columns = [
    { name: 'sector_segment', title: 'Setor/Segmento' },
    { name: 'oportunidades', title: 'Oportunidades' },
    { name: 'call_plans', title: 'Call Plans' },
    { name: 'perc', title: '%' }
  ]

  const data = [
    { sector_segment: 'AGRONEGÓCIO', oportunidades: 0, call_plans: 0, perc: 0 },
    { sector_segment: 'Fazendas', oportunidades: 0, call_plans: 0, perc: 0 },
    {
      sector_segment: 'Usinas de Açúcar e Etanol',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      sector_segment: 'CONCESSIONARIA',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      sector_segment: 'CONCES AUTO AUDI',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    }
  ]

  return (
    <Paper>
      <Grid rows={data} columns={columns}>
        <Table />
        <TableHeaderRow />
      </Grid>
    </Paper>
  )
}

const ProductFamily = () => {
  const columns = [
    { name: 'product_family', title: 'Família de Produto' },
    { name: 'oportunidades', title: 'Oportunidades' },
    { name: 'call_plans', title: 'Call Plans' },
    { name: 'perc', title: '%' }
  ]

  const data = [
    {
      product_family: 'Graxas Automotivas',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      product_family: 'Graxas Industriais',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      product_family: 'Graxas de Aviação',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      product_family: 'Óleo de Processo',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    },
    {
      product_family: 'Óleo para Aviação',
      oportunidades: 0,
      call_plans: 0,
      perc: 0
    }
  ]

  return (
    <Paper>
      <Grid rows={data} columns={columns}>
        <Table />
        <TableHeaderRow />
      </Grid>
    </Paper>
  )
}

const FilterForm = ({ setor, setSetor, segmento, setSegmento }) => {
  const getSegmentList = async setor => {
    const perfis = ls.get('perfis')

    const { data } = await Axios.post(
      `${perfis[0].url}INT_SHELL_SPANCOP_BUSCA_SEGMENTOS`,
      {
        distribuidor: perfis[0].value,
        setor: setor?.value
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )

    const results = data.map(d => {
      return {
        value: d.descricao,
        label: d.descricao
      }
    })

    setSegmentList(results)
  }

  useEffect(() => {
    getSegmentList(setor)
    // eslint-disable-next-line
  }, [setor])

  const [segmentList, setSegmentList] = useAtom(segmentListAtom)

  return (
    <div className='flex flex-col'>
      <ReactSelect
        onChange={v => {
          setSetor(v)
        }}
        value={setor || ''}
        label='Setor'
        id='sector'
        options={setorList}
        allowSelectAll={false}
        placeholder='Selecione...'
        maxMenuHeight={200}
      />
      <ReactSelect
        onChange={v => {
          setSegmento(v)
        }}
        value={segmento || ''}
        label='Segmento'
        id='segment'
        options={segmentList}
        allowSelectAll={false}
        placeholder='Selecione...'
        maxMenuHeight={200}
      />
    </div>
  )
}

export default function Popsa() {
  const [setor, setSetor] = useState(false)
  const [segmento, setSegmento] = useState(false)
  return (
    <div className='flex gap-2'>
      <div className='flex flex-col w-1/2'>
        <div className='mb-2'>
          <TypeCustomerTable />
        </div>
        <div>
          <SectorSegmentTable />
        </div>
      </div>
      <div className='flex flex-col  w-1/2'>
        <div className='mb-2'>
          <FilterForm {...{ setor, setSetor, segmento, setSegmento }} />
        </div>
        <div>
          <ProductFamily />
        </div>
      </div>
    </div>
  )
}
