import { atom } from 'jotai'

export const researchAtoms = atom({
  id: '',
  distributor_name: '',
  id_pesquisa: '',
  cancelamento: '',
  question_1: '',
  question_2: '',
  question_2_1: '',
  question_2_1_1: '',
  question_2_2: '',
  question_2_2_1: '',
  question_3: '',
  question_3_1: '',
  question_3_1_1: '',
  question_3_2: '',
  question_3_2_1: '',
  question_3_2_2: '',
  question_3_3: '',
  question_3_3_1: '',
  question_4: '',
  question_4_1: '',
  question_4_1_1: '',
  question_5: '',
  question_5_1: '',
  question_5_1_1: '',
  question_5_2: '',
  question_5_2_1: '',
  question_5_3: '',
  question_5_4: '',
  question_5_5: '',
  question_5_6: '',
  question_5_7: '',
  question_6: '',
  question_6_1: '',
  question_6_1_1: '',
  question_6_1_2: '',
  question_6_1_3: '',
  question_7: ''
})

export const questionsAtoms = atom([])
