import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  ExportPanel,
  Grid,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui'
import { Paper } from '@material-ui/core'
import React, { useCallback, useRef } from 'react'
import saveAs from 'file-saver'
import { currentCampaignAtom } from '../../_global/Atoms'
import { useAtom } from 'jotai'
import { PercentCell } from '../PercentCell'

export default function ResultsTable({ data }) {
  const [currentCampaign] = useAtom(currentCampaignAtom)
  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'DataGrid.xlsx'
      )
    })
  }

  const exporterRef = useRef(null)

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  let columns

  if (currentCampaign.chave.value === 'cnpj') {
    columns = [
      { name: 'grupo_descricao', title: 'Grupo' },
      { name: 'distribuidor', title: 'Distribuidor' },
      { name: 'nome_vend1', title: 'Vendedor' },
      { name: 'cnpj', title: 'CNPJ' },
      { name: 'ibm_shell', title: 'IBM Shell' },
      { name: 'cidade', title: 'Cidade' },
      { name: 'estado', title: 'Estado' },
      { name: 'razao', title: 'Dealer Name' },
      { name: 'alvo', title: 'Alvo Total' },
      { name: 'faturado', title: 'Faturado Total' },
      { name: 'perc_total', title: '% Total' }
    ]
  } else {
    columns = [
      { name: 'grupo', title: 'Grupo' },
      { name: 'alvo', title: 'Alvo Total' },
      { name: 'faturado', title: 'Faturado Total' },
      { name: 'perc_total', title: '% Total' }
    ]
  }

  return (
    <Paper>
      <Grid columns={columns} rows={data}>
        <Table
          cellComponent={PercentCell}
          messages={{ noData: 'Nenhum dado encontrado' }}
        />
        <TableHeaderRow />
        <Toolbar />
        <ExportPanel startExport={startExport} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={columns}
        rows={data}
        onSave={onSave}
      />
    </Paper>
  )
}
