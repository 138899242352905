import React from 'react'
import { useAtom } from 'jotai'

import { filterDataAtom } from '../_global/Atoms'
import ReactSelect from '../Inputs/ReactSelect'
import { DateInput } from '../Inputs/DateInput'
import { listSituation, getTiposCliente } from '../../constData/constData'

import {
  changeFilterField,
  updateSelect,
  getDistributors
} from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const handleChange = (e, sendId) => {
    const newData = changeFilterField(e, sendId, filterData)
    setFilterData(newData)
  }

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap mb-4'>
        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            isMulti
            maxMenuHeight={200}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Emissão De *'
            id='emissao_inicial'
            selected={filterData.emissao_inicial}
            onChange={e => {
              handleChange(e, 'emissao_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Emissão até *'
            id='emissao_final'
            selected={filterData.emissao_final}
            onChange={e => {
              handleChange(e, 'emissao_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Venc de *'
            id='vencimento_inicial'
            selected={filterData.vencimento_inicial}
            onChange={e => {
              handleChange(e, 'vencimento_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Venc até *'
            id='vencimento_final'
            selected={filterData.vencimento_final}
            onChange={e => {
              handleChange(e, 'vencimento_final')
            }}
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'situacao', filterData, setFilterData)
            }}
            value={filterData.situacao}
            label='Situação *'
            id='situacao'
            options={listSituation()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'canal', filterData, setFilterData)
            }}
            value={
              filterData.canal
                ? filterData.canal
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Canal de Venda *'
            id='canal'
            options={getTiposCliente()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Pagamento de'
            id='pagamento_inicial'
            selected={filterData.pagamento_inicial}
            onChange={e => {
              handleChange(e, 'pagamento_inicial')
            }}
          />
        </div>
        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <DateInput
            label='Pagamento até'
            id='pagamento_final'
            selected={filterData.pagamento_final}
            onChange={e => {
              handleChange(e, 'pagamento_final')
            }}
          />
        </div>
      </div>
    </form>
  )
}
