import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Axios from 'axios'
import React, { useState } from 'react'
import xlsxParser from 'xlsx-parse-json'
import { LoadingIndicator } from '../_global/LoadingIndicator'
// import isReachable from 'is-reachable'

export default function UploadFWS() {
  const [open, setOpen] = useState(true)
  const [file, setFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)

  const sendData = async data => {
    // const reach = await isReachable(
    //   'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/'
    // )

    // if (!reach)
    //   setStatus('Não foi possível se conectar ao servidor.')
    //   return false
    // }

    try {
      const result = await Axios.post(
        'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_GRAVA_INTEGRADA_TRACKING',
        data,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      setStatus(result.data)
    } catch (error) {
      if (!error.response) {
        setStatus('Não foi possível se conectar ao servidor.')
      } else {
        setStatus(error.response.data.message)
      }
    }
  }

  const parseFile = () => {
    setStatus('Iniciando o processamento...')
    setLoading(true)
    xlsxParser.onFileSelection(file).then(data => processParsedData(data))
  }

  const processParsedData = data => {
    const newData = data['Data Base']
    const processData = newData.map((d, index) => {
      return {
        month_year: d['Month_Year'],
        division: d['Division'],
        distribuidor: d['Distributor'],
        ibm_dodo: d['IBM Dodo'],
        ibm_client: d['IBM Client'],
        cnpj: d['CNPJ'],
        client: d['Client'],
        group: d['Group'],
        dsc_segmento: d['Dsc Segmento Mercado'],
        consultor: d['Consultor - F2_VEND'],
        kam: d['KAM - F2_VEND2'],
        supervisor: d['Supervisor - A3_SUPER do F2_VEND'],
        city: d['City'],
        state: d['State'],
        sku: d['SKU'],
        sku_desc: d['SKU_Desc'],
        sum_vol: parseFloat(d['SumOfVol_LT']),
        sum_proceeds: parseFloat(d['SumOfProceeds']),
        sum_net_proceeds: parseFloat(d['SumOfNet_Proceeds'])
      }
    })

    sendData(processData)
    setLoading(false)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Enviar arquivo FWS</DialogTitle>
      <DialogContent>
        <Button variant='contained' component='label' fullWidth>
          Selecionar Arquivo
          <input
            type='file'
            hidden
            onChange={e => setFile(e.target.files[0])}
          />
        </Button>
        {file && (
          <div className='w-full mt-2'>
            <Alert severity='info'> Arquivo selecionado: {file.name}</Alert>
          </div>
        )}
        {loading && <LoadingIndicator />}
        {status && <Alert severity='warning'>{status}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => parseFile()}
          size='large'
        >
          Enviar
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => setOpen(false)}
          size='small'
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
