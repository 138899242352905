import React, { useState } from 'react'
import {
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSummary,
  SortingState,
  SummaryState,
  PagingState,
  IntegratedPaging
} from '@devexpress/dx-react-grid'
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableGroupRow,
  TableSummaryRow,
  TableFixedColumns,
  ExportPanel,
  Toolbar,
  PagingPanel,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui'
import { PercentCell } from '../PercentCell'

export default function IndividualGrid({
  columns,
  data,
  sorting,
  setSorting,
  filters,
  setFilters,
  grouping,
  groupSummaryItems,
  columnWidths,
  setColumnWidths,
  startExport,
  leftColumns
}) {
  const [pageSizes] = useState([25, 50, 100, 0])

  return (
    <Grid columns={columns} rows={data}>
      <PagingState defaultCurrentPage={0} defaultPageSize={25} />
      <IntegratedPaging />
      <SortingState sorting={sorting} onSortingChange={setSorting} />
      <FilteringState filters={filters} onFiltersChange={setFilters} />
      <GroupingState grouping={grouping} />
      <SummaryState groupItems={groupSummaryItems} />

      <IntegratedGrouping />
      <IntegratedSummary />
      <IntegratedFiltering />
      <IntegratedSorting />
      <VirtualTable
        cellComponent={PercentCell}
        messages={{ noData: 'Nenhum dado encontrado' }}
        height={350}
      />

      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />

      <TableHeaderRow
        messages={{ sortingHint: 'Ordenar por' }}
        showSortingControls
      />
      <PagingPanel pageSizes={pageSizes} />
      <Toolbar />
      <ExportPanel
        startExport={startExport}
        messages={{ exportAll: 'Exportar todos os dados' }}
      />

      <TableFilterRow messages={{ filterPlaceholder: 'Filtrar...' }} />
      <TableGroupRow showColumnsWhenGrouped />
      <TableSummaryRow />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  )
}
