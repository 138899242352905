const BOPadrao = [
  'Distribuidor',
  'Cod Cliente',
  'Loja',
  'CNPJ',
  'Razão Social',
  'Nome Mes',
  'Emissão',
  'AnoMes',
  'Produto',
  'Tier',
  'Familia',
  'Aplicação',
  'Descrição',
  'Embalagem',
  'Mega Seg',
  'Cod Seg',
  'Nome Seg',
  'Volume',
  'Vol. Total',
  'Quantidade',
  'Receita',
  'Numero NF',
  'Serie',
  'CFOP',
  'Municipio',
  'Estado',
  'Cod IBGE',
  'Cod Fornec',
  'Cod Vendedor',
  'Banding',
  'Custo',
  'Tipo',
  'Valor Liquido',
  'Tipo Cliente',
  'Clube Status',
  'IBM Shell',
  'Cod Vendedor2',
  'IBM Cliente',
  'Armazem',
  'Cond Pgto',
  'Prazo Medio',
  'Grupo Alvorada',
  'Oferta Integrada',
  'CNPJ Fornecedor',
  'Pitstop',
  'Peso Bruto',
  'Transportadora',
  'Ano',
  'Combo',
  'Cod Combo',
  'DT Emis PV',
  'Pedido',
  'Custo Reposicao',
  'Bonificação',
  'Nome do Vendedor',
  'Origem'
]

const BOCliente = [
  'Distribuidor',
  'Cod Vendedor',
  'Código',
  'Filial',
  'Loja',
  'CNPJ/CPF',
  'Fisica/Juridica',
  'Inscr.Estadual',
  'Nome',
  'N Fantasia',
  'Contato',
  'DDD',
  'Telefone',
  'Telex',
  'E-mail',
  'Pot.Cliente',
  'Segmento 1',
  'Segmento 2',
  'Endereço',
  'Cidade',
  'Estado',
  'Banding',
  'Ult.Compra',
  'Data Inclusão',
  'Dia Visita',
  'Frequencia',
  'Sequência',
  'Semana',
  'Status Clube',
  'Status PDV',
  'Granel',
  'Coleta Preço',
  'Grupo de Clientes - FWS'
]

const BOTitulos = [
  'Distribuidor',
  'Filial',
  'Vendedor',
  'Razão Social',
  'CNPJ',
  'Canal de Venda',
  'Numero Documento',
  'Data Emissão',
  'Data Vencimento',
  'Valor Original',
  'Valor Pago',
  'Valor Saldo'
]

const BOCombo = [
  'Distribuidor',
  'Cliente',
  'CNPJ',
  'Status Churn',
  'Banding',
  'Clube Status',
  'Numero NF',
  'Data Emissão NF',
  'Combo',
  'Código SKU',
  'Produto Shell',
  'Tipo Produto',
  'Quantidade Combo',
  'Quantidade Produto',
  'Valor Unitário Bruto',
  'Valor Total Bruto',
  'Volume Vendido',
  'Mês',
  'Referência Combo',
  'Vendedor',
  'Código Vendedor',
  'CFOP',
  'Segmento1',
  'Desc. Segmento1',
  'Tipo Cliente Shell'
]

const BOPedidosPorSKU = [
  'IBM',
  'Base',
  'SKU',
  'Descrição do Material',
  'Volume',
  'Quantidade',
  'Mês/Ano',
  'Origem',
  'Emb'
]

const BOProduto = [
  'Distribuidor',
  'SKU',
  'Descrição',
  'Tier',
  'Família',
  'Volume',
  'Tipo'
]

const BOPedidos = [
  'Distribuidor',
  'Célula',
  'Código Cliente',
  'IBM Cliente',
  'CNPJ',
  'Razão Social',
  'Fantasia',
  'Tipo Cliente',
  'Código Segmento',
  'Segmento',
  'Número Pedido',
  'Data Emissão',
  'Mês',
  'Ano',
  'SKU',
  'Descrição Produto',
  'Classe',
  'Família',
  'Tier',
  'Embalagem',
  'Aplicação',
  'Origem',
  'Tipo Produto',
  'Volume',
  'Quantidade Vendida',
  'Volume Vendido',
  'Valor Bruto',
  'Valor Líquido',
  'IBM Distribuidor',
  'Armazem',
  'Código Vendedor',
  'TMK',
  'Importado Blink',
  'Origem Pedido',
  'CFO',
  'Bonificação',
  'Nota Fiscal',
  'Status Pedido',
  'Código Fornecedor',
  'Fornecedor'
]

const BODashboardClube = [
  'Distribuidor',
  'CNPJRevenda CLIENTE',
  'Revenda NOME CLIENTE',
  'Tipo Revenda',
  'Primeiro Acesso',
  'Periodo',
  'Situacao',
  'Alvo',
  'Realizado Total',
  'Realizado Premium',
  'Realizado Mainstream',
  'Pontos Liberados',
  'Pontos Bloqueados',
  'Zona Venda VENDEDOR',
  'Total Influenciadores',
  'n Influenciadores Tampinhas',
  'n Influenciadores Treinamento',
  'Tampinhas',
  'Treinamentos',
  'Churn',
  'Programa',
  'Ativo',
  'Engajado',
  'Influenciador',
  'UF',
  'e Commerce'
]

const BOVisitaBPS = [
  'Gerente',
  'Supervisor',
  'Código Vendedor',
  'Vendedor',
  'Tipo de Cliente',
  'Código Filial',
  'Código Cliente',
  'Loja',
  'CNPJ',
  'Razão Social',
  'Cidade',
  'Estado',
  'Data da Visita',
  'Fim da Visita',
  'Tempo de Visita',
  'Distância do Cliente',
  'Precisão',
  'Cliente do Roteiro',
  'Cliente Ativo A1_MSBLQL',
  'Pedido/Motivo - STATUS AD7',
  'Status da Visita',
  'Observações',
  'Observações Visita',
  'Próximas Ações',
  'Frequência'
]

const RLEstoque = [
  'Distribuidor',
  'SKU',
  'Descrição',
  'Família',
  'Código Filial',
  'Armazém',
  'Estoque Atual',
  'Estoque Empenho',
  'Estoque Pedidos',
  'Estoque Disponível',
  'Disponível - Pedidos',
  'Valor Estoque',
  'Volume Estoque',
  'Valor Empenho',
  'Tipo Local'
]

const RLTitulos = [
  'Distribuidor',
  'Vendedor',
  'Razão Social',
  'CNPJ',
  'Canal de Venda',
  'Número Documento',
  'Data Emissão',
  'Data Vencimento',
  'Valor Original',
  'Valor Pago',
  'Valor Saldo'
]

const BONovo = [
  'Distribuidor',
  'Código Vendedor 1',
  'Vendedor 1',
  'Código Cliente',
  'CNPJ',
  'Razão Social',
  'Fantasia',
  'Endereço',
  'Bairro',
  'Cidade',
  'Mícro Região',
  'Meso Região',
  'Estado',
  'CEP',
  'IBMBroker',
  'Clube Status',
  'Tipo CLiente',
  'Classificação do Clube',
  'Código Segmento',
  'Segmento',
  'Data Emissão',
  'Ano Mês',
  'Data Entrega',
  'SKU',
  'Descrição Produto',
  'Classe',
  'Família',
  'Tier',
  'Aplicação',
  'Origem',
  'Tipo Produto',
  'Volume',
  'CFO',
  'Número NF',
  'Série',
  'Quantidade Vendida',
  'Volume Vendido',
  'Faturamento Bruto',
  'Valor Líquido',
  'Valor Custo',
  'IBM Shell',
  'Embalagem',
  'Código Vendedor2',
  'Armazém',
  'Grupo Alvorada',
  'Oferta Integrada',
  'Pit Stop',
  'Código Supervisor',
  'Supervisor',
  'Código Gerente',
  'Gerente',
  'Combo',
  'Código Combo',
  'Peso Bruto',
  'Transportadora',
  'Custo Reposição',
  'Código Município',
  'Condição Pagamento',
  'Prazo Médio'
]

const BONovo2 = [
  'Distribuidor',
  'Filial  Anterior',
  'Código Vendedor 1',
  'Vendedor 1',
  'Célula',
  'Código Cliente',
  'CNPJ',
  'Razão Social',
  'Fantasia',
  'Endereço',
  'Bairro',
  'Cidade',
  'Micro Região',
  'Meso Região',
  'Estado',
  'Região',
  'Cep',
  'IBMBroker',
  'Clube Status',
  'Tipo Cliente',
  'Banding',
  'Código Segmento',
  'Segmento',
  'Data Emissão',
  'Mês',
  'Ano',
  'Ano Mês',
  'Data Entrega',
  'SKU',
  'Descrição Produto',
  'Classe',
  'Família',
  'Tier',
  'Aplicacao',
  'Origem',
  'Tipo Produto',
  'Volume',
  'CFO',
  'Número NF',
  'Série',
  'Quantidade Vendida',
  'Volume Vendido',
  'Faturamento Bruto',
  'Valor Líquido',
  'Valor Líquido Shell',
  'Valor Custo',
  'IBM Distribuidor',
  'Tipo Produto Global',
  'IBM Shell',
  'Embalagem',
  'Código Vendedor2',
  'Armazém',
  'Grupo Alvorada',
  'Rede Top',
  'Segmentação',
  'Troca Óleo',
  'Pit Stop',
  'Código Supervisor',
  'Supervisor',
  'Código Gerente',
  'Gerente',
  'Combo',
  'Código Combo',
  'Peso Bruto',
  'Transportadora',
  'Custo Reposicao',
  'Código Município',
  'Condição Pagamento',
  'Prazo Médio',
  'Tipo Produto 2'
]

const BOScore = [
  'Distribuidor',
  'IBM Raízen',
  'Data',
  'Vendedor',
  'Nome Vendedor',
  'Cliente',
  'Loja',
  'Nome Cliente',
  'Possui troca de óleo',
  'Pista Capo',
  'Pista Tampinha',
  'Conhece Promoção',
  'Craque Troca',
  'Conhece Alvo',
  'Produtos Limpos',
  'Produtos Precificados 28',
  'Exclusivo Shell',
  'Tabela Exposta',
  'Painel Exposto',
  'Promocao Vigente',
  'Expostos Limpos',
  'Produtos Precificados 45',
  'Score Total'
]

const ApproveExtra = [
  '',
  '',
  'SKU',
  'Distribuidor',
  'Produto',
  'Armazém',
  'Qtd Trava (Embalagem)',
  'Qtd Comprada',
  'Qtd Extra',
  'Qtd Trava (Nova)'
]

const ApproveExtraExport = [
  'id',
  'sku',
  'distribuidor',
  'produto',
  'armazem',
  'limite',
  'pedido',
  'extra',
  'nova_trava'
]

const FeeEstoque = [
  'Distribuidor',
  'Código',
  'Descrição',
  'Est. Inicial - Embalagens',
  'Est. Inicial - Vol Lts',
  'Est. Inicial - Preço Unit',
  'Est. Inicial - Preço Total',
  'Compras - Emb',
  'Compras -Vol Lts',
  'Compras -Valor',
  'Mov Estoques - Emb',
  'Mov Estoques - Vol Lts',
  'Mov Estoques - Valor',
  'Dev. Compras - Emb',
  'Dev. Compras - Vol Lts',
  'Dev. Compras - Valor',
  'Est. Total - Emb',
  'Est. Total - Vol Lts',
  'Est. Total - Preço Unitário',
  'Est. Total - Preço Total',
  'Vendas - Emb',
  'Vendas - Vol Lts',
  'Vendas - CMV',
  'Vendas - Receita',
  'Vend. Fut. - Emb',
  'Vend. Fut. - Vol Lts',
  'Vend. Fut. - CMV',
  'Vend. Fut. - Receita',
  'Ent. Fut. - Emb',
  'Ent. Fut. - Vol Lts',
  'Ent. Fut. - Valor',
  'Doações - Emb',
  'Doações - Vol Lts',
  'Doações - Valor',
  'Est. Total - Emb',
  'Est. Total - Vol Lts',
  'Est. Total - Uni',
  'Est. Total - Valor'
]

const BOFinancas = [
  'Distribuidor',
  'IBM Distribuidor',
  'Cod Cliente',
  'Loja',
  'CNPJ',
  'Razão Social',
  'Mês',
  'Emissão',
  'AnoMes',
  'Produto',
  'Impacto',
  'Tier',
  'Família',
  'Aplicação',
  'Descrição',
  'Embalagem',
  'Mega Seg.',
  'Cod. Seg.',
  'Nome Seg.',
  'Volume',
  'Vol Total',
  'Quantidade Vendida',
  'Receita',
  'Número NF',
  'Série',
  'CFOP',
  'Município',
  'Estado',
  'Cod IBGE',
  'Cod. Fornecedor',
  'Cod. Vendedor',
  'Banding',
  'Contador',
  'Custo',
  'Filial Dirtr',
  'Tipo',
  'Valor Líquido',
  'Tipo Cliente',
  'Clube Status',
  'Tipo Produto Global',
  'IBM Shell',
  'Cod Vendedor 2',
  'IBM Cliente',
  'Armazém',
  'Condição Pagamnento',
  'Prazo Médio',
  'Grupo Alvorada',
  'Rede Top',
  'Oferta Integrada',
  'Valor Líquido Shell',
  'Nome Filial Gerencial',
  'Nome Filial Painel',
  'Família Troca Certa',
  'Família Macro',
  'Pilar Plano',
  'CNPJ Fornecedor',
  'Segmentação',
  'Troca de Óleo',
  'Pitstop',
  'Peso Bruto',
  'Transportadora',
  'Ano',
  'Combo',
  'Código Combo',
  'Data de Emissão do Pedido',
  'Número do Pedido',
  'Custo Reposição',
  'Código Município'
]

const BOFee = [
  'Distribuidor',
  'IBM Distribuidor',
  'Cod Cliente',
  'Loja',
  'CNPJ',
  'Razão Social',
  'Mês',
  'Emissão',
  'AnoMes',
  'Produto',
  'Impacto',
  'Tier',
  'Família',
  'Aplicação',
  'Descrição',
  'Embalagem',
  'Mega Seg.',
  'Cod. Seg.',
  'Nome Seg.',
  'Volume',
  'Vol Total',
  'Quantidade Vendida',
  'Receita',
  'Número NF',
  'Série',
  'CFOP',
  'Município',
  'Estado',
  'Cod IBGE',
  'Cod. Fornecedor',
  'Cod. Vendedor',
  'Banding',
  'Contador',
  'Custo',
  'Filial Dirtr',
  'Tipo',
  'Valor Líquido',
  'Tipo Cliente',
  'Clube Status',
  'Tipo Produto Global',
  'IBM Shell',
  'Cod Vendedor 2',
  'IBM Cliente',
  'Armazém',
  'Condição Pagamnento',
  'Prazo Médio',
  'Grupo Alvorada',
  'Rede Top',
  'Oferta Integrada',
  'Valor Líquido Shell',
  'Nome Filial Gerencial',
  'Nome Filial Painel',
  'Família Troca Certa',
  'Família Macro',
  'Pilar Plano',
  'CNPJ Fornecedor',
  'Segmentação',
  'Troca de Óleo',
  'Pitstop',
  'Peso Bruto',
  'Transportadora',
  'Ano',
  'Combo',
  'Código Combo',
  'Data de Emissão do Pedido',
  'Número do Pedido',
  'Custo Reposição',
  'Código Município',
  'CMV Realizado',
  'Tipo Conta'
]

const BOPesquisaSatisfacao = [
  'Distribuidor',
  'Razão Social',
  'CNPJ',
  'Cidade',
  'Estado',
  'Telefone',
  'Banding',
  'Tipo Cliente',
  'Potencial SA1',
  'Ano',
  'Mes',
  'Satisfação Geral com o DODO',
  '(2.1) - O atendimento do distribuidor via telefone atende suas expectativas?',
  '(2.1.1) - Principais fatores de sua insatisfação com o atendimento telefônico',
  '(2.2) - O Atendimento do consultor atende suas expectativas?',
  '(2.2.1) Fatores de sua insatisfação com o consultor',
  'Satisfação geral com atendimento DODO',
  '(3.1) - Suas entregas tem sido completas conforme produtos e quantidades solicitadas?',
  '(3.1.1) - Quais os fatores de sua insatisfação com a Entrega/Coleta?',
  '(3.2) - Seu produto foi entregue conforme o tempo esperado e/ou comunicado pelo seu consultor?',
  '(3.2.1) Foi comunicado quanto ao atraso e/ou causa do atraso da entrega?',
  '(3.2.2) - Motivos identificados para o atraso e/ou causa do atraso:',
  '(3.3) - Seus produtos chegaram em boas condições?',
  '(3.3.1) - Informe por favor, os problemas encontrados com os produtos:',
  'Satisfação geral com entrega/coleta DODO',
  '(4.1) - Tem recebido informações sobre promoções ou incentivos relevantes para o seu negócio?',
  '(4.1.1) - Por favor, identifique os problemas quanto às promoções/incentivos:',
  'Satisfação geral com as promoções/incentivos DODO',
  '(5.1) Você tem recebido treinamentos adequados?',
  '(5.1.1) Por favor, identifique seu problema com os treinamentos:',
  '(5.2) - Você tem recebido suporte técnico adequado?',
  '(5.2.1) - Por favor, identifique seus problemas com relação ao suporte técnico:',
  'Satisfação geral com o suporte técnico',
  '(7) - Você nos autoriza como seu fornecedor a contatá-lo?',
  '(7.1) - Pode nos confirmar seus dados de contato?',
  '(7.1.1) - Por favor, informe seu nome:',
  '(7.1.2) - Telefone',
  '(7.1.3) - E-mail:',
  '(8) - Outros comentários que gostaria de fazer'
]

const BOMatinalVespertina = [
  'Distribuidor',
  'Supervisor',
  'Data',
  'Vendedor',
  'Volume Shell Matinal',
  'Volume Shell Objetivo',
  'Volume Shell Realizado',
  'Volume Shell Vespertina',
  'Efetivos Matinal',
  'Efetivos Objetivo',
  'Efetivos Realizado',
  'Efetivos Vespertina',
  'Premium Matinal',
  'Premium Objetivo',
  'Premium Realizado',
  'Premium Vespertina',
  'Canal'
]

const BOCoaching = [
  'Distribuidor',
  'Supervisor',
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
  'Canal'
]

const BOMatrizDesempenho = [
  'Distribuidor',
  'Supervisor',
  'Vendedor',
  'Data',
  'Visitas',
  'Merchandising',
  'Exposição',
  'Precificação',
  'Verificar Planejamento PDV',
  'Verificar Merchandising',
  'Verificar Exposição',
  'Verificar Checklist do Cliente Clube Profissional',
  'Verificar Estoque',
  'Verificar Preços',
  'Vender Produtos Indicados'
]

const BOSpancopCallPlan = [
  'Distribuidor',
  'Vendedor',
  'Empresa',
  'Data prevista Início',
  'Tipo Visita',
  'Realizado',
  'Não Realizado',
  'Data Conclusão',
  'Propósito',
  'Objetivo',
  'Premissa',
  'Estratégia',
  'Antecipar',
  'Notas',
  'Informações Adicionais',
  'Conclusão'
]

const BOHSSE = [
  'Distribuidor',
  'Vendedor',
  'Data do Report',
  'Tipo do Report',
  'Título',
  'Descrição'
]

const FeeResumo = [
  'Distribuidor',
  'Tipo Conta',
  'Volume',
  'Receita',
  'CMV Realizado',
  'MB'
]

const BOColetas = [
  'Empresa',
  'Data',
  'Código do Vendedor',
  'Célula',
  'Ramo Atividade - Segmento1',
  'Banding',
  'IBM do Cliente',
  'Código Cliente',
  'CNPJ',
  'Fantasia',
  'Razão Social',
  'Estado',
  'Cidade',
  'Código SKU',
  'Descrição',
  'Equivalente Shell',
  'Marca',
  'Embalagem',
  'Unidade',
  'Classe',
  'Preço In',
  'Preço Out',
  'Última Compra',
  'Condição Pagamento'
]

const BOJustificativaColeta = [
  'Distribuidor',
  'Data',
  'Código do Vendedor',
  'Nome do Vendedor',
  'Atividade',
  'Banding',
  'IBM do Cliente',
  'Código do Cliente',
  'CNPJ',
  'Fantasia',
  'Razão Social',
  'Estado',
  'Cidade',
  'Justificativa'
]

const ChecklistMotoClube = [
  'Distribuidor',
  'Data',
  'Vendedor',
  'Nome Vendedor',
  'Código Cliente',
  'Loja Cliente',
  'Nome Cliente',
  'VerificadoAlvoRealizadoMes',
  'CliAcessouSiteUltimos30DiaOrie',
  'VerifPontosLibBloqCliente',
  'TemPropostaPacoteBeneficios',
  'CadastroInfluenciadoresRealiz',
  'ReforcoInfluenciadoresTreinam',
  'MaterialMerchandisingAtualizad',
  'PromocaoVigente',
  'ImplementacaoPromocaoSmartphon',
  'VerificadoInfluPdvPerforPromo',
  'OfereceuNovoPacote'
]

const ChecklistClubeProfissional = [
  'Distribuidor',
  'Data',
  'Vendedor',
  'Nome Vendedor',
  'Código Cliente',
  'Loja Cliente',
  'Nome Cliente',
  'CadastroInfluenciadoresRealiz',
  'CadastroTampinasRealizadoSuces',
  'ReforcoInfluenciadoresTreinam',
  'RelembrarNivelInfluenciadorRev',
  'MaterialMerchandisingAtualizad',
  'PromocaoVigente',
  'ImplementacaoPromocaoSmartphon',
  'VerificadoInfluPdvPerforPromo',
  'OfereceuNovoPacote',
  'VerificadoAlvoRealizadoMes',
  'CliAcessouSiteUltimos30DiaOrie',
  'VerifPontosLibBloqCliente',
  'TemPropostaPacoteBeneficios'
]

const CheckListVarejo = [
  'Distribuidor',
  'Data',
  'Vendedor',
  'Nome Vendedor',
  'Código Cliente',
  'Loja Cliente',
  'Nome Cliente',
  'AcaoPendenteChecklistUltVisita',
  'AlvoXrealVcAlvoTrimestre',
  'AlvoXrealVcAlvoAnoSafra',
  'AlvoXrealVcAlvoMesVigente',
  'ExistePromocaoVigenteOfertada',
  'PortoTemEspecialistaCadastrado',
  'PostoCadastraTampinhas',
  'TemInfluenc100TreinamentosReal',
  'PostoFezDistPontosRimula',
  'TemTrocaDeOleo',
  'TimePistaTrocaPedeAberturaCapo',
  'TimePistaTrocaCadastraTampinha',
  'TimePistaTrocaConhecePromocao',
  'TrocadorConheceCraqueTroca',
  'GerenteConheceAlvoLubVoceConqu',
  'ProdutosLimposExposicaoPadrao',
  'ProdutosEstaoPrecificados28',
  'PostoTrabalhaExclusiveLubShel',
  'TabelaLubAtuaEstaExposta',
  'PainelDigitalPriceExpostoPree',
  'MaterialPVPromocaoVigente',
  'ProdutosExpostosEstaoLimpos',
  'ProdutosEstaoPrecificados45',
  'NivelEstoqueShellBaixo',
  'PostoTemTrocaCerta',
  'SeSimQualProduto',
  'VolumeMinimoLiberarPromocao',
  'VolumeEstimadoDespejo',
  'ViscosidadesApresentamDespejo',
  'OutraViscosidadeComDespejo',
  'MarcasDespejadasPosto',
  'AlgumaOutraMarcaDespejadaPost',
  'OndeEstaLocalizadoDespejo',
  'MotivoDespejo',
  'AcoesMapeadas'
]

const OportunidadesSpancop = [
  'Distribuidor',
  'Supervisor',
  'Nome Supervisor',
  'Vendedor',
  'Nome Vendedor',
  'Empresa',
  'Tipo Negociação',
  'Volume Estimado',
  'Volume Previsto',
  'Volume Realizado',
  'Criação',
  'Suspeito',
  'Prospecção',
  'Análise',
  'Negociação',
  'Contrato',
  'Ordem',
  'Pagamento',
  'Produto',
  'Última Atualização',
  'Lost',
  'Ranking'
]

const BOPromocoes = [
  'Distribuidor',
  'Vendedor',
  'Nome Vendedor',
  'Cliente',
  'Loja',
  'Nome Cliente',
  'Produto',
  'Descrição Produto',
  'Quantidade'
]

const BOTrocaOleo = [
  'Distribuidor',
  'Vendedor',
  'Nome Vendedor',
  'Cliente',
  'Loja',
  'Nome Cliente',
  'CNPJ',
  'Tem Troca',
  'Data'
]

const BOMovInternos = [
  'Distribuidor',
  'Filial',
  'Emissão',
  'TM',
  'CF',
  'Produto',
  'Descrição Produto',
  'UM',
  'Quantidade',
  'Custo',
  'Armazém',
  'Tipo',
  'Documento'
]

module.exports = {
  BOPadrao,
  BOCliente,
  BOTitulos,
  BOPedidosPorSKU,
  BOCombo,
  BOProduto,
  BOVisitaBPS,
  RLEstoque,
  BOPedidos,
  RLTitulos,
  BODashboardClube,
  BONovo,
  BONovo2,
  BOScore,
  ApproveExtra,
  ApproveExtraExport,
  FeeEstoque,
  BOFinancas,
  BOPesquisaSatisfacao,
  BOMatinalVespertina,
  BOCoaching,
  BOMatrizDesempenho,
  BOSpancopCallPlan,
  BOHSSE,
  BOFee,
  FeeResumo,
  BOColetas,
  BOJustificativaColeta,
  CheckListVarejo,
  ChecklistClubeProfissional,
  ChecklistMotoClube,
  OportunidadesSpancop,
  BOPromocoes,
  BOTrocaOleo,
  BOMovInternos
}
