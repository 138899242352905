import React, { useState } from 'react'

import InputForm from '../Inputs/InputForm'
import {
  getDistributorsWithoutFiliais,
  listWarehouse
} from '../../constData/constData'
import ReactSelect from '../Inputs/ReactSelect'
import { useAtom } from 'jotai'
import { filterDataAtom } from './Atoms'
import { loadAPI } from '../../functions/functions'
import Axios from 'axios'
import { distributors } from '../../constData/urls'

const Form = () => {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [periods, setPeriods] = useState([])

  const changeFilterField = (e, sendId) => {
    const value = sendId === 'date_range' ? e : e.target.value

    const update = {
      id: sendId ? sendId : e.target.id,
      value: value
    }
    setFilterData({ ...filterData, [update.id]: update.value })
  }

  const updateSelect = (value, field) => {
    setFilterData({ ...filterData, [field]: value })
  }

  const granelOptions = [
    {
      label: 'NÃO',
      value: 'N'
    },
    {
      label: 'SIM',
      value: 'S'
    }
  ]

  const getPeriods = v => {
    const getData = async () => {
      //eslint-disable-next-line
      const { url, value, key } = v
      const apiUrl = loadAPI('LimitePCPeriods', '', url)

      const perfil = distributors.find(item => item.id === key)

      const { data } = await Axios.get(
        `${apiUrl.url}?distribuidor=${perfil.distribuidor.trim()}`
      )
      let id = 0

      const newPeriods = data.map(item => {
        return {
          value: `${item.dataini} - ${item.datafim}`,
          label: `${item.dataini} - ${item.datafim}`,
          key: id++
        }
      })

      setPeriods(newPeriods)
    }
    getData()
  }

  return (
    <form className='w-full mt-2'>
      <div className='flex flex-wrap  mb-4'>
        <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData)
              getPeriods(v)
            }}
            value={filterData.distribuidor}
            label='Distribuidor'
            id='distribuidor'
            options={getDistributorsWithoutFiliais()}
            allowSelectAll={false}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <InputForm
            onChange={changeFilterField}
            value={filterData.sku}
            label='SKU'
            id='sku'
          />
        </div>

        <div className='w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3'>
          <InputForm
            onChange={changeFilterField}
            value={filterData.descri}
            label='Descrição'
            id='descri'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'armazem', filterData)
            }}
            value={
              filterData.armazem
                ? filterData.armazem
                : { value: '', label: 'Todos', key: 1 }
            }
            label='Armazém'
            id='armazem'
            options={listWarehouse()}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'granel', filterData)
            }}
            value={filterData.granel}
            label='Granel'
            id='granel'
            placeholder='Selecione...'
            options={granelOptions}
          />
        </div>

        <div className='w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'date_range')
            }}
            value={filterData.date_range}
            label='Periodo'
            id='date_range'
            options={periods}
            placeholder='Selecione...'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <InputForm
            onChange={changeFilterField}
            value={filterData.meses_suges}
            label='Meses Sugestão'
            id='meses_suges'
          />
        </div>

        <div className='w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6 px-3'>
          <InputForm
            onChange={changeFilterField}
            value={filterData.perc_suges}
            label='% Acr. Sugestão'
            id='perc_suges'
          />
        </div>
      </div>
    </form>
  )
}

export default Form
