import { dateFromBRString } from '../functions'
import {
  acompanhamentoPor,
  chave,
  tiposAgrupamento,
  tiposApuracao
} from '../../constData/constData'

export const transformData = data => {
  const newData = data.map(item => {
    const arrTiposApuracao = item.tipo_apuracao.map(i => {
      return tiposApuracao.find(t => t.value === i)
    })
    const arrTipoAgrupamento = tiposAgrupamento.find(
      t => t.value === item.tipo_agrupamento
    )
    const arrChave = chave.find(c => c.value === item.chave)
    const arrAcompanhamentoPor = acompanhamentoPor.find(
      i => i.value === item.acompanhamento_por
    )

    return {
      ...item,
      data_inicial: dateFromBRString(item.data_inicial),
      data_final: dateFromBRString(item.data_final),
      tipo_apuracao: arrTiposApuracao,
      tipo_agrupamento: arrTipoAgrupamento,
      chave: arrChave,
      segmento: { value: item.segmento, label: item.segmento, key: 1 },
      acompanhamento_por: arrAcompanhamentoPor
    }
  })

  return newData.sort((a, b) => a.id - b.id)
}
