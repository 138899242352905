import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { Cancel, Edit, Save } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import api from '../../api/api'
import InputForm from '../Inputs/InputForm'
import ReactSelect from '../Inputs/ReactSelect'

const listProfiles = () => {
  return [
    { value: 'master', label: 'master' },
    { value: 'quite', label: 'quite' },
    { value: 'dellas', label: 'dellas' },
    { value: 'formula', label: 'formula' },
    { value: 'lubtrol', label: 'lubtrol' },
    { value: 'brazmax', label: 'brazmax' },
    { value: 'lubpar', label: 'lubpar' },
    { value: 'gamma', label: 'gamma' },
    { value: 'portolub', label: 'portolub' },
    { value: 'agricopel', label: 'agricopel' },
    { value: 'nacional', label: 'nacional' },
    { value: 'boanova', label: 'boanova' },
    { value: 'gasoleo', label: 'gasoleo' },
    { value: 'planejamento', label: 'planejamento' },
    { value: 'pricing', label: 'pricing' },
    { value: 'wesley.junior', label: 'wesley.junior' },
    { value: 'paulo.freitas', label: 'paulo.freitas' },
    { value: 'spm', label: 'spm' },
    { value: 'rodolfo.godoy', label: 'rodolfo.godoy' },
    { value: 'marcos.donizete', label: 'marcos.donizete' },
    { value: 'rodrigo.santos', label: 'rodrigo.santos' },
    { value: 'frederico.petrucelli', label: 'frederico.petrucelli' },
    { value: 'guilherme.takamine', label: 'guilherme.takamine' },
    { value: 'marketing', label: 'marketing' }
  ]
}

const AlterUser = ({ open, setOpen, user }) => {
  const profiles = listProfiles()
  const [pwd, setPwd] = useState('')
  const [perfil, setPerfil] = useState({
    label: user.perfil,
    value: user.perfil
  })
  const [message, setMessage] = useState(false)

  const updateUser = async () => {
    const json = {
      pwd,
      perfil: perfil.value,
      id: user._id
    }
    await api.updateUser(json)
    setMessage(true)

    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Alterando usuário {user.user}</DialogTitle>
      <DialogContent>
        <div className='h-72'>
          <InputForm
            onChange={e => setPwd(e.target.value)}
            value={pwd}
            label='Senha'
            id='pwd'
          />
          <ReactSelect
            onChange={v => {
              setPerfil(v)
            }}
            value={perfil}
            label='Perfil'
            id='perfil'
            options={profiles}
            placeholder='Selecione...'
            maxMenuHeight={70}
          />
        </div>
        {message && (
          <div className='mt-3'>
            <Alert severity='info'>
              Usuário alterado com sucesso! Aguarde...
            </Alert>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<Cancel />}
          onClick={() => setOpen(false)}
        >
          Fechar sem alterar
        </Button>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<Save />}
          onClick={updateUser}
        >
          Salvar usuário
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const InactivateUser = ({ open, setOpen, user }) => {
  const [message, setMessage] = useState(false)

  const inactivateUser = async () => {
    const json = {
      pwdPlain: '111',
      perfil: user.perfil,
      id: user._id
    }
    await api.updateUser(json)
    setMessage(true)

    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Inativando usuário {user.user}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja inativar este usuário? (Para reativá-lo basta
          alterar sua senha novamente quando desejar)
        </DialogContentText>
        {message && (
          <div className='mt-3'>
            <Alert severity='info'>
              Usuário alterado com sucesso! Aguarde...
            </Alert>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<Cancel />}
          onClick={() => setOpen(false)}
        >
          Não
        </Button>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<Save />}
          onClick={inactivateUser}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function UserItem({ user, profiles }) {
  const [openAlter, setOpenAlter] = useState(false)
  const [openInactive, setOpenInactive] = useState(false)

  return (
    <>
      <div className='w-1/4 rounded bg-gray-200 flex border border-gray-500 flex-col p-3 mb-2 '>
        <div className=' border-b-2 border-gray-400 text-xl'>{user.user}</div>
        <div className='mt-1'>
          Perfil | <span className='text-orange-500'>{user.user}</span>{' '}
          {user.pwd === '111' && <Chip label='Inativo' />}
        </div>
        <div className='flex justify-between mt-3'>
          <Button
            variant='outlined'
            color='secondary'
            startIcon={<Cancel />}
            disabled={user.pwd === '111'}
            onClick={() => setOpenInactive(true)}
          >
            Inativar
          </Button>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Edit />}
            onClick={() => setOpenAlter(true)}
          >
            Alterar
          </Button>
        </div>
      </div>
      <AlterUser
        open={openAlter}
        setOpen={setOpenAlter}
        {...{ user, profiles }}
      />
      <InactivateUser
        open={openInactive}
        setOpen={setOpenInactive}
        user={user}
      />
    </>
  )
}
