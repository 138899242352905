import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { FilePond } from 'react-filepond'
import xlsxParser from 'xlsx-parse-json'
import { Button } from '@material-ui/core'

import 'filepond/dist/filepond.min.css'
import { Cached } from '@material-ui/icons'
import { filterDataAtom } from './Atoms'
import { useAtom } from 'jotai'
import { distributors } from '../../constData/urls'
import moment from 'moment'
import _ from 'lodash'

export default function ImportDataDialog({
  open,
  setOpen,
  setReportData,
  setOkAlert
}) {
  const [files, setFiles] = useState([])
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  const parseFile = () => {
    xlsxParser
      .onFileSelection(files[0][0])
      .then(data => processParsedData(data))
  }

  const processParsedData = data => {
    const sheetKey = Object.keys(data)[0]
    const newData = data[sheetKey]
    let id = 0
    let period
    let distributor

    const processData = newData.map(d => {
      const iniImported = d['Data Inicial'] || d.dataini || d['DT. INÍCIO']
      const endImported = d['Data Final'] || d.datafim || d['DT. FIM']
      const mx = iniImported.split('/')
      const my = endImported.split('/')
      let newDataIni
      let newDataFim

      if (_.isNaN(Date.parse(iniImported))) {
        newDataIni = new Date(mx[2], mx[1] - 1, mx[0])
      } else {
        newDataIni = new Date('20' + mx[2], mx[0] - 1, mx[1])
      }

      if (_.isNaN(Date.parse(endImported))) {
        newDataFim = new Date(my[2], my[1] - 1, my[0])
      } else {
        newDataFim = new Date('20' + my[2], my[0] - 1, my[1])
      }

      period = {
        value: `${moment(newDataIni).format('DD/MM/YYYY')} - ${moment(
          newDataFim
        ).format('DD/MM/YYYY')}`,
        label: `${moment(newDataIni).format('DD/MM/YYYY')} - ${moment(
          newDataFim
        ).format('DD/MM/YYYY')}`,
        key: 0
      }

      const findedDistributor = distributors.find(
        item =>
          item.distribuidor.split('/')[0].trim() ===
          (d['Distribuidor'] || d.distribuidor || d['DISTRIBUIDOR'])
            .split('/')[0]
            .trim()
      )

      distributor = {
        value: findedDistributor.distribuidor.split('/')[0].trim(),
        label: findedDistributor.distribuidor.split('/')[0].trim(),
        key: findedDistributor.id,
        url: findedDistributor.url,
        sortColumn: findedDistributor.distribuidor.split('/')[0].trim()
      }

      return {
        id: id++,
        distribuidor: d['Distribuidor'] || d.distribuidor || d['DISTRIBUIDOR'],
        sku: d['SKU'] || d.sku,
        descricao: d['Descrição'] || d.descricao || d['PRODUTO'],
        dataini: moment(newDataIni).format('DD/MM/YYYY'),
        datafim: moment(newDataFim).format('DD/MM/YYYY'),
        armazem: d['Armazem'] || d.armazem,
        limite: Number(
          d['Qtd. Trava'] || d.limite || d['QTD TRAVA (EMBALAGEM)']
        ),
        pedido: Number(d['Qtd. Comprada'] || d.pedido || d['QTD COMPRADA']),
        extra: Number(d['Qtd. Extra'] || d.extra || d['QTD EXTRA']),
        atingido: d['% Atingido'] || d.atingido || d['% ATINGIDO'],
        new_item: false,
        alter_datas: false,
        alter_limite: false,
        alter_extra: false,
        imported: true
      }
    })

    setReportData(processData)
    setFilterData({
      ...filterData,
      date_range: period,
      distribuidor: distributor
    })
    setFiles([])
    setOpen(false)
    setOkAlert(true)
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogContent>
        <FilePond
          files={files}
          onupdatefiles={fileItems => {
            let items = fileItems.map(fileItem => fileItem.file)
            setFiles([...files, items])
          }}
          allowMultiple={false}
          labelIdle='Arraste e solte seu arquivo ou <span class="filepond--label-action">Procure</span>'
        />
        <div className='flex justify-center'>
          {files.length > 0 && (
            <Button
              color='secondary'
              variant='contained'
              startIcon={<Cached />}
              onClick={parseFile}
            >
              Processar
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
