import c3 from 'c3'

export default clientes => {
  c3.generate({
    bindto: '#atingiramAlvo',
    data: {
      columns: [
        ['atingiram', clientes.realizado],
        ['nao atingiram', clientes.naoRealizado]
      ],
      type: 'gauge'
    },
    legend: {
      show: false
    },
    gauge: {
      label: {
        format: function (value, ratio) {
          return value //returning here the value and not the ratio
        }
      },
      min: 0,
      max: clientes.realizado + clientes.naoRealizado
    }
  })
}
