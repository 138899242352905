import c3 from 'c3'

export default percentualRealizado => {
  c3.generate({
    bindto: '#volumeAtingido',
    data: {
      columns: [['volume %', percentualRealizado]],
      type: 'gauge'
    },
    gauge: {
      label: {
        format: function (value, ratio) {
          return value //returning here the value and not the ratio
        }
      },
      min: 0,
      max: percentualRealizado > 100 ? percentualRealizado : 100
    },
    color: {
      pattern: ['red', 'yellow', 'green'],
      threshold: {
        values: [90, 100]
      }
    }
  })
}
