import React from 'react'

export default function ComponentContainer(props) {
  return (
    <div
      className='w-full rounded overflow-hidden shadow-lg m-auto mt-2 border border-solid border-gray-400 bg-white p-2'
      style={{ minHeight: 400 }}
    >
      {props.children}
    </div>
  )
}
