import { useAtom } from 'jotai'
import React, { useCallback, useRef, useState } from 'react'
import { rawEventsAtom } from '../Atoms'
import saveAs from 'file-saver'
import { Paper } from '@material-ui/core'
import {
  ExportPanel,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
  TableFilterRow
} from '@devexpress/dx-react-grid-material-ui'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  PagingState
} from '@devexpress/dx-react-grid'

export default function CallPlanTable() {
  const [rawEvents] = useAtom(rawEventsAtom)
  const [defaultColumnWidths] = useState([
    { columnName: 'distribuidor', width: 180 },
    { columnName: 'id', width: 180 },
    { columnName: 'vendedor', width: 180 },
    { columnName: 'nome_vendedor', width: 180 },
    { columnName: 'supervisor', width: 180 },
    { columnName: 'nome_supervisor', width: 180 },
    { columnName: 'empresa', width: 180 },
    { columnName: 'data_prevista', width: 180 },
    { columnName: 'hora_inicio', width: 180 },
    { columnName: 'hora_fim', width: 180 },
    { columnName: 'tipo_visita', width: 180 },
    { columnName: 'realizado', width: 180 },
    { columnName: 'data_conclusao', width: 180 },
    { columnName: 'proposito', width: 180 },
    { columnName: 'objetivo', width: 180 },
    { columnName: 'premissa', width: 180 },
    { columnName: 'estrategia', width: 180 },
    { columnName: 'antecipar', width: 180 },
    { columnName: 'notas', width: 180 },
    { columnName: 'motivo_naorealizado', width: 180 },
    { columnName: 'informacao_adicional', width: 180 },
    { columnName: 'conclusao', width: 180 },
    { columnName: 'volume_estimativa', width: 180 },
    { columnName: 'contato', width: 180 }
  ])
  const [pageSizes] = useState([50, 100, 200, 0])
  const [filters, setFilters] = useState([])

  const columns = [
    { name: 'distribuidor', title: 'Distribuidor' },
    { name: 'id', title: 'ID' },
    { name: 'vendedor', title: 'Cod Vendedor' },
    { name: 'nome_vendedor', title: 'Vendedor' },
    { name: 'supervisor', title: 'Cod Supervisor' },
    { name: 'nome_supervisor', title: 'Supervisor' },
    { name: 'empresa', title: 'Empresa' },
    { name: 'data_prevista', title: 'Data Prevista' },
    { name: 'hora_inicio', title: 'Hora Início' },
    { name: 'hora_fim', title: 'Hora Fim' },
    { name: 'tipo_visita', title: 'Tipo Visita' },
    { name: 'realizado', title: 'Realizado' },
    { name: 'data_conclusao', title: 'Data Conclusão' },
    { name: 'proposito', title: 'Propósito' },
    { name: 'objetivo', title: 'Objetivo' },
    { name: 'premissa', title: 'Premissa' },
    { name: 'estrategia', title: 'Estratégia' },
    { name: 'antecipar', title: 'Antecipar' },
    { name: 'notas', title: 'Notas' },
    { name: 'motivo_naorealizado', title: 'Motivo não realizado' },
    { name: 'informacao_adicional', title: 'Informação Adicional' },
    { name: 'conclusao', title: 'Conclusão' },
    { name: 'volume_estimativa', title: 'Volume Estimativa' },
    { name: 'contato', title: 'Contato' }
  ]

  const exporterRef = useRef()

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])

  const onSave = workbook => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Oportunidades.xlsx'
      )
    })
  }

  return (
    <div>
      <Paper>
        <Grid rows={rawEvents} columns={columns}>
          <PagingState defaultCurrentPage={0} defaultPageSize={50} />
          <IntegratedPaging />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering />
          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow />
          <PagingPanel
            pageSizes={pageSizes}
            messages={{ rowsPerPage: 'Linhas por página', showAll: 'Todos' }}
          />
          <TableFilterRow messages={{ filterPlaceholder: 'Filtrar' }} />
          <Toolbar />
          <ExportPanel
            startExport={startExport}
            messages={{ exportAll: 'Exportar todos os registros' }}
          />
        </Grid>
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rawEvents}
          onSave={onSave}
        />
      </Paper>
    </div>
  )
}
