import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import AddCircle from '@material-ui/icons/AddCircle'
import DialogCoachingWizard from './DialogCoachingWizard'
import { coachingQuestions as questions } from '../../../constData/constData'
import { useAtom } from 'jotai'
import { coachingDialogTypeAtom } from '../../_global/Atoms'
import { allResponsesAtom, coachingDataAtom } from './Atoms'
import { completeCoachingResponses } from '../../../functions/functions'

export default function CoachingTable() {
  const [openNewCustomerCoaching, setOpenNewCustomerCoaching] = useState(false)
  const [coachingDialogType] = useAtom(coachingDialogTypeAtom)
  const [allResponses, setAllResponses] = useAtom(allResponsesAtom)
  const [coachingData] = useAtom(coachingDataAtom)
  const [titles, setTitles] = useState([])

  useEffect(() => {
    if (coachingDialogType === 'new') {
      setAllResponses(completeCoachingResponses([]))
    }
    // eslint-disable-next-line
  }, [coachingDialogType, coachingData.clientes])

  useEffect(() => {
    if (coachingData.clientes) {
      const lista = coachingData.clientes.map(cliente => {
        return cliente.nome_cliente
      })
      setTitles(lista)
    }
  }, [coachingData.clientes])

  const HeaderRow = () => {
    return (
      <TableRow>
        <TableCell>Pergunta</TableCell>
        {[...Array(10)].map((x, i) => {
          return (
            <TableCell key={i} className='cursor-pointer'>
              <Tooltip title={titles[i] || ''}>
                <span>{i + 1}</span>
              </Tooltip>
            </TableCell>
          )
        })}
        <TableCell>% PDV executado</TableCell>
      </TableRow>
    )
  }

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <HeaderRow />
          </TableHead>
          <TableBody>
            {questions.map(question => {
              let rowKey = Math.random()
              return (
                <TableRow key={rowKey}>
                  <TableCell>{question.question}</TableCell>
                  {allResponses &&
                    allResponses.map(response => {
                      let cellKey = Math.random()
                      return (
                        <TableCell key={cellKey}>
                          {response[question.key]}
                        </TableCell>
                      )
                    })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {coachingData.data_fechamento === '' && (
        <div className='flex justify-center my-3'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddCircle />}
            className='w-9/12'
            onClick={() => {
              setOpenNewCustomerCoaching(true)
            }}
          >
            Adicionar Cliente ao Coaching
          </Button>
        </div>
      )}
      <DialogCoachingWizard
        open={openNewCustomerCoaching}
        setOpen={setOpenNewCustomerCoaching}
      />
    </Fragment>
  )
}
