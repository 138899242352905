import React from 'react'

const dateBRDiffToday = date => {
  let dateToCompare
  if (typeof data === 'string') {
    const mx = date.split('/')
    dateToCompare = new Date(mx[2], mx[1], mx[0])
  } else {
    dateToCompare = date
  }
  const today = new Date()

  const compare = Date.parse(today) - Date.parse(dateToCompare)
  return compare
}

const StatusBadge = ({ date }) => {
  const compareDates = dateBRDiffToday(date)
  const params =
    compareDates < 0
      ? { status: 'Ativa', color: 'bg-green-400' }
      : { status: 'Vencida', color: 'bg-red-400' }
  return (
    <div className={`${params.color} w-2/6 rounded p-2 flex justify-center`}>
      {params.status}
    </div>
  )
}

export default StatusBadge
