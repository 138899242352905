export const tipoNegocioList = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Franquia', value: 'Franquia' },
  { label: 'FWS SubFornecimento', value: 'FWS SubFornecimento' }
]

export const setorList = [
  { label: 'Todos', value: '*' },
  { label: 'Agronegócio', value: 'Agronegócio' },
  { label: 'Concessionária', value: 'Concessionária' },
  { label: 'Construção', value: 'Construção' },
  { label: 'Indústria', value: 'Indústria' },
  { label: 'Transporte', value: 'Transporte' }
]

export const possibilidadeNegocioList = [
  { label: 'Alta', value: 'Alta' },
  { label: 'Média', value: 'Média' },
  { label: 'Baixo', value: 'Baixo' },
  { label: 'Ganho', value: 'Ganho' },
  { label: 'Perdido', value: 'Perdido' }
]

export const big5List = [
  { label: 'Big5', value: 'Big5' },
  { label: 'Top5', value: 'Top5' },
  { label: 'Normal', value: 'Normal' }
]

export const segmentoList = [
  { label: 'Têxtil', value: 'Têxtil' },
  { label: 'Metalúrgica', value: 'Metalúrgica' },
  { label: 'Rodoviário de Cargas', value: 'Rodoviário de Cargas' },
  { label: 'Indústria Plástica', value: 'Indústria Plástica' },
  { label: 'Madeira', value: 'Madeira' }
]

export const tipoOportunidadeList = [
  { label: 'Novo Cliente', value: 'Novo Cliente' },
  { label: 'Aumento de participação', value: 'Aumento de participação' },
  { label: 'Cross-Sell', value: 'Cross-Sell' },
  { label: 'Novo Site', value: 'Novo Site' },
  { label: 'Up-Sell', value: 'Up-Sell' }
]

export const grupoProdutoList = [
  {
    label: 'Óleo para Motores a Diesel',
    value: 'Óleo para Motores a Diesel'
  },
  {
    label: 'Óleo para Sistemas Hidráulicos',
    value: 'Óleo para Sistemas Hidráulicos'
  },
  { label: 'Graxas Industriais', value: 'Graxas Industriais' }
]
