import React from 'react'
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid'
import {
  Grid,
  TableColumnResizing,
  Table,
  TableHeaderRow,
  TableFixedColumns,
  ExportPanel,
  Toolbar,
  TableTreeColumn
} from '@devexpress/dx-react-grid-material-ui'
import { PercentCell } from '../PercentCell'
import { tableColumnsAtom } from '../../_global/Atoms'
import { useAtom } from 'jotai'

const getChildRows = (row, rootRows) => (row ? row.items : rootRows)

export default function GroupGridWithValues({
  data,
  columnWidths,
  setColumnWidths,
  startExport,
  leftColumns
}) {
  const [columns] = useAtom(tableColumnsAtom)

  return (
    <Grid rows={data} columns={columns}>
      <TreeDataState />
      <CustomTreeData getChildRows={getChildRows} />
      <Table
        cellComponent={PercentCell}
        messages={{ noData: 'Nenhum dado encontrado' }}
      />

      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />

      <TableHeaderRow />
      <Toolbar />
      <ExportPanel
        startExport={startExport}
        messages={{ exportAll: 'Exportar todos os dados' }}
      />
      <TableTreeColumn for='grupo' />
      <TableFixedColumns leftColumns={leftColumns} />
    </Grid>
  )
}
