import React, { useEffect } from 'react'
import Header from '../_global/Header'
import ls from 'local-storage'
import { listSupervisorMenuItems } from '../../constData/constData'
import Accordion from '../_global/Accordion'
import { useAtom } from 'jotai'
import {
  alertParamsAtom,
  filterDataAtom,
  loadedModuleAtom
} from '../_global/Atoms'
import ControlPanel from './ControlPanel/ControlPanel'
import Coaching from './Coaching/Index'
import AlertDialog from '../_global/AlertDialog'
import ReportIndex from '../Supervisor/Reports/Index'
import Spancop from '../Spancop/Index'
import { supervisorDataAtom } from './Atoms'

const menuItems = listSupervisorMenuItems(ls.get('tipo'), ls.get('segmento'))

const SupervisorMain = () => {
  const [filterData, setFilterData] = useAtom(filterDataAtom)
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [alertParams] = useAtom(alertParamsAtom)
  const [supervisorData] = useAtom(supervisorDataAtom)
  const user = ls.get('user')

  useEffect(() => {
    setFilterData({ ...filterData, distribuidor: supervisorData.distribuidor })
    // eslint-disable-next-line
  }, [])

  const loadModule = loadedModule => {
    switch (loadedModule) {
      case 'ControlPanel':
        return <ControlPanel />
      case 'Coaching':
        return <Coaching />
      case 'SpancopIndex':
        return <Spancop />
      default:
        return <ReportIndex />
    }
  }

  return (
    <div className='w-full h-full'>
      <Header
        label='BackOffice Shell - Supervisor/SPANCOP'
        title='Shell BackOffice - Supervisor/SPANCOP'
        user={user}
        exit={true}
      />
      <div className='m-2'>
        <div className='grid gap-2 grid-cols-12'>
          <div className='col-span-2 border-gray-400 border-2 rounded p-2 text-sm'>
            {menuItems.map(item => (
              <Accordion
                key={item.name}
                title={item.label}
                items={item.items}
              />
            ))}
          </div>
          <div className='col-span-10 border-gray-400 border-2 rounded p-2 w-full'>
            {loadedModule && loadModule(loadedModule)}
          </div>
        </div>
      </div>
      <AlertDialog
        open={alertParams.open}
        text={alertParams.text}
        onClick={alertParams.onClick}
      />
    </div>
  )
}

export default SupervisorMain
