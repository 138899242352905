import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useAtom } from 'jotai'
import Axios from 'axios'
import { distributors } from '../../../constData/urls'
import {
  selectedSellerAtom,
  selectedYearAtom
} from '../Atoms'
import {
  filterDataAtom,
  alertParamsAtom
} from '../../_global/Atoms'
 
import moment from 'moment'


import { LoadingIndicator } from '../../_global/LoadingIndicator'
import  SpancopCoachingItem  from './SpancopCoachingItem'
import SpancopCoachingDialog from './SpancopCoachingDialog'
import {openSpancopCoachingDialogAtom, spancopCoachingDialogTypeAtom} from './Atom'


import ls from 'local-storage'

const codigo_supervisor = ls.get('codigo_vededor')

export default function CoachingSpancop() {

  const [coachingList, setCoachingList] = useState([])
  const [isLoading, setLoading] = useState([])
  const [newDisabled, setNewDisabled] = useState(false)
  const [openSpancopCoachingDialog, setOpenCoachingDialog] = useAtom(openSpancopCoachingDialogAtom)

  const [filterData] = useAtom(filterDataAtom)
  //eslint-disable-next-line
  const [selectedSeller, setSelectedSeller] = useAtom(selectedSellerAtom)
  //eslint-disable-next-line
  const [selectedYear, setSelectedYear] = useAtom(selectedYearAtom)
  //eslint-disable-next-line
  const [spancopCoachingDialogType,setCoachingDialogType] = useAtom(spancopCoachingDialogTypeAtom)
  //eslint-disable-next-line
  const [alertParams, setAlertParams] = useAtom(alertParamsAtom)

  const openNewCoaching = type => {
    if (!selectedSeller) {
      setAlertParams({
        open: true, 
        text: 'Selecione um vendedor.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    if (selectedSeller.value === '' || selectedSeller.value === 'Todos') {
      setAlertParams({
        open: true,
        text: 'Selecione um vendedor.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    const openedCoachings = coachingList.filter(
      coaching =>
        coaching.data_fechamento === '' && coaching.data_cancelamento === ''
    )

    if (openedCoachings.length > 0) {
      setAlertParams({
        open: true,
        text: 'Vendedor possui coaching em aberto. Finalize o coaching aberto para abrir um novo.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    const openedToday = coachingList.filter(
      coaching =>
        coaching.data_abertura === moment(new Date()).format('DD/MM/YY')
    )

    if (openedToday.length > 0) {
      setAlertParams({
        open: true,
        text: 'Um coaching já foi aberto para este vendedor na data de hoje.',
        onClick: () => {
          setAlertParams({ open: false })
        }
      })
      return false
    }

    setCoachingDialogType('new')
    //setNewCoachingType(type)
    setOpenCoachingDialog(true)

  }

  const fetchData = async () => {
    setLoading(true)
    const json = {
      distribuidor: filterData.distribuidor[0].value,
      supervisor: codigo_supervisor,
      vendedor: selectedSeller.value,
      data_inicial: `01/01/${selectedYear.value}`,
      data_fim: `31/12/${selectedYear.value}`,
      com_respostas: 'N'
    }

    const distributorData = distributors.find(
      distributor =>
        distributor.distribuidor === filterData.distribuidor[0].value
    )

    const result = await Axios.post(
      `${distributorData.url}INT_SHELL_SPANCOP_BUSCA_COACHING`,
      json,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ) 
    setCoachingList(result.data) 
    setLoading(false)
  }

  useEffect( () => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  
  useEffect( () => {
    const openedCoaching = coachingList.find(cl => cl.data_fechamento === '')

    if (openedCoaching) {
      setNewDisabled(true)
    } else {
      setNewDisabled(false)
    }
    // eslint-disable-next-line
  }, [coachingList])

  return ( 
    <Fragment>
      {/*  <SpancopCoachinResponsesForm show={true}>
        </SpancopCoachinResponsesForm> */}
          {isLoading ? (
            <LoadingIndicator />
          ) : coachingList.length > 0 ? (
            <div>
              {coachingList.map(coaching => (
                <SpancopCoachingItem data={coaching} key={coaching.id_coaching} />
              ))}
            </div>
          ) : (
             
              <Alert severity='info'>
                Nenhum dado encontrado com os filtros selecionados.
              </Alert>
            
          )} 
      <div className='flex my-3 gap-2'>
        <Button
          variant='contained'
          color='primary'
          onClick={() => openNewCoaching('B2B')}
          disabled={ newDisabled } 
        >
          + Coaching
        </Button>
      </div>
      <div>
       <SpancopCoachingDialog
        open={openSpancopCoachingDialog}
        handleClose={() => {
          fetchData()
          setOpenCoachingDialog(false)
        }}
      /> 
      </div>
    </Fragment>
  )
}
