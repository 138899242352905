import React from 'react'
import Filter from '../_global/Filter'
import Header from '../_global/Header'
import { useAtom } from 'jotai'

import { loadedModuleAtom } from '../_global/Atoms'
import { loadLabelBase } from '../../functions/functions'

export default function BaseComponent() {
  const [loadedModule] = useAtom(loadedModuleAtom)

  const labels = loadLabelBase(loadedModule)
  return (
    <div>
      <Header label={labels.label} title={labels.title} />
      <Filter />
    </div>
  )
}
