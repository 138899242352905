import React from 'react'
import FaixaFilterButton from './FaixaFilterButton'

export default function GoalDataSection({
  currentCampaign,
  activeFilter,
  setActiveFilter
}) {
  return (
    <div className='w-1/3 mx-4 border border-gray-400 rounded-md p-2'>
      <div>Faixas de Atingimento? {currentCampaign?.faixas}</div>
      <div>
        Faixa 1: {currentCampaign?.faixa_1} %{' '}
        <FaixaFilterButton
          field={currentCampaign?.faixa_1}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      </div>
      {currentCampaign?.faixas === 'SIM' && (
        <div>
          {currentCampaign?.faixa_2 !== 0 && (
            <div>
              Faixa 2: {currentCampaign?.faixa_2} %{' '}
              <FaixaFilterButton
                field={currentCampaign?.faixa_2}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </div>
          )}
          {currentCampaign?.faixa_3 !== 0 && (
            <div>
              Faixa 3: {currentCampaign?.faixa_3} %{' '}
              <FaixaFilterButton
                field={currentCampaign?.faixa_3}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </div>
          )}
          {currentCampaign?.faixa_4 !== 0 && (
            <div>
              Faixa 4: {currentCampaign?.faixa_4} %{' '}
              <FaixaFilterButton
                field={currentCampaign?.faixa_4}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </div>
          )}
          {currentCampaign?.faixa_5 !== 0 && (
            <div>
              Faixa 5: {currentCampaign?.faixa_5} %{' '}
              <FaixaFilterButton
                field={currentCampaign?.faixa_5}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
