import moment from 'moment'
import React from 'react'
import RankingFilterButton from './RankingFilterButton'

const findTP = tp => {
  const array = [
    { id: 'bl', name: 'Bateu-Levou' },
    { id: 'r', name: 'Ranking' }
  ]

  const findedItem = array.find(item => item.id === tp)
  return findedItem.name
}

export default function CampaignDataSection({
  currentCampaign,
  activeFilter,
  setActiveFilter
}) {
  return (
    <div className='w-1/3 mx-4 border border-gray-400 rounded-md p-2'>
      <div>
        Período:{' '}
        {typeof currentCampaign?.data_inicial === 'object'
          ? moment(currentCampaign?.data_inicial).format('DD/MM/YYYY')
          : currentCampaign?.data_inicial}{' '}
        a{' '}
        {typeof currentCampaign?.data_final === 'object'
          ? moment(currentCampaign?.data_final).format('DD/MM/YYYY')
          : currentCampaign?.data_final}
      </div>
      <div>
        Tipos de Apuração:
        {currentCampaign?.tipo_apuracao
          ?.filter(tipo => tipo)
          .map(tp => (
            <span
              className='mx-2 bg-gray-300 p-1 text-xs rounded-md'
              key={tp ? tp.key : Math.random()}
            >
              {tp.label ? tp.label : findTP(tp)}
            </span>
          ))}
      </div>
      {currentCampaign?.quant_ranking > 0 && (
        <div>
          Premiados no Ranking: {currentCampaign?.quant_ranking}{' '}
          <RankingFilterButton
            field={currentCampaign?.quant_ranking}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        </div>
      )}
      <div>
        Tipo de Agrupamento:{' '}
        {currentCampaign?.tipo_agrupamento?.label
          ? currentCampaign?.tipo_agrupamento?.label
          : currentCampaign?.tipo_agrupamento}
      </div>
      <div>
        Chave:{' '}
        {currentCampaign?.chave?.label
          ? currentCampaign?.chave?.label
          : currentCampaign?.chave}
      </div>
      <div>
        Segmento:{' '}
        {currentCampaign?.segmento?.label
          ? currentCampaign?.segmento?.label
          : currentCampaign?.segmento}
      </div>
      <div>
        Acompanhamento Por:{' '}
        {currentCampaign?.acompanhamento_por?.label
          ? currentCampaign?.acompanhamento_por?.label
          : currentCampaign?.acompanhamento_por}
      </div>
    </div>
  )
}
