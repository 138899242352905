import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import InputForm from '../Inputs/InputForm'

export default function CampaignRangeFields({ campaign, handleChange }) {
  const ids = [
    { id: 'faixa_1', label: '1' },
    { id: 'faixa_2', label: '2' },
    { id: 'faixa_3', label: '3' },
    { id: 'faixa_4', label: '4' },
    { id: 'faixa_5', label: '5' }
  ]

  return (
    <div className='flex flex-row items-center justify-between gap-2 mt-2 mb-3'>
      <div className='w-1/6'>
        <label
          className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
        >
          Configurar Faixas (%) <FontAwesomeIcon icon={faArrowCircleRight} />
        </label>
      </div>
      {ids.map(item => {
        return (
          <div className='w-1/6'>
            <InputForm
              id={item.id}
              label={item.label}
              defaultValue={campaign?.[item.id]}
              onChange={handleChange}
              disabledinput={item.id === 'faixa_1' ? 'true' : 'false'}
              bg={item.id === 'faixa_1' ? 'bg-gray-200' : 'bg-white'}
            />
          </div>
        )
      })}
    </div>
  )
}
