import { useAtom } from 'jotai'
import React from 'react'
import TDItems from '../Table/TDItems'
import { reportDataAtom } from '../_global/Atoms'
import HeaderItems from './HeaderItems'
import StickyTable from 'react-sticky-table-thead'

export default function DataTable() {
  let j = 999999
  // eslint-disable-next-line
  const [reportData, setReportData] = useAtom(reportDataAtom)
  return (
    <div className='overflow-x-auto overflow-y-auto w-full mt-4'>
      <StickyTable height={550}>
        <table className='relative w-full border hover'>
          <thead>
            <HeaderItems />
          </thead>
          <tbody>
            {reportData.length > 0 &&
              reportData.map(item => {
                return <TDItems item={item} key={++j} />
              })}
            {reportData.length === 0 && (
              <tr>
                <td colSpan='28' className='text-red-700 text-sm'>
                  Não há dados.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </StickyTable>
    </div>
  )
}
