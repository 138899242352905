import React from 'react'
import { useAtom } from 'jotai'
import { filterDataAtom } from '../_global/Atoms'

import ReactSelect from '../Inputs/ReactSelect'
import { getDistributors } from '../../constData/constData'

import { updateSelect } from '../../functions/functions'

export default function Form() {
  const [filterData, setFilterData] = useAtom(filterDataAtom)

  return (
    <form className='w-full mt-2' id='formFilter'>
      <div className='flex flex-wrap  mb-4'>
        <div className='w-full sm:w-full md:w-full lg:w-full xl:w-full px-3'>
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'distribuidor', filterData, setFilterData)
            }}
            value={filterData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={getDistributors()}
            allowSelectAll={true}
            isMulti
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
        </div>
      </div>
    </form>
  )
}
