import React from 'react'

import Header from '../_global/Header'
import Filter from './Filter'

export const LimitePC = () => (
  <div>
    <Header label='Limite de PC' title='Shell - Limite de PC' />
    <Filter />
  </div>
)

export default LimitePC
