import axios from 'axios'
import dotenv from 'dotenv'

dotenv.config()

const apiUrl = process.env.REACT_APP_BACKEND_ADDRESS

const api = {
  login: async json => {
    return await axios.post(`${apiUrl}/login`, json)
  },
  validate: async json => {
    return await axios.post(`${apiUrl}/validate-session`, json)
  },
  createUser: async json => {
    return await axios.post(`${apiUrl}/user`, json)
  },
  loadSupervisors: async distributor => {
    return await axios.get(
      `${distributor.url}INT_SHELL_APP_VENDEDORES?distribuidor=${distributor.value}`,
      {
        headers: {
          'Accept-Language': 'pt-BR,en;q=0.9'
        }
      }
    )
  },
  loadUsers: async () => {
    return await axios.get(`${apiUrl}/users`)
  },

  updateUser: async json => {
    return await axios.patch(`${apiUrl}/user`, json)
  }
}

export default api
