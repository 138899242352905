//import { useAtom } from 'jotai'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
//import { usePromiseTracker } from 'react-promise-tracker'
//import { researchAtoms } from './Atoms'
import AlertDialog from '../_global/AlertDialog'
import { researchAtoms } from './Atoms'
import { distributors } from '../../constData/urls.js'
import Axios from 'axios'

export default function SendContainer() {
  const [research] = useAtom(researchAtoms)
  const [openAlert, setOpenAlert] = useState(false)
  const [researchResult, setResearchResult] = useState('')

  //const { promiseInProgress } = usePromiseTracker()

  const handleSendResearch = async () => {
    const { url } = distributors.find(
      distributor => distributor.id === Number(research.id)
    )

    const { data } = await Axios.post(
      `${url}INT_SHELL_RETORNO_PESQUISA`,
      research,
      {
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
    )
    setResearchResult(data.retorno)
    setOpenAlert(true)
  }
  const btnClass = `h-10 pr-10 pl-10 text-indigo-100 transition-colors duration-150 rounded-md focus:shadow-outline border bg-green-500`
  return (
    <div className='text-center p-3'>
      <button className={btnClass} type='button' onClick={handleSendResearch}>
        Enviar
      </button>
      <AlertDialog
        open={openAlert}
        text={researchResult}
        onClick={() => {
          setOpenAlert(false)
          setResearchResult('')
        }}
      />
    </div>
  )
}
