import React, { useState } from 'react'
import ReactSelect from './Inputs/ReactSelect'
import Header from './Login/Header'
import { distributors } from '../constData/urls'
import InputForm from './Inputs/InputForm'
import Button from './Login/Button'
import api from '../api/api'
import { useAtom } from 'jotai'
import Alert from '@material-ui/lab/Alert'
import ls from 'local-storage'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { Fragment } from 'react'
import { supervisorDataAtom, vendedorDataAtom } from './Supervisor/Atoms'

export default function LoginSupervisor() {
  const [authData, setAuthData] = useState({
    distribuidor: '',
    user: '',
    pwd: ''
  })
  const [distributorData, setDistributorData] = useState([])
  // eslint-disable-next-line
  const [supervisorData, setSupervisorData] = useAtom(supervisorDataAtom)
  // eslint-disable-next-line
  const [vendedorData, setVendedorData] = useAtom(vendedorDataAtom)
  const [loginError, setLoginError] = useState({})
  const [processing, setProcessing] = useState(false)
  const [success, setSuccess] = useState(false)
  const { promiseInProgress } = usePromiseTracker()

  const handleSelectChange = (v, id) => {
    setAuthData({ ...authData, [id]: v })
    const getData = async () => {
      const { data } = await trackPromise(api.loadSupervisors(v))
      setDistributorData(data)
    }
    getData()
  }

  const handleChange = e => {
    const { id, value } = e.target
    setAuthData({ ...authData, [id]: value })
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  const handleClick = async () => {
    setProcessing(true)
    const filterSupervisor = distributorData.find(
      d =>
        d.codigo_vededor === authData.user &&
        d.senha === authData.pwd &&
        d.tipo === 'SUPERVISOR'
    )

    const filterVendedor = distributorData.find(
      d =>
        d.codigo_vededor === authData.user &&
        d.senha === authData.pwd &&
        d.tipo === 'VENDEDOR' &&
        (d.segmento === 'FWS' || d.segmento === 'B2B')
    )

    if (!filterSupervisor && !filterVendedor) {
      setLoginError({ error: true, msg: 'Usuário ou senha inválidos' })
      setProcessing(false)
      return false
    }

    if (filterVendedor) {
      const sellers = distributorData.filter(
        d =>
          d.tipo === 'VENDEDOR' &&
          d.codigo_vededor === filterVendedor.codigo_vededor
      )
      await setVendedorData({ ...filterVendedor })
      setProcessing(false)
      setSuccess(true)
      ls.set('codigo_vededor', filterVendedor.codigo_vededor)
      ls.set('codigo_supervisor', filterVendedor.codigo_supervisor)
      ls.set('user', filterVendedor.nome_vededor)
      ls.set('tipo', filterVendedor.tipo)
      ls.set('perfis', [authData.distribuidor])
      ls.set('session', Math.random().toString(36).substr(2, 9))
      ls.set('sellers', sellers)
      ls.set('segmento', filterVendedor.segmento)
      setTimeout(() => {
        window.location = '/supervisor/Home'
      }, 2000)
      return false
    }

    if (filterSupervisor) {
      const sellers = distributorData.filter(
        d =>
          d.tipo === 'VENDEDOR' &&
          d.codigo_supervisor === filterSupervisor.codigo_vededor
      )
      await setSupervisorData({
        supervisor: filterSupervisor,
        sellers: sellers,
        distribuidor: authData.distribuidor
      })
      setProcessing(false)
      setSuccess(true)
      ls.set('codigo_vededor', filterSupervisor.codigo_vededor)
      ls.set('user', filterSupervisor.nome_vededor)
      ls.set('tipo', filterSupervisor.tipo)
      ls.set('perfis', [authData.distribuidor])
      ls.set('sellers', sellers)
      ls.set('session', Math.random().toString(36).substr(2, 9))
      ls.set('segmento', filterSupervisor.segmento)
      setTimeout(() => {
        window.location = '/supervisor/Home'
      }, 2000)
      return false
    }
  }

  const listDistributors = () => {
    let x = 0
    let distributorList = []
    distributors.forEach(distributor => {
      const exp = distributor.distribuidor.split('/')
      distributorList.push({
        value: distributor.distribuidor,
        label: distributor.distribuidor,
        key: ++x,
        sortColumn: exp[0].trim() + exp[1].trim(),
        url: distributor.url
      })
    })
    return distributorList
  }

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96'>
        <Header title='Login Supervisor / SPANCOP' />
        <hr />
        <div className='mb-4 mt-3'>
          <ReactSelect
            onChange={v => {
              handleSelectChange(v, 'distribuidor')
            }}
            value={authData.distribuidor}
            label='Distribuidor *'
            id='distribuidor'
            options={listDistributors()}
            allowSelectAll={false}
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
          {promiseInProgress ? (
            <Alert severity='info'>
              <FontAwesomeIcon icon={faCircleNotch} spin /> Aguarde...
            </Alert>
          ) : (
            <Fragment>
              <div className='mb-4 mt-3'>
                <InputForm
                  id='user'
                  label='Código do Supervisor ou Vendedor'
                  placeholder='Digite seu código'
                  value={authData.user}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className='mb-6'>
                <InputForm
                  id='pwd'
                  label='Senha'
                  type='password'
                  placeholder='******************'
                  value={authData.pwd}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </div>

              <div className='flex items-center justify-center'>
                <Button handleClick={handleClick} />
              </div>
            </Fragment>
          )}
          <div className='mt-4 p-4 text-xs'>
            {processing && (
              <Alert severity='info'>
                <FontAwesomeIcon icon={faCircleNotch} spin /> Aguarde...
              </Alert>
            )}
            {success && (
              <Alert severity='success'>
                Login realizado com sucesso! Entrando no sistema...
              </Alert>
            )}
            {loginError && loginError.error && (
              <Alert severity='error'>{loginError.msg}</Alert>
            )}
          </div>
        </div>
      </form>
      <a href='/' className='text-md text-blue-400 hover:text-blue-600'>
        Ir para Login PIV
      </a>
    </div>
  )
}
