import React from 'react'
import {
  Box,
  CircularProgress,
  // LinearProgress,
  Typography
} from '@material-ui/core'

// const LinearProgressWithLabel = props => {
//   return (
//     <Box display='flex' alignItems='center'>
//       <Box width='100%' mr={1}>
//         <LinearProgress variant='determinate' {...props} />
//       </Box>
//       <Box minWidth={35}>
//         <Typography
//           variant='body2'
//           color='textSecondary'
//         >{`${props.value.toFixed(1)}%`}</Typography>
//       </Box>
//     </Box>
//   )
// }

const CircularProgressWithLabel = props => {
  const drawValue = Math.round(props.value > 100 ? 100 : props.value)

  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        variant='determinate'
        value={drawValue}
        style={{
          color:
            drawValue < 90
              ? 'red'
              : drawValue >= 90 && drawValue < 100
              ? 'yellow'
              : 'green'
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'
        >{`${Math.round(props.label)}%`}</Typography>
      </Box>
    </Box>
  )
}

const PercentageGraph = ({ obj, realiz }) => {
  const value = (parseFloat(realiz) / parseFloat(obj)) * 100
  return <CircularProgressWithLabel value={value} label={value} />
}

export default PercentageGraph
