export const horarioList = [
  { label: '07:00', value: '07:00' },
  { label: '07:30', value: '07:30' },
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
  { label: '20:30', value: '20:30' },
  { label: '21:00', value: '21:00' },
  { label: '21:30', value: '21:30' },
  { label: '22:00', value: '22:00' }
]

export const tipoVisitaList = [
  { label: 'Prospecção', value: 'Prospecção' },
  { label: 'Relacionamento/Manutenção', value: 'Relacionamento/Manutenção' }
]

export const motivoNaoRealizacaoList = [
  { label: 'Selecione...', value: '' },
  { label: 'Cancelado pelo Cliente', value: 'Cancelado pelo Cliente' },
  { label: 'Cancelado pelo Consultor', value: 'Cancelado pelo Consultor' },
  {
    label: 'Cliente não Atendeu na hora e data marcada',
    value: 'Cliente não Atendeu na hora e data marcada'
  },
  { label: 'Questões de HSSE', value: 'Questões de HSSE' },
  { label: 'Remarcado pelo Cliente', value: 'Remarcado pelo Cliente' },
  { label: 'Remarcado pelo Consultor', value: 'Remarcado pelo Consultor' }
]

export const realizadoList = [
  { label: 'Selecione...', value: '' },
  { label: 'Realizado', value: 'S' },
  { label: 'Não Realizado', value: 'N' }
]

export const tipoNegocioList = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Franquia', value: 'Franquia' },
  { label: 'FWS SubFornecimento', value: 'FWS SubFornecimento' }
]

export const setorList = [
  { label: 'Agronegócio', value: 'Agronegócio' },
  { label: 'Concessionária', value: 'Concessionária' },
  { label: 'Construção', value: 'Construção' },
  { label: 'Indústria', value: 'Indústria' },
  { label: 'Transporte', value: 'Transporte' }
]

export const big5List = [
  { label: 'Big5', value: 'Big5' },
  { label: 'Top5', value: 'Top5' },
  { label: 'Normal', value: 'Normal' }
]

export const segmentoList = [
  { label: 'Têxtil', value: 'Têxtil' },
  { label: 'Metalúrgica', value: 'Metalúrgica' },
  { label: 'Rodoviário de Cargas', value: 'Rodoviário de Cargas' },
  { label: 'Indústria Plástica', value: 'Indústria Plástica' },
  { label: 'Madeira', value: 'Madeira' }
]
