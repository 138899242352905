import { useAtom } from 'jotai'
import React, { useState, useEffect } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Alert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

import {
  loadCampaignDates,
  loadFinanceData,
  loadLabelBase
} from '../../../functions/functions'
import ReactSelect from '../../Inputs/ReactSelect'
import { loadedModuleAtom, tempCampaignListAtom } from '../../_global/Atoms'
import { default as Heading } from '../../_global/Header'
import CampaignHeaderSection from '../CampaignHeaderSection'
import { currentCampaignAtom } from '../../_global/Atoms'
import ChartSection from './ChartSection'
import {
  financeData3MonthsAtom,
  financeDataAtom,
  financeDataLastYearAtom
} from '../CampaignAtoms'
import { LoadingIndicator } from '../../_global/LoadingIndicator'
import { transformData } from '../../../functions/campaign/functions'
import Axios from 'axios'
import moment from 'moment'

export default function Index() {
  const [loadedModule] = useAtom(loadedModuleAtom)
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const [campaignList, setCampaignList] = useAtom(tempCampaignListAtom)
  const [campaignSelect, setCampaignSelect] = useState([])
  const [currentCampaign, setCurrentCampaign] = useAtom(currentCampaignAtom)
  //eslint-disable-next-line
  const [financeData, setFinanceData] = useAtom(financeDataAtom)
  // eslint-disable-next-line
  const [financeDataLastYear, setFinanceDataLastYear] = useAtom(
    financeDataLastYearAtom
  )
  //eslint-disable-next-line
  const [financeData3Months, setFinanceData3Months] = useAtom(
    financeData3MonthsAtom
  )
  const [viewErrors, setViewErrors] = useState(false)
  const [errors, setErrors] = useState([])
  const { promiseInProgress } = usePromiseTracker()

  const updateSelect = v => {
    setSelectedCampaign(v)
    const current = campaignList.find(item => item.id === v.value)
    setCurrentCampaign(current)
  }

  useEffect(() => {
    const getData = async () => {
      const json = {
        id: '',
        usuario: ''
      }
      const { data } = await trackPromise(
        Axios.post(
          'https://lubpar-prd-protheus.totvscloud.com.br:10819/rest/INT_SHELL_BUSCA_TRACKING',
          json,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
      )
      setCampaignList(transformData(data))
    }
    getData()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const list = campaignList.map(item => {
      return {
        label: `${moment(item.data_inicial).format('DD/MM/YYYY')} - ${moment(
          item.data_final
        ).format('DD/MM/YYYY')} : ${item.name}`,
        value: item.id,
        key: item.id
      }
    })
    setCampaignSelect(list)
  }, [campaignList])

  useEffect(() => {
    if (Object.keys(currentCampaign).length > 0) {
      const promises = [
        getFinanceData('current'),
        getFinanceData('lastYear'),
        getFinanceData('3Months')
      ]
      Promise.all(promises)
    }
    // eslint-disable-next-line
  }, [currentCampaign])

  const labels = loadLabelBase(loadedModule)

  const getFinanceData = async type => {
    const dates = loadCampaignDates(currentCampaign, type)

    const result = await trackPromise(
      loadFinanceData(
        dates.data_inicial,
        dates.data_final,
        currentCampaign.acompanhamento_por
      )
    )

    switch (type) {
      case 'current':
        setFinanceData(result.data)
        break
      case 'lastYear':
        setFinanceDataLastYear(result.data)
        break
      case '3Months':
        setFinanceData3Months(result.data)
        break
      default:
        setFinanceData(result.data)
    }

    setErrors(result.errors)
  }

  const ErrorsDialog = ({ open, setOpen }) => (
    <Dialog open={open}>
      <DialogTitle>Lista de endpoints com erro</DialogTitle>
      <DialogContent>
        {errors.map(error => {
          const jsonError = JSON.parse(error.config.data)
          return (
            <Alert severity='error' className='my-1' key={Math.random()}>
              <div>Distribuidor: {jsonError.distribuidor}</div>
              <div>URL: {error.config.url}</div>
              <div>Erro: {error.message}</div>
            </Alert>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          startIcon={<Close />}
          onClick={() => setOpen(false)}
        ></Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <div>
      <Heading label={labels.label} title={labels.title} />
      <div>
        {campaignList.length > 0 ? (
          <ReactSelect
            onChange={v => {
              updateSelect(v, 'selectedCampaign')
            }}
            value={selectedCampaign}
            label='Campanha *'
            id='campaign'
            options={campaignSelect}
            placeholder='Selecione...'
            maxMenuHeight={200}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <div>
        {Object.keys(currentCampaign).length > 0 && <CampaignHeaderSection />}
      </div>
      {errors.length > 0 && (
        <div className='mt-2 mb-2'>
          <Alert severity='error'>
            Alguns endpoints apresentaram erro ao buscar os dados.{' '}
            {viewErrors ? (
              <span
                onClick={() => setViewErrors(false)}
                className='cursor-pointer hover:underline'
              >
                Esconder Lista
              </span>
            ) : (
              <span
                onClick={() => setViewErrors(true)}
                className='cursor-pointer hover:underline'
              >
                Ver Lista
              </span>
            )}
          </Alert>
        </div>
      )}
      <div>
        {Object.keys(currentCampaign).length > 0 ? (
          promiseInProgress ? (
            <LoadingIndicator />
          ) : (
            <ChartSection />
          )
        ) : (
          <div>
            <Alert severity='info'>Selecione uma campanha</Alert>
          </div>
        )}
      </div>
      <ErrorsDialog open={viewErrors} setOpen={setViewErrors} />
    </div>
  )
}
